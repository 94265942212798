<script>
import {useFileManagerStore} from "@/store/fileManangerStore";
import config from "@/config";
import FolderComponent from "@/components/fileManager/FolderComponent.vue";
import FileComponent from "@/components/fileManager/FileComponent.vue";
import UploadButton from "@/components/fileManager/UploadButton.vue";

export default {
  name: "FileManager",
  components: {UploadButton, FileComponent, FolderComponent},
  data() {

    return {
      config,
      loading: false
    }
  },
  setup() {
    const fileMangerStore = useFileManagerStore()
    return {
      fileMangerStore
    }
  },
  mounted() {
    this.fetchFiles("base")
    this.fileMangerStore.fetchSpaceAvailable()
  },
  methods: {
    fetchFiles(folder = null) {
      this.loading = true
      this.fileMangerStore.getFilesFetch(folder).finally(() => {
        this.loading = false
      })
    },
    createNewFolder() {
      this.fileMangerStore.createFolder().then(() => {
        this.fetchFiles()
      })
    }
  },
  watch: {
    '$route'() {
       this.fetchFiles()
    }
  },
  computed: {
    files() {
      return this.fileMangerStore.getFiles
    },
    folders() {
      return this.fileMangerStore.getFolders
    },
    spaceAvailable() {
      return this.fileMangerStore.getSpaceAvailable
    },
    percentUsed() {
      return (this.spaceAvailable.maxSpaceAvailable - this.spaceAvailable.spaceAvailable) / this.spaceAvailable.maxSpaceAvailable * 100
    }
  }
}
</script>

<template>
  <div class="page-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">
              <div class="row mb-3">
                <div class="col-lg-4 col-sm-6">
                  <button v-if="this.fileMangerStore.getFolderHistory.length > 1" @click="this.fileMangerStore.goBack" class="btn btn-primary">
                    <i class="mdi mdi-arrow-left me-1"></i> {{$t('app.back')}}
                  </button>
                </div>
                <div class="col-lg-8 col-sm-6">
                  <div class="mt-4 mt-sm-0 d-flex align-items-center justify-content-sm-end">

                    <div class="mb-2 me-2">
                      <div class="dropdown">
                        <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                          <i class="mdi mdi-plus me-1"></i> {{$t('app.new')}}
                        </button>
                        <div class="dropdown-menu dropdown-menu-end">
                          <a class="dropdown-item" href="#" @click="createNewFolder"><i class="mdi mdi-folder-outline me-1"></i> {{$t('app.folder')}}</a>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <h5 class="font-size-16 me-3 mb-0">My Files</h5>
              <div class="row mt-4">
                <div class="col-xl-4 col-sm-6">
                  <div class="card shadow-none border">
                    <div class="card-body p-3">
                      <div class="">

                        <div class="d-flex align-items-center">
                          <div class="avatar align-self-center me-3">
                            <div class="avatar-title rounded bg-soft-primary text-primary font-size-24">
                              <i class="mdi mdi-content-save"></i>
                            </div>
                          </div>

                          <div class="dropdown float-end" style="z-index: 99999999">
                            <a class="text-muted dropdown-toggle font-size-16" href="#" role="button"
                               data-bs-toggle="dropdown" aria-haspopup="true">
                              <i class="bx bx-dots-vertical-rounded font-size-20"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-end">
                              <a class="dropdown-item" href="#">Edit</a>
                              <a class="dropdown-item" href="#">Action</a>
                              <a class="dropdown-item" href="#">Remove</a>
                            </div>
                          </div>

                          <div class="flex-1">
                            <h5 class="font-size-15 mb-1">{{$t('app.spaceAvailable')}}</h5>
                          </div>
                        </div>
                        <div class="mt-3 pt-1">
                          <div class="d-flex justify-content-between">
                            <p class="text-muted font-size-13 mb-1">{{(spaceAvailable.maxSpaceAvailable - spaceAvailable.spaceAvailable).toFixed(2)}} MB</p>
                            <p class="text-muted font-size-13 mb-1">{{spaceAvailable.maxSpaceAvailable}} MB</p>
                          </div>
                          <div class="progress animated-progess custom-progress">
                            <div class="progress-bar bg-gradient bg-primary" role="progressbar" :style="{width: percentUsed+'%'}"
                                 aria-valuenow="90" aria-valuemin="0" aria-valuemax="90">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end row -->

              <h5 class="font-size-16 me-3 mb-0">Folders</h5>
              <div class="row mt-4" v-if="!loading">
                <FolderComponent  :folder="folder" v-for="folder in folders" :key="folder.name"/>
                <div v-if="folders.length === 0" class="text-center">
                  <span >{{$t('app.noFolders')}}</span>
                </div>
              </div>
              <!-- end row -->
              <div class="d-flex flex-wrap justify-content-between">
                <h5 class="font-size-16 me-3">Files</h5>
                <UploadButton/>
              </div>
              <hr class="mt-2">
              <div class="row mt-4" v-if="!loading">
                <FileComponent :config="config" :file="file" v-for="file in files" :key="file.name"/>
                <div v-if="files.length === 0" class="text-center">
                  <span >{{$t('app.noFiles')}}</span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.search-box .form-control {
  border-radius: 10px;
  padding-left: 40px
}

.search-box .search-icon {
  position: absolute;
  left: 13px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  fill: #545965;
  width: 16px;
  height: 16px
}

.card {
  margin-bottom: 24px;
  -webkit-box-shadow: 0 2px 3px #e4e8f0;
  box-shadow: 0 2px 3px #e4e8f0;
}

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #eff0f2;
  border-radius: 1rem;
}

.me-3 {
  margin-right: 1rem !important;
}



.font-size-24 {
  font-size: 24px !important;
}

.avatar-title {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #3b76e1;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}

.bg-soft-info {
  background-color: rgba(87, 201, 235, .25) !important;
}

.bg-soft-primary {
  background-color: rgba(59, 118, 225, .25) !important;
}

.avatar-xs {
  height: 1rem;
  width: 1rem
}

.avatar-sm {
  height: 2rem;
  width: 2rem
}

.avatar {
  height: 3rem;
  width: 3rem
}

.avatar-md {
  height: 4rem;
  width: 4rem
}

.avatar-lg {
  height: 5rem;
  width: 5rem
}

.avatar-xl {
  height: 6rem;
  width: 6rem
}

.avatar-title {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #3b76e1;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%
}

.avatar-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 8px
}

.avatar-group .avatar-group-item {
  margin-left: -8px;
  border: 2px solid #fff;
  border-radius: 50%;
  -webkit-transition: all .2s;
  transition: all .2s
}

.avatar-group .avatar-group-item:hover {
  position: relative;
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px)
}

.fw-medium {
  font-weight: 500;
}

a {
  text-decoration: none !important;
}
</style>