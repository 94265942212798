<script>
import {ErrorHandler} from "@/utils/ErrorHandler";
import axios from "@/boostrap/apiConfig";
import {NotifyHandler} from "@/utils/NotifyHandler";
export default {
  name: "FIleUpload",
  props: {
    mode: {
      type: String,
      default: 'file', // or 'image'
      validator: (value) => ['image', 'file'].includes(value)
    },
    uploadUrl: {
      type: String,
      required: true
    },
    initialValues: {
      type: Object,
      default: () => {
      }
    },
    field: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    initialImage(){
      let fieldName =  this.field.name
      if (this.initialValues && this.initialValues[fieldName]){
        return this.initialValues[fieldName]
      }
      return null
    }
  },
  methods: {
    triggerInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.uploadFile(file);
      }
    },
    async uploadFile(file) {
      const formData = new FormData();
      formData.append('file', file);

      try {
        await axios.post(this.uploadUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(() => {
          NotifyHandler.handle( "success","Arquivo enviado com sucesso", )
        })
      } catch (error) {
        ErrorHandler.handle(error, "Erro ao fazer upload do arquivo")
      }
    }
  }
}
</script>

<template>
    <div class="container">
      <div :class="['drop-area', mode === 'image' ? 'image-mode' : 'file-mode']">
        <input type="file" @change="handleFileChange" :accept="mode === 'image' ? 'image/*' : '*/*'" hidden ref="fileInput">
        <div class="text-center" @click="triggerInput">
          <p>{{$tc('app.dragAndDrop',{"mode": $t('app.'+mode)})}}</p>
          <img v-if="initialImage && mode === 'image'" :src="initialImage" alt="Initial Image" :width="field.imageWidth" :height="field.imageHeight">
        </div>
      </div>
    </div>
</template>

<style scoped>
.drop-area {
  border: 2px dashed #6c757d; /* Bootstrap's secondary color */
  border-radius: 0.25rem; /* Bootstrap border-radius */
  padding: 20px;
  cursor: pointer;
  background-color: #f8f9fa; /* Bootstrap's light background */
  transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.drop-area:hover {
  border-color: #007bff; /* Bootstrap's primary color */
  background-color: #e9ecef; /* Slightly darker background on hover */
}

.drop-area p {
  margin: 0;
  font-size: 1rem;
  color: #495057; /* Bootstrap's default text color */
}

.drop-area img {
  max-width: 100%;
  height: auto;
  border-radius: 0.25rem; /* Rounded corners for the image */
  margin-top: 15px;
}

.image-mode {
  text-align: center;
}

.image-mode .drop-area {
  height: 250px; /* Fixed height for image mode */
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-mode .drop-area {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px; /* Shorter height for file mode */
}
</style>