import {defineStore} from "pinia";
import axios from "@/boostrap/apiConfig";
import {ErrorHandler} from "@/utils/ErrorHandler";

export const useMyCompany = defineStore({
    id: 'myCompanyStore',
    state: () => ({
        myCompany: null,
    }),
    getters: {
        getMyCompany() {
            return this.myCompany;
        }
    },
    actions: {
        async fetchMyCompany() {
            return axios.get('account').then((response) => {
                this.myCompany = response.data
            }).catch((error) => {
                ErrorHandler.handle(error)
            })
        },
        async updateMyCompany(payload) {
            return axios.put('account', payload).then((response) => {
                this.myCompany = response.data
            }).catch((error) => {
                ErrorHandler.handle(error)
                throw error
            })
        }
    },

})