<template>
  <div class="col-md-12 mt-4" v-if="!loading" v-auto-animate>
    <h3 class="text-center">{{$t('pdv.openedCarts')}}</h3>
    <div class="row" v-if="this.posStore.getCarts.length > 0">
      <div class="col-md-12 col-lg-6 col-xxl-3" v-for="cart in this.posStore.getCarts" :key="cart.id" v-auto-animate>
        <div class="card">
          <div class="card-body row justify-content-between">
            <div class="col-sm-12 col-md-8">
              <span class="d-block">
                    <strong>{{$t('app.customer')}}</strong> {{ cart.customer.firstname }}
              </span>
              <span class="d-block">
                <strong>{{$t('app.items')}}</strong> {{ cart.products.length }}
              </span>
              <span>
                <strong>{{$t('app.createdAt')}} </strong> <span v-format-date="cart.created_at"></span>
              </span>
            </div>
            <div class="col-4 d-flex justify-content-end gap-1">
              <button @click="selectCart(cart)" class="btn btn-primary">
                <i class="mdi mdi-cart"></i>
              </button>
              <button @click="deleteCart(cart)" class="btn btn-danger">
                <i class="mdi mdi-trash-can"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-md-12 text-center mt-4">
        <h4>{{$t('pdv.noOpenedCarts')}}</h4>
      </div>
    </div>
  </div>
  <div v-else class="col-md-12 mt-4 text-center">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>
<script>
import {usePosStore} from "@/store/PosStore";
import {useCashierStore} from "@/store/cashierStore";

export default {
  name: 'OpenedCarts',
  setup(){
    const posStore = usePosStore()
    const cashierStore = useCashierStore()
    return {
      posStore,
      cashierStore
    }
  },
  data() {
    return {
      loading: false
    }
  },
  directives: {
    formatDate(el, binding) {
      el.innerHTML = new Date(binding.value).toLocaleDateString()
    }
  },
  methods: {
    deleteCart(cart) {
      this.posStore.deleteCart(cart)
    },
    selectCart(cart) {
      if (!this.cashierStore.getCashierIsOpen){
        this.$notify({
          text: this.$t('cashier.alerts.cashierIsClosed'),
          type: 'error'
        })
        return
      }
      this.posStore.selectCart(cart)
    },
  },
  beforeMount() {
    this.loading = true
    this.posStore.retrieveCarts().finally(() => {
      this.loading = false
    })
  }
}
</script>
<style scoped>
::selection {
  background: #d5bbf7;
}

.card input[type="radio"] {
  display: none;
}
</style>