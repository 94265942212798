

export const directives = {
    currency: {
        mounted(el, binding) {
            el.textContent = parseFloat(binding.value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        },
        updated(el, binding) {
            el.textContent = parseFloat(binding.value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        }
    },
};
