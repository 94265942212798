<template>
  <div class="page-content">
    <div class="container-fluid">
      <h1>Termos</h1>
      <TermBox :title="title"  @accepted="onAccepted" @declined="onDeclined"/>
    </div>
  </div>
</template>

<script>


import TermBox from "@/components/terms/TermBox.vue";

export default {
  name: 'TermPage',
  components: {
    TermBox
  },
  data() {
    return {
      title: 'Termos de Serviço',

    }
  },
  methods: {
    onAccepted() {
      console.log('Termos aceitos');
      localStorage.setItem('terms', 'accepted');
      this.$router.push({name: 'saleStarter'});
    },
    onDeclined() {
      console.log('Termos recusados');
      this.$notify('Você precisa aceitar os termos para continuar');
    }
  }
}
</script>
