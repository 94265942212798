<template>
  <!--  <EditableTable :data="items" :columns="columns" @saveRow="save" @deleteRow="handleDelete">-->

  <!--  </EditableTable>-->
  <div class="container-fluid" :oncontextmenu="showContextMenu">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link" href="#" @click="newRow" v-wave><em class="fa fa-plus-circle"></em></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#" @click="getFreights" v-wave><em class=" fa fa-refresh"></em></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#" @click="saveAll" v-wave v-if="unSaved"> {{ $tc('app.unsavedItems', {items: unSavedItems})}} <em class=" fa fa-save"> </em></a>
        </li>
      </ul>

    </nav>
    <vue-excel-editor v-model="items" filter-row autocomplete @select="showMenu" ref="grid"
                      v-click-outside-element="closeMenu" @update="updateTable">
      <vue-excel-column :field="column.field" :label="column.label" :type="column.type" :invisible="column.invisible"
                        :change="column.change" :autoFillWidth="true" key-field :to-text="column.format"
                        :mandatory="column.required"
                        v-for="column in columns" :key="column.field"/>
    </vue-excel-editor>
  </div>
  <context-menu
      :show="show"
      :options="optionsComponent"
  >
    <context-menu-item label="Apagar" @click="handleDelete"/>
    <context-menu-item label="Adicionar Linha" v-if="isLast" @click="newRow"/>
  </context-menu>
</template>
<script>


import {ContextMenu, ContextMenuItem} from '@imengyu/vue3-context-menu'
import '@imengyu/vue3-context-menu/lib/vue3-context-menu.css'

export default {
  name: 'FreightTable',
  components: {ContextMenu, ContextMenuItem},
  data() {
    return {
      columns: [
        {
          label: 'ID',
          field: 'id',
          invisible: true,
          type: 'number',
          rules: {
            required: true,
          }
        },
        {
          label: 'Região',
          field: 'region',
          type: 'string',
          rules: {
            required: true,
          }
        },
        {
          label: 'CEP Inicial',
          field: 'initial_zip_range',
          type: 'number',
          rules: {
            required: true,
          },
          change: (value) => {
            if (isNaN(value)) {
              return false
            }
            if (value.length !== 8) {
              return false
            }
          },
          format: (value) => {
            return value.replace(/(\d{5})(\d{3})/, '$1-$2')
          },
        },
        {
          label: 'CEP Final',
          field: 'final_zip_range',
          type: 'number',
          rules: {
            required: true,
          },
          change: (value) => {
            if (isNaN(value)) {
              return false
            }
            if (value.length !== 8) {
              return false
            }
          },
          format: (value) => {
            return value.replace(/(\d{5})(\d{3})/, '$1-$2')
          },
        },
        {
          label: 'Peso Inicial',
          field: 'initial_weight',
          rules: {
            required: true,
          },
          type: 'number',
        },
        {
          label: 'Peso Final',
          field: 'final_weight',
          rules: {
            required: true,
          },
          type: 'number',
        },
        {
          label: 'Valor',
          field: 'value',
          rules: {
            required: true,
          },
          type: 'number',
        },
        {
          label: 'Prazo',
          field: 'deadline',
          rules: {
            required: true,
          },
          type: 'number',
        },
        {
          label: 'Valor Adicional',
          field: 'add_value',
          type: 'number',
        },

        {
          label: 'Peso Adicional',
          field: 'add_weight',
          type: 'number',
        },
      ],
      fields: [],
      items: [],
      rowUpdate: {},
      show: false,
      selectedKeys: [],
      mousePosition: {x: 0, y: 0},
      optionsComponent: {
        zIndex: 99999,
        minWidth: 230,
        x: 500,
        y: 200
      },
    };
  },
  methods: {
    updateTable(data) {
      console.log(data)
      let RowsData = this.populateFields(data)
      for (let i = 0; i < RowsData.length; i++) {
        let row = RowsData[i]
        this.save(row)
      }
    },
    saveAll() {
      let toSave = this.items.filter(item => String(item.id).includes('§'))
      //unset id
      toSave.forEach(item => {
        delete item.id
        delete item.$id
      })
      for (let i = 0; i < toSave.length; i++) {
        let row = toSave[i]
        this.save(row)
      }
    },
    populateFields(datas) {
      let dataToSave = []
      for (let i = 0; i < datas.length; i++) {
        let data = datas[i]
        //check if  any field in keys contains § if it ignore this data
        if (data.keys.some(key => String(key).includes('§'))) {
          continue
        }
        let currentData = data.keys
        let fields = {}
        for (let j = 0; j < this.columns.length; j++) {
          let key = this.columns[j].field
          fields[key] = currentData[j]
        }
        dataToSave.push(fields)
      }
      return dataToSave
    },
    newRow() {
      const record = {
        region: '',
        initial_zip_range: '',
        final_zip_range: '',
        initial_weight: '',
        final_weight: '',
        value: '',
        deadline: '',
        add_value: '',
        add_weight: '',
      }
      this.$refs.grid.newRecord(record)
    },
    showContextMenu(event) {
      event.preventDefault();
      this.show = !this.show;
      this.optionsComponent.x = this.mousePosition.x;
      this.optionsComponent.y = this.mousePosition.y;
    },
    closeMenu() {
      this.show = false;
    },
    showMenu(event) {
      this.selectedKeys = event
    },

    updateMousePosition(event) {
      this.mousePosition.x = event.clientX;
      this.mousePosition.y = event.clientY;
    },
    onMenuClick(index) {
      console.log('onMenuClick', index)
    },
    save(values) {
      this.axios.post('/freights', values).then(() => {
        this.$notify('Frete atualizado com sucesso!')
        this.getFreights()
      }).catch(() => {
        this.$notify({
          text: 'Erro ao atualizar frete!',
          type: 'error'
        })
      })
    },
    async handleDelete() {
      try {
        // Criar um array para armazenar todas as Promessas
        const deletePromises = []

        for (let i = 0; i < this.selectedKeys.length; i++) {
          let data = this.items[this.selectedKeys[i]]
          this.rowUpdate = {id: data.id, action: "delete"}

          // Adicionar a Promessa ao array
          deletePromises.push(
              this.axios.delete('/freights/' + data.id).then(() => {
                this.$notify('Frete deletado com sucesso!')
              }).catch(() => {
                this.$notify({
                  text: 'Erro ao deletar frete!',
                  type: "error"
                })
              })
          )
        }

        // Esperar todas as Promessas serem resolvidas
        await Promise.all(deletePromises)
      } catch (e) {
        console.log(e)
      } finally {
        this.getFreights()
      }
    },
    getFreights() {
      this.axios.get('/freights').then(response => {
        this.items = response.data
      })
    },
  },
  computed: {
    unSavedItems() {
      return this.items.filter(item => String(item.id).includes('§')).length
    },
    unSaved() {
      return this.items.some(item => String(item.id).includes('§'))
    },
    isMultiple() {
      return this.selectedKeys.length > 1
    },
    isSingle() {
      return this.selectedKeys.length === 1
    },
    isLast() {
      let last = this.selectedKeys[this.selectedKeys.length - 1]
      let lastIndex = this.items.length - 1
      return last === lastIndex
    },
  },
  mounted() {
    this.getFreights()
    window.addEventListener('mousemove', this.updateMousePosition);
  },
  destroyed() {
    window.removeEventListener('mousemove', this.updateMousePosition);
  },

}
</script>
