<template lang="html">
  <div class="dropdown  d-none d-md-inline-flex ms-2">
    <button type="button" class="btn header-item noti-icon" v-wave="{color: '#0a1832',}"
            id="page-header-notifications-dropdown" data-bs-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
      <i class="ion ion-md-notifications"></i>
      <span class="badge bg-danger rounded-pill" v-if="notifications.length > 0">{{ notifications.length }}</span>
    </button>
    <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" style="max-height: 350px;overflow-y: auto;"
         aria-labelledby="page-header-notifications-dropdown">
      <div class="p-3">
        <div class="row align-items-center">
          <div class="col">
            <h5 class="m-0 font-size-16"> Notificações ({{ notifications.length }}) </h5>
          </div>
        </div>
      </div>
      <a href="#" @click="actionNotification(notification.channel,notification._id)"
         class="text-reset notification-item"
         style="max-height: 230px;" v-for="(notification, index) in notifications" :key="index">
        <div class="media d-flex">
          <div class="avatar-xs me-3">
                                        <span class="avatar-title bg-success rounded-circle font-size-16">
                                            <i :class="getIcon(notification.channel)"></i>
                                        </span>
          </div>
          <div class="flex-1">
            <p class="font-size-14 mb-1 text-truncate">{{ notification.name }}</p>
            <h6 class="mt-0 font-size-15 mb-1">{{ notification.content }}</h6>
          </div>
        </div>
      </a>
      <div class="p-2 border-top text-center">
        <a class="btn btn-sm btn-link font-size-14 w-100" @click.prevent="removeAllNotifications">
          Marcar todas como lidas
        </a>
      </div>
    </div>
  </div>
</template>
<script>
// import {SimpleBar } from 'simplebar-vue3';
import config from '@/config';
import {NotifyHandler} from "@/utils/NotifyHandler";

export default {
  name: 'notificationsComponent',
  data() {
    return {
      notifications: [],
      vendorCode: '',
    }
  },
  methods: {
    getIcon: function (channel) {
      switch (channel) {
        case 'delivery_credit':
          return 'fas fa-truck'
        case 'order':
          return 'fas fa-shopping-cart'
        case 'report':
          return 'fas fa-chart-bar'
        default:
          return 'fas fa-bell'

      }
    },
    getNotifications: function () {
      const userId = this.vendorCode // Método fictício para obter o ID do usuário
      this.eventSource = new EventSource(`${config.serviceUrl}/api/v1/notifications?userId=${userId}`);
      this.eventSource.onmessage = (event) => {
        const notification = JSON.parse(event.data);
        this.notifications.push(notification);
      };
    },
    actionNotification: function (channel, id) {
      switch (channel) {
        case 'delivery_credit':
          NotifyHandler.handle('success', 'Seus Creditos podem ser ecnontrados no icone ao lado');
          break;
        case 'order':
          this.$router.push({name: 'orders'});
          break;
        case 'report':
          this.$router.push({name: 'myFiles'})
          break;
        default:
          break;
      }
      this.markAsRead(id);
    },
    markAsRead: function (id) {
      return this.axios.put(`notifications/${id}`).then(() => {
        this.notifications = this.notifications.filter(notification => notification._id !== id);
      }).catch(error => {
        console.log(error);
      });
    },
    removeAllNotifications: function () {
      return this.axios.delete('notifications').then(() => {
        this.notifications = [];
      }).catch(error => {
        console.log(error);
      });

    },
    getVendorCode: async function () {
      return this.axios.get('settings/vendorCode').then(response => {
        this.vendorCode = response.data.vendorToken;
      }).catch(error => {
        console.log(error);
      });
    },
    getAllNotifications: function () {
      return this.axios.get('notifications/all').then(response => {
        this.notifications = response.data;
      }).catch(error => {
        console.log(error);
      });
    },
  },
  mounted() {
    this.getVendorCode().then(() => {
      this.getNotifications();
      this.getAllNotifications();
    })
  },
  components: {
    // SimpleBar,
  }
}
</script>
<style lang="scss" scoped>
/* https://github.com/jgthms/bulma/issues/2514 */
/* Issue with menus not closing because Bulma does come with any javascript. The @media screen and mounted hook is the fix for that */
@media screen and (min-width: 1025px) {
}
</style>
