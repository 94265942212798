<template>
  <div class="col-xl-4">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title mb-4">{{ $t('dashboard.cartChart')}}</h4>
        <DoughnutChart :chartData="donutData"/>
      </div>
    </div>
  </div>
</template>
<script>
import {DoughnutChart} from 'vue-chart-3';
import {Chart, registerables} from "chart.js";

Chart.register(...registerables);
export default {
  props: ['cartChart'],
  name: 'CartChart',
  data() {
    return {
      data: this.cartChart,
      donutData: {
        labels: ['Carrinhos', 'Pedidos'],
        datasets: [
          {
            data: [this.cartChart.carts, this.cartChart.orders],
            backgroundColor: ['#77CEFF', '#0079AF'],
          },
        ],
      },
    }
  },
  components: {
    DoughnutChart
  },
}
</script>
