import {defineStore} from "pinia";
import axios from "@/boostrap/apiConfig";
import {ErrorHandler as HandleError} from "@/utils/ErrorHandler";

export const useOnboardingStore = defineStore({
    id: 'onboardingStore',
    state: () => ({
        forms: []
    }),
    getters: {
        getForms() {
            return this.forms;
        }
    },
    actions: {
        async register(forms) {
           return axios.post('auth/onboarding', forms).then((response) => {
                console.log(response)
            }).catch((error) => {
                HandleError.handle(error)
               throw error
            })

        }
    }

})