<template>
  <div class="col-xl-8">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title mb-4">{{ $t('dashboard.paymentMethodData')}}</h4>
        <PolarAreaChart
            id="area" :chartData="areaData">
        </PolarAreaChart>
      </div>
    </div>
  </div>
</template>
<script>
import {PolarAreaChart} from 'vue-chart-3';
import {Chart, registerables} from "chart.js";
import {collect} from "collect.js";

Chart.register(...registerables);
export default {
  props: ['paymentData'],
  data() {
    return {
      areaData: {
        labels: this.paymentData.labels,
        datasets: [
          {
            data: collect(this.paymentData.total).toArray(),
            backgroundColor: this.paymentData.colors
          },
        ],
      },
    }
  },
  methods: {
    formatLabel: function () {
      return function (x) {
        // get month name in pt-br from var x is date
        var month = new Date(x).toLocaleString('pt-br', {month: 'long'});
        return month


      }
    }
  },
  components: {
    PolarAreaChart
  },
  name: 'PaymentMethodData'
}
</script>
