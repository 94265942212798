<template>
  <div class="col-md-6 bg-soft-light rounded-2 ">
    <div class="card">
      <div class="mb-2">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-10">
                <span class="text-uppercase bold fw-bold m-2">{{ $t('app.customerInfo') }}</span>
              </div>
              <div class="col-md-2">
                <button class="btn btn-primary text-uppercase btn-sm w-100" @click.prevent="showCart = !showCart">
                  <i class="fas fa-arrow-up"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PerfectScrollbar class="cartProductsWrapper bg-soft-light rounded-2" :options="options" v-show="showCart" v-if="customer">
        <div name="slide-fade" class="row d-flex gap-2">
          <div class="col-md-12 text-center">
            <h5>{{ customer?.firstname }} {{customer?.lastname}}</h5>
            <small>{{customer?.document}}</small>
          </div>
        </div>
      </PerfectScrollbar>
    </div>
  </div>
</template>
<script>

import {usePosStore} from "@/store/PosStore";

export default {
  name: 'CustomerInfoCart',
  setup() {
    const posStore = usePosStore()

    return {
      posStore
    }
  },
  data() {
    return {
      showCart: true,
      options: {
        useBothWheelAxes: false,
        suppressScrollX: true,
      },
    }
  },
  computed: {
    customer() {
      return this.posStore.getCart?.customer
    }
  }
}
</script>
<style scoped>
::selection {
  background: #d5bbf7;
}

.card input[type="radio"] {
  display: none;
}

.card {
  width: 100%;
  border-radius: 5px;
  padding: 20px;
}

.card .title {
  font-size: 22px;
  font-weight: 500;
}

.card .content {
  margin-top: 20px;
}

.card label.box {
  margin-top: 12px;
  padding: 10px 12px;
  display: flex;
  border-radius: 5px;
  border: 2px solid transparent;
  cursor: pointer;
  transition: all 0.25s ease;
}
</style>