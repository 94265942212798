<template>
  <div class="account-bg">
    <div class="account-pages my-5 pt-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-8 col-xl-8 bg-white rounded-4" v-if="!finished">
            <form-wizard color="#2f8ee0" @on-complete="submitForm" ref="wizard" @on-change="setIndex">
              <tab-content :title="$t('onboarding.myCompany')" :before-change="() => validateForm('company')">
                <div class="text-center">
                  <span class="text-muted">{{ $t('onboarding.companyMessage') }}</span>
                </div>
                <FormGenerator :form-schema="companySchema" :no-button="true" :use-store="true" id="company"/>
              </tab-content>
              <tab-content :title="$t('onboarding.address')" :before-change="() => validateForm('address')">
                <div class="text-center">
                  <span class="text-muted">{{ $t('onboarding.addressMessage') }}</span>
                </div>
                <ComponentDuplicator>
                  <FormGenerator :form-schema="address" :no-button="true" :use-store="true" id="address"/>
                </ComponentDuplicator>
              </tab-content>
              <tab-content :title="$t('onboarding.warehouse')" :before-change="() => validateForm('warehouse')">
                <div class="text-center">
                  <span class="text-muted">{{ $t('onboarding.warehouseMessage') }}</span>
                </div>
                <ComponentDuplicator :max-items="addressFormCount" :message-limit="$t('onboarding.addressLimit')">
                  <FormGenerator :form-schema="warehouse" :no-button="true" :use-store="true" id="warehouse"/>
                </ComponentDuplicator>
              </tab-content>
              <tab-content :title="$t('onboarding.calendars')" :before-change="() => validateForm('period')">
                <div class="text-center">
                  <span class="text-muted">{{ $t('onboarding.calendarsMessage') }}</span>
                </div>
                <ComponentDuplicator :max-items="warehouseFormCount" :message-limit="$t('onboarding.warehouseLimit')">
                  <FormGenerator :form-schema="period" :no-button="true" :use-store="true" id="period"/>
                </ComponentDuplicator>
              </tab-content>
              <tab-content :title="$t('onboarding.users')" :before-change="() => validateForm('users')">
                <div class="text-center">
                  <span class="text-muted">{{ $t('onboarding.usersMessage') }}</span>
                </div>
                <FormGenerator :form-schema="users" :no-button="true" :use-store="true" id="users"/>
              </tab-content>
            </form-wizard>
            <router-link :to="{name:'login'}" class="btn btn-primary">{{ $t('onboarding.goToLogin') }}</router-link>
          </div>
          <div class="col-md-8 col-lg-8 col-xl-8 bg-white rounded-4" v-else>
            <div class="text-center">
              <h1 class="text-success">{{ $t('onboarding.congratulations') }}</h1>
              <span class="text-muted d-block"> {{ $t('onboarding.congratulationsMessage') }}</span>
              <span class="text-muted d-block"> {{ $t('onboarding.congratulationsMessage2') }}</span>
            </div>
            <div class="text-center mt-4" >
              <router-link :to="{name:'login'}" class="btn btn-primary">{{ $t('onboarding.goToLogin') }}</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {FormWizard, TabContent} from 'vue3-form-wizard'
import 'vue3-form-wizard/dist/style.css'
import FormGenerator from "@/components/helper/formGenerator/FormGenerator.vue";
import ComponentDuplicator from "@/components/helper/duplicator/ComponentDuplicator.vue";
import {useFormGeneratorStore} from "@/components/helper/formGenerator/store/formGeneratorStore";
import {useOnboardingStore} from "@/store/onBoardingStore";

export default {
  name: "OnBoardingWrapper",
  components: {
    ComponentDuplicator,
    FormGenerator,
    FormWizard,
    TabContent
  },
  setup() {
    const formGeneratorStore = useFormGeneratorStore();
    const onBoardingStore = useOnboardingStore();
    const forms = formGeneratorStore.forms;
    return {forms, onBoardingStore};
  },
  data() {
    return {
      finished: false,
      tabsIndex: {
        company: 0,
        address: 1,
        warehouse: 2,
        period: 3,
        users: 4,
      },
      oldIndex: 0,
      companySchema: [
        {
          "name": "name",
          "label": this.$t('onboarding.companySchema.name'),
          "span": "left",
          "type": "text",
          "rules": "required"
        },
        {
          "name": "state_register",
          "label": this.$t('onboarding.companySchema.state_register'),
          "span": "right",
          "type": "text",
        },
        {
          "name": "phone",
          "label": this.$t('onboarding.companySchema.phone'),
          "span": "left",
          "type": "text",
          "tab": "Informações para entega",
          "rules": "required",
        },
        {
          "name": "email",
          "label": this.$t('onboarding.companySchema.email'),
          "span": "right",
          "type": "text",
          "rules": "required|email",
        },
        {
          "name": "company_document",
          "label": this.$t('onboarding.companySchema.company_document'),
          "span": "left",
          "type": "text",
          "comment": this.$t('onboarding.companySchema.company_documentComment'),
          "rules": "required",
        },
      ],
      address: [
        {
          "name": "lines",
          "label": this.$t('onboarding.addressSchema.lines'),
          "span": "left",
          "type": "textarea",
          "rules": "required"
        },
        {
          "name": "name",
          "label": this.$t('onboarding.addressSchema.name'),
          "span": "right",
          "type": "text",
          "rules": "required"
        },
        {
          "name": "zip",
          "label": this.$t('onboarding.addressSchema.zip'),
          "span": "left",
          "type": "text",
          "rules": "required",
          "mask": "#####-###"
        },
        {
          "name": "city",
          "label": this.$t('onboarding.addressSchema.city'),
          "span": "right",
          "type": "text",
          "rules": "required"
        },
        {
          "name": "country",
          "label": this.$t('onboarding.addressSchema.country'),
          "span": "left",
          "async": {
            "url":"auth/location/country",
          },
          "type": "dropdown",
          "rules": "required"
        },
        {
          "name": "state",
          "label": this.$t('onboarding.addressSchema.state'),
          "span": "right",
          "async": {
            "url":"auth/location/state",
          },
          "type": "dropdown",
          "dependsOn": "country",
          "rules": "required"
        },
        {
          "name": "details",
          "label": this.$t('onboarding.addressSchema.details'),
          "span": "left",
          "type": "textarea",
          "rules": "required"
        },
        {
          "name": "number",
          "label": this.$t('onboarding.addressSchema.number'),
          "span": "right",
          "type": "text",
          "rules": "required"
        },
        {
          "name": "district",
          "label": this.$t('onboarding.addressSchema.district'),
          "span": "left",
          "type": "text",
          "rules": "required"
        }
      ],
      warehouse: [
        {
          "name": "name",
          "label": this.$t('onboarding.warehouseSchema.name'),
          "span": "left",
          "type": "text",
          "rules": "required"
        },
        {
          "name": "secure_limit",
          "label": this.$t('onboarding.warehouseSchema.secure_limit'),
          "span": "right",
          "type": "number",
          "rules": "required"
        },
        {
          "name": "dangerous_limit",
          "label": this.$t('onboarding.warehouseSchema.dangerous_limit'),
          "span": "left",
          "type": "number",
          "rules": "required"
        },
        {
          "name": "additional_time",
          "label": this.$t('onboarding.warehouseSchema.additional_time'),
          "span": "right",
          "type": "number",
          "rules": "required"
        },
        {
          "name": "delivery_range",
          "label": this.$t('onboarding.warehouseSchema.delivery_range'),
          "span": "right",
          "type": "number",
          "rules": "required"
        },
        {
          "name": "address_selected",
          "label": this.$t('onboarding.warehouseSchema.address_selected'),
          "span": "left",
          "type": "dropdown",
          "options": this.addressOptions,
          "rules": "required"
        },
      ],
      period: [
        {
          "name": "name",
          "label": this.$t('onboarding.periodSchema.name'),
          "span": "left",
          "type": "text"
        },
        {
          "name": "max_delivery",
          "label": this.$t('onboarding.periodSchema.max_delivery'),
          "span": "right",
          "type": "number",
          "comment": this.$t('onboarding.periodSchema.max_deliveryComment'),
        },
        {
          "name": "start_at",
          "label": this.$t('onboarding.periodSchema.start_at'),
          "mode": "time",
          "span": "left",
          "type": "time"
        },
        {
          "name": "end_at",
          "label": this.$t('onboarding.periodSchema.end_at'),
          "mode": "time",
          "span": "right",
          "type": "time"
        },
        {
          "name": "weeks_day",
          "label": this.$t('onboarding.periodSchema.weeks_day'),
          "multi": true,
          "options": [
            {id: '0', name: this.$t('app.daysOfWeek.sunday')},
            {id: '1', name: this.$t('app.daysOfWeek.monday')},
            {id: '2', name: this.$t('app.daysOfWeek.tuesday')},
            {id: '3', name: this.$t('app.daysOfWeek.wednesday')},
            {id: '4', name: this.$t('app.daysOfWeek.thursday')},
            {id: '5', name: this.$t('app.daysOfWeek.friday')},
            {id: '6', name: this.$t('app.daysOfWeek.saturday')},
          ],
          "span": "left",
          "type": "checkboxlist"
        },
        {
          "name": "receive_until",
          "label": this.$t('onboarding.periodSchema.receive_until'),
          "mode": "time",
          "span": "right",
          "type": "time"
        },
        {
          "name": "warehouse_selected",
          "label": this.$t('onboarding.periodSchema.warehouse_selected'),
          "span": "right",
          "type": "dropdown",
          "options": this.warehouseOptions,
          "rules": "required",
        }
      ],
      users: [
        {
          "name": "first_name",
          "label": this.$t('onboarding.userSchema.first_name'),
          "span": "full",
          "type": "text",
          "rules": "required|max:255",
        },
        {
          "name": "last_name",
          "label": this.$t('onboarding.userSchema.last_name'),
          "span": "left",
          "type": "text",
          "comment": this.$t('onboarding.userSchema.last_nameComment'),
          "rules": "required",
        },
        {
          "name": "login",
          "label": this.$t('onboarding.userSchema.login'),
          "span": "left",
          "type": "text",
          "comment": this.$t('onboarding.userSchema.loginComment'),
          "rules": "required",
        },
        {
          "name": "email",
          "label": this.$t('onboarding.userSchema.email'),
          "span": "left",
          "type": "email",
          "rules": "required|email",
        },
        {
          "name": "password",
          "label": this.$t('onboarding.userSchema.password'),
          "span": "left",
          "type": "password",
          "rules": "required|min:8",
        },
        {
          "name": "password_confirmation",
          "label": this.$t('onboarding.userSchema.password_confirmation'),
          "span": "left",
          "type": "password",
          "rules": "required|min:8|confirmed:@password",
        },
      ],
      addressesOptions: [
        {
          id: null,
          name: "Selecione um endereço"
        }
      ],
    }
  },
  methods: {
    setIndex(prevIndex, nextIndex) {
      this.oldIndex = nextIndex
    },
    async validateForm(form) {
      const forms = this.getAllFormsById(form);
      try {
        const results = await Promise.all(forms.map(form => form.validate()));
        return results.every(result => result.valid);
      } catch (error) {
        console.error("Validation error:", error);
        return false;
      }
    },
    getAllFormsById(id) {
      return this.forms.filter(form => form.id === id)
    },
    addressOptions() {
      let addressForms = this.forms.filter(form => form.id === 'address');
      let data = addressForms.map(form => {
        return {
          //mustbe int unique
          id: form.formId,
          name: form.form.name
        }
      })
      data = data.filter(function (el) {
        return el != null;
      });
      console.log(data)
      return data
    },
    warehouseOptions() {
      let warehouseForms = this.forms.filter(form => form.id === 'warehouse');
      let data = warehouseForms.map(form => {
        return {
          //mustbe int unique
          id: form.formId,
          name: form.form.name
        }
      })
      data = data.filter(function (el) {
        return el != null;
      });
      console.log(data)
      return data
    },
    submitForm() {
      // get allform and group by form.id and map forms with fromId and value with form
      let allForms = this.forms.map(form => {
        return {
          formId: form.formId,
          form: form.form,
          type: form.id
        }
      })
      this.onBoardingStore.register(allForms).then(()=>{
        this.finished = true
      }).catch((error) => {
        this.applyBackendErrors(error.response.data)
      })
    },
    applyBackendErrors(data) {
      /* response type
      {
  "success": false,
  "errors": {
    "error": {
      "company_document": [
        "company document já está sendo utilizado."
      ],
      "state_register": [
        "state register já está sendo utilizado."
      ],
      "form": [
        "company"
      ]
    }
  }
}*/
      //but first we need to get form in errors.error
      let form = data.errors.error.form[0]
      //then we need to get all forms with this id
      let forms = this.getAllFormsById(form)

      this.$refs.wizard.changeTab(this.oldIndex,this.tabsIndex[form])


      // now we need to get ref(form) is the form reference in component set errors
      forms.forEach(form => {
        let formRef = form.$refs.form
        let allErrors = data.errors.error
        console.log(allErrors)
        console.log(formRef)
        // remove form from errors
        delete allErrors.form
        Object.keys(data.errors.error).forEach((field) => {
          let message = data.errors.error[field][0]
          console.log(message)
          console.log(field)
          formRef.setErrors({[field]: [message]})
        })
      })
    }
  },
  computed: {
    addressFormCount() {
      return this.forms.filter(form => form.id === 'address').length
    },
    warehouseFormCount() {
      return this.forms.filter(form => form.id === 'warehouse').length
    },

  }
}
</script>
<style scoped>

</style>