import {useNotification} from "@kyvg/vue3-notification";
import {useLocalizationStore} from "@/store/localizationStore";



export class NotifyHandler {
    static handle(type = 'success', message = 'Operação realizada com sucesso') {
        let {notify} = useNotification();
        let locale = useLocalizationStore();

        notify({
            title: locale.translator(message),
            type: type
        })

    }
}