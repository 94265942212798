<template>
  <div>
    <div class="row">

      <div class="col-12 gap-3 d-flex justify-content-end ">
        <new-field :usedField="fieldInUse" @update="update"></new-field>
      </div>
    </div>
    <div class="container-fluid mt-3">
      <div class="row">
        <div class="col-md-7">
          <div class="card">
            <div class="card-body">
              <custom-field-wrapper :field="field" v-for="field in  product.custom_fields" :key="field.id"
                                    @removeField="removeFromProduct"></custom-field-wrapper>
            </div>
          </div>
        </div>
        <div class="col-md-5">
          <div class="row mb-3 ">
            <div class="col-md-9">
              <label for="search">{{ $t("app.search") }}</label>
              <input type="text" id="search" v-model="search" :placeholder="$t('app.searchPlaceholder')"
                     class="form-control">
            </div>
            <div class="col-md-3">
              <label>{{ $t("product.customFields.onlyEditable") }}</label>
              <input type="checkbox" id="onlyEditable" v-model="onlyEditable" switch="none"/>
              <label for="onlyEditable" data-on-label="Sim" data-off-label="Não"></label>
            </div>

          </div>
          <perfect-scrollbar>
            <div class="card">
              <div class="card-body">
                <transition-group name="fade" tag="div">
                  <div class="card" v-for="custom in filteredData" :key="custom.field.id">
                    <article class="blog-card" :class="{'self':custom.field.editable}">
                      <div class="article-details col-md-12">
                        <h4 class="post-category">{{ custom.field.name }}</h4>
                        <h3 class="post-title">
                          <span v-currency="custom.field.price/100"></span>
                        </h3>
                        <p class="post-description d-flex justify-content-between">
                          <!--                      buttons to edit and add to list-->
                          <button class="btn btn-primary-outline" @click="useModel(custom)"><em
                              class="fa fa-copy"></em></button>

                          <button class="btn btn-primary-outline" v-if="custom.field.editable"
                                  @click="editField(custom)"><em class="fa fa-pen"></em></button>
                          <button class="btn btn-primary-outline" @click="addToProduct(custom.field)"><em
                              class="fa fa-plus"></em></button>
                        </p>
                        <p class="post-author d-flex justify-content-between" v-if="custom.field.values.length > 0">
                          Valores {{ custom.field.values.length }} <em
                            @click="custom.field.showValues = !custom.field.showValues" class="fa fa-question pp"></em>
                        </p>
                        <transition name="slide">
                          <ul v-if="custom.field.showValues" class="list-group mt-3">
                            <li class="list-group-item " v-for="value in custom.field.values" :key="value.id">
                              <div class="row">
                                <div class="col-md-6">
                                  {{ value.name }}
                                </div>
                                <div class="col-md-6">
                                  {{ value.value }}
                                </div>
                                <div class="col-md-12" v-currency="value.price/100">

                                </div>
                              </div>
                            </li>
                          </ul>
                        </transition>
                      </div>
                    </article>
                  </div>
                </transition-group>
              </div>
            </div>

          </perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomFieldWrapper from "@/components/products/customFields/CustomFieldWrapper";
import NewField from "@/components/products/customFields/NewField";
// import MoneyFormat from 'vue-money-format'

export default {
  name: "CustomFields",
  props: {
    product: {}
  },
  data() {
    return {
      fields: [],
      fieldInUse: null,
      search: '',
      filteredData: [],
      onlyEditable: false
    }
  },
  methods: {
    getCustomFields() {
      this.axios.get(`/product/${this.product.id}/custom-fields`).then(response => {
        this.fields = response.data;
        this.filteredData = this.searchByName()
      })
    },
    addToProduct(field) {
      this.$confirm(
          {
            message: this.$tc('product.customFields.confirmAdd', {field: field.name}),
            button: {
              no: this.$t('app.no'),
              yes: this.$t('app.yes'),
            },
            callback: confirm => {
              if (confirm) {
                this.filteredData = this.filteredData.filter(f => f.field.id !== field.id)
                this.axios.post(`/product/${this.product.id}/custom-fields/add`, {field_id: field.id}).then(response => {
                  this.$emit('updateProduct', response.data);
                  this.update()
                  this.$notify('Campo adicionado com sucesso')
                })
              }
            }
          })
    },
    removeFromProduct(field) {
      this.$confirm(
          {
            message: this.$tc('product.customFields.confirmRemove', {field: field.name}),
            button: {
              no: this.$t('app.no'),
              yes: this.$t('app.yes'),
            },
            callback: confirm => {
              if (confirm) {
                this.axios.post(`/product/${this.product.id}/custom-fields/remove`, {field_id: field.id}).then(response => {
                  this.$emit('updateProduct', response.data);
                  this.update()
                  this.$notify('Campo removido com sucesso')
                })
              }
            }
          })
    },
    update() {
      this.getCustomFields();
      this.$emit('update');
    }
    ,
    useModel(field) {
      this.$confirm({
        message: `Deseja usar o modelo ${field.field.name}?`, // Mensagem de confirmação
        button: {
          no: 'Não',  // Botão de negação
          yes: 'Sim', // Botão de afirmação
        },
        callback: confirm => {
          if (confirm) {
            // Lógica para usar o modelo
            let copyfield = JSON.parse(JSON.stringify(field));

            copyfield.field.name = copyfield.field.name + ' (cópia)'
            // remove id
            delete copyfield.field.id;
            copyfield.field.price = (copyfield.field.price / 100)
            this.fieldInUse = copyfield;
          }
        }
      });

      //crea te copy of the field


    }
    ,
    editField(field) {

      this.$confirm({
        message: `Deseja editar o campo ${field.field.name}?`, // Mensagem de confirmação
        button: {
          no: 'Não',  // Botão de negação
          yes: 'Sim', // Botão de afirmação
        },
        callback: confirm => {
          if (confirm) {
            // Lógica para editar o campo
            field = JSON.parse(JSON.stringify(field));
            field.field.editing = true;
            field.field.price = (field.field.price / 100);
            this.fieldInUse = field;
          }
        }
      });


    }
    ,
    filterByEditable() {
      return this.fields.filter(field => field.field.editable)
    }
    ,
    searchByName() {
      if (this.onlyEditable) {
        return this.filterByEditable().filter(field => field.field.name.toLowerCase().includes(this.search.toLowerCase()))
      }
      return this.fields.filter(field => field.field.name.toLowerCase().includes(this.search.toLowerCase()))
    }
    ,
  },
  watch: {
    search() {
      this.filteredData = this.searchByName()
    },
    onlyEditable() {
      console.log('entered')
      if (this.onlyEditable) {
        this.filteredData = this.filterByEditable()
      } else {
        this.filteredData = this.fields
      }
    },
  },
  mounted() {
    this.getCustomFields();
  },
  components: {
    NewField,
    CustomFieldWrapper,
    // MoneyFormat
  }
}
</script>

<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700');

.bounce-enter-active {
  animation: bounce-in .7s;
}

.bounce-leave-active {
  animation: bounce-in .7s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.20);
  }
  100% {
    transform: scale(1);
  }

}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0
}

.ps {
  max-height: 1000px;
}

.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter, .slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

.pp {
  cursor: pointer;
}

$bg: #eedfcc;
$text: #fff;
$black: #fff;
$background: #b6babe;
$background2: #c4c3c3 !important;
$white: #fff;
$red: #e04f62;
$border: #ebebeb;
$shadow: rgba(0, 0, 0, 0.2);

@mixin transition($args...) {
  transition: $args;
}

.self {
  background-color: $background2;
}

.blog-card {
  display: flex;
  flex-direction: row;
  background-color: $background;
  box-shadow: 0 0.1875rem 1.5rem $shadow;
  border-radius: 0.375rem;
  overflow: hidden;
}

.card-link {
  position: relative;
  display: block;
  color: inherit;
  text-decoration: none;

  &:hover .post-title {
    @include transition(color 0.3s ease);
    color: $red;
  }

  &:hover .post-image {
    @include transition(opacity 0.3s ease);
    opacity: 0.9;
  }
}

.post-image {
  @include transition(opacity 0.3s ease);
  display: block;
  width: 100%;
  object-fit: cover;
}

.article-details {
  padding: 1.5rem;
  width: 100%;
}

.post-category {
  display: inline-block;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.0625rem;
  margin: 0 0 0.75rem 0;
  padding: 0 0 0.25rem 0;
  border-bottom: 0.125rem solid $border;
}

.post-title {
  @include transition(color 0.3s ease);
  font-size: 1.125rem;
  line-height: 1.4;
  color: $black;
  font-weight: 700;
  margin: 0 0 0.5rem 0;
}

.post-author {
  font-size: 0.875rem;
  line-height: 1;
  margin: 1.125rem 0 0 0;
  padding: 1.125rem 0 0 0;
  border-top: 0.0625rem solid $border;
}

@media (max-width: 40rem) {
  #container {
    width: 18rem;
    height: 27.25rem;
  }

  .blog-card {
    flex-wrap: wrap;
  }
}

@supports (display: grid) {


  #container {
    grid-area: main;
    align-self: center;
    justify-self: center;
  }

  .post-image {
    height: 100%;
  }

  .blog-card {
    display: flex;
  }

  @media (max-width: 40rem) {
    .blog-card {
      grid-template-columns: auto;
      grid-template-rows: 12rem 1fr;
    }
  }
}
</style>