<template>
  <div>

    <div class="mall-form-control">
      <label :for="fieldId" class="mall-label">{{ field.name }}</label>
      <div class="mall-field-color-selector">
        {% if field.custom_field_options.count > 0 %}
        <input type="hidden" :id="fieldId" :name="'fields['+field.hashId+']'"
               data-mall-custom-field
        >
        {% for option in field.custom_field_options %}
        <a class="mall-option-selector" :data-target="'#'+ fieldId" :data-value="option.hashId">
                <span :style="'background:' +option.option_value +'; width: 20px; height: 20px; display: inline-block;'"></span>
        </a>
        {% endfor %}
        {% else %}
        <input type="color" :id="fieldI" :name="'fields['+field.hashId+']'"
               data-mall-custom-field
        >
        {% endif %}
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'colorComponent',
  props: {
    field: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    fieldId() {
      return 'field-' + this.field.hashId
    },
  },


}
</script>

<style lang="scss" scoped>

</style>