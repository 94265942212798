<template>
  <div class=" col-md-4 bg-soft-light rounded-2 mt-3 " v-auto-animate>
    <div class="card">
      <div class="mb-2">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-10">
                <span class="text-uppercase bold fw-bold">Aplicador de cupon</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="showPayment" v-auto-animate>
        <Form @submit="applycupon" v-auto-animate>
          <div class="row">
            <div class="col-md-12" v-auto-animate>
              <Field v-model="cupon" name="cupon" v-slot="{field}">
                <div class="form-group">
                  <label for="exampleDataList" class="form-label">Cupons</label>
                  <input class="form-control"  v-bind="field" list="datalistOptions" id="exampleDataList"
                         placeholder="Escreva o Codigo do Cupom">
                  <datalist id="datalistOptions">
                    <option v-for="cupon in cupons" :value="cupon.code" :key="cupon.id">{{cupon.code}}</option>

                  </datalist>
                </div>
              </Field>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-12">
              <button class="btn btn-primary w-100">Aplicar</button>
            </div>
          </div>
        </Form>
        <div class="text-center p-2">
          <span>cupons aplicados </span>
          <div v-for="discounts in posStore.getCart?.discounts" :key="discounts.id">
            <div class="d-flex justify-content-between p-3">
              <span>{{discounts.code}}</span>
              <button @click="removeDiscount(discounts.id)" class="btn-close"></button>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>

</template>
<script>
import {Form, Field} from 'vee-validate';
import {usePosStore} from "@/store/PosStore";

export default {
  name: 'CuponApplier',
  components: {Form, Field},
  setup: () => ({posStore: usePosStore()}),

  props: {
    showProducts: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      showPayment: true,
      cupons: [],
      cupon: ''
    }
  },
  methods: {
    applycupon() {
     this.posStore.applyCupon(this.cupon);
    },
    removeDiscount(id) {
      this.posStore.removeDiscount(id);
    },
    fetchCupons() {
      this.axios.get('/pos/cupons').then((response) => {
        this.cupons = response.data;
      }).catch((error) => {
        console.log(error);
      });
    }
  },
  beforeMount() {
    this.fetchCupons();
  }
}
</script>
<style scoped>
::selection {
  background: #d5bbf7;
}

.card input[type="radio"] {
  display: none;
}
.card {
  width: 100%;
  border-radius: 5px;
  padding: 20px;
}

.card .title {
  font-size: 22px;
  font-weight: 500;
}

.card .content {
  margin-top: 20px;
}

.card label.box {
  margin-top: 12px;
  padding: 10px 12px;
  display: flex;
  border-radius: 5px;
  border: 2px solid transparent;
  cursor: pointer;
  transition: all 0.25s ease;
}

</style>