<template>
  <div class="container-fluid mt-3">
    <div class="py-2 d-flex justify-content-end gap-2">
      <router-link class="btn btn-dark" :to="{name:'newDriver'}"><em class="fa fa-plus-circle"></em></router-link>
    </div>
    <div class="row">

      <div class=" col-sm-6" v-for="(driver,index) in drivers" :key="index">
        <DriverCard :driver="driver" @save="save"/>
        <!-- end card -->
      </div>
    </div>
  </div>
</template>

<script>
import DriverCard from "@/components/delivery/drivers/DriverCard.vue";

export default {
  name: "DriversComponent",
  components: {DriverCard},
  data() {
    return {
      drivers: []
    }
  },
  methods: {
    save(driver) {
      this.axios.put(`/drivers/${driver.id}`, driver)
          .then(() => {
            this.$notify('Motorista atualizado com sucesso')
            this.getDrivers()
          })
          .catch(error => {
            console.log(error)
          })
    },
    getDrivers() {
      this.axios.get('/drivers')
          .then(response => {
            this.drivers = response.data
          })
          .catch(error => {
            console.log(error)
          })
    }
  },
  beforeMount() {
    this.getDrivers()
  }
}
</script>

