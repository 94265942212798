<script>
import EasyDataTable from "vue3-easy-data-table";
import {useUsersStore} from "@/store/usersStore";

export default {
  name: "UsersWrapper",
  components: {EasyDataTable},
  setup() {
    const usersStore = useUsersStore()
    return {
      usersStore
    }
  },
  data() {
    return {
      headers: [
        {
          text: this.$t('users.header.name'),
          value: 'name'
        },
        {
          text: this.$t('users.header.email'),
          value: 'email'
        },
        {
          text: this.$t('users.header.role'),
          value: 'role'
        },
        {
          text: this.$t('users.header.status'),
          value: 'status'
        },
        {
          text: this.$t('users.header.actions'),
          value: 'actions'
        }
      ]
    }
  },
  methods: {
    setUser(id) {
      this.usersStore.setUser(id)
      this.$router.push({name: 'editUser', params: {id}})
    }
  },
  computed: {
    hasInactive(){
      return this.usersStore.getUsers.some(user => user.status === 'Inactive')
    }
  },
  beforeMount() {
    this.usersStore.fetchUsers()
    this.usersStore.setUser(null)
    this.usersStore.setUserData(null)
  }
}
</script>

<template>
  <div class="page-content">
    <div class="container-fluid">
      <div class="py-2 d-flex justify-content-end gap-2">
        <button class="btn btn-primary" @click="$router.push({name:'createUser'})" v-wave><em
            class="fa fa-plus-circle"></em></button>
        <button class="btn btn-secondary" @click="usersStore.fetchUsers()" v-wave><em
            class="mdi mdi-refresh-circle"></em></button>
      </div>
      <EasyDataTable
          :headers="headers"
          :items="usersStore.getUsers">

        <template #item-actions="{id,status}">
          <div class="d-flex gap-2">
            <button class="btn btn-sm btn-primary" @click="setUser(id)" v-wave><em class="fa fa-edit"></em></button>
            <button class="btn btn-sm btn-danger"  v-if="status === 'Active'" @click="usersStore.deleteUser(id)" v-wave><em
                class="fa fa-trash"></em></button>
            <button class="btn btn-sm btn-success" @click="usersStore.activeUser(id)" v-if="status === 'Inactive'"><em class="fa fa-toggle-on"></em></button>

          </div>
        </template>

        <template #item-status="{status}">
          <span v-if="status === 'Active'" class="badge bg-success text-success rounded-circle p-2"> <span
              class="fa fa-user"></span></span>
          <span v-else class="badge bg-danger text-danger rounded-circle p-2"> <span class="fa fa-user"></span></span>
        </template>
      </EasyDataTable>
      <div class="alert alert-warning mt-2" v-if="hasInactive">
        <span>{{$t('users.hasInactive')}}</span>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>