import {useStorage} from "@vueuse/core";
import axios from "@/boostrap/apiConfig"
import {ErrorHandler} from "@/utils/ErrorHandler";
import {defineStore} from "pinia";

export const useChainDeliveryStore = defineStore('chainDelivery', {
    state: () => ({
        chainDeliveries: useStorage('chainDeliveries', []),
        providers: useStorage('providers', []),
        periods: useStorage('periods', []),
        period: useStorage('period', ''),
        provider: useStorage('provider', ''),
        store: useStorage('store', ''),
        eps: useStorage('eps', 3),
        minSamples: useStorage('minSamples', 2),
        loading: false
    }),
    getters: {
        getChainDeliveries() {
            return this.chainDeliveries;
        },
        getProviders() {
            return this.providers;
        },
        getPeriods() {
            return this.periods;
        },
        getPeriod() {
            return this.period;
        },
        getProvider() {
            return this.provider;
        },
        getStore() {
            return this.store;
        }

    },
    actions: {
        async getChainDeliveriesFetch() {
            this.loading = true;
            return axios.post(`/chain-deliveries/${this.provider}/${this.period}`,{
                warehouse: this.store,
                eps: this.eps,
                minSamples: this.minSamples
            }).then(response => {
                this.chainDeliveries = response.data.deliveries;
            }).catch(error => {
                ErrorHandler.handle(error, error.response.data.error);
            }).finally(() =>{
                this.loading = false;
            })
        },
        async fetchPossibleProviders() {
            return axios.get(`/chain-deliveries/providers`).then(response => {
                this.providers = response.data.providers;
            }).catch(error => {
                ErrorHandler.handle(error);
            })
        },
        async fetchPossiblePeriods() {
            return axios.get(`/chain-deliveries/periods`).then(response => {
                this.periods = response.data.periods;
            }).catch(error => {
                ErrorHandler.handle(error);
            })
        },
        async quote(deliveries){
            return axios.post('chain-deliveries/quote', {deliveries, warehouse: this.store, provider: this.provider}).then(response => {
                return response.data.quote;
            }).catch(error => {
                ErrorHandler.handle(error);
            })
        },
        async processDelivery(deliveries, force= false){
            return axios.post('chain-deliveries/process', {deliveries, warehouse: this.store, provider: this.provider, force}).then(response => {
                return response.data;
            }).catch(error => {
                throw error;
            })
        },
        fetchStore: async function() {
            return  axios.get('settings/store').then(response => {
                this.store = response.data.store;
            }).catch(error => {
                console.log(error);
            });
        },
    }
});