<template>
  <div class="page-content">
    <div class="container-fluid" v-if="!loading">
      <FormGenerator :form-schema="formSchema" :initial-values="initialValues" @onSubmit="onSubmit"/>
      <TabsFormGenerator :tabs="tabs" :initialValues="initialValues" @onSubmit="onSubmit"/>
    </div>
    <div v-else class="d-flex justify-content-center align-items-center" style="height: 100vh">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
import FormGenerator from "@/components/helper/formGenerator/FormGenerator.vue";
import TabsFormGenerator from "@/components/helper/formGenerator/TabsFormGenerator.vue";
import {useMyCompany} from "@/store/myCompanyStore";

export default {
  name: "MyCompany",
  setup() {
    const myCompanyStore = useMyCompany()
    return {
      myCompanyStore
    }
  },
  components: {TabsFormGenerator, FormGenerator},
  data() {
    return {
      loading: true,
      formSchema: [
        {
          "name": "name",
          "label": "Nome",
          "span": "full",
          "type": "text",
          "rules": "required"
        },
        {
          "name": "shipping_providers",
          "label": "Provedores de envio",
          "span": "left",
          "path": "shipping_provider",
          "type": "list",
          "headers": [
            {text: 'ID', value: 'id'},
            {text: 'Nome', value: 'name'},
          ],
          "scopes": [
            "add",
            "remove",
          ],
          "urls": {
            "get": "account/shipping_providers",
            "update": "account/shipping_providers",
            "remove": "account/shipping_providers"
          }
        },
        {
          "name": "warehouses",
          "label": "Depositos",
          "span": "right",
          "path": "depositos",
          "type": "list",
          "scopes": [],
          "headers": [
            {text: 'ID', value: 'id'},
            {text: 'Nome', value: 'name'},
          ],
          "urls": {
            "get": "account/warehouses",
            "update": "account/warehouses",
            "remove": "account/warehouses"
          }

        },
      ],
      tabs: [
        {
          "tab": "Informações para entega",
          "icon": "fa fa-truck",
          "fields": [
            {
              "name": "state_register",
              "label": "Registro estadual",
              "span": "left",
              "type": "text",
              "rules": "required",
            },
            {
              "name": "phone",
              "label": "Telefone",
              "span": "right",
              "type": "text",
              "tab": "Informações para entega",
              "rules": "required",
            },
            {
              "name": "email",
              "label": "Email",
              "span": "left",
              "type": "text",
              "tab": "Informações para entega",
              "rules": "required|email",
            },
            {
              "name": "company_document",
              "label": "CNPJ ou CPF",
              "span": "right",
              "type": "text",
              "comment": "sem pontuação",
              "tab": "Informações para entega",
              "rules": "required",
            },
            {
              "name": "note",
              "label": "detalhes adicionais",
              "span": "left",
              "type": "text",
              "tab": "Informações para entega",
              "rules": "required",
            },
          ]
        },
        {
          "tab": "showcase",
          "icon": "fa fa-store",
          "fields": [
            {
              "name": "logo",
              "label": "Logo",
              "span": "auto",
              "type": "fileupload",
              "mode": "image",
              "imageWidth": 150,
              "imageHeight": 50,
              "tab": "Showcase",
              "url": "account/logo"
            },
            {
              "name": "background",
              "label": "Background",
              "span": "auto",
              "type": "fileupload",
              "mode": "image",
              "tab": "Showcase",
              "url": "account/background",
            },
            {
              "name": "about",
              "label": "Sobre",
              "span": "auto",
              "type": "text",
              "tab": "Showcase"
            }
          ]
        }
      ]
    }
  },
  computed: {
    initialValues() {
      return this.myCompanyStore.getMyCompany?.data
    }
  },
  async beforeMount() {
      await this.fetchData()
  },
  methods: {
    async fetchData(){
      this.loading = true
      await this.myCompanyStore.fetchMyCompany().finally(() => {
      })
      this.loading = false
    },
     onSubmit(data) {
      this.myCompanyStore.updateMyCompany(data).then(async () => {
        this.$notify({
          title: 'Sucesso',
          text: 'Dados atualizados com sucesso',
          type: 'success'
        })
        await this.fetchData()
      }).catch(() => {
        this.$notify({
          title: 'Erro',
          text: 'Erro ao atualizar dados',
          type: 'error'
        })
      })
    }
  }

}
</script>
<style scoped>

</style>