<template>
  <Form @submit="handleSubmit" ref="form">
    <slot name="header">

    </slot>
    <slot>
      <div class="form-group row">
        <div v-for="field in formSchema" :key="field.name" class="mb-3" :class="getFieldLabelClass(field)">
          <label :for="field.name">{{ field.label }} <span class="text-danger" v-if="isRequired(field)">*</span></label>
          <template v-if="field.options">
            <VueMultiselect :id="field.name" :name="field.name" v-model="form[field.name]" :multiple="field.multi"
                            :options="getOptions(field.options)"
                            :disabled="field.disabled" label="name" track-by="name" :preselect-first="true">

            </VueMultiselect>
          </template>
          <template v-else-if="field.type === 'list'">
           <ListType :scopes="field.scopes" :headers="field.headers" :get-items-url="field.urls.get" :update-items-url="field.urls.update" :remove-items-url="field.urls.remove"/>
          </template>
          <template v-else-if="field.type === 'fileupload'">
            <FIleUpload :upload-url="field.url" :mode="field.mode" :field="field" :initial-values="initialValues" />
          </template>
          <template v-else-if="field.type === 'switch'">
<!--            //boostrap switch-->
            <div class="form-check form-switch">
              <input class="form-check-input" v-model="form[field.name]" :checked="form[field.name]" type="checkbox" role="switch" :id="field.name">
              <label class="form-check-label" for="flexSwitchCheckDefault">{{field.label}}</label>
            </div>
          </template>
          <template v-else-if="field.type === 'recordFinder'">
            <RecordFinder :initial-value="initialValues[field.name]" :field="field" @record-selected="setFormValue"></RecordFinder>
          </template>
          <template v-else-if="field.type === 'dropdown'">
            <DropdownSelect :dependsOnValue="form[field.dependsOn]" v-model="form[field.name]" :selectValue="field?.selectValue" :options="field?.options" :async-url="field.async.url" v-if="!(field.dependsOn) || form[field.dependsOn]"></DropdownSelect>
            <select v-else disabled class="form-control">
              <option value="" disabled selected> {{ $t('app.selectFirst', [findField(field.dependsOn)?.label]) }}</option>
            </select>
          </template>
          <template v-else-if="field.mask">
            <!-- Field with mask -->
            <Field v-if="field.mask" :name="field.name" :rules="fieldRules(field)" @blur="actionBlur(field)">
              <template #default="{ field: veeField }">
                <input v-maska
                       :data-maska="field.mask"
                       v-bind="veeField"
                       v-model="form[field.name]"
                       :type="field.type"
                       :id="field.name"
                       class="form-control"
                       :class="{'form-check-input': field.type === 'checkbox'}"
                       :checked="field.checked"
                       :disabled="field.disabled">
              </template>
            </Field>
          </template>
          <template v-else>
            <Field v-model="form[field.name]"  :name="field.name" :label="field.label" :rules="fieldRules(field)" @blur="actionBlur(field)" >
              <template #default="{ field: veeField,errors, valid, }">
                <input v-bind="veeField"
                       :type="field.type"
                       :id="field.name"
                       class="form-control"
                       :checked="field.checked"
                       :class="{'is-invalid': errors[0],'is-valid':valid,'form-check-input': field.type === 'checkbox' }"
                       :disabled="field.disabled">
              </template>
            </Field>
          </template>
          <small v-if="field.comment" class="form-text text-muted d-block">{{ field.comment }}</small>
          <ErrorMessage :name="field.name" class="invalid-tooltip" />

        </div>
      </div>
    </slot>
    <slot class="form-group row" name="submit">
      <div class="form-group mt-2 text-center ">
        <div class="col-sm-12">
          <button type="submit" class="btn btn-primary" v-if="!noButton" > {{ $t(textButton) }} <span v-if="alteredFieldsCount > 0"> ({{ alteredFieldsCount }}) </span></button>
        </div>
      </div>
    </slot>
  </Form>
</template>
<script>
import VueMultiselect from 'vue-multiselect'
import {Form, Field, ErrorMessage} from 'vee-validate'
import ListType from "@/components/helper/formGenerator/types/ListType.vue";
import FIleUpload from "@/components/helper/formGenerator/types/FIleUpload.vue";
import RecordFinder from "@/components/helper/formGenerator/types/RecordFinder.vue";
import {useFormGeneratorStore} from "@/components/helper/formGenerator/store/formGeneratorStore";
import {vMaska} from "maska";

import { configure } from 'vee-validate';
import {useLocalizationStore} from "@/store/localizationStore";
import DropdownSelect from "@/components/helper/formGenerator/types/DropdownSelect.vue";



export default {
  name: 'FormGenerator',
  components: {DropdownSelect, RecordFinder, FIleUpload, ListType, VueMultiselect, Form, Field, ErrorMessage},
  setup(){
    const localizationStore = useLocalizationStore()
    configure({
      generateMessage: context => {
        return localizationStore.translator(context.rule.name, context.field, context.rule.params.toString())
      },
    });
  },
  props: {
    formSchema: {},
    textButton: {
      type: String,
      default: 'app.submit'
    },
    noButton: {
      type: Boolean,
      default: false
    },
    initialValues: {
      type: Object,
      default: () => {
      }
    },
    id: {
      type: String,
      default: () => {
      }
    },
    useStore: {
      type: Boolean,
      default: false
    }
  },
  directives: { maska: vMaska },
  data() {
    return {
      formId: null,
      form: {},
      oldValues: {}
    }
  },
  mounted() {
    if (this.initialValues){
      this.form = this.initialValues
      this.oldValues = JSON.parse(JSON.stringify(this.initialValues));
    }

    this.formId = Math.random().toString(36).substring(7);
  },
  beforeUnmount() {
    if (this.useStore) {
      const store = useFormGeneratorStore();
      store.unregisterForm(this);
    }
  },
  created() {
    if (this.useStore) {
      const store = useFormGeneratorStore();
      store.registerForm(this);
    }
  },
  computed:{
    alteredFieldsCount() {
      // Check if formSchema is defined and is an array
      if (!Array.isArray(this.formSchema)) {
        return 0;
      }

      return this.formSchema.filter(field =>
          this.oldValues?.[field?.name] !== this.initialValues?.[field?.name]
      ).length;
    },

  },
  methods: {
    actionBlur(field) {
        if (field.blur && typeof field.blur === 'function'){
          field.blur()
        }
    },
    findField(name) {
      let field =  this.formSchema.find((field) => {
        return field.name === name
      })
      console.log(field)
      return field
    },
    getOptions(options) {
      if (typeof options === 'function') {
        return options()
      }
      return options
    },
    setFormValue(value, field) {
      this.form[field] = value
    },
    isRequired(field) {
      return field.rules ? field.rules.includes('required') : false
    },
    fieldRules(field) {
      return field.rules ? field.rules : ''
    },
    validate(){
      return this.$refs.form.validate()
    },
    async handleSubmit() {
      let result = await this.$refs.form.validate()
      console.log(result)
      if (result.valid) {
        // get only fields that are in formSchema
        let form = {}
        for (let field of this.formSchema) {
          if (this.form[field.name]) {
            form[field.name] = this.form[field.name]
          }
        }
        this.$emit('onSubmit', form)
      }
    },
    getFieldLabelClass(field) {
      const classes = [];
      classes.push("span")
      if (field.span) {
        classes.push(`span-${field.span}`);
      }
      if (field.hidden) {
        classes.push(`hidden`);
      }
      return classes;
    },

    watch: {
      initialValues: {
        handler: function (val) {
          this.form = val
          this.oldValues = JSON.parse(JSON.stringify(val));
        },
        deep: true
      }
    }

  }
}
</script>

<style scoped lang="scss">

.span-full {
  width: 100%;
  float: left;
}

.span-left {
  float: left;
  width: 50%;
  clear: left;
}

.span-right {
  float: right;
  width: 50%;
  clear: right;
}

.clear-full {
  clear: both;
}

.clear-left {
  clear: left;
}

.clear-right {
  clear: right;
}

</style>