<template>
  <div class="col-md-12 d-flex justify-content-center p-2">
    <div class="row">
      <div class="dol-md-12 text-center">
        <span>{{ field.name }}</span>
      </div>
      <div class="col-md-12 d-flex justify-content-center">
        <QuantityButton :name="'fields['+field.hashId+']'" :id="'field-'+field.hashId" :defaultQuantity="0"/>
      </div>
    </div>


  </div>
</template>

<script>
import QuantityButton from "@/components/pdv/products/QuantityButton.vue";

export default {
  name: 'additionalComponent',
  components: {QuantityButton},
  props: {
    field: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },

}
</script>

<style lang="scss" scoped>

</style>