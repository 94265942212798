<template>
  <div class="tab-pane fade" id="pos" role="tabpanel" aria-labelledby="pos-tab">
    <div class="container-fluid mt-3">
      <div class="mb-3 row">
        <div class="col-md-6">
          <select v-model="selectedPrinter" class="form-select">
            <option>Select</option>
            <option :key="printer.driverName" :value="printer.name" v-for="printer in availablePrinters">
              {{ printer.name }}
            </option>
          </select>
        </div>
        <div class="col-md-6">
          <input type="text" v-model="buffer" class="form-control" id="validationCustom01"
                 placeholder="Tamanho do Buffer" required="">
          <div class="valid-tooltip">
            Looks good!
          </div>
        </div>
        <div class="col-md-12">
          <div class="mb-0 mt-2 text-center">
            <div>
              <button type="submit" @click="savePosPrinter" v-wave class="btn btn-pink waves-light ">
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'pos-printer',
  props: {
    availablePrinters: {}
  },
  data() {
    return {
      selectedPrinter: null,
      buffer: null,
    }
  },
  methods: {
    savePosPrinter: function() {
      this.axios.post('/settings/pos-printer', {
        printer: this.selectedPrinter,
        buffer: this.buffer
      }).then(() => {
        this.$notify('Impressora POS salva com sucesso!');
      })
    },
    getPostPrinter: function() {
      this.axios.get('/settings/pos-printer').then(response => {
        this.selectedPrinter = response.data.printer
        this.buffer = response.data.buffer
      })
    }
  },
  mounted() {
    this.getPostPrinter()
  }
}
</script>
