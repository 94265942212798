<template>
  <div class="page-content slideInDown animated">
    <error-box :error.sync="error" @clearError="error = null"/>
    <div class="row mb-2">
      <div class="col-md-6">
        <VueMultiselect v-model="selectedStates" :options="statesOptions" :multiple="true"></VueMultiselect>
      </div>
      <div class="col-md-4">
        <input type="text" id="search" class="form-control" v-model="searchText" @input="searchFunction" placeholder="Procurar">
      </div>
      <div class="col-md-2 d-flex justify-content-end">
        <p class="card-title-desc mx-2">Mostrar Tudo</p>
        <input type="checkbox" id="switch9" switch="dark" v-model="filterAll" checked="">
        <label for="switch9" data-on-label="Sim" data-off-label="não"></label>
      </div>
      <div class="col-md-2">
        <input type="date" id="startDate" class="form-control" v-model="startDate"  placeholder="Data inicial">
      </div>
      <div class="col-md-2">
        <input type="date" id="endDate" class="form-control" v-model="endDate" placeholder="Data final">
      </div>
      <div class="col-md-4">
        <button class="btn btn-primary" @click="FilterByDate">Filtrar</button>
      </div>
    </div>
    <div class="accordion accordion-flush slideInLeft animated" id="ordersAccordion"  v-if="!loading">
      <lazy-load-component  v-for="(group,index) in filteredByState" :key="index">
        <div class="accordion-item" >
          <h2 class="accordion-header" id="flush-headingOne">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    :data-bs-target="'#flush-'+index" aria-expanded="false" aria-controls="'flush-'+index">
              {{ momentParse(index) }}
            </button>
          </h2>
          <div :id="'flush-'+index" class="accordion-collapse collapse" aria-labelledby="flush-headingOne"
               data-bs-parent="#ordersAccordion" style="">
            <div class="accordion-body text-body">
              <lazy-load-component   v-for="order in group" :key="order.id">

                <order @error="errorEmitted" @refresh="refresh" :buttonDefinitions="buttonDefinitions" :data="order"
                :exhibition="true"/>
              </lazy-load-component>
            </div>
          </div>
        </div>
      </lazy-load-component>
    </div>
  </div>
  <div class="container p-4">
    <div class="row">
      <div class="col-md-12 d-flex justify-content-center gap-3">
        <button class="btn btn-primary" v-if="hasPreviousPage" @click="previousPage">Anterior</button>
        <button class="btn btn-primary" v-if="hasNextPage" @click="nextPage">Próximo</button>
      </div>
    </div>
  </div>
</template>

<script>
import VueMultiselect from 'vue-multiselect'
import Order from "@/components/orders/Order.vue";
import ErrorBox from '../generic/ErrorBox.vue';
import LazyLoadComponent from "../helper/lazyload/LazyLoadComponent.vue";

export default {
  name: "OrderHistoryWrapper",
  data() {
    return {
      currentPage: 1,
      width: null,
      error: null,
      orderGroups: null,
      selectedGroups: null,
      vendorCode: null,
      filterAll: null,
      statesOptions: [],
      selectedStates: null,
      filteredByState: null,
      buttonDefinitions: null,
      searchText: '',
      loading: false,
      startDate: null,
      endDate: null,
      hasNextPage: true,
      hasPreviousPage: false,
    }
  },
  methods: {
    FilterByDate() {
      this.currentPage = 1
      this.getOrders()
    },
    nextPage() {
      this.currentPage += 1
      this.getOrders()
    },
    previousPage() {
      this.currentPage -= 1
      if (this.currentPage < 1) {
        this.currentPage = 1
      }
      this.getOrders()
    },
    searchFunction() {
      let data = this.filterAllFunction(this.selectedStatesFunction(this.orderGroups))
      const asArray = Object.entries(data);
      let filtered = []
      // filter orders by delivery or order code
      for (let i = 0; i < asArray.length; i++) {
        for (let j = 0; j < asArray[i][1].length; j++) {
          if (asArray[i][1][j].order.order_number.toString().includes(this.searchText) || asArray[i][1][j].code.toString().includes(this.searchText)) {
            filtered.push(asArray[i][1][j])
          }
        }
      }
      // transform to object again
      let obj = {}
      for (let i = 0; i < filtered.length; i++) {
        if (!Object.prototype.hasOwnProperty.call(obj, filtered[i].delivery_in)) {
          obj[filtered[i].delivery_in] = [];
        }
        obj[filtered[i].delivery_in].push(filtered[i])
      }
      this.filteredByState = this.filterAllFunction(obj)
    },
    getOrders: function() {
      this.loading = true
      let url  = `orders/history?page=${this.currentPage}`
      if (this.startDate) {
        url += `&startDate=${this.startDate}`
      }
      if (this.endDate) {
        url += `&endDate=${this.endDate}`
      }
      this.axios.get(url)
          .then(response => {
            this.orderGroups = this.sortObject(response.data.data)
            this.getPossibleStateFromSelectedGroups(response.data.data)
            this.hasNextPage = response.data.next_page_url !== null
            this.hasPreviousPage = response.data.prev_page_url !== null
            this.selectedStates = ''
          }).catch(error => {
        if (error.message === 'Network Error') {  // we got an network error - server is down?
          this.error = 'Server is down - contact site admin.';
        } else {                // we got error from the server
          this.error = error.response.data.error;
        }
      }).finally(() => {
        this.loading = false
        this.filterAll = true
        this.filterAll = false
        this.filteredByState = this.filterAllFunction(this.selectedStatesFunction(this.orderGroups))
      })
    },
    sortObject: function(o) {
      return Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {});
    },
    momentParse: function(date) {
      return this.moment(date).format('MMMM D, dddd, YYYY');
    },
    getPossibleStateFromSelectedGroups: function(data) {
      let states = []
      const asArray = Object.entries(data);
      for (let i = 0; i < asArray.length; i++) {
        for (let j = 0; j < asArray[i][1].length; j++) {
          if (!states.includes(asArray[i][1][j].order_state) && asArray[i][1][j].order_state != null) {
            states.push(asArray[i][1][j].order_state.name)
          }
        }
      }
      // remove duplicated states
      states = [...new Set(states)]
      this.statesOptions = states
      return states
    },
    filterAllFunction: function(val) {
      if (!this.filterAll) {
        //filter  by date now and old ordergroups is a boject
        const asArray = Object.entries(val);

        const filtered = asArray.filter(([key]) => {
          let now = this.moment().format('YYYY-MM-DD')
          return key <= now
        });
        // give back date to key and value to value
        return Object.fromEntries(filtered);

      } else {
        return val
      }
    },
    selectedStatesFunction: function(value) {
      //select order by status
      if (this.selectedStates.length > 0) {
        let data = this.filterAllFunction(value)
        const asArray = Object.entries(data);
        let filtered = []
        // get orders with multiple states in val
        for (let i = 0; i < asArray.length; i++) {
          for (let j = 0; j < asArray[i][1].length; j++) {
            if (this.selectedStates.includes(asArray[i][1][j].order_state.name)) {
              filtered.push(asArray[i][1][j])
            }
          }
        }
        // trasnform to object again
        let obj = {}
        for (let i = 0; i < filtered.length; i++) {
          //error: Do not access Object.prototype method 'hasOwnProperty' from target object (no-prototype-builtins) at

          if (!Object.prototype.hasOwnProperty.call(obj, filtered[i].delivery_in)) {
            obj[filtered[i].delivery_in] = [];
          }
          obj[filtered[i].delivery_in].push(filtered[i])
        }
        return this.filterAllFunction(obj)
      } else {
        return this.filterAllFunction(value)
      }

    },
    errorEmitted: function(error) {
      this.error = error
    },
    refresh: function() {
      this.getOrders()

      // aplply all filter again

    },
  },
  beforeMount() {
    //get .page-content width minus margin and padding
    this.width = 1599
    this.getOrders()

  }, watch: {
    filterAll: function() {
      this.filteredByState = this.filterAllFunction(this.selectedStatesFunction(this.orderGroups))
    },
    selectedStates: function(val) {
      //select order by status
      if (val.length > 0) {
        let data = this.filterAllFunction(this.orderGroups)
        const asArray = Object.entries(data);
        let filtered = []
        // get orders with multiple states in val
        for (let i = 0; i < asArray.length; i++) {
          for (let j = 0; j < asArray[i][1].length; j++) {
            if (val.includes(asArray[i][1][j].order_state.name)) {
              filtered.push(asArray[i][1][j])
            }
          }
        }
        // trasnform to object again
        let obj = {}
        for (let i = 0; i < filtered.length; i++) {
          if (!Object.prototype.hasOwnProperty.call(obj, filtered[i].delivery_in)) {
            obj[filtered[i].delivery_in] = [];
          }
          obj[filtered[i].delivery_in].push(filtered[i])
        }
        this.filteredByState = this.filterAllFunction(obj)
      } else {
        this.filteredByState = this.filterAllFunction(this.orderGroups)
      }

    },
  },
  components: {
    LazyLoadComponent,
    Order,
    VueMultiselect,
    ErrorBox
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
