<template>
  <div class="col-md-6">
    <div class="card info-box">
      <div class="card-header d-flex justify-content-between">
        <strong>{{ $t('cashier.transactions.title')}}</strong>
        <button class="btn btn-sm  btn-primary btn-rounded btn-sm" @click="generateReport"
                v-if="!cashierStore.getReportData && authStore.checkRole('systemRole', 'COM')">{{ $t('cashier.generateReport')}}
        </button>
        <JsonCSV class="btn btn-sm btn-success btn-rounded btn-sm" :data="cashierStore.getReportData"
                 @click="cashierStore.reportData = null"
                 v-if="authStore.checkRole('systemRole', 'COM') && cashierStore.getReportData">
          {{ $t('cashier.downloadReport')}}
        </JsonCSV>
      </div>
      <PerfectScrollbar>
        <div class="bg-transparent d-flex  w-100 gap-3 m-auto vertically-center p-1"
             v-for="transaction in cashierStore.getTransactions.slice(0,15)" :key="transaction.id">
          <span class="block-icon p-3" :class="getIcons(transaction.type)"></span>
          <span>
                  <strong>{{ transaction.username }}</strong> {{ getMessage(transaction.type) }} <span
              v-currency="transaction.amount /100" v-if="showCashier"
              :class="{'text-success': transaction.amount > 0, 'text-danger': transaction.amount < 0}"></span>
                  <span v-else>********</span>
                </span>
        </div>
      </PerfectScrollbar>
    </div>
  </div>
</template>
<script>
import {useCashierStore} from "@/store/cashierStore";
import {useAuthStore} from "@/store/authStore";
import JsonCSV from 'vue-json-csv'

export default {
  name: 'CashierTransactions',
  setup() {
    let cashierStore = useCashierStore()
    let authStore = useAuthStore()
    return {cashierStore, authStore}
  },
  components: {
    JsonCSV
  },
  props: {
    showCashier: {}
  },
  methods: {
    generateReport() {
      this.$swal({
        title: this.$t('cashier.selectReportType'),
        input: 'select',
        inputOptions: {
          '': this.$t('cashier.reportTypes.daily'),
          'week': this.$t('cashier.reportTypes.weekly'),
          'month': this.$t('cashier.reportTypes.monthly'),
          'todos': this.$t('cashier.reportTypes.all'),
          'incidents': this.$t('cashier.reportTypes.incidents'),
        },
        inputPlaceholder: this.$t('cashier.selectReportType'),
        focusConfirm: false,
      }).then((result) => {
        let type = result.value
        this.cashierStore.generateReport(type).then(() => {
          this.$swal({
            title: this.$t('cashier.reportGenerated'),
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          })
        })
      })
    },
    getMessage(type) {
      switch (type) {
        case 'open-cashier':
          return this.$t('cashier.transactions.openCashier')
        case 'sale':
          return this.$t('cashier.transactions.sale')
        case 'addition':
          return this.$t('cashier.transactions.addition')
        case 'subtraction':
          return this.$t('cashier.transactions.subtraction')
        case 'close-cashier':
          return this.$t('cashier.transactions.closeCashier')
        case 'change':
          return this.$t('cashier.transactions.change')
        case 'refund':
          return this.$t('cashier.transactions.refund')
        case 'payments-to-suppliers':
          return this.$t('cashier.transactions.paymentsToSuppliers')
      }
    },
    getIcons(type) {
      switch (type) {
        case 'open-cashier':
          return 'fa fa-power-off text-success'
        case 'sale':
          return 'fa fa-arrow-up text-success'
        case 'addition':
          return 'fa fa-plus text-success'
        case 'subtraction':
          return 'fa fa-minus text-danger'
        case 'close-cashier':
          return 'fa fa-power-off text-secondary'
        case 'change':
          return 'fa fa-exchange-alt text-danger'
        case 'refund':
          return 'fa fa-exchange-alt text-danger'
        case 'payments-to-suppliers':
          return 'fa fa-exchange-alt text-primary'
      }

    }
  }
}
</script>
<style scoped>

.info-box {
  max-height: 350px;
  overflow-y: auto;
}

.vertically-center {
  align-items: center;
}

.block-icon {
  height: 50px;
  width: 50px;
  background-color: white;
  border-radius: 15px
}
</style>