<template>
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-12 justify-content-start">
      <posHeader :header="$t('app.products')" :fixed="fixed" @toggle-fixed="fixed = !fixed" @searchHandler="searchHandler" />
      </div>
      <div class="col-md-12 col-md-6 mt-2 ">
        <nav aria-label="Page navigation">
          <ul class="pagination justify-content-center">
            <li class="page-item" :class="{ 'disabled': currentPage === 1 }">
              <button class="page-link" @click="goToPage(currentPage - 1)" aria-label="Previous">
                <span aria-hidden="true">&laquo;</span>
              </button>
            </li>
            <li class="page-item" v-for="page in totalPages" :key="page" :class="{ 'active': currentPage === page }">
              <button class="page-link" @click="goToPage(page)">{{ page }}</button>
            </li>
            <li class="page-item" :class="{ 'disabled': currentPage === totalPages }">
              <button class="page-link" @click="goToPage(currentPage + 1)" aria-label="Next">
                <span aria-hidden="true">&raquo;</span>
              </button>
            </li>
          </ul>
        </nav>
      </div>
      <hr>
    </div>
    <perfect-scrollbar v-auto-animate class="animated fadeIn animatedHeight p-2 m-0 row" v-if="hasProducts" :class="{'fixed-height': fixed, 'unfixed-height': !fixed}" :options="options">
          <PosProduct :product="product" v-for="(product) in paginatedProducts" :key="product.id" :unrender="true" />
    </perfect-scrollbar>
    <div v-else class="col-md-12 mt-4 text-center">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>


  </div>
</template>

<script>
import PosHeader from "@/components/pdv/PosHeader.vue";
import PosProduct from "@/components/pdv/products/PosProduct.vue";
import { usePosStore } from "@/store/PosStore";

export default {
  name: "PosProducts",
  components: { PosProduct, PosHeader },
  setup() {
    const posStore = usePosStore();
    return { posStore };
  },
  data() {
    return {
      options: {
        suppressScrollX: true,
      },
      fixed: true,
      search: "",
      currentPage: 1,
      pageSize: 3,
    };
  },
  methods: {
    searchHandler: function (search) {
      this.search = search;
      this.currentPage = 1; // Reset to the first page when searching
    },
    goToPage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
  },
  computed: {
    products() {
      return this.posStore.getProducts;
    },
    hasProducts() {
      return this.posStore.getProducts.length > 0;
    },
    filteredProducts() {
      return this.products.filter((product) => {
        let searchPlaces = [
          product.name,
          product.sku,
        ];
        let data = searchPlaces.join(" ");
        return data.toLowerCase().includes(this.search.toLowerCase());
      });
    },
    paginatedProducts() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.filteredProducts.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredProducts.length / this.pageSize);
    },
  },
  beforeMount() {
    this.posStore.getCatalogs()
  }
};
</script>
<style scoped>
.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all .0s ease;
}

.slide-fade-enter {
  transform: translateX(100px);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateX(-100px);
  opacity: 0;
}

/* Back Scrolling */
.slide-fade-reverse-enter-active {
  transition: all .3s ease;
}

.slide-fade-reverse-leave-active {
  transition: all .3s ease;
}

.slide-fade-reverse-enter {
  transform: translateX(-100px);
  opacity: 0;
}

.slide-fade-reverse-leave-to {
  transform: translateX(100px);
  opacity: 0;
}
</style>