<template>
  <div class="card border border-1" v-if="!removing">
    <div class="card-body row" v-if="field">
      <div class="col-md-3">
        {{ field.name }}
      </div>
      <div class="col-md-3 ">{{ $t('product.customFields.fields.'+field.type) }}</div>
      <div  class="col-md-3"><span v-if="field.prices.length >0">{{ field.prices[0]['price_formatted'] }}</span> <span v-else>{{$t('app.free')}}</span></div>
      <div class="col-md-3">
        <!--    iedit button and remove-->
        <button class="btn btn-danger" @click="removeField(field)"><em class="dripicons-trash"></em></button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "CustomFieldWrapper",
  props: {
    field: {}
  },
  data() {
    return {
      removing: false
    }
  },
  methods: {
    removeField(field) {
      this.$emit('removeField', field);
    }
  }
}
</script>

<style scoped>

</style>