<template>
  <div class="col-md-6" v-if="!loading">
    <div v-if="this.plannerStore.getPeriods.length > 0">
      <div class="card mb-3" v-for="periods in this.plannerStore.getPeriods" :key="periods.id">
        <div class="card-body">
          <h5 class="card-title">{{ periods.name }}</h5>
          <p class="card-text">
            <strong>Inicio: </strong>{{ periods.starts_date }}<br>
            <strong>Fim: </strong>{{ periods.ends_date }}<br>
            <strong>Receber até: </strong>{{ periods.receive_until }}<br>
            <strong>Entregar até: </strong>{{ periods.end_at }}<br>
            <strong>Maximo de entregas: </strong>{{ periods.max_delivery }}
          </p>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6">
                <button class="btn btn-danger" @click="deletePeriod(periods.id)">Excluir</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="card-body">
        <div class="text-center">
          <span class="text-muted">nenhum periodo adicionado</span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6" v-else>
    <div class="card">
      <div class="card-body">
        <div class="text-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {usePlannerStore} from "@/store/plannerStore";

export default {
  name: 'EventSpecialPeriods',
  data() {
    return {loading: false}
  },
  setup() {
    const plannerStore = usePlannerStore()
    return {
      plannerStore
    }
  },
  methods:{
    deletePeriod(periodId){
      this.loading = true
      let id = this.$route.params.id
      this.plannerStore.deleteSpecialPeriod(id,periodId).finally(() => {
        this.loading = false
      })
    }
  },
  beforeMount() {
    this.loading = true
    let id = this.$route.params.id
    this.plannerStore.fetchPeriods(id).finally(() => {
      this.loading = false
    })
  }
}
</script>