<template>
  <div class="container mt-3">
    <div class="mb-3">
      <button class="btn btn-primary" @click="duplicateContent">Adicionar Mais</button>
    </div>
    <div v-for="(item, index) in duplicatedItems" :key="item.id">
      <div class="col-md-12 text-end">
        <div v-if="duplicatedItems.length > 1" class="toggle-control " @click="toggleVisibility(item)">
          {{ item.isVisible ? 'Hide' : 'Show' }}
        </div>
        <hr>
      </div>
      <!-- Updated toggle control -->

      <div v-show="item.isVisible">
        <slot></slot> <!-- Slot for duplicating content -->
        <!-- Remove button, not shown for the first item -->
        <button v-if="index !== 0" class="btn btn-danger btn-sm ml-2" @click="removeItem(index)">Remove</button>
      </div>
    </div>
  </div>
</template>

<script>
import {NotifyHandler} from "@/utils/NotifyHandler";

export default {
  name: 'ComponentDuplicator',
  props: {
    maxItems: {
      type: Number,
      default: 10,
    },
    messageLimit: {
      type: String,
      default: 'Você atingiu o limite de itens duplicados',
    },
  },
  data() {
    return {
      duplicatedItems: [{ id: 1, isVisible: true }], // Initialize with one visible item
    };
  },
  methods: {
    duplicateContent() {
      if (this.duplicatedItems.length >= this.maxItems){

        NotifyHandler.handle('warning',this.messageLimit);
        return;
      }
      this.duplicatedItems.push({ id: this.duplicatedItems.length + 1, isVisible: true });
    },
    removeItem(index) {
      this.duplicatedItems.splice(index, 1);
    },
    toggleVisibility(item) {
      item.isVisible = !item.isVisible;
    }
  }
};
</script>

<style>
.toggle-control {
  cursor: pointer;
  padding: 5px;
  border: 1px solid #ddd;
  display: inline-block;
  margin-bottom: 5px;
}
</style>
