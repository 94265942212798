const pjson = require('../package.json');
const metadata = require('../src/metadata.json');

export const info = {
    version: pjson.version,
    applicationName: 'Commerce Order Manager',
    packageName: 'Atreus',
    build: metadata.buildNumber
}

