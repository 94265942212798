<template>
  <VueMultiselect v-if="authStore.checkRole('systemRole', 'COM')" v-model="perspective" :allowEmpty="false"
                  :options="cashierStore.getPerspectives" label="name" :multiple="false"
                  trackBy="name"></VueMultiselect>
  <h2 v-else>{{ authStore.user.name }}</h2>
</template>
<script>
import {useCashierStore} from "@/store/cashierStore";
import {useAuthStore} from "@/store/authStore";
import VueMultiselect from "vue-multiselect";


export default {
  name: 'CashierPerspective',
  data() {
    return {
      perspective: {username: this.cashierStore.perspective, name: this.authStore.user.name}
    }
  },
  setup() {
    let cashierStore = useCashierStore()
    let authStore = useAuthStore()
    return {cashierStore, authStore}
  },
  beforeMount() {
    if (this.authStore.checkRole('systemRole', 'COM')) {
      this.cashierStore.fetchPerspectives()
    }
  },
  watch: {
    perspective: function (val) {
      this.cashierStore.changePerspective(val.username)
    }
  },
  components: {
    VueMultiselect
  },
}
</script>