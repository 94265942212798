<template>
  <div class="card p-2" v-auto-animate>
    <div class="card-header d-flex justify-content-between ">
      <h3 class="my-auto">{{$t('app.filter')}}</h3>
      <button class="btn btn-primary" @click.prevent="show= !show"><i class="fa fa-filter"></i></button>
    </div>
    <div class="card-body" v-show="show" v-auto-animate>
      <div class="row g-2">
        <component
            v-for="(filter, index) in filters"
            :key="index"
            :is="filter.component"
            ref="filterComponents"
            :data="originalData"
        />
        <div class="col-md-12 text-center">
          <button @click="applyAllFilters" class="btn btn-primary" v-wave>Aplicar FIltros</button>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import FilterBySearch from './FilterBySearch.vue';
import FilterByStates from './FilterByStates.vue';
import FilterByShowAll from './FilterByShowAll.vue';
import FilterByBadges from "@/components/orders/filters/FilterByBadges.vue";

export default {
  components: {
    FilterBySearch,
    FilterByStates,
    FilterByBadges,
    FilterByShowAll
  },
  props: {
    originalData: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      show: false,
      filteredData: [],
      filters: [
        {component: 'FilterBySearch'},
        {component: 'FilterByStates'},
        {component: 'FilterByBadges'},
        {component: 'FilterByShowAll'},
      ]
    };
  },
  methods: {
    applyAllFilters() {
      this.$emit('filtering', true)
      let data = this.originalData
      this.$refs.filterComponents.forEach(filterComp => {
        if (filterComp.isActive) {
          data = filterComp.filterData();
        }
      });
      this.filteredData = data;
      this.$emit('filtered', data);
      this.$emit('filtering', false)
    }
  },
  mounted() {
    this.applyAllFilters();
  }
}
</script>