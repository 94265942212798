<template>
  <div class="container-fluid mt-3">
    <save-update-action-bar @save="save" @update="update"></save-update-action-bar>
    <div class="row">
      <div class="col-md-12">
        <div class="card" v-for="(properties,index) in productCopy.additional_properties " :key="'properties'+index">
          <div class="card-body">
            <input type="text" v-model="properties.name" class="form-control" placeholder="nome"/>
            <textarea id="textarea" class="form-control" v-model="properties.value" maxlength="225" rows="3"
                      placeholder="Valores"></textarea>
          </div>
          <button class="btn btn-secondary btn-sm" @click="removeProperties(index)">{{$t('app.delete')}}</button>
        </div>

      </div>
      <div class="col-md-12">
        <button class="btn btn-secondary btn-lg btn-block" @click="addProperties">{{$t('app.add')}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import SaveUpdateActionBar from "@/components/generic/SaveUpdateActionBar";

export default {
  name: "DetailsComponent",
  props: {
    product: {}
  },
  data() {
    return {
      productCopy: {}
    };
  },
  created() {
    this.productCopy = Object.assign({}, this.product)
  },
  methods: {
    save() {
      this.$emit("save", this.productCopy);
    },
    update() {
      this.$emit("update");
    },
    addProperties() {
      if (!this.product.additional_properties) {
        this.productCopy.additional_properties = [];
      }
      this.productCopy.additional_properties.push({
        name: "",
        value: ""
      });
    },
    removeProperties(index) {
      this.productCopy.additional_properties.splice(index, 1);
    }
  },
  components: {
    SaveUpdateActionBar
  }
}
</script>

<style scoped>

</style>