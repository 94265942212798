<template>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">

            <h4 class="card-title text-uppercase">Novo Campo {{getFieldName()}}</h4>
            <p class="card-title">Crie um novo campo</p>
            <!--            create fields for the following fields: mandatory (boolean),price (number), name (string)-->
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="name">Nome</label>
                  <input type="text" class="form-control" id="name" v-model="fieldCopy.name">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="price">Preço</label>
                  <input type="number" class="form-control" id="price" v-model="fieldCopy.price">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mt-3">
                  <h4 class="card-title">Obrigatorio</h4>
                    <input type="checkbox" id="mandatory" v-model="fieldCopy.mandatory" switch="none" />
                    <label for="mandatory" data-on-label="Sim" data-off-label="Não"></label>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "CommonComponent",
  props: {
    field: {},
    fieldType: {}
  },
  data() {
    return {
      fieldCopy: {}
    }
  },
  created() {
    this.fieldCopy = Object.assign({}, this.field)
  },
  watch:{
    fieldCopy(){
      this.$emit('update:field', this.fieldCopy)
    }
  },
  methods: {
    getFieldName() {
      switch (this.fieldType) {
        case 'TextField':
          return 'Texto'
        case 'TextareaField':
          return 'Texto Longo'
        case 'CheckboxField':
          return 'Checkbox'
        case 'DropdownField':
          return 'Dropdown'
        case 'ImageField':
          return 'Imagem'
        case 'ColorField':
          return 'Cor'
        case 'AdditionalField':
          return 'Adicional'
      }
    }
  }
}
</script>

<style scoped>

</style>