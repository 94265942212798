<template>
  <div class="quantity-toggle w-100">
    <button @click.prevent="decrement()" class=" btn btn-primary rounded-0">-</button>
    <input type="text" class="form-control bg-white rounded-0 w-100 " :name="name" :id="id" :value="quantity" readonly>
    <button @click.prevent="increment()" class="btn btn-primary rounded-0">+</button>
  </div>
</template>
<script>
import {NotifyHandler} from "@/utils/NotifyHandler";

export default {
  name: 'QuantityButton',
  props: {
    name: {
      type: String,
    },
    id: {
      type: String,
    },
    defaultQuantity: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      quantity: this.defaultQuantity
    }
  },
  methods: {
    increment() {
      this.quantity++
    },
    decrement() {
      if (this.quantity === 1) {
        NotifyHandler.handle('error',this.$t('pdv.errors.quantity'))
      } else {
        this.quantity--
      }
    }
  },
  watch: {
    quantity() {
      this.$emit('quantity', this.quantity)
    }
  }
}
</script>

<style scoped lang="scss">
pre {
  padding: 1rem;
  border-radius: 5px;
}

$border: 2px solid #ddd;

.quantity-toggle {
  display: flex;

  input {
    border-width: 0;
    width: 2.5rem;
    text-align: center;
    padding: 0 .5rem;
  }

  button {
    border: $border;
    padding: .5rem;
    font-size: 1rem;
    cursor: pointer;
  }

  .btn-lined {
    border: 1px solid #ddd;
    background-color: transparent;
    color: white;

  }
}
</style>