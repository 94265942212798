<template>
  <div class="mall-form-control">
    <label :for="'field-'+field.hashId" class="mall-label">{{ field.name }}</label>
    <select class="mall-input" :id="'field-'+ field.hashId "
            :name="'fields['+field.hashId+']'"

            data-mall-custom-field>
      <option value="">selecione</option>

      <option :value="option.hashId" v-for="option in field.custom_field_options" :key="option.id">
        {{ option.name }}
      </option>

    </select>

  </div>
</template>

<script>
export default {
  name: 'dropdownComponent',
  props: {
    field: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
