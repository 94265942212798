<template>
  <div class="row">
    <common  :field.sync="field" :fieldType="fieldType"></common>
    <div class="col-md-12">
      <button @click="save" class="btn btn-primary"> salvar</button>
    </div>
  </div>
</template>
<script>
import Common from "@/components/products/customFields/newField/Common";

export default {
  name: "TextField",
  props: {
    fieldType: {},
    usedField: {}
  },
  data() {
    return {
      field: {
        name: '',
        price: 0,
        mandatory: false,
      }
    }
  },
  mounted() {
    if (this.usedField) {
      this.field = this.usedField.field;
    }
  },
  watch: {
    usedField: function (newVal) {
      this.field = newVal.field;
    }
  },
  methods: {
    updateField(field) {
      this.field = field;
    },
    save() {
        this.$emit('save', this.field);
    }
  },
  components: {
    Common,
  }
}
</script>

<style scoped>

</style>