<template>
  <div class="page-content">
    <div class="container-fluid">
      <FormGenerator :formSchema="formSchema" @onSubmit="onSubmit" :text-button="isUpdate ?'users.form.update' :'users.form.register'" :initial-values="usersStore.getUserData" v-if="!loading"/>
    </div>
  </div>
</template>

<script>
import FormGenerator from "@/components/helper/formGenerator/FormGenerator.vue";
import {useUsersStore} from "@/store/usersStore";

export default {
  name: "NewUser",
  setup(){
    const usersStore = useUsersStore()
    return {
      usersStore
    }
  },
  components: {FormGenerator},
  data() {
    return {
      loading: false,
      formSchema: [
        {
          "name": "first_name",
          "label": "Nome",
          "span": "full",
          "type": "text",
          "rules": "required|max:255",
        },
        {
          "name": "last_name",
          "label": "Sobrenome",
          "span": "left",
          "type": "text",
          "comment": "Sobrenome do usuário",
          "rules": "required",
        },
        {
          "name": "login",
          "label": "Nome de Usuário",
          "span": "left",
          "type": "text",
          "comment": "Nome de usuário",
          "rules": "required",
        },
        {
          "name": "email",
          "label": "Email",
          "span": "left",
          "type": "email",
          "rules": "required|email",
        },
        {
          "name": "role",
          "label": "Função",
          "span": "left",
          "type": "select",
          "comment": "Função do usuário",
          "rules": "required",
          "options": this.options(),
        },
        {
          "name": "password",
          "label": "Senha",
          "span": "left",
          "type": "password",
          "rules": this.passwordRules(),
        },
        {
          "name": "password_confirmation",
          "label": "Confirmar Senha",
          "span": "left",
          "type": "password",
          "rules": this.passwordConfirmationRules(),
        },
      ],
    }
  },
  beforeMount() {
    if (this.$route.params.id){
      this.usersStore.setUser(this.$route.params.id)
    }
    if (this.usersStore.getUser !== null){
      this.loading = true
      this.usersStore.fetchUser().then(async ()=>{
        this.form = this.usersStore.getUserData
        await this.usersStore.fetchRoles()
      }).finally(()=>{
        this.loading = false
      })
    }
  },
  methods: {
    onSubmit(data) {
      if (this.isUpdate) {
        this.usersStore.updateUser(data).then(()=>{
          this.$router.push({name:'users'})
        })
      } else {
        this.usersStore.createUser(data).then(()=>{
          this.$router.push({name:'users'})
        })
      }
    },
    options(){
      return this.usersStore.getRoles
    },
    passwordRules(){
      return this.checkUpdate() ? '' : 'required|min:8'
    },
    checkUpdate(){
       return this.$route.params.id !== undefined || this.usersStore.getUser !== null
    },
    passwordConfirmationRules(){
      return this.checkUpdate() ? '' : 'required|min:8|confirmed:@password'
    }
  },
  computed:{
    isUpdate(){
      return this.$route.params.id !== undefined || this.usersStore.getUser !== null
    }
  }
}
</script>

<style scoped>

</style>