import { reactive } from 'vue';
import Echo from 'laravel-echo';

export default {
    install: (app) => {
        const echo = new Echo({
            broadcaster: 'pusher',
            key: 'fgzi528dw3xyed6c14gsz1',
            cluster: '',
            wsHost: 'websocket.mirelladoces.com.br',
            disableStats: true,
            encrypted: true,
        });
        // Make reactive
        app.config.globalProperties.$echo = reactive(echo);
    }
}