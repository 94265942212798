import {defineStore} from 'pinia';
import jsonwebtoken from 'jsonwebtoken';
import axios from '../boostrap/apiConfig';
import router from "../router";
import {useStorage} from "@vueuse/core";
import {ErrorHandler} from "@/utils/ErrorHandler";

export const useAuthStore = defineStore('auth', {
    state: () => ({
        user: {
            JWToken: useStorage('jwt', null),
            authenticated: useStorage('authenticated', false),
            systemRole: useStorage('systemRole', null),
            username: useStorage('username', null),
            name: useStorage('name', null),
        },
        $router: router,
        error: null,
    }),
    getters: {
        getError() {
            return this.error;
        }
    },

    actions: {
        async changePassword(creds) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.JWToken;
          return axios.post('settings/changePassword', {user: this.user,  password: creds.password, newPassword: creds.newPassword, confirmPassword: creds.confirmPassword});

        },
        async login(creds, redirect) {
            try {
                const response = await axios.post('auth/login', {username: creds.username, password: creds.password, vendorToken: creds.vendorToken});
                localStorage.setItem('jwt', response.data.token);
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('jwt');

                this.user.JWToken = response.data.token;
                this.user.authenticated = true;
                this.user.systemRole = response.data.systemRole;

                let jwtDecoded = jsonwebtoken.decode(this.user.JWToken);
                let lastName = jwtDecoded.user.lastName !== null ? jwtDecoded.user.lastName : '';
                this.user.name = jwtDecoded.user.firstName + ' ' + lastName;
                this.user.username = jwtDecoded.user.username;
                console.log(jwtDecoded)

                if (redirect) await router.push(redirect);
                else await router.push('/');
            } catch (error) {
                ErrorHandler.handle(error, "Erro ao fazer login");
                throw error;
            }
        },

        async checkForVendorToken(creds, redirect) {
            this.isLoading = true;
            try {
                await this.login(creds, redirect);
            } catch (error) {
                this.handleError(error, 'checkForVendorToken');
                throw error;
            }
        },

        async logout() {
            this.clearAuthInfo();
            await router.push('/login');
        },

        clearAuthInfo() {
            localStorage.removeItem('jwt');
            this.user.JWToken = null;
            this.user.authenticated = false;
            this.user.systemRole = null;
            this.user.name = null;

        },

        checkAuth() {
            let jwt = localStorage.getItem('jwt');
            if (jwt) {
                this.user.authenticated = true;
                this.user.JWToken = jwt;

                let jwtDecoded = jsonwebtoken.decode(jwt);
                this.user.systemRole = jwtDecoded.user.systemRole;
                let lastName = jwtDecoded.user.lastName !== null ? jwtDecoded.user.lastName : '';
                this.user.name = jwtDecoded.user.firstName + ' ' + lastName;
            } else {
                this.user.authenticated = false;
            }
        },

        checkRole(roleType, role) {
            let userRole;
            switch (roleType) {
                case 'systemRole':
                    userRole = this.user.systemRole;
                    break
                case 'Employee':
                    userRole = this.user.systemRole;
                    break;
                default:
                    return false;
            }

            console.log(userRole)

            switch (role) {
                case 'Employee':
                    return ['Employee', 'COM', 'developer', 'publisher'].includes(userRole);
                case 'user':
                    return ['user', 'COM', 'developer', 'publisher'].includes(userRole);
                case 'COM':
                    return ['COM', 'developer', 'publisher'].includes(userRole);
                case 'developer':
                    return userRole === 'developer';
                default:
                    return false;
            }
        },
        actualAccessRole() {
            let accessTier = [
                {"requiresSystemAdmin": ['developer']},
                {"requiresSystemPoweruser": ['COM', 'developer', 'publisher']},
                {"requiresEmployee": ['Employee', 'COM', 'developer', 'publisher']},

            ]
            let role = this.user.systemRole;
            let access = accessTier.find((item) => {
                let key = Object.keys(item)[0];
                return item[key].includes(role);
            })
            return Object.keys(access)[0];

        },

        handleError(error, action) {
            console.log(error)
            this.isLoading = false;
            if (error.message === 'Network Error') {
                this.error = 'Server is down - contact site admin.';
            } else if (error.response) {
                this.error = error.response.data.error;
            } else {
                this.error = `An error occurred during ${action}.`;
            }
            //after 5 seconds, clear the error
            setTimeout(() => {
                this.error = null;

            })
        }
    }
});
