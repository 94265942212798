<script>
import {useLocalizationStore} from "@/store/localizationStore";

export default {
  name: "LanguageSelector",
  setup() {
    const localizationStore = useLocalizationStore()
    return {
      localizationStore
    }
  },
  data() {
    return {
      countries: [
        {
          name: 'Brasil',
          code: 'pt',
          image: 'https://flagsapi.com/BR/flat/64.png'

        },
        {
          name: 'Estados Unidos',
          code: 'en',
          image: 'https://flagsapi.com/US/flat/64.png'
        },
      ],
    }
  },
  methods: {
    changeLocation(code) {
      this.localizationStore.changeLocale(code)
      location.reload()

    }
  }
}
</script>

<template>
  <div class="dropdown d-none d-md-inline-flex ms-2">
    <button class="btn header-item noti-icon align-middle" type="button" v-for="country in countries" v-wave
            :key="country.code" @click="changeLocation(country.code)">
          <span class="cde font-size-20 fw-bolder d-flex gap-2 flags">
             <img :src="country.image" :alt="country.name"
                  class=" avatar-sm " style="width:100%; height:100%; ">
          </span>
    </button>
  </div>
</template>

<style scoped lang="scss">
.flags {
  width: 30px;
  height: 30px;
  object-fit: cover;

}
</style>