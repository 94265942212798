<template>

    <div class="col-md-12 d-flex justify-content-end ">
      <button @click="newField" type="button" class="m-2 btn btn-light" v-wave>Novo Campo</button>
    </div>
    <component v-if="currentField" @save="save" @updateField="update" :usedField="usedField" :fieldType="fieldType"
               v-bind:is="currentField"></component>

</template>
<script>
import TextField from "@/components/products/customFields/newField/TextField";
import DropdownField from "@/components/products/customFields/newField/DropdownField";

export default {
  name: "NewField",
  props: {
    usedField: {}
  },
  data() {
    return {
      currentField: null,
      fieldType: null,
    }
  },
  methods: {
    update(){
      this.$emit('update');
    },
    save(field) {
      this.axios.post('/custom-fields', {
        field: field,
        type: this.fieldType
      }).then(() => {
        this.currentField = null;
        this.fieldType = null;
        this.$notify('Campo salvo com sucesso');
        this.$emit('update');
      });
    },
    newField() {
      var self = this;
      this.$swal.fire({
        title: 'Selecione o tipo de campo',
        input: 'select',
        inputOptions: {
          'TextField': 'texto',
          'TextareaField': 'area de texto',
          'CheckboxField': 'seleção',
          'DropdownField': 'lista',
          'ImageField': 'imagem',
          'ColorField': 'cor',
          'AdditionalField': 'Adicionais',
        },
        inputPlaceholder: 'Obrigatorio',
        showCancelButton: true,
        inputValidator: function (value) {
          return new Promise(function (resolve) {
            if (value !== '') {
              resolve();
            } else {
              resolve('Você precisa selecionar um tipo de campo');
            }
          });
        }
      }).then(function (result) {
        if (result.isConfirmed) {
          self.currentField = self.getFieldComponent(result.value);
          self.fieldType = result.value;
        }
      });
    },
    getFieldComponent(field) {
      switch (field) {
        case 'TextField':
          return 'TextField';
        case 'TextareaField':
          return 'TextField';
        case 'CheckboxField':
          return 'TextField';
        case 'DropdownField':
          return DropdownField;
        case 'ImageField':
          return DropdownField;
        case 'ColorField':
          return DropdownField;
        case 'AdditionalField':
          return 'TextField';
      }
    },
  },
  watch: {
    usedField: function (newVal) {
      this.currentField = newVal.field.fieldType;
      this.fieldType = newVal.field.fieldType;
    }
  },
  components: {
    TextField,
    DropdownField
  }
}
</script>

<style scoped>

</style>