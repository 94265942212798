<template>

  <PerfectScrollbar>
    <ProductList :headers="headers" :items="items" :select-all-items="selectAllItems"/>
  </PerfectScrollbar>
  <div class="col-md-12 p-2 text-center">
    <button class="btn btn-primary" @click="addAllSelected" type="button"> Adicionar Selecionados</button>
  </div>

</template>
<script>
import {usePlannerStore} from "@/store/plannerStore";
import 'vue3-easy-data-table/dist/style.css';
import ProductList from "@/components/planner/ProductList.vue";

export default {
  name: 'EventProductAdd',
  setup() {
    const plannerStore = usePlannerStore()
    return {
      plannerStore
    }
  },
  data() {
    return {

      headers: [
        {text: 'Selecionar', value: 'select', sortable: false},
        {text: 'ID', value: 'id'},
        {text: 'Nome', value: 'name'},
        {text: 'Preço', value: 'price'},
      ],
    }
  },
  components: {
    ProductList
  },
  methods: {
    addAllSelected() {
      //getall selected checkboxes
      let checkboxes = document.getElementsByClassName('select')
      let selected = []
      for (let i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].checked) {
          selected.push(checkboxes[i].value)
        }
      }
      if (selected.length === 0) {
        this.$notify({
          title: 'Erro',
          text: 'Selecione pelo menos um produto',
          type: 'error'
        })
        return;
      }

      let id = this.$route.params.id
      this.plannerStore.addProductsToPlanner(id, selected).then(() => {
        this.$notify({
          title: 'Sucesso',
          text: 'Produtos adicionados com sucesso',
          type: 'success'
        })
      }).catch(() => {
        this.$notify({
          title: 'Erro',
          text: 'Erro ao adicionar produtos',
          type: 'error'
        })
      })

    }
  },
  computed: {
    items() {
      return this.plannerStore.getNonAddedProducts
    },
  },
  beforeMount() {
    let id = this.$route.params.id
    this.plannerStore.fetchNotAddedProducts(id)
  }
}
</script>