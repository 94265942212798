<template>
  <div class="page-content">
    <div class="container-fluid">
      <vue-tabs :options="{ useUrlFragment: false }">
        <v-tab :name="$t('delivery.tabs.drivers')">
          <Drivers/>
        </v-tab>
        <v-tab :name="$t('delivery.tabs.freight')">
          <FreightTable/>
          </v-tab>
      </vue-tabs>
    </div>
  </div>
</template>

<script>
import {Tabs, Tab} from 'vue3-tabs-component';
import Drivers from "@/components/delivery/drivers/Drivers.vue";
import FreightTable from "@/components/delivery/FreightTable.vue";

export default {
  name: "DeliveryComponent",
  components: {
    FreightTable,
    Drivers,
    VueTabs: Tabs,
    VTab: Tab
  }
}
</script>

