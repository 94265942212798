<template>
  <div class="container-fluid mt-3">
    <save-update-action-bar @save="save" @update="update"></save-update-action-bar>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
<!--            create 4 inputs for the following fields: width (number), height (number), length (number), weight (number)-->
            <h4 class="card-title">{{$t('product.delivery.dimensions')}}</h4>
            <p class="card-title-desc">{{$t('product.delivery.dimensionsDescription')}}</p>
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="width">{{$t('product.delivery.width')}}</label>
                  <input type="number" class="form-control" id="width" v-model="productCopy.width">
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="height">{{$t('product.delivery.height')}}</label>
                  <input type="number" class="form-control" id="height" v-model="productCopy.height">
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="length">{{$t('product.delivery.length')}}</label>
                  <input type="number" class="form-control" id="length" v-model="productCopy.length">
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="weight">{{$t('product.delivery.weight')}}</label>
                  <input type="number" class="form-control" id="weight" v-model="productCopy.weight">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SaveUpdateActionBar from "@/components/generic/SaveUpdateActionBar";
export default {
  name: "DeliveryComponent",
  props: {
    product: {}
  },
  data() {
    return {
      productCopy: {}
    }
  },
  created() {
    this.productCopy = Object.assign({}, this.product)
  },
  methods:{
    save(){
      this.$emit('save', this.productCopy)
    },
    update(){
      this.$emit('update')
    }
  },
  components: {
    SaveUpdateActionBar
  }

}
</script>

<style scoped>

</style>