<template>
  <div class="tab-pane fade active show" id="note" role="tabpanel" aria-labelledby="note-tab">
    <div class="row">
      <div class="col-md-6 mt-3 m-auto">

        <select v-model="selectedPrinter" class="form-select">
          <option>Select</option>
          <option :key="printer.driverName" :value="printer.driverName" v-for="printer in availablePrinters">
            {{ printer.name }}
          </option>
        </select>
        <div class="input-group mt-3">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fa fa-arrow-up" style="font-size: 21px;"></i></span>
          </div>
          <input type="text" v-model="up" class="form-control" aria-label="Sizing example input"
                 aria-describedby="inputGroup-sizing-default">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fa fa-arrow-down" style="font-size: 21px;"></i></span>
          </div>
          <input type="text" v-model="down" class="form-control" aria-label="Sizing example input"
                 aria-describedby="inputGroup-sizing-default">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fa fa-arrow-left" style="font-size: 21px;"></i></span>
          </div>
          <input type="text" v-model="left" class="form-control" aria-label="Sizing example input"
                 aria-describedby="inputGroup-sizing-default">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fa fa-arrow-right" style="font-size: 21px;"></i></span>
          </div>
          <input type="text" v-model="right" class="form-control" aria-label="Sizing example input"
                 aria-describedby="inputGroup-sizing-default">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fa fa-font" style="font-size: 21px;"></i></span>
          </div>
          <input type="text" v-model="font" class="form-control" aria-label="Sizing example input"
                 aria-describedby="inputGroup-sizing-default">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fa-regular fa-note-sticky" style="font-size: 21px;"></i></span>
          </div>
          <select class="form-control" v-model="paperType">
            <option value="" selected disabled>Selecione o tipo de papel</option>
            <option value="A4">A4</option>
            <option value="A5">A5</option>
            <option value="A6">A6</option>
            <option value="A7">A7</option>
          </select>
        </div>
        <UploadImages v-model="myFiles" class="mt-3" @changed="handleImage" :max="1"/>

        <div class="col-md-12 mt-3 justify-content-center d-flex">
          <button class="btn btn-primary" @click="saveNormalPrinter">Salvar informações</button>
        </div>


      </div>
      <div class="col-md-6">
        <div class="h-100 w-100">
          <div :size="paperType" :style="margin" class="page mt-4">
            <div class="margin text-center text-light text-black" :style="fontSize">
              lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus adipisci alias aliquid amet
              asperiores atque autem beatae commodi consequatur corporis cumque cupiditate delectus deleniti
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UploadImages from "vue-upload-drop-images"

export default {
  name: 'normal-printer',
  props: {
    availablePrinters: {}
  },
  data() {
    return {
      selectedPrinter: null,
      paperType: null,
      myFiles: [],
      up: 0,
      down: 0,
      left: 0,
      right: 0,
      font: 16,
      backgroundImage: null,
      image: null,
      base64Image: null
    }
  },
  methods: {
    handleImage: function (files) {
      if (files.length > 0) {
        this.myFiles = files
        let formData = new FormData();
        formData.append('file', files[0]);
        this.axios.post('settings/upload', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
        ).then(response => {
          this.backgroundImage = response.data
          this.image = this.baseURL + `/files/upload/${this.backgroundImage.file.filename}`
        }).catch(error => {
          this.$notify(error.response.data.error)
        })
      }
    },
    fetchImage: async function () {
      let response = await this.axios.get(this.image)
      this.base64Image = response.data

    },
    saveNormalPrinter: function () {
      this.axios.post('settings/normal-printer', {
        printer: this.selectedPrinter,
        up: this.up,
        down: this.down,
        paperType: this.paperType,
        left: this.left,
        right: this.right,
        font: this.font,
        image: this.image
      }).then(response => {
        this.$notify(response.data.message)
      })
          .catch(error => {
            this.$notify(error.response.data.error)
          })
    },
    getNormalPrinter: async function () {
      return this.axios.get('settings/normal-printer')
          .then(response => {
            this.selectedPrinter = response.data.printer
            this.up = response.data.up
            this.down = response.data.down
            this.paperType = response.data.paperType
            this.left = response.data.left
            this.right = response.data.right
            this.font = response.data.font
            this.image = response.data.image
          })
          .catch(error => {
            this.$notify(error.response.data.error)
          })
    }
  },
  mounted() {
    this.getNormalPrinter().then(() => {
      this.fetchImage()
    })

  },
  computed: {
    margin: function () {
      return {
        'padding-top': (this.up / 8) + 'mm',
        'padding-bottom': (this.down / 8) + 'mm',
        'padding-left': (this.left / 8) + 'mm',
        'padding-right': (this.right / 8) + 'mm',
        'background-image': this.base64Image ? `url(${this.base64Image})` : 'rgba(255, 255, 255, 0.6)',
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
      }

    },
    fontSize: function () {
      return {
        'font-size': (this.font * 0.45) + 'pt'
      }
    }
  },
  components: {
    UploadImages
  },
}
</script>

<style lang="css">
.page {
  background: rgba(255, 255, 255, 0.6);
  display: block;
  margin: 0 auto;
  color: #000;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
}

.page[size="A4"] {
  width: 74.41mm;
  height: 105.23mm;
}

.page[size="A5"] {
  width: 52.91mm;
  height: 74.41mm;
}

.page[size="A6"] {
  width: 37.21mm;
  height: 52.91mm;
}

.page[size="A7"] {
  width: 26.46mm;
  height: 37.21mm;
}

.margin {
  width: 100%;
  height: 100%;
  border: #ff0000 1px solid;
  background: rgba(255, 255, 255, 0.6);
}
</style>
