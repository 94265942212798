<template>
   <span class="d-none">nothing herer</span>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';
import {useNotification} from "@kyvg/vue3-notification";

export default defineComponent({
  props: {
    error: {
      default: null, // Set a default value for the error prop
      // You can also specify the type if you want to enforce the prop type.
      // For example: type: [String, Object],
    },
  },
  setup(props, { emit }) {
    // You can use refs to reactively handle the error
    const errorMessage = ref(props.error);
    const { notify } = useNotification()

    // Watch for changes to the error prop
    watch(
        () => props.error,
        (newValue) => {
          errorMessage.value = newValue;
          if (newValue) {
            // Show a notification when the error is updated
            showNotification(newValue);

            // Automatically close the error box after 5 seconds
            setTimeout(() => {
              closeErrorBox();
            }, 5000);
          }
        }
    );

    // Method to close the error box
    const closeErrorBox = () => {
      errorMessage.value = null;
      emit('clearError');
    };

    // Method to show a notification for the error
    const showNotification = (errorMessage) => {
      // Replace this with your notification logic (e.g., using a notification library)
      notify({
        title: 'Error',
        text: errorMessage,
        type: 'error',
      });
    };

    return {
      errorMessage,
      closeErrorBox,
    };
  },
});
</script>