<script>
import VueMultiselect from 'vue-multiselect'

export default {
  name: "FilterByStates",
  props: {
    data: {
      required: true
    }
  },
  data() {
    return {
      selectedStates: [],
      statesOptions: []
    }
  },
  methods: {
    getPossibleStateFromSelectedGroups: function (data) {
      let states = []
      const asArray = Object.entries(data);
      for (let i = 0; i < asArray.length; i++) {
        for (let j = 0; j < asArray[i][1].length; j++) {
          if (!states.includes(asArray[i][1][j].order_state) && asArray[i][1][j].order_state != null) {
            states.push(asArray[i][1][j].order_state.name)
          }
        }
      }
      // remove duplicated states
      states = [...new Set(states)]
      this.statesOptions = states
      return states
    },
    filterData() {
      const asArray = Object.entries(this.data);
      let filtered = []
      for (let i = 0; i < asArray.length; i++) {
        for (let j = 0; j < asArray[i][1].length; j++) {
          if (this.selectedStates.includes(asArray[i][1][j].order_state.name)) {
            filtered.push(asArray[i][1][j])
          }
        }
      }
      let obj = {}
      for (let i = 0; i < filtered.length; i++) {
        if (!Object.prototype.hasOwnProperty.call(obj, filtered[i].delivery_in)) {
          obj[filtered[i].delivery_in] = [];
        }
        obj[filtered[i].delivery_in].push(filtered[i])
      }
      return obj
    }

  },
  computed: {
    isActive() {
      return this.selectedStates.length > 0;
    }
  },
  mounted() {
    this.getPossibleStateFromSelectedGroups(this.data)
  },
  components: {VueMultiselect},
}
</script>

<template>
  <div class="col-md-4">
    <VueMultiselect v-model="selectedStates" :options="statesOptions" :multiple="true" placeholder="Filtrar por estado do pedido" selectLabel="Clique para selecionar"></VueMultiselect>
  </div>

</template>

<style scoped>

</style>