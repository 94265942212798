<template>
  <div class="header-bg">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 mb-4 pt-5">
          <BarChart
              :chartData="barData"></BarChart>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {BarChart} from 'vue-chart-3';
import {Chart, registerables} from "chart.js";
import {collect} from "collect.js";

Chart.register(...registerables);


export default {
  props: ["mainChart","loaded"],
  name: 'main-chart',
  data() {
    return {
      barData: {
        labels: [
          this.$t("app.months.january"),
          this.$t("app.months.february"),
          this.$t("app.months.march"),
          this.$t("app.months.april"),
          this.$t("app.months.may"),
          this.$t("app.months.june"),
          this.$t("app.months.july"),
          this.$t("app.months.august"),
          this.$t("app.months.september"),
          this.$t("app.months.october"),
          this.$t("app.months.november"),
          this.$t("app.months.december"),
        ],
        datasets: [
          {
            label: this.$t('app.monthlySales'),
            data: collect(this.mainChart['sales']).pluck('sales').toArray(),
            backgroundColor: '#4e73df',
          },
          {
            label: this.$t('app.notPaidOrders'),
            data: collect(this.mainChart['not_sales']).pluck('not_sales').toArray(),
            backgroundColor: '#1cc88a',
          }
        ],
      }
    }
  },
  methods: {
    generateColor() {
      let color;
      do {
        color = '#' + Math.floor(Math.random() * 16777215).toString(16);
      } while (color.length !== 7);
      return color;
    },
    generateColors() {
      return Array.from({ length: 12 }, this.generateColor);
    },
    convertToCurrency: function() {
      return function(y) {
        var formatter = new Intl.NumberFormat('pt-br', {
          style: 'currency',
          currency: 'BRL',
        });

        return formatter.format(y); /* $2,500.00 */
      }
    }
  },
  mounted(){
    console.log(this.mainChart)
  },
  components: {
    BarChart
  },
}
</script>
