<template>
  <div class="page-content slideInDown animated">
    <div class="card p-2">
      <div class="card-header row g-2  ">
        <div class="form-group col-md-4">
          <label for="provider">Proveedor</label>
          <select name="provider" v-model="chainStore.provider" class="form-control">
            <option v-for="provider in chainStore.getProviders" :key="provider.id" :value="provider.code">
              {{ provider.name }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-4">
          <label for="period">Periodo</label>
          <select name="period" v-model="chainStore.period" class="form-control">
            <option v-for="period in chainStore.getPeriods" :key="period.id" :value="period.id">
              {{ period.name }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-4">
          <label for="eps" class="form-label">Distancia de Entrega (KM)</label>
          <input type="number" name="eps" v-model="chainStore.eps" class="form-control" placeholder="eps"/>
        </div>
        <div class="form-group col-md-4">
          <label for="minSamples">Minimo de Entregas</label>
          <input type="number" name="eps" v-model="chainStore.minSamples" class="form-control" placeholder="minimo de entregas"/>
        </div>
        <div class="form-group col-md-3 text-end">
          <button class="btn btn-primary" v-wave @click.prevent="chainStore.getChainDeliveriesFetch"> Buscar</button>
        </div>

      </div>
    </div>
    <ProgressBar :loading="chainStore.loading"/>
    <DeliveriesCard :date="index" :dates="dates" v-for="(dates, index) in chainStore.getChainDeliveries" :key="index"
                    :definitions="buttonDefinitions"/>

  </div>
</template>

<script>
import {useChainDeliveryStore} from "@/store/chainDeliveryStore";
import DeliveriesCard from "@/components/chainDelivery/DeliveriesCard.vue";
import ProgressBar from "@/components/generic/ProgressBar.vue";

export default {
  name: "ChainDelivery",
  components: {ProgressBar, DeliveriesCard},
  data() {
    return {
      buttonDefinitions: null
    }
  },
  setup() {
    const chainStore = useChainDeliveryStore()
    return {
      chainStore
    }
  },
  methods: {
     getButtonDefinitions: async function () {
      return this.axios.get('settings/getButtonDefinitions')
          .then(response => {
            this.buttonDefinitions = response.data
          }).catch(error => {
            if (error.message === 'Network Error') {  // we got an network error - server is down?
              this.error = 'Server is down - contact site admin.';
            } else {                // we got error from the server
              this.error = error.response.data.error;
            }
          })
    },
  },
  beforeMount() {
    this.getButtonDefinitions().then(() => {
      this.chainStore.fetchStore().then(() => {
        this.chainStore.fetchPossiblePeriods().then(() => {
          this.chainStore.fetchPossibleProviders()
        })
      })
    })
  },

}
</script>