<template>
  <label class="radio-tile-group col-md-3 p-1 m-0">
    <div class="input-container">
      <input
          id="walk"
          class="radio-button"
          :checked="selectedPaymentMethod === paymentMethod.id"
          type="radio"
          name="radio"
          :value="paymentMethod.id"
          @change="updatePaymentMethod"
      />
      <div class="radio-tile">
        <div class="icon walk-icon">
          <img v-if="paymentMethod.logo" class="img-fluid" :src="paymentMethod.logo.path" alt="payment"/>
          <small v-else>{{ paymentMethod.name }}</small>
        </div>
      </div>
    </div>
  </label>
</template>

<script>
export default {
  name: 'PaymentMethod',
  props: {
    paymentMethod: {
      type: Object,
      required: true
    },
    cartPaymentMethod: {}
  },
  data() {
    return {
      selectedPaymentMethod: this.cartPaymentMethod
    }
  },
  methods: {
    updatePaymentMethod(event) {
      this.selectedPaymentMethod = event.target.value;
      this.$emit('selectedPaymentMethodHandler', this.selectedPaymentMethod);
    }
  },
}
</script>
<style scoped lang="scss">
::selection {
  background: #d5bbf7;
}

.card input[type="radio"] {
  display: none;
}

$primary-color: #079ad9;


.radio-tile-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  //set dimensions for invisible container
  .input-container {
    position: relative;
    height: 4rem;
    width: 4rem;
    margin: 0.5rem;

    //make actual radio input invisible
    // + stretch to fill container
    .radio-button {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      margin: 0;
      cursor: pointer;
    }

    //default tile styles
    .radio-tile {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      border: 2px solid $primary-color;
      border-radius: 5px;
      padding: 1rem;
      transition: transform 300ms ease;
    }

    .icon svg {
      fill: $primary-color;
      width: 3rem;
      height: 3rem;
    }

    .radio-tile-label {
      text-align: center;
      font-size: 0.75rem;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: $primary-color;
    }

    //active tile styles
    .radio-button:checked + .radio-tile {
      background-color: $primary-color;
      border: 2px solid $primary-color;
      color: white;
      transform: scale(1.1, 1.1);

      .icon svg {
        fill: white;
        background-color: $primary-color;
      }

      .radio-tile-label {
        color: white;
        background-color: $primary-color;
      }
    }
  }
}
</style>