<template>
  <div class="col-xl-12">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title mb-4">{{ $t('dashboard.lastTransactions')}}</h4>

        <div class="table-responsive">
          <table class="table mt-4 mb-0 table-centered table-nowrap">

            <tbody>

            <tr v-for="transaction in transactions" :key="transaction.id">
              <td>
                 {{ transaction.customer.firstname }} {{ transaction.customer.lastname }}
              </td>
              <td><i class="mdi mdi-checkbox-blank-circle" :style="{'color':transaction.order_state.color}"></i>
                {{ transaction.order_state.name }}
              </td>
              <td>
                {{ (transaction.total_post_taxes /100).toFixed(2) }}
                <p class="m-0 text-muted">Amount</p>
              </td>
              <td>
                {{ transaction.created_at  }}
                <p class="m-0 text-muted">Date</p>
              </td>
            </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: ['transactionData'],
  name: 'LastTransaction',
  data(){
    return {

      transaction :null
    }
  },
  methods: {

  },computed: {
    transactions(){
      return this.transactionData
    }
  },

}
</script>
