<template>

  <nav aria-label="breadcrumb">
    <ol class="breadcrumb  bg-transparent p-0">
      <li class="breadcrumb-item" v-for="route in navigationHistory" :key="route.path"> <router-link :to="route.path">{{ $t('menu.'+route.name) }}</router-link></li>
    </ol>
  </nav>
</template>

<script>
import { navigationHistory } from '@/router';

export default {
  name: 'BreadCrumb',
  setup() {
    return { navigationHistory };
  }
};
</script>