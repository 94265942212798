

<template>
  <div class="mall-form-control">
    <label :for="'field-'+field.hashId" class="mall-label mb-1">{{ field.name }}</label>
    <input type="text" class="form-control" :id="'field-'+ field.hashId "
           :name="'fields['+field.hashId+']'"

           data-mall-custom-field>
  </div>
</template>

<script>
export default {
  name: 'textComponent',
  props: {
    field: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },

}
</script>

<style lang="scss" scoped>

</style>