<template>
  <div class="row">
    <div class="col-xl-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">{{ $t('dashboard.interactions')}}</h4>
          <GoogleMap id="world-map-markers" :zoom="9" :center="center" v-if="interactions.length > 0"
                     api-key="AIzaSyDYr2ToaFPuUjMAxeM0rrAQZ5L2OwG7AVg">
            <Marker :options="{
              position: location.center,
              icon:location.icon
            }" v-for="(location, index) in interactions" :key="index+'l'"/>
          </GoogleMap>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {GoogleMap, Marker} from "vue3-google-map";
import {collect} from "collect.js";

export default {
  name: 'InteractionsComponent',
  components: {
    GoogleMap,
    Marker
  },
  data() {
    return {
      center: {lat: 0, lng: 0},
      interactions: [],
    }
  },
  methods: {
    listenInteractions() {
      this.$echo.channel('interactions').listen('.SendInteractionEvent', (e) => {
        this.interactions.push(e);
      });
    },
    getCenterFromInteractions() {
      let lat = collect(this.interactions).pluck('center.lat').avg()
      let lng = collect(this.interactions).pluck('center.lng').avg()
      this.center = {lat: lat, lng: lng}
    },
    getInteractions() {
      this.axios.get('interactions').then(response => {
        this.interactions = response.data;
        this.listenInteractions()
      })
    }
  },
  mounted() {
    this.getInteractions();
  },
  watch: {
    interactions() {
      //remove markers older than 30 minutes
      if (this.interactions.length > 0) {
        this.getCenterFromInteractions()
      }
    }
  }
}
</script>
<style scoped>
#world-map-markers {
  height: 400px;
}
</style>
