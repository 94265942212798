import {defineStore} from "pinia";
import {ErrorHandler} from "@/utils/ErrorHandler";
import axios from "@/boostrap/apiConfig";
import {NotifyHandler} from "@/utils/NotifyHandler";
export const useMyWarehousesStore = defineStore({
    id: 'myWarehousesStore',
    state: () => ({
        warehouses: [],
        warehouse: null,
    }),
    getters: {
        getWarehouses() {
            return this.warehouses;
        },
        getWarehouse() {
            return this.warehouse;
        }
    },
    actions: {
        async fetchWarehouses() {
            return axios.get('account/warehouses').then((response) => {
                this.warehouses = response.data
            }).catch((error) => {
                ErrorHandler.handle(error)
            })
        },
        async fetchWarehouse(id) {
            return axios.get('account/warehouses/' + id+'/data').then((response) => {
                this.warehouse = response.data
            }).catch((error) => {
                ErrorHandler.handle(error)
            })
        },
        async updateWarehouse(warehouse,id) {
            return axios.put('account/warehouses/'+id, warehouse).then(() => {
                NotifyHandler.handle('success', 'Warehouse updated successfully')
            }).catch((error) => {
                ErrorHandler.handle(error, 'Warehouse update failed')
            })
        }
    }
})