<template>
  <PasswordProtectedButton class="btn btn-circle btn-success" v-wave v-if="cashierStore.getCashierIsOpen"
                           @onClick="additionTransaction">+
  </PasswordProtectedButton>
</template>
<script>
import PasswordProtectedButton from "@/components/helper/PasswordProtectedButton.vue"
import {useCashierStore} from "@/store/cashierStore";
import {useAuthStore} from "@/store/authStore";

export default {
  name: 'AdditionTransaction',
  components: {PasswordProtectedButton},
  setup() {
    let cashierStore = useCashierStore()
    let authStore = useAuthStore()
    return {cashierStore, authStore}
  },
  methods: {
    additionTransaction() {
      this.$swal({
        title: this.$t('cashier.chooseATransactionType'),
        text: this.$t('cashier.chooseATransactionTypeDescription'),
        input: 'select',
        inputOptions: {
          'sale': this.$t('cashier.additionTransactionTypes.sale'),
          'addition': this.$t('cashier.additionTransactionTypes.addition'),
        },
        inputPlaceholder: this.$t('cashier.chooseATransactionType'),
        focusConfirm: false,
        inputValidator: function (value) {
          return new Promise(function (resolve) {
            if (value !== '') {
              resolve();
            } else {
              resolve(this.$t('cashier.chooseATransactionType'));
            }
          });
        }
      }).then((result) => {
        let type = result.value
        if (result.value) {
          this.$swal({
            title: this.$t('cashier.insertAmount'),
            text: this.$t('cashier.insertAmountDescription'),
            input: 'number',
            inputPlaceholder: 'Insira o valor',
            focusConfirm: false,
            inputValidator: function (value) {
              return new Promise(function (resolve) {
                if (value !== '') {
                  resolve();
                } else {
                  resolve(this.$t('cashier.insertAmount'));
                }
              });
            }
          }).then((result) => {
            let value = result.value
            if (result.value)
              this.cashierStore.createTransaction(type, value, true).then(() => {
                this.$swal({
                  title: this.$t('cashier.transactionAdded'),
                  text: this.$t('cashier.transactionAddedDescription'),
                  icon: 'success',
                  timer: 2000,
                  timerProgressBar: true,
                  showConfirmButton: false,
                })
              }).catch(() => {
                this.$swal({
                  title: this.$t('cashier.errorAddingTransaction'),
                  text: this.$t('cashier.errorAddingTransactionDescription'),
                  icon: 'error',
                  timer: 2000,
                  timerProgressBar: true,
                  showConfirmButton: false,
                })
              })
          })
        }
      })
    },
  }

}
</script>
<style scoped>

.btn-circle {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>