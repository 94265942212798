<template>

    <canvas
        ref="canvas"
        class="img-fluid"
        :width="this.width"
        :height="this.height"
    />

</template>

<script>
import { decode } from 'blurhash'

export default {
  props: {
    hash: {
      type: String,
      required: true
    },

    aspectRatio: {
      type: Number,
      default: 1
    },
    width: {
      type: Number,
      default: 32
    },
    height: {
      type: Number,
      default: 32
    }
  },

  mounted() {
    const pixels = decode(this.hash, this.width, this.height)
    const imageData = new ImageData(pixels, this.width, this.height)
    const context = this.$refs.canvas.getContext('2d')
    context.putImageData(imageData, 0, 0)
  }
}
</script>