import {defineStore} from "pinia";
import axios from "@/boostrap/apiConfig";
import {useStorage} from "@vueuse/core";
import {ErrorHandler} from "@/utils/ErrorHandler";
import {NotifyHandler} from "@/utils/NotifyHandler";

export const useUsersStore = defineStore('users', {
    state: () => ({
        users: useStorage('users', []),
        user: null,
        userData: null,
        roles: useStorage('roles', [])
    }),
    getters: {
        getUsers() {
            return this.users
        },
        getUser() {
            return this.user
        },
        getUserData() {
            return this.userData
        },
        getRoles() {
            return this.roles
        }
    },
    actions: {
        setUser(user) {
            this.user = user
        },
        setUserData(userData) {
            this.userData = userData
        },
        fetchUsers() {
            axios.get('users').then((response) => {
                this.users = response.data.users
            }).catch((error) => {
                ErrorHandler.handle(error, 'fetchUsers')
            })
        },
        async fetchUser() {
            return axios.get(`users/${this.user}`).then((response) => {
                this.userData = response.data.user
            }).catch((error) => {
                ErrorHandler.handle(error, 'fetchUser')
            })
        },
        async fetchRoles() {
            return axios.get(`users/roles`).then((response) => {
                this.roles = response.data.roles
            }).catch((error) => {
                ErrorHandler.handle(error, 'fetchRoles')
            })
        },
        deleteUser(id) {
            axios.delete('users/' + id).then(() => {
                this.fetchUsers()
                NotifyHandler.handle('success', 'message.userDeactivated')
            }).catch((error) => {
                ErrorHandler.handle(error, 'deleteUser')
            })
        },
        activeUser(id) {
            axios.put('users/' + id + '/active').then(() => {
                this.fetchUsers()
                NotifyHandler.handle('success', 'message.userActivated')
            }).catch((error) => {
                ErrorHandler.handle(error, 'activeUser')
            })
        },
        async createUser(user) {
            return axios.post('users', user).then(() => {
                this.fetchUsers()
                NotifyHandler.handle('success', 'message.userCreated')
            }).catch((error) => {
                ErrorHandler.handle(error, 'createUser')
            })
        },
        async updateUser(user) {
            return axios.put('users/' + this.user, user).then(() => {
                this.fetchUsers()
                NotifyHandler.handle('success', 'message.userUpdated')
            }).catch((error) => {
                ErrorHandler.handle(error, 'updateUser')
            })
        }

    }
})