import jsonwebtoken from 'jsonwebtoken';
import axios from 'axios';
import { useStorage } from '@vueuse/core'

export default {
  user: {
    JWToken: useStorage('jwt', null),
    authenticated: useStorage('authenticated', false),
    systemRole: useStorage('systemRole', ''),
    name: useStorage('name', ''),
  },
  login(context, creds, redirect) {
    axios.post('auth/login', { username: creds.username, password: creds.password })
      .then(response => {

        localStorage.setItem('jwt', response.data.token);
        axios.defaults.headers.common['Authorization'] = 'Bearer ' +  localStorage.getItem( 'jwt');

        // submitted in the body response along with the token
        this.user.JWToken = response.data.token;
        this.user.authenticated = true;
        this.user.systemRole = response.data.systemRole;

        // get also first name and last name - only part of the token
        let jwtDecoded = jsonwebtoken.decode(this.user.JWToken);
        let lastName = (jwtDecoded.user.lastName !== null) ? jwtDecoded.user.lastName : '';
        this.user.name = jwtDecoded.user.firstName + ' ' + lastName;

        if (jwtDecoded.user.changePassword) {
            context.$router.push('/changePassword');
        }

        // if jwt expires, user has to relog but redirect is passed along so he goes to where he was before
        if (redirect) context.$router.push(redirect);
        else context.$router.push('/');

      })
      .catch(error => {
        context.isLoading = false; // disable the is-loading class on the login submit button when there is an error, any kind
        if (error.message === 'Network Error') {  // we got an network error - server is down?
          context.error = 'Server is down - contact site admin.';
        } else {                // we got error from the server
          context.error = error.response.data.error;
          context.isLoading = false;
        }
      });
  },

  checkForVendorToken(context, creds, redirect) {
    context.isLoading = true;
    if (creds.vendorToken) {
      axios.post('auth/vendorToken', { vendorToken: creds.vendorToken, username: creds.username })
        .then(() => {
          this.login(context, creds, redirect);
        })
        .catch(error => {
          context.isLoading = false; // disable the is-loading class on the login submit button when there is an error, any kind
          if (error.message === 'Network Error') {
            context.isLoading = false;
            context.error = 'Server is down - contact site admin.';
          } else {                // we got error from the server
            context.isLoading = false;
            context.error = error.response.data.error;
          }
        });
    } else {
      axios.get('auth/checkForVendorToken',).then(() => {
        this.login(context, creds, redirect);
      }).catch(error => {
        context.isLoading = false;
        if (error.message === 'Network Error') {
          context.isLoading = false;
          context.error = 'Server is down - contact site admin.';
        }
        if (error.response.data.needsVendorToken) {
          context.error = error.response.data.error;
          context.isLoading = false;
          context.needsVendorToken = true;
        }
      })
    }

  },

  logout(context) {
    this.clearAuthInfo();
    context.$router.push('/login');
  },

  clearAuthInfo() {
    localStorage.removeItem( 'jwt');
    this.user.authenticated = false;
  },

  checkAuth() {
    let jwt =  localStorage.getItem( 'jwt')
    if (jwt) {
      this.user.authenticated = true;
      this.user.JWToken = jwt;

      let jwtDecoded = jsonwebtoken.decode(jwt);

      this.user.systemRole = jwtDecoded.user.systemRole;
      let lastName = (jwtDecoded.user.lastName !== null) ? jwtDecoded.user.lastName : '';
      this.user.name = jwtDecoded.user.firstName + ' ' + lastName;
    } else {
      this.user.authenticated = false;
    }
  },

  checkRole(roleType, role) {
    let userRole;

    switch (roleType) { // only one case here but you can add more role types
      case 'systemRole':
        userRole = this.user.systemRole;
        break;
      // add a case for more role types
      default:
        return false;
    }

    switch (role) {
      case 'user':
        return userRole === 'user' || userRole === 'COM' || userRole === 'developer' || userRole === 'publisher';
      case 'COM':
        return userRole === 'COM' || userRole === 'developer' || userRole === 'publisher';
      case 'developer':
        return userRole === 'developer';
      default:
        return false;
    }
  },
}
