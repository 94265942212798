export const pt = {
    "sales-report": "Relatório de vendas",
    "app": {
        "filter": "Filtrar",
        "fillRequiredFields": "Preencha os campos obrigatórios",
        "selectReport": "Selecione um relatório",
        "customerInfo": "Informações do Cliente",
        "selectFirst": "Selecione um(a) {0} primeiro(a)",
        "save": "Salvar",
        "generateAnotherReport": "Gerar outro relatório",
        "update": "Atualizar",
        "next": "Próximo",
        "previous": "Anterior",
        "pick": "Selecionar",
        "clickToSelect": "Clique para selecionar",
        "clickToDeselect": "Clique para deselecionar",
        "yes": "Sim",
        "no": "Não",
        "edit": "Editar",
        "download": "Baixar",
        "search": "Pesquisar",
        "searchPlaceholder": "Pesquisar...",
        "free": "Grátis",
        "add": "Adicionar",
        "monthlySales": "Vendas mensais",
        "notPaidOrders": "Pedidos não pagos",
        "customer": "Cliente",
        "items": "Itens",
        "createdAt": "Criado em",
        "delete": "Deletar",
        "addItems": "Adicionar itens",
        "removeItems": "Remover itens",
        "submit": "Enviar",
        "create": "Criar",
        "image": "Imagem",
        "file": "Arquivo",
        "noFiles": "Nenhum arquivo",
        'spaceAvailable': 'Espaço disponível',
        "folder": "Pasta",
        "new": "Novo",
        "back": "Voltar",
        "open": "Abrir",
        "noFolders": "Nenhuma pasta",
        "reportGenerated": "Relatório gerado com sucesso",
        "reportGeneratedMessage": "Relatorio enviado para file de processamento, uma notificação será enviada quando o relatório estiver pronto",
        "select": "Selecionar",
        'products': 'Produtos',
        "productsCheck": "Verificar produtos",
        "hasDistribution": "Possui centro de distribuição",
        "address": "Endereço",
        "dragAndDrop": "Arraste e solte um(a) {mode} aqui ou clique para selecionar",
        "unsavedItems": " {items} item não salvo | {items} itens não salvos",
        "filesInsideCount": "{0} Arquivo|{0} Arquivos",
        "auth": {
            "passwordChanged": "Senha alterada com sucesso, você será redirecionado para a página de login",
            "changePassword": "Alterar senha",
        },
        "months": {
            "january": "Janeiro",
            "february": "Fevereiro",
            "march": "Março",
            "april": "Abril",
            "may": "Maio",
            "june": "Junho",
            "july": "Julho",
            "august": "Agosto",
            "september": "Setembro",
            "october": "Outubro",
            "november": "Novembro",
            "december": "Dezembro",
        },
        "daysOfWeek": {
            "monday": "Segunda-feira",
            "tuesday": "Terça-feira",
            "wednesday": "Quarta-feira",
            "thursday": "Quinta-feira",
            "friday": "Sexta-feira",
            "saturday": "Sábado",
            "sunday": "Domingo",
        }
    },
    "showcase": "Vitrine",
    "cashier": {
        "chooseATransactionType": "Escolha um tipo de transação",
        "chooseATransactionTypeDescription": "Escolha um tipo de transação para continuar",
        "subtractTransactionTypes": {
            "subtract": "Subtrair",
            "change": "Troco",
            "refund": "Devolução",
            "paymentsToSuppliers": "Pagamentos a Fornecedores",
        },
        "balance": {
            "missing": "Faltando no caixa",
            "title": "Saldo",
        },
        "alerts": {
            "cashierIsClosed": "O caixa está fechado, por favor abra o caixa para continuar",
            "changingPerspective": "Alterando perspectiva, por favor aguarde",
            "cashierIsLocked": "O caixa está bloqueado",
            "cashierIsLockedDescription": "O caixa está bloqueado, por favor aguarde o desbloqueio para continuar",
        },

        "insertAmount": "Insira o valor",
        "insertAmountDescription": "Insira o valor da transação",
        "transactionAdded": "Transação adicionada com sucesso",
        "transactionAddedDescription": "A transação foi adicionada com sucesso",
        "errorAddingTransaction": "Erro ao adicionar transação",
        "errorAddingTransactionDescription": "Erro ao adicionar transação, por favor tente novamente",
        "selectReportType": "Selecione o tipo de relatório",
        "reportGenerated": "Relatório gerado com sucesso",
        "downloadReport": "Baixar relatório",
        "generateReport": "Gerar relatório",
        "additionTransactionTypes": {
            "sale": "Venda",
            "addition": "Adição",
        },
        "user": "Atendente",
        "transactions": {
            "title": "Transações",
            "openCashier": "Abriu o caixa com",
            "sale": "Fez uma venda de",
            "addition": "Adicionou",
            "subtraction": "Subtraiu",
            "closeCashier": "Fechou o caixa com",
            "change": "Deu de troco",
            "refund": "Devolução de",
            "paymentsToSuppliers": "Pagamentos a fornecedores de",

        },
        "reportTypes": {
            "daily": "Diário",
            "weekly": "Semanal",
            "monthly": "Mensal",
            "all": "Todos",
            "incidents": "Incidentes",
        }

    },
    "users": {
        "form": {
            "register": "Cadastrar",
            "update": "Atualizar"
        },
        "header": {
            "name": "Nome",
            "email": "Email",
            "role": "Função",
            "active": "Ativo",
            "actions": "Ações",
            "status": "Status",

        },
        "hasInactive": "Usuarios Inativos irão ser removidos após 60 dias"
    },
    "product": {
        "icmsTributeSituations": "Situação tributária do ICMS",
        "pisTribute": "Tributação do PIS",
        "icmsOrigem": "Origem do ICMS",
        "cofinsTributeSituations": "Tributação do COFINS",
        "pisTributeSituations": "Tributação do PIS",
        "name": "Nome",
        "code": "Código",
        "tax": "Imposto",
        "price": "Preço",
        "active": "Ativo",
        "virtual": "Virtual",
        "category": "Categoria",
        "stock": "Estoque",
        "sales_count": "Vendas",
        "newProduct": "Novo Produto",
        "isVirtual": "É virtual?",
        "stockManagement": "Gerenciameto de estoque",
        "ncm": "NCM",
        "categories": "Categorias",
        "catalog": "Catálogo",
        "reservedStock": "Estoque reservado",
        "oversoldMax": "Estoque máximo",
        "preSale": "Pré-venda",
        "prices": {
            "fixed_expenses_description": "são todos os gastos que você tem para a manutenção da sua operação e que não estão diretamente ligados ao custo do produto. Por exemplo: aluguel, luz, água, telefone internet, despesas administrativas etc;EX (Total das Despesas Fixas ÷ Total das Vendas x 100)",
            "variable_expenses_description": "são aquelas que acontecem junto com a realização da venda, como impostos e comissões que incidem sobre o valor transacionado; EX: (Valor do Produto / (impostos,Comissões...)",
            "profit_margin_description": "é o percentual que você espera ganhar sobre o preço unitário de cada produto. Sua empresa pode ter uma margem de lucro igual para todo o seu inventário ou adotar uma margem para cada item a depender da sua estratégia. Sendo que, ter uma margem para cada produto torna os negócios mais flexíveis e pode garantir maior competitividade nos preços de alguns artigos;",
            "direct_cost_description": "é o valor despendido para a aquisição ou produção de um produto. Ou seja, o preço que você paga por ele ao seu fornecedor.",
            "fixed_expenses": "Despesas fixas",
            "variable_expenses": "Despesas variáveis",
            "profit_margin": "Margem de lucro",
            "direct_cost": "Custo direto",
            "commission": "Comissão",
            "price": "Preço",
            "old_price": "Preço antigo",
            "commissionDescription": "Valor do produto após aplicar o comisionamento.",
            "commissionDescription2": "não inclua esta porcentagem no calculo de markup, ele sera adicionado automaticamente",
            "factor": "Fator",
            "suggested_price": "Preço sugerido",
            "markupError": "Valores extremamente altos ou negativos não são permitidos, por favor verifique os valores inseridos",
            "markupWarning": "O valor do markup alto, pode ser que o preço sugerido não seja competitivo",
            "markupHighWarning": "O valor do markup está muito alto, pode ser que esteja perdendo dinheiro com este produto",
            "markup": {
                "cost": "Custo",
                "costPlaceholder": "Custo do produto",
                "packaging": "Embalagem",
                "packagingPlaceholder": "Custo da embalagem",
                "shipping": "Frete",
                "shippingPlaceholder": "Custo do frete",
                "profit": "Lucro",
                "profitPlaceholder": "Lucro desejado",
                "commissions": "Comissões",
                "commissionsPlaceholder": "Comissões",
                "simpleTax": "Imposto",
                "simpleTaxPlaceholder": "Imposto",
                "icmsTax": "ICMS",
                "icmsTaxPlaceholder": "ICMS",
                "pisTax": "PIS",
                "pisTaxPlaceholder": "PIS",
                "cofinsTax": "COFINS",
                "cofinsTaxPlaceholder": "COFINS",
                "calculate": "Calcular",
            }

        },
        "generalConfiguration": {
            "skuDescription": "O identificador (SKU) deve conter letras e números, possuir mais de 3 caracteres e ser único.",
            "shortDescriptionDescription": "A descrição curta do produto não deve ser vazia e ter menos de 500 caracteres.",
            "descriptionDescription": 'A descrição do produto não deve ser vazia e ter menos de 2500 caracteres.',
            "stockDescription": "O estoque deve ser um número inteiro maior ou igual a zero.",
            "categoryDescription": "O produto deve possuir pelo menos uma categoria associada.",
            "ncmDescription": "O produto deve possuir um NCM definido",
            "imagesDescription": "O produto deve possuir pelo menos uma imagem associada.",
            "dimensionsDescription": "O produto deve ter largura, altura, peso e comprimento definidos",
            "classificationDescription": "Todos os campos de classificação devem ser preenchidos",
            "sku": "SKU",
            "shortDescription": "Descrição curta",
            "description": "Descrição",
            "stock": "Estoque",
            "categories": "Categoria",
            "price": "Preço",
            "name": "Validação",
            "catalogs": "Catálogos",
            "active": "Ativo",
            "activeDescription": "Se o produto está ativo ou não",
            "valid": "Válido",
            "attributes": "Atributos",
            "attributesDescription": "Verificação de Quantidade de atributos",
            "ncm": "NCM",
            "images": "Imagens",
            "dimensions": "Dimensões",
            "classification": "Classificação",
            "status": "Status",
            "pending": "Pendente",
            "approved": "Aprovado",
            "rejected": "Rejeitado",
            "alterationHeader": {
                "field": "Campo",
                "oldValue": "Valor antigo",
                "newValue": "Valor novo",
                "date": "Data",
                "status": "status",
            },
            "statusAlteration": {
                "pending": "Pendente",
                "approved": "Aprovado",
                "rejected": "Rejeitado",
            }
        },
        "seo": {
            "metaTitle": "Meta título",
            "metaTitleDescription": "O título da página que aparece nos resultados de pesquisa.",
            "metaDescription": "Meta descrição",
            "metaDescriptionDescription": "Uma breve descrição da página que aparece nos resultados de pesquisa.",
            "metaKeywords": "Meta palavras-chave",
            "metaKeywordsDescription": "Uma lista de palavras-chave separadas por vírgulas que descrevem a página.",
        },
        "customFields": {
            "onlyEditable": "Apenas editável",
            "confirmRemove": "Tem certeza que deseja remover o campo {field}?",
            "confirmAdd": "Tem certeza que deseja adicionar o campo {field}?",
            "fields": {
                "textarea": "Área de texto",
                "text": "Texto",
                "additional": "Adicional",
            }

        },
        "cart": {
            "stackable": "Empilhável",
            "stackableDescription": "Se o produto pode ser adicionado mais de uma vez no carrinho",
            "allowOutOfStockPurchases": "Permitir compras fora de estoque",
            "allowOutOfStockPurchasesDescription": "Se o produto pode ser comprado mesmo se estiver fora de estoque",
            "defaultQuantity": "Quantidade padrão",
            "defaultQuantityDescription": "A quantidade padrão que será adicionada ao carrinho",
            "minimumQuantity": "Quantidade mínima",
            "minimumQuantityDescription": "A quantidade mínima que pode ser adicionada ao carrinho",
            "maximumQuantity": "Quantidade máxima",
            "maximumQuantityDescription": "A quantidade máxima que pode ser adicionada ao carrinho",
            "stockManagement": "Gerenciamento de estoque",
            "stockManagementDescription": "Gerenciar estoque deste produto",
        },
        "delivery": {
            "dimensions": "Dimensões",
            "dimensionsDescription": "As dimensões do produto",
            "width": "Largura(cm)",
            "height": "Altura(cm)",
            "length": "Comprimento(cm)",
            "weight": "Peso(kg)",
        },
        "descriptions": {
            "description": "Descrição",
            "description_short": "Descrição curta",
            "sku": "SKU",
            "gtin": "GTIN",
            "mpn": "MPN",
            "ncm": "NCM",
            "name": "Nome",
            "icmsTributeSituation": "Situação tributária do ICMS",
            "pisTribute": "Tributação do PIS",
            "icmsOrigem": "Origem do ICMS",
            "cofinsTribute": "Tributação do COFINS",
        },
        "tab": {
            "general": "Geral",
            "attachment": "Anexos",
            "price": "Preços",
            "description": "Descrição",
            "details": "Detalhes",
            "delivery": "Entrega",
            "cart": "Carrinho",
            "customFields": "Campos personalizados",
            "seo": "SEO",
            "classification": "Classificação",
        }
    },
    "message": {
        "userCreated": "Usuário criado com sucesso",
        "userUpdated": "Usuário atualizado com sucesso",
        "userActivated": "Usuário ativado com sucesso",
        "userDeactivated": "Usuário desativado com sucesso",
    },
    "delivery": {
        "tabs": {
            "drivers": "Motorista",
            "freight": "Tabela de fretes",
        }
    },
    "dashboard": {
        "paymentMethodData": "Dados de pagamento",
        "lastTransactions": "Últimas transações",
        "interactions": "Interações",
        "deliveryMap": "Mapa de entregas",
        "cartChart": "Gráfico de carrinhos",
        "sinceBegin": "Desde o início",
        "perWeek": "Por semana",
        "grandTotal": "Total geral",
        "averageSales": "Média de vendas",
    },
    "deliveryCredit": {
        "credits": "Quantidade de créditos",
        "addCredits": "Adicionar créditos",
        "paymentInstructions": "Instruções de pagamento",
        "paymentInstructionsDescription": "Siga as instruções abaixo para realizar o pagamento",
    },
    "drivers": {
        "transferPercentage": "Porcentagem de repasse",
        "totalPayToDriver": "Total a pagar ao motorista",
        "totalDeliveryMontly": "Total de entregas mensais",
        "totalDeliveries": "Total de entregas",
        "totalPayToDriverNotPaid": "Total a pagar ao motorista não pago",
        "noDeliveries": "Nenhuma entrega",
        "deliveries": "Entregas",
        "pay": "Pagar ao motorista",
    },
    "pdv": {
        "openedCarts": "Carrinhos abertos",
        "noOpenedCarts": "Nenhum carrinho aberto",
        "errors": {
            "quantity": "A quantidade deve ser maior que zero",
        }
    },
    "pos": {
        "channels": "Canais",
        "channelSelector": "Seletor de canais",
        "selectProducts": "Selecione os Produtos",
        "deliveryMethod": "Método de Entrega",
        "checkout": "Finalizar Pedido",
    },
    "menu": {
        "chainDelivery": "Entrega encadeada",
        "dashboard": "Dashboard",
        "users": "Usuários",
        "orders": "Pedidos",
        "ordersHistory": "Histórico de pedidos",
        "products": "Produtos",
        "saleStarter": "Inicializador de vendas",
        "cashier": "Caixa",
        "planner": "Planejador",
        "configuration": "Configurações",
        "delivery": "Entregas",
        "printers": "Impressoras",
        "administration": "Administração",
        "myCompany": "Minha empresa",
        "myWarehouses": "Meus depósitos",
        "editProduct": "Editar produto",
        "warehouse": "Depósito",
        "event": "Eventos",
        "pos": "PDV",
        "myFiles": "Meus arquivos",
        "reports": "Relatórios",
        "undefined": "Indefinido",
    },
    "onboarding": {
        "addressLimit": "Você so pode adicionar Depósitos baseados na quantidade de endereços que você cadastrou no passo anterior",
        "warehouseLimit": "Você so pode adicionar Agendas baseados na quantidade de Depositos que você cadastrou no passo anterior",
        "congratulations": "Parabéns!",
        "congratulationsMessage": "Você concluiu com sucesso o processo de integração. Agora, precisamos de algum tempo para analisar seus dados e ativar sua conta. Você receberá um e-mail quando sua conta estiver ativada.",
        "congratulationsMessage2": "Esse processo pode levar até 24 horas.",
        "goToLogin": 'Ir para o login',
        "myCompany": 'Minha Empresa',
        "address": 'Endereço',
        "warehouse": 'Armazém',
        "calendars": 'Calendários',
        "users": 'Usuários',
        "companyMessage": 'Preencha os campos abaixo para registrar sua empresa',
        "addressMessage": 'Preencha os campos abaixo para registrar seu endereço',
        "warehouseMessage": 'Preencha os campos abaixo para registrar seu armazém',
        "calendarsMessage": 'Preencha os campos abaixo para registrar seus calendários',
        "usersMessage": 'Preencha os campos abaixo para registrar seus usuários',
        "companySchema": {
            "name": "Nome da Empresa",
            "state_register": "Registro Estadual",
            "phone": "Telefone",
            "email": "E-mail",
            "company_document": "Documento da Empresa",
            "company_documentComment": "Apenas números",
        },
        "addressSchema": {
            "lines": "Endereço",
            "number": "Número",
            "name": "Nome",
            "district": "Bairro",
            "city": "Cidade",
            "state": "Estado",
            "country": "País",
            "zip": "CEP",
            "details": "Detalhes",
        },
        "warehouseSchema": {
            "name": "Nome",
            "address_selected": "Endereço",
            "additional_time": "Tempo Adicional",
            "dangerous_limit": "Limite de Risco",
            "secure_limit": "Limite de Segurança",
            "delivery_range": "Faixa de Entrega",
        },
        "periodSchema": {
            "name": "Nome",
            "receive_until": "Receber Até",
            "max_delivery": "Máximo de Entregas",
            "start_at": "Início Em",
            "end_at": "Término Em",
            "weeks_day": "Dias Úteis",
            "warehouse_selected": "Armazém",
            'max_deliveryComment': 'Número máximo de entregas que podem ser feitas neste período',
        },
        "userSchema": {
            "first_name": "Primeiro Nome",
            "last_name": "Sobrenome",
            'last_nameComment': 'seu sobrenome',
            "email": "E-mail",
            "login": "Login",
            'loginComment': 'seu login para acessar o sistema',
            "password": "Senha",
            "password_confirmation": "Confirmação de Senha",
        }

    },
    "required": "O campo {0} é obrigatório",
    "email": "O campo {0} deve ser um email válido",
    "min": "O campo {0} deve ter no mínimo {1} caracteres",

    "companyInfo": "Informações da Empresa",
    "cnpjRaiz": "CNPJ Raiz",
    "razaoSocial": "Razão Social",
    "capitalSocial": "Capital Social",
    "atualizadoEm": "Atualizado em",
    "porte": "Porte",
    "emailField": "email",
    "naturezaJuridica": "Natureza Jurídica",
    "qualificacaoDoResponsavel": "Qualificação do Responsável",
    "atividadePrincipal": "Atividade Principal",
    "situacaoCadastral": "Situação Cadastral",
    "estabelecimento": "Estabelecimento",
    "cnpj": "CNPJ",
    "tipo": "Tipo",
    "nomeFantasia": "Nome Fantasia",
    "dataSituacaoCadastral": "Data da Situação Cadastral",
    "dataInicioAtividade": "Data de Início de Atividade",
    "logradouro": "Logradouro",
    "bairro": "Bairro",
    "cep": "CEP",
    "cidade": "Cidade",
    "telefone": "Telefone",
    "atividadesSecundarias": "Atividades Secundárias",
    "simplesNacional": "Simples Nacional",
    "simples": "Simples",
    "dataOpcaoSimples": "Data de Opção pelo Simples",
    "dataExclusaoSimples": "Data de Exclusão do Simples",
    "mei": "MEI",
    "dataOpcaoMei": "Data de Opção pelo MEI",
    "dataExclusaoMei": "Data de Exclusão do MEI",
    "inscricoesEstaduais": "Inscrições Estaduais"


}