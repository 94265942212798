<template>
  <div>
  <div class="terms-box">
    <h1>Termos de Uso da Aplica&ccedil;&atilde;o PDV da Mirella Doces</h1>
    
    <p>Bem-vindo &agrave; aplica&ccedil;&atilde;o PDV da Mirella Doces. Ao utilizar esta aplica&ccedil;&atilde;o, voc&ecirc; concorda com os seguintes termos e condi&ccedil;&otilde;es de uso:</p>
    
    <p>Descri&ccedil;&atilde;o da Aplica&ccedil;&atilde;o: A aplica&ccedil;&atilde;o PDV da Mirella Doces &eacute; um sistema de pedidos que permite aos usu&aacute;rios realizar pedidos de forma r&aacute;pida e simples, com op&ccedil;&otilde;es de personaliza&ccedil;&atilde;o, adi&ccedil;&atilde;o e remo&ccedil;&atilde;o de itens no carrinho, sele&ccedil;&atilde;o de m&eacute;todos de entrega e pagamento. N&atilde;o h&aacute; mensalidade, o usu&aacute;rio deve pagar uma taxa din&acirc;mica para cada pedido. A aplica&ccedil;&atilde;o &eacute; de propriedade da Rockethub e &eacute; disponibilizada exclusivamente para os lojistas que fazem parte do marketplace da Mirella Doces.</p>
    
    <p>P&uacute;blico-alvo: A aplica&ccedil;&atilde;o PDV da Mirella Doces &eacute; destinada a todos os lojistas que fazem parte do marketplace da Mirella Doces. Ao utilizar a aplica&ccedil;&atilde;o, o usu&aacute;rio declara que &eacute; um lojista registrado no marketplace da Mirella Doces e que tem autoriza&ccedil;&atilde;o para utilizar a aplica&ccedil;&atilde;o.</p>
    
    <p>Cadastro: O cadastro na aplica&ccedil;&atilde;o &eacute; realizado atrav&eacute;s de contato com nossos agentes que realizam o pr&eacute;-cadastro, a prospec&ccedil;&atilde;o e a valida&ccedil;&atilde;o do cliente para ver se ele se encaixa no nicho de produtos vendidos na Mirella Doces. O usu&aacute;rio deve fornecer informa&ccedil;&otilde;es precisas e atualizadas no momento do cadastro e manter essas informa&ccedil;&otilde;es atualizadas posteriormente.</p>
    
    <p>Coleta de Dados: A aplica&ccedil;&atilde;o coleta dados de uso, m&eacute;tricas, problemas da aplica&ccedil;&atilde;o, dados criptografados de transa&ccedil;&otilde;es para coleta de an&aacute;lises e dados b&aacute;sicos do usu&aacute;rio, como e-mail, nome e documento. A Mirella Doces se compromete a n&atilde;o vender, compartilhar ou divulgar informa&ccedil;&otilde;es pessoais do usu&aacute;rio para terceiros, exceto nos casos previstos em lei.</p>
    
    <p>Responsabilidades do Usu&aacute;rio: O usu&aacute;rio &eacute; o &uacute;nico respons&aacute;vel por todas as informa&ccedil;&otilde;es fornecidas na aplica&ccedil;&atilde;o, incluindo endere&ccedil;o, produto e m&eacute;todos de pagamento. O usu&aacute;rio declara que as informa&ccedil;&otilde;es fornecidas s&atilde;o verdadeiras e que tem autoriza&ccedil;&atilde;o para utilizar os m&eacute;todos de pagamento escolhidos. O usu&aacute;rio concorda em usar a aplica&ccedil;&atilde;o apenas para fins legais e em conformidade com estes termos de uso e todas as leis aplic&aacute;veis.</p>
    
    <p>Privacidade: Todos os dados trafegados no servidor s&atilde;o criptografados e dados n&atilde;o essenciais n&atilde;o permanecem no servidor por mais de 30 dias. A Mirella Doces se compromete a proteger a privacidade do usu&aacute;rio e a n&atilde;o compartilhar informa&ccedil;&otilde;es pessoais do usu&aacute;rio com terceiros, exceto nos casos previstos em lei.</p>
    
    <p>Den&uacute;ncias: O usu&aacute;rio pode denunciar qualquer problema ou irregularidade atrav&eacute;s do e-mail contato@mirelladoces.com.br ou pelo chat do site. A Mirella Doces se compromete a investigar todas as den&uacute;ncias recebidas e a tomar as medidas necess&aacute;rias para solucionar o problema.</p>
    <p>Cancelamento e reembolsos: Todos os reembolsos ser&atilde;o tratados diretamente pelo departamento financeiro da Mirella Doces, exceto os pagamentos coletados diretamente pelo cliente. Em caso de cancelamento do pedido por parte do cliente, a taxa din&acirc;mica cobrada n&atilde;o ser&aacute; reembolsada. A Mirella Doces se reserva ao direito de recusar qualquer solicita&ccedil;&atilde;o de reembolso que n&atilde;o esteja em conformidade com os termos e condi&ccedil;&otilde;es da aplica&ccedil;&atilde;o. Qualquer d&uacute;vida ou solicita&ccedil;&atilde;o de reembolso deve ser direcionada ao departamento financeiro da Mirella Doces atrav&eacute;s do e-mail financeiro@mirelladoces.com.br.</p>
    
    <p>Beta: A aplica&ccedil;&atilde;o encontra-se em fase beta, o que significa que a mesma pode conter falhas ou instabilidades. O usu&aacute;rio compreende e aceita que o uso da aplica&ccedil;&atilde;o &eacute; de sua responsabilidade e que a Mirella Doces n&atilde;o se responsabiliza por quaisquer danos ou preju&iacute;zos decorrentes do uso da mesma. A Mirella Doces se reserva ao direito de suspender ou encerrar o acesso do usu&aacute;rio &agrave; aplica&ccedil;&atilde;o a qualquer momento, sem aviso pr&eacute;vio, caso seja identificado que o usu&aacute;rio est&aacute; fazendo uso inadequado da aplica&ccedil;&atilde;o ou em caso de viola&ccedil;&atilde;o destes termos e condi&ccedil;&otilde;es.</p>
    
    <p>Acordos: Estes termos e condi&ccedil;&otilde;es constituem o acordo integral entre o usu&aacute;rio e a Mirella Doces em rela&ccedil;&atilde;o ao uso da aplica&ccedil;&atilde;o, substituindo todas as comunica&ccedil;&otilde;es e propostas anteriores ou contempor&acirc;neas, sejam elas eletr&ocirc;nicas, verbais ou escritas. Qualquer altera&ccedil;&atilde;o ou modifica&ccedil;&atilde;o destes termos e condi&ccedil;&otilde;es somente ser&aacute; v&aacute;lida se expressamente acordada por escrito pela Mirella Doces. Caso qualquer cl&aacute;usula destes termos e condi&ccedil;&otilde;es seja considerada inv&aacute;lida ou inexequ&iacute;vel, tal cl&aacute;usula ser&aacute; removida sem afetar as demais disposi&ccedil;&otilde;es destes termos e condi&ccedil;&otilde;es. A omiss&atilde;o da Mirella Doces em exigir o cumprimento de qualquer cl&aacute;usula destes termos e condi&ccedil;&otilde;es n&atilde;o constituir&aacute; uma ren&uacute;ncia a tal cl&aacute;usula ou a qualquer outra disposi&ccedil;&atilde;o destes termos e condi&ccedil;&otilde;es.</p>
    
    <p>Disputa: Estes termos e condi&ccedil;&otilde;es ser&atilde;o regidos e interpretados de acordo com as leis brasileiras. O usu&aacute;rio concorda que qualquer disputa decorrente ou relacionada ao uso da aplica&ccedil;&atilde;o ser&aacute; submetida &agrave; jurisdi&ccedil;&atilde;o exclusiva dos tribunais brasileiros.</p>
    
    
    
  </div>
  <div class="terms-buttons d-flex justify-content-between container mt-3">
    <button class="btn btn-primary" @click="accept">Aceitar</button>
    <button class="btn btn-danger" @click="decline">Recusar</button>
  </div>
  </div>
</template>

<script>
export default {
  name: 'TermsBox',
  props: {
    title: {
      type: String,
      required: true
    },
  },
  methods: {
    accept() {
      // Lógica para aceitar os termos
      this.$emit('accepted');
    },
    decline() {
      // Lógica para recusar os termos
      this.$emit('declined');
    }
  }
}
</script>

<style scoped>
.terms-box {
  border: 1px solid #ccc;
  padding: 20px;
  max-height: 450px;
  overflow: auto;
}
</style>