<script>
import VueMultiselect from 'vue-multiselect'

export default {
  name: "FilterByBadges",
  props: {
    data: {
      required: true
    }
  },
  data() {
    return {
      selectedBadges: [],
      badgesOptions: []
    }
  },
  methods: {
    checkPunctuality: function (delivery) {
      if (delivery.delivery_in > this.moment().format('YYYY-MM-DD')) {
        return 'Adiantado'
      } else if (delivery.delivery_in === this.moment().format('YYYY-MM-DD')) {
        return 'No Prazo'
      } else {
        return 'Atrasado'
      }
    },
    getPossibleStateFromSelectedGroups: function (data) {
      let badges = []
      const asArray = Object.entries(data);
      for (let i = 0; i < asArray.length; i++) {
        for (let j = 0; j < asArray[i][1].length; j++) {
          let delivery = asArray[i][1][j]
          badges.push({name: this.checkPunctuality(delivery), trigger: 'punctuality'})
          badges.push({name: delivery.period.name, trigger: 'period'})
          badges.push({name: delivery.provider, trigger: 'provider'})
          if (delivery.buyed_shipping) {
            badges.push({name: 'Ticket Disponivel', trigger: 'ticket'})
          }
          badges.push({name: delivery.warehouse.name, trigger: 'warehouse'})

          if (delivery.national) {
            badges.push({name: 'Nacional', trigger: 'national'})
          }
        }
      }
      // remove duplicated badges object
      badges = [...new Set(badges.map(JSON.stringify))].map(JSON.parse)
      this.badgesOptions = badges
      return badges
    },
    filterData() {
      const asArray = Object.entries(this.data);
      let filtered = [];

      // Filter based on selected badges
      for (let i = 0; i < asArray.length; i++) {
        for (let j = 0; j < asArray[i][1].length; j++) {
          let delivery = asArray[i][1][j];

          // Check if delivery matches all selected badges
          if (this.selectedBadges.every(badge => {
            if (badge.trigger === 'punctuality') {
              return this.checkPunctuality(delivery) === badge.name;
            } else if (badge.trigger === 'period') {
              return delivery.period.name === badge.name;
            } else if (badge.trigger === 'provider') {
              return delivery.provider === badge.name;
            } else if (badge.trigger === 'ticket' && badge.name === 'Ticket Disponivel') {
              return delivery.buyed_shipping;
            } else if (badge.trigger === 'warehouse') {
              return delivery.warehouse.name === badge.name;
            } else if (badge.trigger === 'national' && badge.name === 'Nacional') {
              return delivery.national;
            }
            // Add additional conditions for other badge triggers if needed
            return true;
          })) {
            filtered.push(delivery);
          }
        }
      }

      // Group filtered data by the 'delivery_in' property
      let obj = {};
      for (let i = 0; i < filtered.length; i++) {
        if (!Object.prototype.hasOwnProperty.call(obj, filtered[i].delivery_in)) {
          obj[filtered[i].delivery_in] = [];
        }
        obj[filtered[i].delivery_in].push(filtered[i]);
      }

      return obj;
    }

  },
  computed: {
    isActive() {
      return this.selectedBadges.length > 0;
    }
  },
  mounted() {
    this.getPossibleStateFromSelectedGroups(this.data)
  },
  components: {VueMultiselect},
}
</script>

<template>
  <div class="col-md-4">
    <VueMultiselect v-model="selectedBadges" :options="badgesOptions" :multiple="true" :close-on-select="false"
                    :clear-on-select="false" :preserve-search="true" placeholder="Filtrar por tag" label="name" selectLabel="Clique para selecionar"
                    track-by="name" :preselect-first="true">
    </VueMultiselect>
  </div>

</template>

<style scoped>

</style>