<template>
  <div id="app">
    <div id="layout-wrapper" v-if="!isCanvas">
      <my-navbar v-if="user.authenticated"/>
      <main-menu v-if="user.authenticated"/>

      <div v-if="user.authenticated" class="main-content">
        <router-view/>
      </div>

      <router-view v-else/>
      <notifications position="bottom right" />
      <vue3-confirm-dialog></vue3-confirm-dialog>
      <ModalsContainer />
    </div>
    <router-view v-else/>
  </div>
</template>

<script>
import Navbar from './components/generic/Navbar.vue';
import MainMenu from "./components/generic/MainMenu.vue";
import {useAuthStore} from "./store/authStore";
import { ModalsContainer } from 'vue-final-modal'



export default {
  data() {
    return {
      user: this.authStore.user,
    }
  },
  setup() {
    const authStore = useAuthStore();
    return {
      authStore
    }
  },
  components: {
    'my-navbar': Navbar,
    'main-menu': MainMenu,
    ModalsContainer

  },
  computed: {
    isCanvas() {
      //chack if route has meta canvas
      return this.$route.meta.canvas
    }
  },
  beforeMount() {
    this.authStore.checkAuth()
  },
}

</script>



