<script>
import Method from "@/components/pdv/details/delivery/Method.vue";
import {usePosStore} from "@/store/PosStore";
import {collect} from "collect.js";

export default {
  name: "DeliveryPack",
  props: {
    pack: {
      type: Object,
      required: true
    }
  },
  setup: () => {
    const posStore = usePosStore()
    return {posStore}
  },
  data(){
    return {
      periodData: null,
      periods: []
    }
  },
  methods:{
    collect,
    changePeriod(event) {
      this.axios.patch('/pos/set-delivery-period', {
        delivery_period_id: event.target.value,
        delivery_id: this.packID
      })
    },
    fetchAvailablePeriods(){
      this.axios.get(`/pos/available-periods-to-delivery/${this.packID}`).then((response) => {
        this.periods = response.data.periods
      }).catch((error) => {
       this.periods = []
        console.log(error)
      })
    },
    removeDate: function () {
      this.$swal({
        title: 'Aguarde...',
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading()
        }
      })
      this.axios.delete(`pos/address/date/${this.posStore.getCart.id}`).then(() => {
        this.$swal.close()
        this.posStore.updateCart()
      }).catch((error) => {
        this.$swal.close()
        console.log(error)
      })
    },
    async showSwalDate() {
      const {value: formValues} = await this.$swal.fire({
        title: 'Selecione a data de entrega',
        html:
            '<input id="dateSelected" class="form-control" required type="date">',
        focusConfirm: false,
        preConfirm: () => {
          let value = document.getElementById('dateSelected').value
          if (!value) {
            this.$swal.showValidationMessage(
                `Você precisa selecionar uma data`
            )

          }
          if (value < new Date().toISOString().split('T')[0]) {
            this.$swal.showValidationMessage(
                `Você precisa selecionar uma data a partir de hoje`
            );
          }
          return {date: value}
        }
      })

      if (formValues) {
        this.$swal({
          title: 'Aguarde...',
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading()
          }
        })
        this.axios.post(`pos/address/date/${this.posStore.getCart.id}`, formValues).then(() => {
          this.$swal.close()
          this.posStore.updateCart()
        }).catch((error) => {
          this.$swal.close()
          console.log(error)
        })

      }

    },
  },
  computed:{
    firstPeriod() {
      return this.pack.selected_shipping.period_id;
    },
    cart(){
      return this.posStore.getCart.id
    },
    packID(){
      return this.pack.id
    },
    dateToDeliveryIsToday(){
      let date  = new Date(this.pack.selected_shipping?.quoted_date_to_delivery)
      let today = new Date()
      return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()
    },
    deliveryDateFormated(){
      return new Date(this.pack.selected_shipping?.quoted_date_to_delivery).toLocaleDateString()
    }
  },
  beforeMount() {
    this.fetchAvailablePeriods()

    if (this.periodData == null) {
      this.periodData = this.firstPeriod
    }
  },
  components: {Method}
}
</script>

<template>
  <div class="col-md-12 mt-2  " v-auto-animate>
    <button class="btn btn-primary text-uppercase btn-sm w-100"
            @click.prevent="showSwalDate">{{deliveryDateFormated}}
    </button>
  </div>
  <Method :method="method" :pack="pack" :index="index" v-for="(method,index) in pack.methods" @updatePeriods="fetchAvailablePeriods" :key="index"/>
  <div class="col-12">
    <select class="form-select" v-model="periodData" @change="changePeriod">
      <option v-for="period in periods" :key="period.id" :value="period.id">{{
          period.name
        }}
      </option>
    </select>
  </div>
</template>

<style scoped>

</style>