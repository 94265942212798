<template lang="html">

  <div class="dropdown d-inline-block ms-2 ">
    <button type="button" class="btn header-item noti-icon" v-wave="{color: '#0a1832',}"
            id="page-header-notifications-dropdown" data-bs-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
      <i class="ion ion-md-cash"></i>
    </button>
    <div class="dropdown-menu dropdown-menu-sm dropdown-menu-end p-0" style="max-height: 350px"
         aria-labelledby="page-header-notifications-dropdown">
      <div class="p-3">
        <div class="row align-items-center">
          <div class="col">
            <h5 class="m-0 font-size-16 text-center">Meus Valores</h5>
          </div>
        </div>
      </div>
      <div class="gap-3 p-2">
        <div class="d-grid">
          <small class="text-muted text-uppercase">creditos de entrega</small>
          <span class="cde font-size-20 fw-bolder d-flex gap-2">
                  <number
                      ref="number1"
                      :from="deliveryCreditBalance.from"
                      :to="deliveryCreditBalance.to"
                      :format="theFormat"
                      :duration="2"
                      class=""
                      easing="Power1.easeOut"/>
                    <router-link :to="{name:'addCredits'}"><i class="fa fa-plus-circle"></i></router-link>
                </span>
        </div>
        <div class="d-grid" v-if="authStore.checkRole('systemRole','COM')">
          <small class="text-muted text-uppercase">valor a receber</small>
          <span class="cde font-size-20 fw-bolder d-flex gap-2">
                  <number
                      ref="number2"
                      :from="accountBalance.from"
                      :to="accountBalance.to"
                      :format="theFormat"
                      :duration="2"
                      class=""
                      easing="Power1.easeOut"/>
              <a href="#" @click.prevent="updateAccountBalance"><i class="fa-solid fa-refresh"></i></a>
                </span>
        </div>
        <div class="d-grid" v-if="authStore.checkRole('systemRole','COM')">
          <small class="text-muted text-uppercase">Pendências</small>
          <span class="cde font-size-20 fw-bolder d-flex gap-2">
                  <number
                      ref="number2"
                      :from="pendencyBalance.from"
                      :to="pendencyBalance.to"
                      :format="theFormat"
                      :duration="2"
                      class=""
                      :class="pendencyBalance.to > 0 ? 'text-danger' : 'text-success'"
                      easing="Power1.easeOut"/>
              <router-link :to="{name:'pendency'}" @click.prevent="updateAccountBalance"><i
                  class="fa-solid fa-money-bill-1-wave"></i></router-link>
                </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {usePosStore} from "@/store/PosStore";
import {useAuthStore} from "@/store/authStore";

export default {
  name: 'DeliveryCreditBalance',
  setup: () => ({posStore: usePosStore(), authStore: useAuthStore()}),
  data() {
    return {
      deliveryCreditBalance: {
        from: 0,
        to: 0
      },
      accountBalance: {
        from: 0,
        to: 0
      },
      pendencyBalance: {
        from: 0,
        to: 0
      },
    }
  },
  methods: {
    theFormat(value) {
      return parseFloat(value).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
    },
    getVendorCode: function () {
      this.axios.get('settings/vendorCode').then(response => {
        this.vendorCode = response.data.vendorToken;
        this.getDeliveryCreditBalance();
        this.getAccountBalance();
        this.listenCreditBalance();
      }).catch(error => {
        console.log(error);
      });
    },
    updateAccountBalance: function () {
      this.accountBalance.from = this.accountBalance.to;
      this.getAccountBalance();
    },
    getAccountBalance() {
      this.axios.get('account/balance').then(response => {
        console.log(response.data)
        this.accountBalance.to = response.data.waiting_funds_amount / 100
      }).catch(error => {
        console.log(error);
      });
    },
    listenCreditBalance: function () {
      this.$echo.channel('CreditBalanceEvent.' + this.vendorCode).listen('.SendBalanceEvent', (e) => {
        // if value is minor than actual put text red for 2 secods

        if (e.balance < this.deliveryCreditBalance.to) {

          this.$refs.number1.$el.classList.add('text-danger')
          setTimeout(() => {
            this.$refs.number1.$el.classList.remove('text-danger')
          }, 2000);
        } else {
          this.$refs.number1.$el.classList.add('text-success');
          setTimeout(() => {
            this.$refs.number1.$el.classList.remove('text-success');
          }, 2000);
        }

        this.deliveryCreditBalance.from = this.deliveryCreditBalance.to;
        this.deliveryCreditBalance.to = e.balance;
        this.posStore.updateCart()

      });
    },
    getPendencies: function () {
      this.axios.get('pendencies').then((response) => {

        let sum = 0;
        response.data.forEach((item) => {
          if (item.status === 'pending') {
            sum += item.amount;
          }
        })
        this.pendencyBalance.to = sum / 100;
      }).catch(error => {
        console.log(error);
      })
    },
    getDeliveryCreditBalance() {
      this.axios.get('deliveryCredit/balance').then(response => {
        this.deliveryCreditBalance.to = response.data.balance;
      }).catch(error => {
        console.log(error);
      });
    }
  },
  mounted() {
    this.getVendorCode()
    this.getPendencies()
  }
}
</script>
<style lang="scss" scoped>
/* https://github.com/jgthms/bulma/issues/2514 */
/* Issue with menus not closing because Bulma does come with any javascript. The @media screen and mounted hook is the fix for that */
@media screen and (min-width: 1025px) {
}
</style>