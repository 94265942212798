<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <!-- create button to add new field -->
          <div class="card-header justify-content-between d-flex">
            <h5>Valores</h5>
            <button class="btn btn-primary" @click="createNewField">Adicionar Valor</button>
          </div>

          <div class="row p-2" v-for="(fieldValue,index) in field.values" :key="'field'+index">
            <div class="col-md-3">
              <input placeholder="Nome" class="form-control" type="text" v-model="fieldValue.name">
            </div>
            <div class="col-md-3">
              <input placeholder="Valor" class="form-control" :type="getFieldType(type)" v-model="fieldValue.value">
            </div>
            <div class="col-md-3">
              <input placeholder="Preço" class="form-control" type="number" v-model="fieldValue.price">
            </div>
            <!-- create button to delete  -->
            <div class="col-md-2">
              <button class="btn btn-danger" @click="deleteField(fieldValue)"><i class="dripicons-trash"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MutilValueNewField",
  props: {
    type: {},
    field: {}
  },
  created() {
    this.copyField = { ...this.field };
  },

  methods: {
    createNewField() {
      this.copyField.values.push({
        name: "",
        value: "",
        price: 0
      });
      this.$emit("update:field", this.copyField);
    },
    deleteField(fieldValue) {
      this.copyField.values = this.copyField.values.filter(field => field !== fieldValue);
      this.$emit("update:field", this.copyField);
    },
    getFieldType(type) {
      switch (type) {
        case 'TextField':
          return 'text'
        case 'TextareaField':
          return 'text'
        case 'CheckboxField':
          return 'text'
        case 'DropdownField':
          return 'text'
        case 'ImageField':
          return 'text'
        case 'ColorField':
          return 'color'
        case 'AdditionalField':
          return 'text'
      }
    }
  }
}
</script>

<style scoped>

</style>