<template>
  <div :class="{'col-md-4':!showProducts, 'col-md-12 ':showProducts}" class=" bg-soft-light rounded-2 mt-3 "
       v-auto-animate>
    <div class="card">
      <div class="mb-2">
        <div class="row">
          <div class="col-md-12">
          </div>
        </div>
      </div>
      <div class="row" v-auto-animate>
        <div class="d-flex">
          <div class="col-md-6">
            <div class=" text-center bg-transparent bold font-size-14 rounded-2"
                 v-if="posStore.getCart.shipping_address && !internal">{{ posStore.getCart.shipping_address.lines }}, {{
                posStore.getCart.shipping_address.number
              }} - {{ posStore.getCart.shipping_address.district }} - {{ posStore.getCart.shipping_address.city }}
            </div>
            <div class="content d-flex justify-content-center" v-show="internalIsAvailable">
              <input type="checkbox" data-toggle="toggle" ref="orderStore" data-onlabel="Interno" v-model="internal"
                     data-offlabel="Externo" data-onstyle="success" data-offstyle="danger">
            </div>
            <div class="content d-flex justify-content-center" v-if="!internal">
              <AddressSelector :noAddress="haveAddress"/>
            </div>
          </div>
          <div class="col-md-6">
            <div class="content" v-if="!this.posStore.deliveryLoading && haveAddress">
              <DeliveryPack class="packages" :pack="pack" :index="index"
                            v-for="(pack,index) in posStore.getDeliveryMethods"
                            :key="index"/>
            </div>
            <div class="content p-4 text-center" v-else>
              <div v-if="!haveAddress"><span>Nenhum endereço cadastro</span></div>
              <div v-else class="spinner"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AddressSelector from "@/components/pdv/details/delivery/AddressSelector.vue";
import {usePosStore} from "@/store/PosStore";
import {onMounted, ref} from "vue";
import {useSettingsStore} from "@/store/settingsStore";
import DeliveryPack from "@/components/pdv/details/delivery/DeliveryPack";


export default {
  name: 'DeliveryMethod',

  props: {
    showProducts: {
      type: Boolean,
      default: true
    },
    deliveryAddress: {}
  },
  setup: () => {
    const orderStore = ref(null)

    onMounted(() => {
      orderStore.value.bootstrapToggle()
    })
    const posStore = usePosStore()
    const settingsStore = useSettingsStore()
    return {
      posStore,
      orderStore,
      settingsStore
    }
  },
  components: {AddressSelector, DeliveryPack},
  data() {
    return {
      showDelivery: true,
      deliveryMethods: [],
      loaded: true,
      internal: false,
      internalIsAvailable: false,
    }
  },
  beforeMount() {
    if (this.posStore.getCart.shipping_address_id != null) {
      this.posStore.retrieveDeliveryMethods()
    }
    this.settingsStore.fetchStore().then(() => {
      this.internalIsAvailable = this.settingsStore.getStore !== null && true && this.settingsStore.getStore !== ""
    })

  },
  computed: {
    haveAddress() {
      return this.posStore.getCart.shipping_address != null
    }
  },
  created() {

    this.emitter.on('paymentEnded', () => {
      //kill component
      this.cartCopy = null
    })
  },
  watch: {
    internal: function (val) {
      this.posStore.setOrderStore(val, this.settingsStore.getStore)
    }
  }
}
</script>
<style scoped>
::selection {
  background: #d5bbf7;
}

.card {
  width: 100%;
  border-radius: 5px;
  padding: 20px;
}

.card .title {
  font-size: 22px;
  font-weight: 500;
}

.card .content {
  margin-top: 20px;
}

.card label.box {
  margin-top: 12px;
  padding: 10px 12px;
  display: flex;
  border-radius: 5px;
  border: 2px solid transparent;
  cursor: pointer;
  transition: all 0.25s ease;
}

#one:checked ~ label.first,
#two:checked ~ label.second,
#three:checked ~ label.third {
  border-color: #8E49E8;
  background: #d5bbf7;
}

.card label.box:hover {
  background: #d5bbf7;
}

.card label.box .circle {
  height: 22px;
  width: 22px;
  background: #ccc;
  border: 5px solid transparent;
  display: inline-block;
  margin-right: 15px;
  border-radius: 50%;
  transition: all 0.25s ease;
  box-shadow: inset -4px -4px 10px rgba(0, 0, 0, 0.2);
}

#one:checked ~ label.first .circle,
#two:checked ~ label.second .circle,
#three:checked ~ label.third .circle {
  border-color: #8E49E8;
  background: #fff;

}

.card label.box .plan {
  display: flex;
  width: 100%;
  align-items: center;
}

.card input[type="radio"] {
  display: none;
}
</style>