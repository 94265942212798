<template>
  <div class="row text-center">
    <div class="col-md-12">
      <input type="radio" :checked="pack.selected_shipping.shipping_method_id === method.id && method.available"
             :disabled="!method.available" @change="selectMethod" name="rd" class="method" :id="index">
      <label :for="index" class="box second" :class="{'bg-danger':!method.available}">
        <div class="plan col-md-7 d-flex p-2">
          <span class="circle"></span>
          <span class="yearly">{{ method.name }}</span>
        </div>
        <span class="col-md-4 d-flex p-2">
        <span class="price">{{ method.price.BRL.price_formatted }}</span>
          </span>
        <span class="col-md-1">
      <popper
          trigger="hover"
          :options="{
      placement: 'top',
      modifiers: { offset: { offset: '0,10px' } }
    }">
        <button class="btn btn-secondary"> <i class="fa-solid fa-info"></i></button>
        <template #content>
              <small v-if="!method.available">você não possui creditos suficientes</small>
          <small v-else> Entrega em {{ method.period.diff }} - {{ method.period.date |formatDate }}</small>

        </template>
      </popper>
          </span>
      </label>
    </div>

  </div>
</template>
<script>
import Popper from 'vue3-popper';
import {usePosStore} from "@/store/PosStore";

export default {
  name: 'MethodComponent',
  data() {
    return {
      balance: 0
    }
  },

  setup: () => ({posStore: usePosStore()}),
  components: {Popper},
  props: {
    method: {
      type: Object,
      required: true
    },
    index: {
      type: String,
      required: true
    },
    pack: {
      type: Object,
      required: true
    },
  },
  methods: {
    selectMethod() {
      this.axios.put(`pos/delivery-methods/${this.posStore.getCart.id}`, {
        shipping: this.method.id,
        delivery: this.pack.id
      }).then(() => {
        this.$notify('Método de entrega atualizado')
        this.posStore.calculateTotals()
        this.$emit('updatePeriods')
      }).catch(() => {
        this.$notify('Erro ao atualizar método de entrega')
        this.posStore.calculateTotals()
      })
    }
  },
  created() {

  }
}
</script>
<style scoped>
::selection {
  background: #2f8ee0;
}


.card label.box {
  margin-top: 12px;
  padding: 10px 12px;
  display: flex;
  border-radius: 5px;
  border: 2px solid transparent;
  cursor: pointer;
  transition: all 0.25s ease;
}


.method:checked ~ label.second {
  border-color: #2f8ee0;
  background: #2f8ee0;
}

.card label.box:hover {
  background: #2f8ee0;
}

.card label.box .circle {
  height: 22px;
  width: 22px;
  background: #ccc;
  border: 5px solid transparent;
  display: inline-block;
  margin-right: 15px;
  border-radius: 50%;
  transition: all 0.25s ease;
  box-shadow: inset -4px -4px 10px rgba(0, 0, 0, 0.2);
}


.method:checked ~ label.second .circle {
  border-color: #2f8ee0;
  background: #fff;

}

.card input[type="radio"] {
  display: none;
}
</style>