<template>
  <div class="container-fluid mt-3">
    <save-update-action-bar
        @save="save"
        @update="update"/>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">{{$t('product.seo.metaTitle')}}</h4>
            <p class="card-title  desc">{{$t('product.seo.metaTitleDescription')}}</p>
            <div>
              <input type="text" class="form-control" v-model="productCopy.meta_title" />
            </div>
            <h4 class="card-title">{{$t('product.seo.metaDescription')}}</h4>
            <p class="card-title  desc">{{$t('product.seo.metaDescriptionDescription')}}</p>
            <div>
              <input type="text" class="form-control" v-model="productCopy.meta_description" />
            </div>
            <h4 class="card-title">{{$t('product.seo.metaKeywords')}}</h4>
            <p class="card-title  desc">{{$t('product.seo.metaKeywordsDescription')}}</p>
            <div>
              <input type="text" class="form-control" v-model="productCopy.meta_keywords" />
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SaveUpdateActionBar from "@/components/generic/SaveUpdateActionBar";

export default {
  name: "SeoComponent",
  props: {
    product: {}
  },
  data(){
    return {
      productCopy: {}
    }
  },
  created() {
    this.productCopy = Object.assign({}, this.product)
  },
  methods: {
    save() {
      this.$emit('save', this.productCopy);
    },
    update() {
      this.$emit('update');
    }
  },
  components: {
    SaveUpdateActionBar
  }
}
</script>

<style scoped>

</style>