<template>
  <div class="row">
    <div class="col-md-6 col-xl-3">
      <div class="card text-center">
        <div class="mb-2 card-body text-muted">
          <h3 class="text-info mt-2">{{ data.baseInfo.since_begin }}</h3> {{ $t('dashboard.sinceBegin')}}
        </div>
      </div>
    </div>
    <div class="col-md-6 col-xl-3">
      <div class="card text-center">
        <div class="mb-2 card-body text-muted">
          <h3 class="text-purple mt-2">{{data.baseInfo.per_week}}</h3> {{ $t('dashboard.perWeek')}}
        </div>
      </div>
    </div>
    <div class="col-md-6 col-xl-3">
      <div class="card text-center">
        <div class="mb-2 card-body text-muted">
          <h3 class="text-primary mt-2">{{ this.$parent.convertCurrency(data.baseInfo.grand_total)}}</h3> {{ $t('dashboard.grandTotal')}}
        </div>
      </div>
    </div>
    <div class="col-md-6 col-xl-3">
      <div class="card text-center">
        <div class="mb-2 card-body text-muted">
          <h3 class="text-danger mt-2">{{ this.$parent.convertCurrency(data.baseInfo.average_sales)}}</h3> {{ $t('dashboard.averageSales')}}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'base-info',
  props: {
    data: {}
  }
}
</script>
