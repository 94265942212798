<template>
  <div @mouseenter="showDelete($el)" @mouseleave="hideDelete($el)" class=" card col-md-12  bg-light m-0">
    <div class="row">
      <a @click.prevent="removeItem" class="cursor-pointer delete-element delete-card col-md-1 text-center p-2">
        <i class="fa fa-trash text-danger"></i>
      </a>
      <div class="product-info d-flex justify-content-between col-md-11 p-2 m-0">
        <div>
          <h5 class="m-0">{{ product.product.name }}</h5>
          <small>Quantidade: {{ product.quantity }}</small>
        </div>
        <small v-currency="product.price.BRL / 100">{{ product.price.BRL / 100 }}</small>

      </div>
      <div class="row d-flex justify-content-between" v-for="(field,index) in product.custom_field_values" :key="index">
        <div class="title col-6">{{field.custom_field.name}}: {{field.value}}</div>
        <div class="price col-6 text-end" v-if="field.custom_field.prices[0]" v-currency="field.custom_field.prices[0].price / 100">{{field.custom_field.prices[0].price / 100}}</div>
      </div>
    </div>
  </div>
</template>
<script>
import {usePosStore} from "@/store/PosStore";

export default {
  name: 'CartProducts',
  setup: () => ({posStore: usePosStore()}),
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  methods: {
    showDelete(element) {
      // find chield element delete-card
      const deleteCard = element.querySelector('.delete-element')
      //remove class delete-card
      deleteCard.classList.remove('delete-card')
    },
    removeItem() {
      this.$swal({
        title: 'Tem certeza?',
        text: 'Você não poderá reverter isso!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, deletar!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete(`pos/cart/item/${this.product.hashid}`).then(() => {
            this.posStore.updateCart()
            this.$swal(
                'Deletado!',
                'O item foi deletado.',
                'success'
            )
          })

        }
      })

    },
    hideDelete(element) {
      // find chield element delete-card
      const deleteCard = element.querySelector('.delete-element')
      //add class delete-card
      deleteCard.classList.add('delete-card')
    }
  }
}
</script>

<style scoped>
.delete-card {
  width: 0;
  height: 0;
  display: none;
}

.delete-element {
  transition: all 0.5s ease;
}
.cursor-pointer {
  cursor: pointer;
}
</style>