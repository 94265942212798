<template>
  <form class="form-horizontal" @submit.prevent="formOnSubmit">
    <div class="row p-2 justify-content-center">
      <div v-for="field in fields" :key="field.hashId"
           :class="{'col-md-12': field.type !== 'additional','col-md-4': field.type === 'additional'}">
        <div
            :class="['mt-4', 'mall-product__property', 'mall-product__property--custom-field', field.required ? 'is-required' : '']">
          <component :is="field.type+'Component'" :field="field" :item="product"></component>
        </div>
      </div>
      <input type="hidden" name="product" :value="product.hashId">
      <input type="hidden" name="cart" :value="posStore.getCart.id">
      <input type="hidden" name="quantity" :value="quantity">
    </div>
    <div class="row p-2 justify-content-center">
      <div class="col-md-12 p-3">
        <button type="submit" class="btn btn-primary btn-block w-100">Adicionar</button>
      </div>
    </div>
  </form>
</template>

<script>
import {usePosStore} from "@/store/PosStore";
import additionalComponent from './customfields/additional.vue';
import dropdownComponent from './customfields/dropdown.vue';
import textComponent from './customfields/text.vue';
import checkboxComponent from './customfields/checkbox.vue';
import colorComponent from './customfields/color.vue';
import textareaComponent from './customfields/textarea.vue';
import {inject} from "vue";
import axiosIntegration from "@/boostrap/apiConfig"
import {useNotification} from "@kyvg/vue3-notification";


export default {
  name: 'CustomFieldsPos',
  setup(props,{emit}) {
    const posStore = usePosStore();
    const swal = inject('$swal')
    const axios = axiosIntegration
    const {notify} = useNotification()
    const formOnSubmit = (event) => {
      var object = {};
      let formData = new FormData(event.target);
      formData.forEach((value, key) => {
        object[key] = value;
      });
      //sweet alert  with a loading
      swal.fire({
        title: 'Aguarde...',
        allowOutsideClick: false,
        didOpen: () => {
          swal.showLoading()
        }
      })
      axios.post('pos/addToCart', formData).then(() => {
        notify('Produto adicionado com sucesso')
        posStore.updateCart()
        swal.close()
      }).catch(() => {
        notify('Erro ao adicionar produto')
        swal.close()
      }).finally(() => {
        emit('closeModal')
      })
    };
    return {
      posStore,
      formOnSubmit,
    }
  },
  props: {
    fields: {
      type: Array,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    quantity: {
      type: Number,
    },
  },
  components: {
    additionalComponent,
    dropdownComponent,
    textComponent,
    checkboxComponent,
    colorComponent,
    textareaComponent
  }
}
</script>
<style lang="scss" scoped>

</style>



