<script>
export default {
  name: "FilterByShowAll",
  data() {
    return {
      filterAll: false
    }
  },
  props: {
    data: {
      required: true
    }
  },
  methods: {
    filterData(){
      const asArray = Object.entries(this.data);

      const filtered = asArray.filter(([key]) => {
        let now = this.moment().format('YYYY-MM-DD')
        return key <= now
      });
      // give back date to key and value to value
      return Object.fromEntries(filtered);
    }
  }
}
</script>

<template>
  <div class="col-md-3 p-2 d-flex gap-2">
    <label for="switch9">Mostrar todos</label>
    <input type="checkbox" id="switch9" switch="dark" v-model="filterAll" checked="">
    <label for="switch9" data-on-label="Sim" data-off-label="Não"></label>
  </div>
</template>

<style scoped>

</style>