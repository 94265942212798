<template>
  <div class="col-md-6" v-if="!loading">
    <div class="card" v-if="products.length > 0">
      <ProductList :headers="headers" :items="products"/>
      <div class="col-md-12 p-2 text-center">
        <button class="btn btn-primary" @click="removeAllSelected" type="button"> Remover Selecionados</button>
      </div>
    </div>
    <div v-else>
      <div class="card-body">
        <div class="text-center">
          <span class="text-muted">nenhum produto adicionado</span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6" v-else>
    <div class="card">
      <div class="card-body">
        <div class="text-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {usePlannerStore} from "@/store/plannerStore";
import ProductList from "@/components/planner/ProductList.vue";

export default {
  name: 'EventProducts',
  components: {ProductList},
  data() {
    return {
      loading: false,
      headers: [
        {text: 'Selecionar', value: 'select', sortable: false},
        {text: 'ID', value: 'id'},
        {text: 'Nome', value: 'name'},
        {text: 'Preço', value: 'price'},
      ],
    }

  },
  setup() {
    const plannerStore = usePlannerStore()
    return {
      plannerStore
    }
  },
  methods: {
    removeAllSelected() {
this.loading = true
      let checkboxes = document.getElementsByClassName('select')
      let selected = []
      for (let i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].checked) {
          selected.push(checkboxes[i].value)
        }
      }
      if (selected.length === 0) {
        this.$notify({
          title: 'Erro',
          text: 'Selecione pelo menos um produto',
          type: 'error'
        })
        return;
      }

      let id = this.$route.params.id
      this.plannerStore.removeProductsFromPlanner(id, selected).then(() => {
        this.$notify({
          title: 'Sucesso',
          text: 'Produtos removidos com sucesso',
          type: 'success'
        })
      }).catch(() => {
        this.$notify({
          title: 'Erro',
          text: 'Erro ao remover produtos',
          type: 'error'
        })
      }).finally(() => {
        this.loading = false
      })

    }
  },
  computed: {
    products() {
      return this.plannerStore.getProducts
    },
  },
  beforeMount() {
    this.loading = true
    let id = this.$route.params.id
    this.plannerStore.fetchProducts(id).finally(() => {
      this.loading = false
    })
  }
}
</script>