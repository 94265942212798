<template>
  <div class="vertical-menu">
    <div data-simplebar class="h-100">
      <div id="sidebar-menu" style="max-height: 100%;overflow: auto;">

        <ul class="metismenu list-unstyled" id="side-menu">
          <li v-for="route in filteredRoutes" :key="route.name" :class="{'menu-title mt-2': route.divisor,'mm-active': $route.name === route.name}">
            <router-link :to="{name: route.name}"  v-if="!route.divisor" v-wave>
              <i :class="route.icon"></i>
              <span> {{ route.text }} </span>
            </router-link>
            <span v-if="route.divisor">{{ route.name }}</span>
          </li>

          <!-- ... resto do seu código ... -->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {useAuthStore} from "@/store/authStore";


export default {
  name: "MainMenu",
  data() {
    return {
      routes: [
        {
          name: 'Main',
          divisor: true
        },
        {
          name: 'dashboard',
          text: this.$t('menu.dashboard'),
          icon: 'dripicons-device-desktop',
          access: 'COM'
        },
        {
          name: 'orders',
          text: this.$t('menu.orders'),
          icon: 'dripicons-suitcase',
          access: 'Employee'
        },
        {
          name: "chainDelivery",
          text: this.$t('menu.chainDelivery'),
          icon: "dripicons-network-3",
          access: "COM"
        },
        {
          name: 'ordersHistory',
          text: this.$t('menu.ordersHistory'),
          icon: 'dripicons-ticket',
          access: 'COM'
        },
        {
          name: 'products',
          text: this.$t('menu.products'),
          icon: 'dripicons-box',
          access: 'COM'
        },
        {
          name: 'saleStarter',
          text: this.$t('menu.saleStarter'),
          icon: 'dripicons-cart',
          access: 'Employee'
        },
        {
          name: "cashier",
          text: this.$t('menu.cashier'),
          icon: "dripicons-wallet",
          access: "Employee"
        },
        {
          name: 'planner',
          text: this.$t('menu.planner'),
          icon: 'dripicons-calendar',
          access: 'COM'
        },
        {
          name: 'reports',
          text: this.$t('menu.reports'),
          icon: 'dripicons-document',
          access: 'COM'
        },
        {
          name: this.$t('menu.configuration'),
          divisor: true
        },
        {
          name: 'delivery',
          text: this.$t('menu.delivery'),
          icon: 'fa fa-truck',
          access: 'COM'
        },
        {
          name: 'configuration',
          text: this.$t('menu.configuration'),
          icon: 'dripicons-view-apps',
          access: 'COM'
        },
        {
          name: 'printers',
          text: this.$t('menu.printers'),
          icon: 'dripicons-print',
          access: 'COM'
        },
        {
          name: 'users',
          text: this.$t('menu.users'),
          icon: 'dripicons-user-group',
          access: 'COM'
        },
        {
          name: this.$t('menu.administration'),
          divisor: true
        },
        {
          name: "myCompany",
          text: this.$t('menu.myCompany'),
          icon: "dripicons-store",
          access: "COM"
        },
        {
          name: "myWarehouses",
          text: this.$t('menu.myWarehouses'),
          icon: "dripicons-weight",
          access: "COM"
        },
        {
          name: "myFiles",
          text: this.$t('menu.myFiles'),
          icon: "dripicons-archive",
          access: "COM"
        }

      ]
    }
  },
  computed:{
    filteredRoutes(){
      return this.routes.filter(route =>
          route.access === undefined ||
          this.authStore.checkRole('systemRole', route.access)
      )
    }
  },
  setup() {
    const authStore = useAuthStore();
    return {
      authStore,
    };
  }
}
</script>


<style scoped>

#sidebar-menu::-webkit-scrollbar {
  display: none;
}
</style>