<template>
  <div class="page-content">
          <div id="preloader" v-if="data == null">
            <div id="status">
              <div class="spinner"></div>
            </div>
          </div>
    <main-chart v-if="data" class="slideInLeft animated" :mainChart="data.mainChart"/>


      <base-info class="slideInLeft animated" :data="data" v-if="data"/>



      <div class="row d-flex" v-if="data">
        <CartChart class="slideInLeft animated" :cartChart="data.cartData"/>
        <PaymentMethodData class="slideInRight animated" :paymentData="data.paymentData"/>
      </div>
      <Interactions v-if="authStore.checkRole('systemRole','developer')"/>

      <DeliveryMap/>
      <div class="row">
        <LastTransaction class="slideInLeft animated" :transactionData="data.lastTransactions" v-if="data"/>


      </div>


  </div>
</template>

<script>


import CartChart from "./CartChart.vue";
import MainChart from "./MainChart.vue";
import BaseInfo from "./BaseInfo.vue";
import PaymentMethodData from "./PaymentMethodData.vue";
import Interactions from "@/components/dashboard/Interactions";
import DeliveryMap from "./DeliveryMap.vue";
import LastTransaction from "./LastTransaction.vue";
import {useAuthStore} from "@/store/authStore";



export default {
  name: "dashboardComponent",
 setup() {
    const authStore = useAuthStore();
    return {
      authStore
    }
 },
  data() {
    return {
      data: null,
      loaded: false,
    }
  },
  methods: {
    getData: function () {
      this.axios.get('dashboard/getData')
          .then(response => {
            this.data = response.data
          })
    },
    convertCurrency(value) {
      return value.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
    }
  },
  beforeMount() {
    this.getData()
  },
  watch: {
    data: function () {
      if (this.data != null) {
        this.loaded = true
      }
    }
  },
  components: {

    LastTransaction,
    DeliveryMap,
    PaymentMethodData,
    BaseInfo,
    MainChart,
    Interactions,
    CartChart,

  }
}
</script>


