<template>
  <div>
    <loading :active="isLoading"
             :is-full-page="true"/>

    <ModalComponent ref="modal">
      <ItemsTable :diff-items="diffItems" :new-headers="newHeaders" :addToList="true" :updateUrl="updateItemsUrl" @addToList="afterAdd"/>
    </ModalComponent>
    <div class="card" v-if="loading">
      <div class="card-body">
        <div class="text-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
    <div class="card" v-else>
      <div class="card-body">
        <div class="col-12 m-2 gap-2 d-flex">
          <button class="btn btn-primary" type="button" @click="openModal" v-if="isAddScope">{{$t('app.addItems')}}</button>
          <button class="btn btn-danger" type="button" @click="remove" v-if="isRemoveScope">{{$t('app.removeItems')}}</button>
          <button class="btn-success btn" type="button" @click="create" v-if="isCreateScope">{{$t('app.create')}}</button>
        </div>
        <ItemsTable :diff-items="items" :new-headers="newHeaders" ref="principalList" />
      </div>
    </div>
  </div>

</template>

<script>

import axios from "@/boostrap/apiConfig";
import {ErrorHandler} from "@/utils/ErrorHandler";
import ModalComponent from "@/components/helper/modal/ModalComponent.vue";
import ItemsTable from "@/components/helper/formGenerator/types/ItemsTable.vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
export default {
  name: "ListType",
  components: {ItemsTable, ModalComponent,Loading},
  data() {
    return {
      loading: true,
      isLoading: false,
      items: [],
      diffItems: [],
      newHeaders: [],
    }
  },
  mounted() {
    this.fetchItems().finally(() => {
      this.loading = false
    })
  },
  beforeMount() {
    this.newHeaders = this.headers
    //push to start {text: this.$t('app.select'), value: 'select', sortable: false} if not exists
    if (this.newHeaders.length ===0 || this.newHeaders[0].value !== 'select') {
      this.newHeaders.unshift({text: this.$t('app.select'), value: 'select', sortable: false})
    }
  },
  computed: {
    isAddScope(){
      return this.scopes.includes('add')
    },
    isRemoveScope(){
      return this.scopes.includes('remove')
    },
    isCreateScope(){
      return this.scopes.includes('create')
    }
  },
  methods: {
    create(){
      this.$emit('create')
    },
    remove(){
      this.isLoading = true
      let items = this.$refs.principalList.selected
      if (items.length > 0) {
        let data = {
          items: items
        }
        axios.delete(this.removeItemsUrl, {
          data: data
        }).then(() => {
          this.fetchItems()
        }).catch((error) => {
          ErrorHandler.handle(error, "Erro ao remover itens")
        }).finally(() => {
          this.isLoading = false
        })
      }else{
        this.$notify({
          title: 'Erro',
          text: 'Selecione pelo menos um item',
          type: 'error'
        })
        this.isLoading = false
      }

    },
    afterAdd(){
      this.isLoading = true
      this.fetchItems().then(() =>{
        this.$refs.modal.closeModal()
      }).finally(() => {
        this.isLoading = false
      })
    },
    async fetchItems() {
      return axios.get(this.getItemsUrl).then((response) => {
        this.items = response.data
      }).catch((error) => {
        ErrorHandler.handle(error, "Erro ao buscar itens")
      })
    },
    openModal(){
     this.fetchDiffItems().then(() =>{
       this.$refs.modal.openModal()
     })
    },
    async fetchDiffItems(){
      this.isLoading = true
      let url = this.getItemsUrl + '?all=true'
      return axios.get(url).then((response) => {
        this.diffItems = response.data
      }).catch((error) => {
        ErrorHandler.handle(error, "Erro ao buscar itens")
      }).finally(() => {
        this.isLoading = false
      })
    }


  },
  props: {
    headers: {
      required: true,
      default: []
    },
    getItemsUrl: {
      required: true,
      default: ''
    },
    updateItemsUrl: {
      required: true,
      default: ''
    },
    removeItemsUrl: {
      required: true,
      default: ''
    },
    scopes: {
      required: true,
    }

  }

}
</script>