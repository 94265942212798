<template>
  <div v-if="!loading" class="page-content slideInDown animated">
    <div class="row">
      <RouterLink :to="{name:'event',params:{id:event.id}}" class="col-md-4" v-for="event in plannerStore.getPlanners" :key="event.id">
        <div :class="colorEventWhenClosest(event)" class=" card border">
          <div class="card-body">
            <div class="row">
              <div class="col-8 d-inline-block">
                <strong class="d-block">{{ event.title }}</strong>
                <span class=" text-muted d-block">
                  <strong>inicia em </strong>
                  <small class="d-block">{{ moment(event.start_str).format('DD/MM/YYYY HH:mm') }}</small></span>
                <span class="text-muted d-block">
                  <strong>termina em </strong>
                  <small class="d-block">{{ moment(event.end_str).format('DD/MM/YYYY HH:mm') }}</small></span>
                <span class="badge bg-danger" v-if="closestToEnd(event)">quase acabando</span>
              </div>
            </div>

          </div>
        </div>
      </RouterLink>

    </div>
  </div>
  <div v-else class="d-flex justify-content-center align-items-center" style="height: 100vh">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>
<script>
import {defineComponent} from 'vue'
import {usePlannerStore} from "@/store/plannerStore";


export default defineComponent({
  name: "PlannerWrapper",
  data() {
    return {loading: false}
  },
  setup() {
    const plannerStore = usePlannerStore()
    return {
      plannerStore
    }
  },
  methods: {
    closestToEnd(event){
      const now = new Date()
      const end = new Date(event.end_str)
      const diff = end.getTime() - now.getTime()
      let timeToEnd = Math.ceil(diff / (1000 * 3600 * 24))
      if (timeToEnd < 0) {
        return false
      }else if (timeToEnd <= 3) {
        return true
      }
    },
    colorEventWhenClosest(event) {
      const now = new Date()
      const end = new Date(event.end_str)
      const diff = end.getTime() - now.getTime()
      const diffDays = Math.ceil(diff / (1000 * 3600 * 24))

      //add loginc if passed
      if (diffDays < 0) {
        return 'border-secondary'
      } else if (diffDays <= 3) {
        return 'border-danger'
      } else if (diffDays <= 7) {
        return 'border-warning'
      } else {
        return 'border-success'
      }



    }
  },
  beforeMount() {
    this.loading = true
    this.plannerStore.fetchPlanners().finally(() => {
      this.loading = false
    })
  }
})
</script>

<style scoped>

</style>