<template>
  <div class="card p-3">
    <div class="card-header p-3">
      <h3 class="text-primary">{{ momentConvert(date) }}</h3>
    </div>

    <table class="table">
      <tbody>
      <tr v-for="delivery in dates" :key="delivery.id">
        <td class="border border-1">
          <div class="fw-bolder font-size-18">
            <input type="checkbox" class="form-check-input" v-model="selected" :value="delivery.id">
          </div>
        </td>
        <td class="border border-1">
          <div class="fw-bolder font-size-18">{{ delivery.code }}<span class="badge  d-block"
                                                                       :style="{'background-color': delivery.order_state.color}">{{ delivery.order_state.name }}</span>
          </div>
        </td>
        <td class="border border-1">
          <div class="text-center">
            <em class="fa fa-check-circle text-success" v-if="valid(delivery)"></em>
            <em class="fa fa-times-circle text-danger" v-else></em>
          </div>
        </td>
        <td class="border border-1 text-danger">
          <div class="fw-bolder font-size-18" v-currency="delivery.selected_shipping.price / 100">
            {{ delivery.selected_shipping.price }}
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="3" class="border border-1 text-end fw-bold">Total:</td>
        <td class="border border-2 text-danger fw-bold" v-currency="calculateTotal(dates) / 100">
          {{ calculateTotal(dates) }}
        </td>
      </tr>
      <tr v-if="!isEmpty(quoteResponse)">
        <td class="border border-2 text-success fw-bold" v-if="isValidPrice(quoteResponse)">
          Valido
        </td>
        <td class="border border-2 text-danger fw-bold" v-else>
          Invalido
        </td>
        <td colspan="2" class="border border-1 text-end fw-bold">Cotação:</td>
        <td class="border border-2 text-success fw-bold" v-currency="quoteResponse.price">
          {{ quoteResponse.price }}
        </td>

      </tr>
      <tr v-if="!isEmpty(quoteResponse)">
        <td colspan="3" class="border border-1 text-end fw-bold">Economia:</td>
        <td class="border border-2 text-success fw-bold"
            v-currency="(calculateTotal(dates) - quoteResponse.priceInteger ) / 100">
          {{ quoteResponse.price }}
        </td>
      </tr>
      <tr>
        <td colspan="4" class="border border-2 text-center" :class="{'locked':processLocked}">
          <div class="p-2">
            <input type="checkbox" checked="" v-model="force">
          </div>
          <button class="btn btn-primary btn-lg m-2" v-wave @click.prevent="quote">Cotar</button>
          <button class="btn btn-primary btn-lg m-2" v-wave v-if="allValid" @click="process">Processar</button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import {useChainDeliveryStore} from "@/store/chainDeliveryStore";
import {useStorage} from "@vueuse/core";
import moment from "moment";
import {NotifyHandler} from "@/utils/NotifyHandler";
import {ErrorHandler} from "@/utils/ErrorHandler";

export default {
  name: 'DeliveriesCard',
  setup() {
    const chainStore = useChainDeliveryStore()
    return {
      chainStore
    }
  },
  data() {
    return {
      selected: [],
      quoteResponse: useStorage(Object.values(this.dates).map(delivery => delivery.id).join('-'), {}),
      force: false,
      processLocked: useStorage(Object.values(this.dates).map(delivery => delivery.id).join('-') + '-locked', false)
    }
  },
  methods: {
    momentConvert(date) {
      return moment(date).format('DD/MM/YYYY')
    },
    isValidPrice(quoteResponse) {
      return quoteResponse.expiresAt > new Date().toISOString()
    },
    async process() {
      let deliveries = this.selected.length > 0 ? this.selected : Object.keys(this.dates).map(key => this.dates[key].id)
      this.chainStore.processDelivery(deliveries, this.force).then(() => {
        NotifyHandler.handle('success', 'app.delivery.processed')
        this.processLocked = true
      }).catch((error) => {
        ErrorHandler.handle(error)
      })
    },
    async quote() {
      let deliveries = this.selected.length > 0 ? this.selected : Object.keys(this.dates).map(key => this.dates[key].id)
      this.quoteResponse = await this.chainStore.quote(deliveries)
      NotifyHandler.handle('success', 'app.delivery.quoted')
    },
    calculateTotal(dates) {
      let total = 0
      for (let date in dates) {
        total += parseInt(dates[date].selected_shipping.price)
      }
      return total
    },
    valid(delivery) {
      if (this.force) {
        return true
      }
      return delivery.delivery_items.length > 0 && delivery.delivery_items.every(item => item.completed) && this.validStatus(delivery)
    },
    validStatus(delivery) {
      let arrayOfStatusObject = this.definitions?.allowed_to_call_transport;
      if (!arrayOfStatusObject) {
        return false
      }
      return arrayOfStatusObject.some(status => status.id === delivery.order_state_id)
    },
    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    }
  },
  computed: {
    allValid() {
      return Object.values(this.dates).every(delivery => this.valid(delivery)) || (this.selected.length > 2 && this.selected.every(id => this.valid(Object.values(this.dates).find(delivery => delivery.id === id))))
    }

  },
  props: {
    date: {
      type: String,
      required: true
    },
    dates: {
      type: Object,
      required: true
    },
    definitions: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.locked {
  pointer-events: none;
  -webkit-filter: blur(3px);
  filter: blur(3px);
  opacity: 0.5;
}

</style>