<template>
  <div class="page-content">
    <div class="container-fluid" v-if="!showEndPage">
      <div class="col-xs-12 table-responsive border-0" v-if="!showEndPage">
        <Vue3EasyDataTable :headers="data.columns" :items="data.rows" v-if="data.rows.length > 0">
          <template #item-amount="{amount}">
            <span v-currency="amount/100"></span>
          </template>
          <template #item-status="{status}">
            <span v-if="status === 'pending'">Pendente</span>
            <span v-else-if="status === 'paid'">Pago</span>
            <span v-else-if="status === 'canceled'">Cancelado</span>
            <span v-else>Novo</span>
          </template>
        </Vue3EasyDataTable>
        <h5 class="text-uppercase text-center p-3" v-else>Sem Pendencias registradas</h5>
      </div>
      <div class="col-md-12 d-flex gap-3 justify-content-center"  v-if="filteredRows.length > 0">
        <button class="btn btn-primary"  v-for="payment in payments" :key="payment.id" @click="payPendencies(payment.id)">Pagar com {{payment.name}}</button>
      </div>
    </div>
    <div class="container-fluid" v-else>
      <div class="col-xs-12 table-responsive border-0">
        <div v-if="responseData.dataPayment.charges[0].last_transaction.qr_code_url" class="text-center">
          <h2 class="text-uppercase text-success"> realize o pagamento do pix abaixo</h2>
          <img :src="responseData.dataPayment.charges[0].last_transaction.qr_code_url" alt="pix image">
          <p class="text-uppercase text-info m-3"> este pix tem a validade de 15 minutos</p>
          <button class="btn btn-primary text-uppercase" @click.prevent="showEndPage = false"> voltar</button>
        </div>
        <h5 class="text-uppercase text-center p-3" v-else>Pagamento realizado com sucesso</h5>
      </div>
    </div>
  </div>
</template>

<script>
import EasyDataTable from 'vue3-easy-data-table'
import 'vue3-easy-data-table/dist/style.css';
import Vue3EasyDataTable from "vue3-easy-data-table";
export default {
  name: "PendenciesComponent",
  components: {Vue3EasyDataTable},
  data() {
    return {
      components: {EasyDataTable},
      payments: [],
      showEndPage: false,
      responseData: null,
      data: {
        columns: [

          {text: 'id', value: 'id'},
          {text: 'descrição', value: 'description'},
          {
            text: 'Valor',
            value: 'amount',
          },
          {
            text: 'status',
            value: 'status',
          }
        ],
        rows: [
        ]
      }
    }
  },
  methods: {
    payPendencies: function (id) {
      this.axios.post('pendencies/pay',{
        payment_method_id: id
      }).then((response) => {
        this.getPendencies()
        if (response.data.redirect) {
          let url = response.data.redirectUrl
          // open in new tab

          window.open(url, '_blank');

        }else{
          this.showEndPage = true
          this.responseData = response.data
        }
      })
    },
    getPendenciesPayments: function () {
      this.axios.get('pendencies/payments').then((response) => {
        this.payments = response.data
      })
    },
    getPendencies: function () {
      this.axios.get('pendencies').then((response) => {
        this.data.rows = response.data
      })
    }
  },
  created() {
    this.getPendencies()
    this.getPendenciesPayments()
  },
  computed:{
    filteredRows(){
      return this.data.rows.filter(row => row.status === 'new')
    }
  }
}
</script>

<style scoped>

</style>