<template>
  <tr>
    <td class="td-name">
      <h5>{{ product.name }}</h5>

      <div v-if="this.cartProduct != null">
        <div class="card-category text-capitalize  mb-0" v-for="customField in this.cartProduct.custom_field_values"
             :key="customField.id">
          <span>{{ customField.custom_field.name }}:</span>
          <small>{{ customFieldValue(customField) }}</small>
        </div>
      </div>
    </td>
    <td>
      {{ this.data.quantity }}
    </td>
    <td class="d-flex justify-content-start">
      <button class="button-hold" :class="{ success: this.data.completed }" @mousedown="completeItem" @mouseup="cancelPress" @touchstart="completeItem" @touchend="cancelPress">
        <div>
          <svg class="icon" viewBox="0 0 16 16">
            <polygon
                points="1.3,6.7 2.7,8.1 7,3.8 7,16 9,16 9,3.8 13.3,8.1 14.7,6.7 8,0"></polygon>
          </svg>
          <svg class="progress" style="background: transparent" viewBox="0 0 32 32">
            <circle r="8" cx="16" cy="16"/>
          </svg>
          <svg class="tick" viewBox="0 0 24 24">
            <polyline points="18,7 11,16 6,12"/>
          </svg>
        </div>
      </button>
    </td>
  </tr>
</template>
<script>
export default {
  name: 'ProductComponent',
  props: {
    data: {}
  },
  data() {
    return {
      duration: 1600,
      product:this.data,
      cartProduct: this.data.cart_product
    }
  },
  methods: {
    completeItem: function() {
      // get pressed button
      let button = event.target
      button.style.setProperty('--duration', this.duration + 'ms');
      if (!button.classList.contains('process') && !button.classList.contains('success')) {
        button.classList.add('process');
        button.timeout = setTimeout(() => {
          button.classList.remove('loading');
          this.axios.post('product/complete', {
            delivery_item: this.data.hashid
          }).then(() => {
            button.classList.add('success');
          }).catch(error => {
            //show error in order componenet
            if (error.message === 'Network Error') {  // we got an network error - server is down?
              this.$emit('error', 'Server is down - contact site admin.');
            } else {                // we got error from the server
              this.$emit('error', error.response.data.error);
            }
          })
        }, this.duration, button);
      }


    },
    cancelPress: function() {

      let button = event.target
      button.style.setProperty('--duration', this.duration + 'ms');
      button.classList.remove('process');
      clearTimeout(button.timeout);
    },
    customFieldValue: function(custom_field) {
      switch (custom_field.custom_field.type) {
        case 'checkbox':
          return custom_field.value
        case 'dropdown':
          return custom_field.display_value
        case 'text':
          return custom_field.value
        case 'textarea':
          return custom_field.value
        case 'image':
          return custom_field.display_value
        case 'color':
          return custom_field.custom_field_option.name
        case 'additional':
          return custom_field.value
        default:
          console.log('custom field not found: ')
      }
    }
  },
}
</script>
<style lang="scss">
.button-hold {
  --color: #192041;
  --background: #ffffff;
  --icon: var(--color);
  --progress-border: #646B8C;
  --progress-active: #00600c;
  --progress-success: #00600c;
  --tick-stroke: var(--progress-active);
  --shadow: #{rgba(#00093D, .2)};
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  padding: 12px 12px 12px 12px;
  border: 0;
  border-radius: 24px;
  outline: none;
  user-select: none;
  cursor: pointer;
  -webkit-backface-visibility: hidden;
  -webkit-appearance: none;
  transition: transform .3s, box-shadow .3s;
  box-shadow: 0 var(--shadow-y, 4px) var(--shadow-blur, 12px) var(--shadow);
  transform: scale(var(--scale, 1)) translateZ(0);
  color: var(--color);
  background: var(--background);

  & > div {
    border-radius: 50%;
    display: inline-block;
    vertical-align: top;
    position: relative;
    pointer-events: none;
    background: var(--background);

    &:before {
      content: '';
      width: 16px;
      height: 16px;
      left: 2px;
      top: 2px;
      z-index: 1;
      position: absolute;
      background: var(--background);
      border-radius: inherit;
      transform: scale(var(--background-scale, 1));
      transition: transform .32s ease;
    }

    svg {
      display: block;

      &.icon,
      &.tick {
        position: absolute;
      }

      &.icon {
        width: 8px;
        height: 8px;
        left: 6px;
        top: 6px;
        fill: var(--icon);
        z-index: 1;
        transition: opacity .2s, transform .2s;
        opacity: var(--icon-opacity, 1);
        transform: translateY(var(--icon-y, 0)) scale(var(--icon-scale, 1));
      }

      &.progress,
      &.tick {
        fill: none;
      }

      &.progress {
        width: 20px;
        height: 20px;
        transform: rotate(-90deg) scale(var(--progress-scale, 1));
        transition: transform .5s ease;

        circle {
          stroke-dashoffset: 1;
          stroke-dasharray: var(--progress-array, 0) 52;
          stroke-width: 16;
          stroke: var(--progress-active);
          transition: stroke-dasharray var(--duration) linear;
        }
      }

      &.tick {
        width: 20px;
        height: 20px;
        left: 0;
        top: 0;
        stroke-width: 3;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke: var(--tick-stroke);
        transition: stroke .3s ease .7s;

        polyline {
          stroke-dasharray: 18 18 18;
          stroke-dashoffset: var(--tick-offset, 18);
          transition: stroke-dashoffset .4s ease .7s;
        }
      }
    }
  }

  &:focus,
  &:hover {
    &:not(.process) {
      --shadow-y: 8px;
      --shadow-blur: 16px;
    }
  }

  &:active {
    &:not(.success) {
      --scale: .96;
      --shadow-y: 4px;
      --shadow-blur: 8px;
    }
  }

  &.process,
  &.success {
    --progress-array: 52;
    --icon-y: -4px;
    --icon-scale: .6;
    --icon-opacity: 0;
  }

  &.success {
    --progress-border: none;
    --progress-scale: .11;
    --tick-stroke: var(--progress-success);
    --background-scale: 0;
    --tick-offset: 36;

    & > div {
      svg {
        &.progress {
          animation: tick .3s linear forwards .4s;
        }
      }
    }
  }
}

@keyframes tick {
  100% {
    transform: rotate(-90deg) translate(0, -5px) scale(var(--progress-scale));
  }
}


</style>
