<template>
  <div class="col-xl-3 col-sm-3">
    <div class="card shadow-none border">
      <div class="card-body p-3">
        <div class="d-flex justify-content-between">
          <div class="avatar align-self-center me-3 mb-3">
            <div class="avatar-title rounded bg-soft-light text-primary font-size-24">
              <img :src="config.serviceUrl+file.icon"/>
            </div>
          </div>
          <div class="position-relative">
            <div class="dropdown">
              <a class="font-size-16 text-muted" role="button" data-bs-toggle="dropdown"
                 aria-haspopup="true">
                <i class="mdi mdi-dots-horizontal"></i>
              </a>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#" @click="fileDelete(file)">{{$t('app.delete')}}</a></li>
                <li><a class="dropdown-item" href="#" @click="downloadFile(file)">{{$t('app.download')}}</a></li>
              </ul>
            </div>
          </div>
        </div>

        <div class="d-flex mt-3">
          <div class="overflow-hidden me-auto">
            <h5 class="font-size-15 text-truncate mb-1">
              <contenteditable tag="span" class="text-body" :contenteditable="editable" v-model="message" :no-nl="true"
                               :no-html="true" @returned="enterPressed"/>
            </h5>

            <p class="text-muted text-truncate mb-0">
              <i class="mdi mdi-clock"></i>
              {{
                moment(file.lastModified).diff(moment(), 'days') > -1 ? moment(file.lastModified).fromNow() :
                    moment(file.lastModified).format('DD/MM/YYYY')
              }}
            </p>
          </div>
          <div class="align-self-end ms-2">
            <p class="text-muted mb-0 font-size-13">
               {{extension}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import contenteditable from 'vue-contenteditable';
import {useFileManagerStore} from "@/store/fileManangerStore";

export default {
  name: 'FileComponent',
  components: {contenteditable},
  props: {
    config: {},
    file: {}
  },
  setup() {
    const fileMangerStore = useFileManagerStore()
    return {
      fileMangerStore
    }
  },
  data() {
    return {
      message: '',
      editable: true
    }
  },
  beforeMount() {
    this.message = this.removeExtension(this.file.name);
  },
  methods: {
    fileDelete(file) {
      this.fileMangerStore.deleteFileOrFolder(file).finally(() => {
        this.fetchFiles();
      })
    },
    downloadFile(file) {
      this.fileMangerStore.downloadFile(file)
    },
    removeExtension(filename) {
      const lastIndex = filename.lastIndexOf(".");
      return lastIndex > 0 ? filename.substring(0, lastIndex) : filename;
    },
    fetchFiles(folder = null) {
      this.loading = true
      this.fileMangerStore.getFilesFetch(folder).finally(() => {
        this.loading = false
      })
    },
    enterPressed(event) {
      const newName = event + (this.extension ? '.' + this.extension : ''); // Append extension
      this.editable = false;
      this.fileMangerStore.rename(this.file, newName).finally(() => {
        this.editable = true;
        this.fetchFiles();
      });
    },
  },
  computed: {
    icon() {
      return this.config.serviceUrl + this.file.icon
    },
    extension() {
      return this.file.ext
    }
  }
}
</script>
<style scoped>

.card {
  margin-bottom: 24px;
  -webkit-box-shadow: 0 2px 3px #e4e8f0;
  box-shadow: 0 2px 3px #e4e8f0;
}

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #eff0f2;
  border-radius: 1rem;
}

.me-3 {
  margin-right: 1rem !important;
}


.font-size-24 {
  font-size: 24px !important;
}

.avatar-title {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #3b76e1;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}

.avatar {
  height: 3rem;
  width: 3rem
}

.avatar-title {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #3b76e1;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%
}

a {
  text-decoration: none !important;
}
</style>