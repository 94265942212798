import {defineStore} from "pinia";
import axios from "@/boostrap/apiConfig"
import swal from 'sweetalert2'
export const useSettingsStore = defineStore('settings', {
    state: () => ({
        vendorCode: '',
        store: '',
        warehouses: []
    }),
    getters:{
        getVendorCode(){
            return this.vendorCode
        },
        getStore(){
            return this.store
        },
        getWarehouses(){
            return this.warehouses
        }
    },
    actions: {
        saveVendorCode: function() {
            axios.post('settings/vendorToken', {
                vendorToken: this.vendorCode
            })
                .then(async () => {
                   await swal.fire({
                        title: 'Sucesso!',
                        text: 'Chave de fornecedor salva com sucesso! por favor, reinicie o aplicativo para que as alterações tenham efeito',
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    })
                })
                .catch(async () => {
                   await swal.fire({
                        title: 'Erro!',
                        text: 'Erro ao salvar a chave do fornecedor',
                        icon: 'error',
                        confirmButtonText: 'Ok'

                   })
                })
        },
        fetchVendorCode: async function() {
            axios.get('settings/vendorCode').then(response => {
                this.vendorCode = response.data.vendorToken;
            }).catch(error => {
                console.log(error);
            });
        },
        fetchWarehouses: function() {
            axios.get('settings/warehouses').then(response => {
                this.warehouses = response.data;
            }).catch(error => {
                console.log(error);
            });
        },
        saveStore: function() {
            axios.post('settings/store', {
                store: this.store
            })
                .then(async () => {
                    await swal.fire({
                        title: 'Sucesso!',
                        text: 'Loja salva com sucesso!',
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    })
                })
                .catch(error => {
                    console.log(error)
                })
        },
        fetchStore: async function() {
           return  axios.get('settings/store').then(response => {
                this.store = response.data.store;
            }).catch(error => {
                console.log(error);
            });
        },
    }
})