<template>
  <div class="card-body" v-if="link">
    <p>O pedido foi confirmado. Por favor, envie este link para o cliente realizar o pagamento</p>
    <div class="input-group mb-3">
      <input type="text" class="form-control" id="payment-link" :value="link">
      <div class="input-group-append">
        <button class="btn btn-primary" type="button" @click="copyToClipboard">Copiar</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CopylinkPayment',
  props: {
    paymentData: {},
    link: {
      type: String,
    }
  },
  methods:{
    copyToClipboard: function () {
      let copyText = document.getElementById("payment-link");
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");
    }
  }

}
</script>
<style scoped>
::selection {
  background: #d5bbf7;
}

.card input[type="radio"] {
  display: none;
}
</style>