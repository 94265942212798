<template>
  <div class="page-content">
    <div class="container-fluid">
      <div class="container">
        <div class="row justify-content-center mt-5">
          <div class="col-md-8">
            <div class="card">
              <div class="card-header">
                <h4 v-if="!paymentData.failedPayment">Confirmação de Pedido</h4>
                <div v-else>
                  <h4>O Pedido Falhou</h4>
                  <span>{{ paymentData.failedPayment.message }}</span>
                </div>
              </div>
              <CopylinkPayment
                  :link="paymentData.paymentData.charges == null ? paymentData.paymentData.payment_link : paymentData.paymentData.charges[0].last_transaction.qr_code"
                  :payment-data="paymentData"/>
              <div class="card-footer">
                <button class="btn btn-primary w-100" @click="startNewSale">Nova Venda</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import CopylinkPayment from "@/components/pdv/CopylinkPayment.vue";
import {usePosStore} from "@/store/PosStore";

export default {
  name: "PaymentComplete",
  components: {CopylinkPayment}, setup: () => ({posStore: usePosStore()}),
  computed: {
    paymentData: function () {
      return this.posStore.getPaymentData
    }
  },
  methods: {
    startNewSale: function () {
      this.$router.push({name: 'pos'})
    }
  }
}
</script>

<style scoped>

</style>