<template>
  <div class="page-content">
    <div class="container-fluid">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Informações Gerais</h4>
          <Form @submit="saveNewDriver">
              <div class="col-md-12 d-flex justify-content-end">
                <button class="btn btn-dark" type="submit">Criar</button>
              </div>
              <div class="row">

                <div class="form-group position-relative">
                  <Field name="name" rules="required" v-model="driver.name" v-slot="{ errors, valid, field }">
                    <label for="name">Nome</label>
                    <input type="text" class="form-control" id="name"  v-bind="field"
                           placeholder="Nome do Motorista" :class="{'is-invalid': errors[0],'is-valid':valid }">
                    <span v-if="errors" class="invalid-tooltip">{{ errors[0] }}</span>


                  </Field>
                </div>
                <div class="form-group position-relative">
                  <Field name="surname" rules="required" v-slot="{ errors, valid, field }" v-model="driver.surname">
                    <label for="name">Sobrenome</label>
                    <input type="text" class="form-control" id="name"  v-bind="field"
                           placeholder="Sobrenome" :class="{'is-invalid': errors[0],'is-valid':valid }">
                    <span v-if="errors" class="invalid-tooltip">{{ errors[0] }}</span>


                  </Field>
                </div>

                <div class="form-group position-relative">
                  <Field name="username" rules="required" v-slot="{ errors, valid, field }" v-model="driver.user">
                    <label for="stock">Nome de Usuario</label>
                    <input type="text" class="form-control" id="stock" v-bind="field"
                           placeholder="Nome de Usuario" :class="{'is-invalid': errors[0],'is-valid':valid }">
                    <span v-if="errors" class="invalid-tooltip">{{ errors[0] }}</span>
                  </Field>
                </div>

                <div class="col-md-6">
                  <div class="form-group position-relative">
                    <Field name="password" vid="password" ref="password" rules="required" v-model="driver.password"
                                         v-slot="{ errors, valid, field }">
                      <label for="name">Senha Provisoria</label>
                      <input type="password" class="form-control" id="password" v-bind="field"
                             placeholder="Senha Provisoria" :class="{'is-invalid': errors[0],'is-valid':valid }">
                      <span v-if="errors" class="invalid-tooltip">{{ errors[0] }}</span>

                    </Field>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group position-relative">
                    <Field name="Confirmation Password" rules="required|confirmed:@password"
                                         v-slot="{ errors, valid, field }"  v-model="driver.confirmation_password">
                      <label for="name">Confirmar Senha Provisoria</label>
                      <input type="password" class="form-control" id="name" v-bind="field"
                             placeholder="Confirmar Senha Provisoria"
                             :class="{'is-invalid': errors[0],'is-valid':valid }">
                      <span v-if="errors" class="invalid-tooltip">{{ errors[0] }}</span>

                    </Field>
                  </div>
                </div>


              </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import {Form, Field, defineRule} from 'vee-validate'

defineRule('confirmed', (value, [other]) => {
  if (value === other) {
    return true;
  }

  return 'As senhas não coincidem';
});
export default {
  name: "NewDriver",
  data() {
    return {
      driver: {
        name: "",
        user: "",
        surname: "",
        password: "",
        confirmation_password: "",
      }
    };
  },
  components: {
    Form,
    Field
  },
  methods: {
    saveNewDriver() {
      this.axios.post('drivers', this.driver).then(() => {
        this.$router.push({name:"delivery"})
      })
    }
  }
}
</script>

<style scoped>

</style>