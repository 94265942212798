<template>
    <div  class="col-md-6 bg-soft-light rounded-2  ">
      <div class="card">
      <div class="mb-2">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-10">
                <span class="text-uppercase bold fw-bold m-2">Carrinho ({{ posStore.getCart.products.length }})</span>
              </div>
              <div class="col-md-2">
                <button class="btn btn-primary text-uppercase btn-sm w-100" @click.prevent="showCart = !showCart">
                  <i class="fas fa-arrow-up"></i>
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>
      <PerfectScrollbar class="cartProductsWrapper bg-soft-light rounded-2" :options="options" v-show="showCart" v-if="products.length > 0" >
        <transition-group name="slide-fade" class="row d-flex gap-2">
          <CartProducts  :product="product" v-for="product in products"
                        v-bind:key="product.product.id" v-auto-animate/>
        </transition-group>
      </PerfectScrollbar>
        <div v-else class="text-center" v-show="showCart">
          <h5>Nenhum produto no carrinho</h5>
        </div>
    </div>
    </div>
</template>
<script>
import CartProducts from "@/components/pdv/details/cart/CartProducts.vue";
import {usePosStore} from "@/store/PosStore";

export default {
  name: 'ProductsInCart',
  setup: () => ({posStore: usePosStore()}),
  props:{
    showProducts: {
      type: Boolean,
      default: true
    }
  },
  components: {CartProducts},
  data() {
    return {
      options: {
        useBothWheelAxes: false,
        suppressScrollX: true,
      },
      showCart: true
    }
  },
  computed: {
    products() {
      return this.posStore.getCart.products
    }
  },
}
</script>

<style scoped>
.cartProductsWrapper {
  max-height: 250px;
  padding: 10px;

  overflow-y: hidden;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.card {
  width: 100%;
  border-radius: 5px;
  padding: 20px;
}

.card .title {
  font-size: 22px;
  font-weight: 500;
}

.card .content {
  margin-top: 20px;
}

.card label.box {
  margin-top: 12px;
  padding: 10px 12px;
  display: flex;
  border-radius: 5px;
  border: 2px solid transparent;
  cursor: pointer;
  transition: all 0.25s ease;
}
</style>