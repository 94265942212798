import axios from 'axios';
import {useAuthStore} from '@/store/authStore'
import jsonwebtoken from 'jsonwebtoken';
import router from '../router';
import pinia from './pinia';
import config from '@/config';


let baseUrl =  config.serviceUrl
axios.defaults.baseURL = baseUrl + '/api/v1';
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('jwt');
axios.defaults.headers.common['Cache-Control'] = 'no-cache';
axios.defaults.headers.common['Pragma'] = 'no-cache'
axios.defaults.headers.common['Expires'] = '0'

axios.interceptors.request.use((config) => {
    let auth = useAuthStore(pinia)
    if (auth.user.JWToken !== null) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + auth.user.JWToken;

        let jwtExpiration = jsonwebtoken.decode(auth.user.JWToken).exp;
        let currentTime = Date.now() / 1000;

        if (jwtExpiration < currentTime) {
            auth.clearAuthInfo();
            router.push({name: 'login', params: {err: 'Your session has expired. Please log in again...'}});
            return;
        }
    }else{
        console.log('no token')
    }
    return config;
});

axios.interceptors.response.use(async (response) => {
    // if repose is 401, redirect to login
    if (response.status === 401) {
        console.log('consle');
        let auth = useAuthStore(pinia)
        auth.clearAuthInfo();
        return  router.push({name: 'login', params: {err: 'Your session has expired. Please log in again...'}});

    }
    return response;
})
export default axios;
