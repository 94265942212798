import config from "@/config";
import {useNotification} from "@kyvg/vue3-notification";

export class ErrorHandler {


    static init() {
        if (!config.appDebug) {
            console.log = function () {
            }
        }

    }

    static handle(error, message) {
        if (message !== undefined) {
            let {notify} = useNotification();

            if (error.response && !message && config.appDebug) {
                message = error.response.data.message;
                notify({
                    title: "Um erro ocorreu",
                    text: message,
                    type: 'error'
                })
            } else {
                notify({
                    title: "Um erro ocorreu",
                    text: message,
                    type: 'error'
                })
            }
        }
        if (config.appDebug) {
            console.error(error)
        }
    }

}