<template>
  <div class="base-timer">
    <svg class="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <g class="base-timer__circle">
        <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
        <path
            :id="'base-timer-path-remaining'"
            :stroke-dasharray="circleDasharray"
            :class="['base-timer__path-remaining', remainingPathColor]"
            d="
            M 50, 50
            m -45, 0
            a 45,45 0 1,0 90,0
            a 45,45 0 1,0 -90,0
          "
        ></path>
      </g>
    </svg>
    <span :id="'base-timer-label'" class="base-timer__label">{{ formatTime(timePassed) }}</span>
  </div>
</template>

<script>
import {ref, onMounted} from "vue";

const FULL_DASH_ARRAY = 283;
const COLOR_CODES = {
  info: {
    color: "green",
  },
  warning: {
    color: "orange",
    threshold: 810,
  },
  alert: {
    color: "red",
    threshold: 2160,
  },
};

export default {
  name: "CountComponent",
  data() {
    return {
      actualTime:''
    }
  },
  props: {
    dateInit: {
      type: Number,
      default: Math.trunc(new Date().getTime() / 1000),
    },
    dateEnd: {
      type: Number,
    },
  },
  setup(props) {
    const now = ref(props.dateEnd || Math.trunc(new Date().getTime() / 1000));
    const timePassed = ref(0);
    const timeLimit = ref(2700);
    const timerInterval = ref(null);
    const remainingPathColor = ref("green");
    const circleDasharray = ref("0 283");

    const calculateTimeFraction = () => {
      const rawTimeFraction = timePassed.value / timeLimit.value;
      return rawTimeFraction - (1 / timeLimit.value) * (1 - rawTimeFraction);
    };

    const setCircleDasharray = () => {
      circleDasharray.value = `${(calculateTimeFraction() * FULL_DASH_ARRAY).toFixed(0)} 283`;
    };

    const setRemainingPathColor = (timePassedValue) => {
      const {alert, warning, info} = COLOR_CODES;
      if (timePassedValue >= alert.threshold) {
        remainingPathColor.value = alert.color;
      } else if (timePassedValue >= warning.threshold) {
        remainingPathColor.value = warning.color;
      } else {
        remainingPathColor.value = info.color;
      }
    };
    const getElapsedTime = (time) => {
      return time > 0 ? time : 0;
    };
    const tick = () => {
      if (!props.dateEnd) {
        now.value = Math.trunc(new Date().getTime() / 1000);
      }
      timePassed.value = getElapsedTime(now.value - props.dateInit);
      setCircleDasharray();
      setRemainingPathColor(timePassed.value);
    };

    onMounted(() => {
      timerInterval.value = setInterval(tick, 1000);
    });

    return {
      now,
      calculateTimeFraction,
      timePassed,
      timeLimit,
      remainingPathColor,
      circleDasharray,
    };
  },
  methods: {
    formatTime(time) {
      if (this.dateEnd == null && this.dateInit == null) {
        return '00:00';
      }
      const minutes = Math.floor(time / 60);
      let seconds = time % 60;
      if (seconds < 10) {
        seconds = `0${seconds}`;
      }
      this.actualTime = `${minutes}:${seconds}`;
      return `${minutes}:${seconds}`;
    },
  },
};
</script>
<style>
body {
  font-family: sans-serif;
  height: 100vh;
  place-items: center;
}

.base-timer {
  position: relative;
  width: 180px;
  height: 180px;
}

.base-timer__svg {
  transform: scaleX(-1);
}

.base-timer__circle {
  fill: none;
  stroke: none;
}

.base-timer__path-elapsed {
  stroke-width: 7px;
  stroke: grey;
}

.base-timer__path-remaining {
  stroke-width: 7px;
  stroke-linecap: round;
  transform: rotate(90deg);
  transform-origin: center;
  transition: 1s linear all;
  fill-rule: nonzero;
  stroke: currentColor;
}

.base-timer__path-remaining.green {
  color: rgb(65, 184, 131);
}

.base-timer__path-remaining.orange {
  color: orange;
}

.base-timer__path-remaining.red {
  color: red;
}

.base-timer__label {
  position: absolute;
  width: 180px;
  height: 180px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 48px;
  left: 0;
}
</style>
