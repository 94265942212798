<script>
import FormGenerator from "@/components/helper/formGenerator/FormGenerator.vue";
import {useMyWarehousesStore} from "@/store/myWarehousesStore";

export default {
  name: "editWarehouse",
  setup() {
    const myWarehouseStore = useMyWarehousesStore()
    return {
      myWarehouseStore
    }
  },
  components: {FormGenerator},
  beforeMount() {
    this.fetchWarehouse()
  },
  data() {
    return {
      loading: true,
      fields: [
        {
          "name": "name",
          "label": "nome",
          "span": "left",
          "type": "text",
          "rules": "required"
        },
        {
          "name": "secure_limit",
          "label": "Limite de segurança(itens)",
          "span": "right",
          "type": "number",
          "rules": "required"
        },
        {
          "name": "dangerous_limit",
          "label": "Limite de perigo(itens)",
          "span": "left",
          "type": "number",
          "rules": "required"
        },
        {
          "name": "additional_time",
          "label": "Tempo adicional de Entrega(dias)",
          "span": "right",
          "type": "number",
          "rules": "required"
        },
        {
          "name": "cars",
          "label": "Agenda",
          "span": "left",
          "path": "cars",
          "type": "list",
          "headers": [
            {text: 'ID', value: 'id'},
            {text: 'Nome', value: 'name'},
          ],
          "scopes": [
            "add",
            "remove",
          ],
          "urls": {
            "get": `account/warehouses/${this.$route.params.id}/calendars`,
            "update": `account/warehouses/${this.$route.params.id}/calendars`,
            "remove": `account/warehouses/${this.$route.params.id}/calendars`
          }
        },
        {
          "name": "delivery_range",
          "label": "Raio de Entrega(km)",
          "span": "right",
          "type": "number",
          "rules": "required"
        },
        {
          "name": "origin_address_id",
          "label": "Endereço para contação do cliente",
          "nameFrom": "name",
          "descriptionFrom": "description",
          "prompt": "Click the %s button to find a address",
          "list": "OFFLINE\\Mall\\Models\\Address",
          "span": "full",
          "type": "recordFinder",
          "rules": "required",
          "headers": [
            {text: 'ID', value: 'id'},
            {text: 'company', value: 'company'},
            {text: 'name', value: 'name'},
            {text: 'lines', value: 'lines'},
            {text: 'zip', value: 'zip'},
            {text: 'city', value: 'city'},
            {text: 'details', value: 'details'},
          ],
        },
        {
          "name": "distribution_center_id",
          "label": "centro de distribuição",
          "nameFrom": "name",
          "descriptionFrom": "description",
          "prompt": "Click the %s button to find a warehouse",
          "list": "Rockethub\\Realtracking\\Models\\WareHouse",
          "span": "full",
          "required": 1,
          "type": "recordFinder",
          "headers": [
            {text: 'ID', value: 'id'},
            {text: 'Nome', value: 'name'},
          ],
        },
        {
          "name": "active",
          "label": "Ativo",
          "span": "auto",
          "type": "switch"
        },
      ],
    }
  },
  methods: {
    fetchWarehouse() {
      this.loading = true
      this.myWarehouseStore.fetchWarehouse(this.$route.params.id).finally(() => {
        this.loading = false
      })
    },
    saveWarehouse(data) {
      this.myWarehouseStore.updateWarehouse(data,this.$route.params.id).finally(() => {
        this.fetchWarehouse()
      })
    }

  }
}
</script>

<template>
  <div class="page-content">
    <div class="container-fluid" v-if="!loading">
      <FormGenerator :form-schema="fields" :initial-values="myWarehouseStore.getWarehouse" @onSubmit="saveWarehouse"/>
    </div>
    <div v-else class="d-flex justify-content-center align-items-center" style="height: 100vh">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>