<template>
  <div v-if="!loading" class="page-content slideInDown animated">
    <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
          <div class="col-md-6">
            <div class="card-title fw-bold fs-4">{{ event.title }}</div>
            <div class="card-text gap-2 d-flex">
              <span class="badge bg-primary" v-if="event.all_day">dura o dia todo</span>
              <span class="badge bg-primary"
                    v-else>começa em {{ moment(event.start_str).format('DD/MM/YYYY HH:mm') }}</span>
              <span class="badge bg-success" v-if="!event.recurring"> Recorrente</span>
            </div>
          </div>
          <div class="col-md-6 m-auto gap-3 d-flex">
            <button class="btn btn-success" @click="openModalProducts"><i class="fas fa-boxes"></i> adicionar produto</button>
            <button class="btn btn-primary" @click="openSpecialPeriods"><i class="fa fa-calendar"></i> adicionar periodo especial</button>
          </div>
          </div>
        </div>
      </div>
    </div>
      <EventProducts/>

      <EventSpecialPeriods/>
    </div>
  </div>
  <div v-else class="d-flex justify-content-center align-items-center" style="height: 100vh">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <ModalComponent ref="productModal">
    <EventProductAdd/>
  </ModalComponent>

  <ModalComponent ref="specialPeriodModal">
    <SpecialPeriods @closeModal="$refs.specialPeriodModal.closeModal()"/>
  </ModalComponent>
</template>
<script>
import {defineComponent} from 'vue'
import {usePlannerStore} from "@/store/plannerStore";
import EventProducts from "@/components/planner/EventProducts.vue";
import ModalComponent from "@/components/helper/modal/ModalComponent.vue";
import EventProductAdd from "@/components/planner/EventProductAdd.vue";
import SpecialPeriods from "@/components/planner/SpecialPeriods.vue";
import EventSpecialPeriods from "@/components/planner/EventSpecialPeriods.vue";

export default defineComponent({
  name: "EventWrapper",
  components: {EventSpecialPeriods, SpecialPeriods, EventProductAdd, ModalComponent, EventProducts},
  setup() {
    const plannerStore = usePlannerStore()
    return {
      plannerStore
    }
  },
  data() {
    return {loading: false}
  },
  computed: {
    event: function () {
      return this.plannerStore.getPlanner
    }
  },
  methods: {
    openModalProducts(){
      this.$refs.productModal.openModal()
    },
    openSpecialPeriods(){
      this.$refs.specialPeriodModal.openModal()
    }
  },
  beforeMount() {
    this.loading = true
    let id = this.$route.params.id
    this.plannerStore.fetchPlanner(id).finally(() => {
      this.loading = false
    })
    this.plannerStore.retrieveSSchedule()
  }
})
</script>