<template>
  <div v-if="!loading" class="page-content slideInDown animated">
    <error-box :error.sync="error" @clearError="error = null"/>
    <FilterWrapper :original-data="orderGroups" v-if="!loading" @filtered="setFiltered" @filtering="setFiltering"/>
    <div class="accordion accordion-flush slideInLeft animated" id="ordersAccordion">
      <lazy-load-component v-for="(group,index) in filteredByState" :key="index">
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingOne">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    :data-bs-target="'#flush-'+index" aria-expanded="false" aria-controls="'flush-'+index">
              {{ momentParse(index) }} ({{group.length}})
            </button>
          </h2>
          <div :id="'flush-'+index" class="accordion-collapse collapse" aria-labelledby="flush-headingOne"
               data-bs-parent="#ordersAccordion" style="">
            <div class="accordion-body text-body" v-auto-animate >
              <Tabs :class="'m-0'" :options="{ useUrlFragment: false }" >
                <Tab :name="`${status} (${ filterOrdersByState(group, status).length})`"  v-for="(status,index) in getPossibleStateFromSelectedGroups(group)" :key="index">
                  <lazy-load-component v-for="order in filterOrdersByState(group, status)" :key="order.id">
                    <order @error="errorEmitted" @refresh="refresh" :buttonDefinitions="buttonDefinitions" :data="order"
                           :ref="order.id" @removeFromList="removeFromList"
                    />
                  </lazy-load-component>
                </Tab>
              </Tabs>

            </div>
          </div>
        </div>
      </lazy-load-component>
    </div>
  </div>
  <div v-else class="page-content">
    <div class="col-12">
      <div class="text-center">
        <div class="spinner-border text-primary m-2" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Order from "./Order.vue";
import ErrorBox from '../generic/ErrorBox.vue';
import LazyLoadComponent from "../helper/lazyload/LazyLoadComponent.vue";
import FilterWrapper from "@/components/orders/filters/FilterWrapper.vue";
import {ErrorHandler} from "@/utils/ErrorHandler";
import {Tab, Tabs} from "vue3-tabs-component";

export default {
  name: "OrdersComponent",
  data() {
    return {
      selectLoading: false,
      loading: false,
      width: null,
      error: null,
      orderGroups: null,
      selectedGroups: null,
      vendorCode: null,
      filterAll: null,
      statesOptions: [],
      selectedStates: null,
      filteredByState: null,
      buttonDefinitions: null,
      searchText: '',
      selectedBadges: null,
      badges: []

    }
  },
  methods: {
    filterOrdersByState: function (data, selectedState) {
      if (!selectedState) {
        return data; // Se nenhum estado estiver selecionado, retorna todos os pedidos.
      }

      const filteredOrders = data.filter(order => {
        return order.order_state && order.order_state.name === selectedState;
      });

      return filteredOrders;
    },
    getPossibleStateFromSelectedGroups: function (data) {
      let states = []
      const asArray = Object.entries(data);
      for (let i = 0; i < asArray.length; i++) {
          if (!states.includes(asArray[i][1].order_state) && asArray[i][1].order_state != null) {
            states.push(asArray[i][1].order_state.name)
        }
      }
      // remove duplicated states
      states = [...new Set(states)]
      this.statesOptions = states
      return states
    },
    removeFromList(order) {
      let index = this.filteredByState[order.delivery_in].findIndex(o => o.id === order.id)
      this.filteredByState[order.delivery_in].splice(index, 1)
    },
    setFiltered: function (data) {
      this.filteredByState = data
    },
    setFiltering: function (data) {
      console.log('filtering', data)
      this.loading = data
    },
    getOrders: function () {
      this.filteredByState = null
      this.orderGroups = null
      this.loading = true
      this.axios.get('orders/getData')
          .then(response => {
            this.orderGroups = this.sortObject(response.data)
          }).catch(error => {
        if (error.message === 'Network Error') {  // we got an network error - server is down?
          this.error = 'Server is down - contact site admin.';
        } else {                // we got error from the server
          this.error = error.response.data.error;
        }
      }).finally(() => {
        this.loading = false
      })
    },
    getButtonDefinitions: function () {
      this.axios.get('settings/getButtonDefinitions')
          .then(response => {
            this.buttonDefinitions = response.data
          }).catch(error => {
        if (error.message === 'Network Error') {  // we got an network error - server is down?
          this.error = 'Server is down - contact site admin.';
        } else {                // we got error from the server
          this.error = error.response.data.error;
        }
      })
    },
    sortObject: function (o) {
      return Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {});
    },
    momentParse: function (date) {
      return this.moment(date).format('MMMM D, dddd ');
    },
    errorEmitted: function (error) {
      this.error = error
    },
    refresh: function () {
      this.getOrders()

      // aplply all filter again

    },
    getVendorCode: function () {
      this.axios.get('settings/vendorCode').then(response => {
        this.vendorCode = response.data.vendorToken;
        this.listenOrders()
      }).catch(error => {
        console.log(error);
      });
    },
    listenOrders: function () {
      try {
        this.$echo.channel('order.' + this.vendorCode).listen('.OrderStatusChangeEvent', (e) => {
          try {
            let order = this.filteredByState[e.delivery_in].find(order => order.id === e.id)
            if (order) {
              order.order_state = e.order_state
              order.order_state_id = e.order_state_id
            }
            if (e.order_state_id === 5) {
              this.removeFromList(order)
            }
          } catch (e) {
            ErrorHandler.handle(e)
          }
        });
      }catch (e) {
        ErrorHandler.handle(e)
      }
    }
  },
  beforeMount() {
    //get .page-content width minus margin and padding
    this.width = 1599
    this.getOrders()
    this.getButtonDefinitions()
    this.getVendorCode()

  },
  components: {
    Tab,
    Tabs,
    FilterWrapper,
    LazyLoadComponent,
    Order,
    ErrorBox
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
