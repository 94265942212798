<template lang="html">
  <div class="container">

    <p class="title is-4 has-text-centered">Requested page was not found.</p>

  </div>

</template>

<script>
</script>

<style>
  .centered {
    display: block;
    margin: 0 auto;
  }
  .top-padding {
    padding-top: 50px;
  }
</style>
