export const en = {
    "sales-report": "Sales Report",
    "app": {
        "filters": "Filters",
        "customerInfo": "Customer Info",
        "fillRequiredFields": "Please fill in all required fields",
        "selectReport": "Select a report",
        "selectFirst": "Select a {0} first",
        "save": "Save",
        "generateAnotherReport": "Generate another report",
        "update": "Update",
        "next": "Next",
        "previous": "Previous",
        "pick": "Select",
        "clickToSelect": "Click to select",
        "clickToDeselect": "Click to deselect",
        "yes": "Yes",
        "no": "No",
        "edit": "Edit",
        "download": "Download",
        "search": "Search",
        "searchPlaceholder": "Search...",
        "free": "Free",
        "add": "Add",
        "monthlySales": "Monthly sales",
        "notPaidOrders": "Unpaid orders",
        "customer": "Customer",
        "items": "Items",
        "createdAt": "Created at",
        "delete": "Delete",
        "addItems": "Add items",
        "removeItems": "Remove items",
        "submit": "Submit",
        "create": "Create",
        "image": "Image",
        "file": "File",
        "noFiles": "No files",
        "spaceAvailable": "Space available",
        "folder": "Folder",
        "new": "New",
        "back": "Back",
        "open": "Open",
        "noFolders": "No folders",
        "reportGenerated": "Report successfully generated",
        "reportGeneratedMessage": "Report sent for processing, a notification will be sent when the report is ready",
        "select": "Select",
        "products": "Products",
        "productsCheck": "Check products",
        "hasDistribution": "Has distribution center",
        "address": "Address",
        "dragAndDrop": "Drag and drop a {mode} here or click to select",
        "unsavedItems": "{items} unsaved item | {items} unsaved items",
        "filesInsideCount": "{0} File|{0} Files",
        "auth": {
            "passwordChanged": "Password successfully changed, you will be redirected to the login page",
            "changePassword": "Change password"
        },
        "months": {
            "january": "January",
            "february": "February",
            "march": "March",
            "april": "April",
            "may": "May",
            "june": "June",
            "july": "July",
            "august": "August",
            "september": "September",
            "october": "October",
            "november": "November",
            "december": "December"
        },
        "daysOfWeek": {
            "monday": "Monday",
            "tuesday": "Tuesday",
            "wednesday": "Wednesday",
            "thursday": "Thursday",
            "friday": "Friday",
            "saturday": "Saturday",
            "sunday": "Sunday"
        }
    },
    "showcase": "Vitrine",
    "cashier": {
        "chooseATransactionType": "Choose a transaction type",
        "chooseATransactionTypeDescription": "Choose a transaction type to continue",
        "subtractTransactionTypes": {
            "subtract": "Subtract",
            "change": "Change",
            "refund": "Refund",
            "paymentsToSuppliers": "Payments to Suppliers",
        },
        "balance": {
            "missing": "Missing in the cash register",
            "title": "Balance",
        },
        "alerts": {
            "cashierIsClosed": "The cashier is closed, please open the cashier to continue",
            "changingPerspective": "Changing perspective, please wait",
            "cashierIsLocked": "The cashier is locked",
            "cashierIsLockedDescription": "The cashier is locked, please wait for it to be unlocked to continue",
        },
        "insertAmount": "Insert the amount",
        "insertAmountDescription": "Insert the transaction amount",
        "transactionAdded": "Transaction added successfully",
        "transactionAddedDescription": "The transaction has been added successfully",
        "errorAddingTransaction": "Error adding transaction",
        "errorAddingTransactionDescription": "Error adding transaction, please try again",
        "selectReportType": "Select the report type",
        "reportGenerated": "Report generated successfully",
        "downloadReport": "Download report",
        "generateReport": "Generate report",
        "additionTransactionTypes": {
            "sale": "Sale",
            "addition": "Addition",
        },
        "user": "Attendant",
        "transactions": {
            "title": "Transactions",
            "openCashier": "Opened the cashier with",
            "sale": "Made a sale of",
            "addition": "Added",
            "subtraction": "Subtracted",
            "closeCashier": "Closed the cashier with",
            "change": "Gave as change",
            "refund": "Refund of",
            "paymentsToSuppliers": "Payments to suppliers of",
        },
        "reportTypes": {
            "daily": "Daily",
            "weekly": "Weekly",
            "monthly": "Monthly",
            "all": "All",
            "incidents": "Incidents",
        }
    },
    "users": {
        "form": {
            "register": "Register",
            "update": "Update"
        },
        "header": {
            "name": "Name",
            "email": "Email",
            "role": "Role",
            "active": "Active",
            "actions": "Actions",
            "status": "Status",
        },
        "hasInactive": "Inactive users will be removed after 60 days"
    },
    "product": {
        "name": "Name",
        "code": "Code",
        "tax": "Tax",
        "price": "Price",
        "active": "Active",
        "virtual": "Virtual",
        "category": "Category",
        "stock": "Stock",
        "sales_count": "Sales",
        "newProduct": "New Product",
        "isVirtual": "Is virtual?",
        "stockManagement": "Stock Management",
        "ncm": "NCM",
        "categories": "Categories",
        "catalog": "Catalog",
        "reservedStock": "Reserved Stock",
        "oversoldMax": "Maximum Stock",
        "preSale": "Pre-sale",
        "prices": {
            "fixed_expenses_description": "Fixed expenses are all the costs you have for the maintenance of your operation that are not directly related to the cost of the product. For example: rent, electricity, water, phone, internet, administrative expenses, etc. (Total Fixed Expenses ÷ Total Sales x 100)",
            "variable_expenses_description": "Variable expenses are those that occur along with the sale, such as taxes and commissions that apply to the transaction amount; e.g., (Product Price / (taxes, commissions...)",
            "profit_margin_description": "Profit margin is the percentage you expect to earn on the unit price of each product. Your company can have the same profit margin for all your inventory or adopt a margin for each item depending on your strategy. Having a margin for each product makes business more flexible and can ensure greater competitiveness in the prices of some items;",
            "direct_cost_description": "Direct cost is the amount spent on the acquisition or production of a product. In other words, the price you pay for it to your supplier.",
            "fixed_expenses": "Fixed Expenses",
            "variable_expenses": "Variable Expenses",
            "profit_margin": "Profit Margin",
            "direct_cost": "Direct Cost",
            "commission": "Commission",
            "price": "Price",
            "old_price": "Old Price",
            "commissionDescription": "Product value after applying commission.",
            "commissionDescription2": "Do not include this percentage in the markup calculation; it will be added automatically",
            "factor": "Factor",
            "suggested_price": "Suggested Price",
            "markupError": "Extremely high or negative values are not allowed, please check the values entered",
            "markupWarning": "A high markup value may result in a non-competitive suggested price",
            "markupHighWarning": "The markup value is too high, you may be losing money with this product",
            "markup": {
                "cost": "Cost",
                "costPlaceholder": "Product cost",
                "packaging": "Packaging",
                "packagingPlaceholder": "Packaging cost",
                "shipping": "Shipping",
                "shippingPlaceholder": "Shipping cost",
                "profit": "Profit",
                "profitPlaceholder": "Desired profit",
                "commissions": "Commissions",
                "commissionsPlaceholder": "Commissions",
                "simpleTax": "Tax",
                "simpleTaxPlaceholder": "Tax",
                "icmsTax": "ICMS",
                "icmsTaxPlaceholder": "ICMS",
                "pisTax": "PIS",
                "pisTaxPlaceholder": "PIS",
                "cofinsTax": "COFINS",
                "cofinsTaxPlaceholder": "COFINS",
                "calculate": "Calculate",
            }
        },
        "generalConfiguration": {
            "skuDescription": "The identifier (SKU) must contain letters and numbers, have more than 3 characters, and be unique.",
            "shortDescriptionDescription": "The short product description must not be empty and have fewer than 500 characters.",
            "descriptionDescription": 'The product description must not be empty and have fewer than 2500 characters.',
            "stockDescription": "Stock must be a non-negative integer.",
            "categoryDescription": "The product must have at least one associated category.",
            "ncmDescription": "The product must have a defined NCM",
            "imagesDescription": "The product must have at least one associated image.",
            "dimensionsDescription": "The product must have width, height, weight, and length defined",
            "classificationDescription": "All classification fields must be filled out",
            "sku": "SKU",
            "shortDescription": "Short Description",
            "description": "Description",
            "stock": "Stock",
            "categories": "Category",
            "price": "Price",
            "name": "Validation",
            "catalogs": "Catalogs",
            "active": "Active",
            "activeDescription": "Whether the product is active or not",
            "valid": "Valid",
            "attributes": "Attributes",
            "attributesDescription": "Attribute quantity verification",
            "ncm": "NCM",
            "images": "Images",
            "dimensions": "Dimensions",
            "classification": "Classification",
            "status": "Status",
            "pending": "Pending",
            "approved": "Approved",
            "rejected": "Rejected",
            "alterationHeader": {
                "field": "Field",
                "oldValue": "Old Value",
                "newValue": "New Value",
                "date": "Date",
                "status": "Status",
            },
            "statusAlteration": {
                "pending": "Pending",
                "approved": "Approved",
                "rejected": "Rejected",
            }
        },
        "seo": {
            "metaTitle": "Meta Title",
            "metaTitleDescription": "The page title that appears in search results.",
            "metaDescription": "Meta Description",
            "metaDescriptionDescription": "A brief description of the page that appears in search results.",
            "metaKeywords": "Meta Keywords",
            "metaKeywordsDescription": "A list of comma-separated keywords that describe the page.",
        },
        "customFields": {
            "onlyEditable": "Editable Only",
            "confirmRemove": "Are you sure you want to remove the field {field}?",
            "confirmAdd": "Are you sure you want to add the field {field}?",
            "fields": {
                "textarea": "Text Area",
                "text": "Text",
                "additional": "Additional",
            }
        },
        "cart": {
            "stackable": "Stackable",
            "stackableDescription": "Whether the product can be added more than once to the cart",
            "allowOutOfStockPurchases": "Allow Out-of-Stock Purchases",
            "allowOutOfStockPurchasesDescription": "Whether the product can be purchased even if it is out of stock",
            "defaultQuantity": "Default Quantity",
            "defaultQuantityDescription": "The default quantity that will be added to the cart",
            "minimumQuantity": "Minimum Quantity",
            "minimumQuantityDescription": "The minimum quantity that can be added to the cart",
            "maximumQuantity": "Maximum Quantity",
            "maximumQuantityDescription": "The maximum quantity that can be added to the cart",
            "stockManagement": "Stock Management",
            "stockManagementDescription": "Manage the stock of this product",
        },
        "delivery": {
            "dimensions": "Dimensions",
            "dimensionsDescription": "The dimensions of the product",
            "width": "Width (cm)",
            "height": "Height (cm)",
            "length": "Length (cm)",
            "weight": "Weight (kg)",
        },
        "descriptions": {
            "description": "Description",
            "description_short": "Short Description",
            "sku": "SKU",
            "gtin": "GTIN",
            "mpn": "MPN",
            "ncm": "NCM",
            "name": "Name",
        },
        "tab": {
            "general": "General",
            "attachment": "Attachments",
            "price": "Prices",
            "description": "Description",
            "details": "Details",
            "delivery": "Delivery",
            "cart": "Cart",
            "customFields": "Custom Fields",
            "seo": "SEO",
            "classification": "Classification",
        }
    },
    "message": {
        "userCreated": "User created successfully",
        "userUpdated": "User updated successfully",
        "userActivated": "User activated successfully",
        "userDeactivated": "User deactivated successfully",
    },
    "delivery": {
        "tabs": {
            "drivers": "Drivers",
            "freight": "Freight Table",
        }
    },
    "dashboard": {
        "paymentMethodData": "Payment Method Data",
        "lastTransactions": "Last Transactions",
        "interactions": "Interactions",
        "deliveryMap": "Delivery Map",
        "cartChart": "Cart Chart",
        "sinceBegin": "Since Begin",
        "perWeek": "Per Week",
        "grandTotal": "Grand Total",
        "averageSales": "Average Sales",
    },
    "deliveryCredit": {
        "credits": "Amount of Credits",
        "addCredits": "Add Credits",
        "paymentInstructions": "Payment Instructions",
        "paymentInstructionsDescription": "Follow the instructions below to make the payment",
    },
    "drivers": {
        "transferPercentage": "Transfer Percentage",
        "totalPayToDriver": "Total Pay to Driver",
        "totalDeliveryMontly": "Total Monthly Deliveries",
        "totalDeliveries": "Total Deliveries",
        "totalPayToDriverNotPaid": "Total Pay to Driver Not Paid",
        "noDeliveries": "No Deliveries",
        "deliveries": "Deliveries",
        "pay": "Pay to Driver",
    },
    "pdv": {
        "openedCarts": "Opened Carts",
        "noOpenedCarts": "No Opened Carts",
        "errors": {
            "quantity": "Quantity must be greater than zero",
        }
    },
    "pos": {
        "channels": "Channels",
        "channelSelector": "Channel Selector",
        "selectProducts": "Select Products",
        "deliveryMethod": "Delivery Method",
        "checkout": "Checkout",
    },
    "menu": {
        "chainDelivery": "Chain Delivery",
        "dashboard": "Dashboard",
        "users": "Users",
        "orders": "Orders",
        "ordersHistory": "Orders History",
        "products": "Products",
        "saleStarter": "Sale Starter",
        "cashier": "Cashier",
        "planner": "Planner",
        "configuration": "Configuration",
        "delivery": "Deliveries",
        "printers": "Printers",
        "administration": "Administration",
        "myCompany": "My Company",
        "myWarehouses": "My Warehouses",
        "editProduct": "Edit Product",
        "warehouse": "Warehouse",
        "event": "Events",
        "pos": "Point of Sale (POS)",
        "myFiles": "My Files",
        "reports": "Reports",
        "undefined": "Undefined",
    },
    "onboarding": {
        "addressLimit": "You can only add Warehouses based on the number of addresses you registered in the previous step",
        "warehouseLimit": "You can only add Schedules based on the number of Warehouses you registered in the previous step",
        "congratulations": "Congratulations!",
        "congratulationsMessage": "You have successfully completed the onboarding process, now we need a time to analyze your data and activate your account, you will receive an email when your account is activated",
        "congratulationsMessage2": "this process can take up to 24 hours",
        'goToLogin': 'Go to login',
        "myCompany": 'My Company',
        "address": 'Address',
        "warehouse": 'Warehouse',
        "calendars": 'Calendars',
        "users": 'Users',
        "companyMessage": 'Fill in the fields below to register your company',
        "addressMessage": 'Fill in the fields below to register your address',
        "warehouseMessage": 'Fill in the fields below to register your warehouse',
        "calendarsMessage": 'Fill in the fields below to register your calendars',
        "usersMessage": 'Fill in the fields below to register your users',
        "companySchema": {
            "name": "Company Name",
            "state_register": "State Register",
            "phone": "Phone",
            "email": "Email",
            "company_document": "Company Document",
            "company_documentComment": "Only numbers",
        },
        "addressSchema": {
            "lines": "Address",
            "number": "Number",
            "name": "Name",
            "district": "District",
            "city": "City",
            "state": "State",
            "country": "Country",
            "zip": "Zip",
            "details": "Details",
        },
        "warehouseSchema": {
            "name": "Name",
            "address_selected": "Address",
            "additional_time": "Additional Time",
            "dangerous_limit": "Dangerous Limit",
            "secure_limit": "Secure Limit",
            "delivery_range": "Delivery Range",
        },
        "periodSchema": {
            "name": "Name",
            "receive_until": "Receive Until",
            "max_delivery": "Max Deliveries",
            "start_at": "Start At",
            "end_at": "End At",
            "weeks_day": "Work Days",
            "warehouse_selected": "Warehouse",
            'max_deliveryComment': 'Maximum number of deliveries that can be made in this period',
        },
        "userSchema": {
            "first_name": "First Name",
            "last_name": "Last Name",
            'last_nameComment': 'your last name',
            "email": "Email",
            "login": "Login",
            'loginComment': 'your login to access the system',
            "password": "Password",
            "password_confirmation": "Password Confirmation",
        }

    },
    "required": "The field {0} is required",
    "email": "The field {0} must be a valid email",
    "min": "The field {0} must have at least {1} characters",

    "companyInfo": "Company Information",
    "cnpjRaiz": "CNPJ Root",
    "razaoSocial": "Company Name",
    "capitalSocial": "Share Capital",
    "atualizadoEm": "Updated On",
    "porte": "Size",
    "naturezaJuridica": "Legal Nature",
    "qualificacaoDoResponsavel": "Responsible Qualification",
    "atividadePrincipal": "Main Activity",
    "situacaoCadastral": "Registration Status",
    "emailField": "Email",
    "estabelecimento": "Establishment",
    "cnpj": "CNPJ",
    "tipo": "Type",
    "nomeFantasia": "Trade Name",
    "dataSituacaoCadastral": "Registration Status Date",
    "dataInicioAtividade": "Start Date",
    "logradouro": "Street",
    "bairro": "Neighborhood",
    "cep": "Postal Code",
    "cidade": "City",
    "telefone": "Phone",
    "atividadesSecundarias": "Secondary Activities",
    "simplesNacional": "National Simple",
    "simples": "Simple",
    "dataOpcaoSimples": "Simple Option Date",
    "dataExclusaoSimples": "Simple Exclusion Date",
    "mei": "MEI",
    "dataOpcaoMei": "MEI Option Date",
    "dataExclusaoMei": "MEI Exclusion Date",
    "inscricoesEstaduais": "State Registrations"

}