<template>
  <Form ref="addProductForm">
    <Vue3EasyDataTable :headers="headers" :items="items">
      <template #header-select>
        <Field name="product[]">
          <input type="checkbox" v-model="selectAllItems"> Tudo
        </Field>
      </template>

      <template #item-select="{ id }">
        <input name="product[]" type="checkbox" :value="id" class="select checkbox"/>
      </template>

    </Vue3EasyDataTable>
  </Form>
</template>
<script>
import Vue3EasyDataTable from 'vue3-easy-data-table'

export default {
  name: 'ProductList',
  components: {Vue3EasyDataTable},
  props: {
    headers: {},
    items: {},
  },
  data() {
    return {
      selectAllItems: false
    }
  },
  watch: {
    selectAllItems(val) {
      if (val) {
        // select all
        //get all checkboxes
        let checkboxes = document.getElementsByClassName('select')
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = true
        }
      } else {
        // deselect all
        //get all checkboxes
        let checkboxes = document.getElementsByClassName('select')
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = false
        }
      }
    }
  },

}
</script>