<template>
  <div class="container-fluid mt-3">
    <save-update-action-bar @save="save" :noUpdate="false" @update="update" title="Classificação"></save-update-action-bar>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <FormGenerator :form-schema="createFields" ref="form" :initial-values="createInitialValues" :no-button="true"></FormGenerator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SaveUpdateActionBar from "@/components/generic/SaveUpdateActionBar.vue";
import FormGenerator from "@/components/helper/formGenerator/FormGenerator.vue";

export default {
  name: "ClassificationComponent",
  components: {FormGenerator, SaveUpdateActionBar},
  props: {
    product: {}
  },
  methods: {
    changeValue(value) {
      this.product.classification.forEach((property) => {
        let propertyCheck = property.id
        let valueCheck = value?.id
        console.log(value.value)
        console.log("propertyCheck:"+ propertyCheck)
        console.log("valueCheck:"+ valueCheck)
        if (property.id === value?.id) {
          property.value = value?.value;
        }
      });
    },
    update() {
      this.$emit('update')
    },
    save() {
      let form = this.$refs.form.form
      this.axios.post(`/product/${this.product.id}/classification`, form).then(() => {
        this.$notify('Dados salvos com sucesso')
      }).catch(() => {
        this.$notify('Erro ao salvar dados')
      })
    },
    generateOptions(options) {
      console.log(options)
      if (!options) return null
      return options.map((option) => {
        return {
          id: option.value,
          name: option.value
        }
      })
    }
  },
  computed: {
    createFields() {
      let firstSpan = 'left'

      return this.product.classification.map((property) => {
        return {
          name: property.id.toString(),
          label: property.property.name,
          type: property.property.type,
          span: firstSpan,
          options: this.generateOptions(property.property.options),
        }
      })
    },
    createInitialValues(){
      let initialValues = {}
      this.product.classification.forEach((property) => {
        if (property.property.type === 'dropdown') {
          initialValues[property.id.toString()] = {id: property.value, name: property.value}
          return
        }
        initialValues[property.id.toString()] = property.value
      })
      return initialValues
    }
  }
}
</script>

<style scoped>

</style>