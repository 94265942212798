<template>
  <div class="change-calculator">
    <div class="mb-3 text-center">
      <h1 class="form-label "><span class="d-block">Valor a pagar</span> <span
          class=" d-block text-success bg-white p-2" v-currency="valueToPay"></span></h1>
      <h5 class="text-warning">Importante: Utilize exclusivamente este método para efetuar pagamentos em lojas físicas.</h5>
      <small class="text-warning">O uso indevido deste método pode resultar em taxas extras</small>
    </div>
    <div class="mb-3">
      <label class="form-label">Valor pago:</label>
      <input type="number" class="form-control p-3 fs-1" v-model.number="PaidValue" min="0">
    </div>
    <div class="result text-center" v-if="change !== null">
      <h3 class="d-block">Troco</h3>
      <h4 class="mt-3" v-currency="change" :class="{'text-danger': change < 0, 'text-success': change > 0}"></h4>
      <span class="text-danger" v-if="!valid">O valor pago não pode ser menor do que o valor a pagar.</span>

    </div>

    <button class="btn btn-primary mt-3 btn-lg w-100" @click="pay" :disabled="!valid">Pagar</button>
  </div>
</template>

<script>
import {useCashierStore} from "@/store/cashierStore";

export default {
  name: 'CashPayment',
  props: {
    valueToPay: {
      type: Number,
      required: true
    }
  },
  setup() {
    let cashierStore = useCashierStore()
    return {cashierStore}
  },
  data() {
    return {
      PaidValue: 0,
    }
  },
  methods: {
    pay() {
      let transactions = []
      let sale = {
        type: 'sale',
        amount: this.PaidValue,
        needsConversion: true
      }
      transactions.push(sale)

      if (this.change > 0) {
        let change = {
          type: 'change',
          amount: (-1 * this.change),
          needsConversion: true
        }
        transactions.push(change)
      }


      this.cashierStore.createPendingTransactions(transactions)
      this.$emit('pay')
    }
  },
  computed: {
    valid() {
      if (this.PaidValue < this.valueToPay) {
        return false
      }
      return true
    },
    change() {
      return (this.PaidValue - this.valueToPay).toFixed(2)
    }
  }
}
</script>

<style lang="scss">
.change-calculator {
  max-width: 70%;
  margin: 0 auto;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 5px;

  .result {
    color: green;
  }
}
</style>