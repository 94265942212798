<template>
  <div class="page-content">
    <div class="container-fluid">
      <div class="d-flex  gap-2">
        <ProductsInCart v-if="posStore.getCart !== null" :show-products="showProducts"/>
        <CustomerInfoCart/>
      </div>

      <FormWizard class="card p-2" :back-button-text="$t('app.back')" :next-button-text="$t('app.next')" >
        <tab-content :title="$t('pos.selectProducts')">
          <PosProducts v-if="posStore.getCart !== null"/>
        </tab-content>
        <tab-content :title="$t('pos.deliveryMethod')">
          <DeliveryMethod v-if="posStore.getCart !== null" :show-products="showProducts"/>
        </tab-content>
        <tab-content :title="$t('pos.checkout')">
          <div class="d-flex">
            <div class="col-md-12">
            <div class="row">
              <CuponApplier :show-products="showProducts"/>
              <ChanelSelector :show-products="showProducts"/>
              <PaymentMethods v-if="posStore.getCart !== null" @checkout="setCartNull()"
                              :show-products="showProducts"/>
              </div>
            </div>
          </div>
        </tab-content>
        <template v-slot:footer="props">
          <div class="wizard-footer-left">
            <wizard-button  v-if="props.activeTabIndex > 0" @click.native="props.prevTab()" :style="props.fillButtonStyle">
              {{ $t('app.back') }}</wizard-button>
          </div>
          <div class="wizard-footer-right">
          <wizard-button v-if="!props.isLastStep" @click.native="props.nextTab()" class="wizard-footer-right" :style="props.fillButtonStyle">{{$t('app.next')}}</wizard-button>

          <wizard-button v-if="props.isLastStep" @click.native="alert('Done')" class="wizard-footer-right finish-button d-none" :style="props.fillButtonStyle">  {{props.isLastStep ? 'Done' : 'Next'}}</wizard-button>
          </div>
        </template>
      </FormWizard>

    </div>
  </div>

</template>

<script>
import PosProducts from "@/components/pdv/products/PosProducts.vue";
import ProductsInCart from "@/components/pdv/details/cart/ProductsInCart.vue";
import DeliveryMethod from "@/components/pdv/details/delivery/DeliveryMethod.vue";
import PaymentMethods from "@/components/pdv/details/paymentMethod/PaymentMethods.vue";
import {usePosStore} from "@/store/PosStore";
import CuponApplier from "@/components/pdv/CuponApplier.vue";
import ChanelSelector from "@/components/pdv/ChannelSelector.vue";
import {FormWizard, TabContent, WizardButton} from "vue3-form-wizard";
import CustomerInfoCart from "@/components/pdv/CustomerInfoCart.vue";

export default {
  name: "PointOfSales",
  setup: () => ({posStore: usePosStore()}),
  components: {
    WizardButton,
    CustomerInfoCart,
    FormWizard,
    TabContent,
    ChanelSelector,
    CuponApplier,
    PaymentMethods, DeliveryMethod, ProductsInCart, PosProducts
  },
  data() {
    return {
      paymentended: false,
      paymentData: null,
      loaded: false,
      showProducts: true,
    }
  },
  methods: {
    toggleProducts() {
      this.showProducts = !this.showProducts; // Toggle the state of the showProducts variable
    },
    setCartNull: function () {
      this.posStore.setCart(null)
    },
    selectedCategoryHandler: function (category) {
      this.selectedCategory = category
    },
  },
  created() {
    this.loaded = true
    if (this.posStore.getCart == null) {
      this.$router.push({name: 'saleStarter'})
    }
    this.emitter.on('paymentEnded', ($data) => {
      this.cart = null
      this.paymentended = true
      this.selectedCategory = null
      this.posStore.setPaymentData($data)
      this.$router.push({name: 'paymentCompleted'})
      this.paymentData = $data
    })
  },
}
</script>

<style scoped>
::selection {
  background: #d5bbf7;
}

.card input[type="radio"] {
  display: none;
}
</style>