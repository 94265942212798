<template>

  <button @click="checkAuthorize" v-if="!accessGranted">
    <slot></slot>
  </button>
  <!--    <input type="password" v-model="password" @keyup.enter="checkPassword"/>-->
  <button v-else @click="handleButtonClick">
    <slot></slot>
  </button>

</template>

<script>
import {useAuthStore} from "@/store/authStore";

export default {
  name: "PasswordProtectedButton",
  setup() {
    const authStore = useAuthStore();
    return {
      authStore
    }
  },
  data() {
    return {
      password: "",
      accessGranted: false,
    };
  },
  methods: {
    checkAuthorize: function () {
      // this.accessGranted = true;
      // this.$emit("onClick");
      let access = !this.authStore.checkRole('systemRole', 'COM')
      if(access) {
        this.$swal({
          title: 'Digite sua senha',
          html: '<input id="swal-input1" class="swal2-input" placeholder="senha" type="password">',
          focusConfirm: false,
          preConfirm: () => {
            return document.getElementById('swal-input1').value

          }
        }).then((result) => {
          if (result.value) {
            this.axios.post('auth/checkAuth', {
              username: this.authStore.user.username,
              password: result.value
            }).then(() => {
              this.accessGranted = true;
              this.$emit("onClick");
            }).catch(() => {
              this.$swal({
                title: 'Senha incorreta',
                icon: 'error',
                timer: 2000,
                showConfirmButton: false,
              })
            })
          }
        })
      }else{
        this.accessGranted = true;
        this.$emit("onClick");
      }
    },
    checkPassword() {
      // Lógica para verificar a senha (pode ser uma requisição à API ou simples comparação)
      const correctPassword = "senha_correta"; // Substitua 'senha_correta' pela senha correta

      if (this.password === correctPassword) {
        this.accessGranted = true;
      } else {
        alert("Senha incorreta!");
        this.password = "";
      }
    },
    handleButtonClick() {
      // Lógica para lidar com o clique do botão original
      this.$emit("onClick");
    },
  },
};
</script>
