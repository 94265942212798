<template>
  <div class="container-fluid mt-3">
    <save-update-action-bar @save="save" @update="update"></save-update-action-bar>
    <div class="row" v-if="priceLines.price">
      <div class="col-md-6">
        <div class="card ">
          <div class="card-body gap-3">
            <div class="col-sm-10">
              <label for="old_price">{{$t('product.prices.price')}}</label>
              <input class="form-control" id="price" type="number" v-model="priceLines.price.price">
            </div>
            <div class="col-sm-10">
              <label for="old_price">{{$t('product.prices.old_price')}}</label>
              <input class="form-control" id="old_price" type="number" v-model="priceLines.old_price.price">
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">{{$t('product.prices.commission')}}</h4>
          </div>
          <div class="card-body">
            <h5>{{ priceLines.commission }}%</h5>
            <h5>{{ getPriceWithCommission() }}</h5>
            <small>{{$t('product.prices.commissionDescription')}}</small>
            <br>
            <small>{{$t('product.prices.commissionDescription2')}}</small>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <MarkupCalculate :commissions="commission"/>
      </div>
    </div>
  </div>
</template>

<script>
import SaveUpdateActionBar from "@/components/generic/SaveUpdateActionBar";
import MarkupCalculate from "@/components/products/tabs/pricelines/MarkupCalculate.vue";

export default {
  name: "PriceLines",
  props: {
    product: {}
  },
  data() {
    return {
      priceLines: [],
      currency: ['BRL'],
    }
  },
  methods: {
    update: function () {
      this.getPriceLines()
    },
    save() {
      this.axios.put(`/product/${this.product.id}/prices`, this.mountRequest()).then(() => {
        this.$notify({
          title: 'Sucesso',
          text:'Preços atualizados com sucesso'
        })
        this.update()
      }).catch(() => {
        this.$notify({
          title: 'Erro',
          text:'Erro ao atualizar os preços',
          type: 'error'
        })
      })
    },
    mountRequest() {
      let request = {};
      this.currency.forEach(currency => {
        request[currency] = request[currency] || []
        request[currency].push({
          id: `product-${this.product.id}`,
          original_id: this.product.id,
          type: "product",
          name: this.product.name,
          stock: this.product.stock,
          price: this.priceLines.price.price,
          additional__1: this.priceLines.old_price.price
        })
      })
      //wrap request in state object
      return {state: request}
    },
    getPriceWithCommission() {
      return new Intl.NumberFormat('pt-br', {
        style: 'currency',
        currency: 'BRL'
      }).format((this.priceLines.price.price - (this.priceLines.price.price * (1 + this.priceLines.commission / 100) - this.priceLines.price.price)).toFixed(2))
    },
    getPriceLines() {
      this.axios.get(`/product/${this.product.id}/prices`).then(response => {
        this.priceLines = response.data;
      })
    }


  },
  computed:{
    commission(){
      return Number(this.priceLines.commission)
    }
  },
  mounted() {
    this.getPriceLines()
  },
  components: {
    MarkupCalculate,
    SaveUpdateActionBar
  }
}
</script>

<style scoped>
.fadeAnimation {
  transition: all 1s ease;
}
</style>