<template>
  <div class="page-content">
    <div class="container-fluid">
      <div class="py-2 d-flex justify-content-end gap-2">
        <button class="btn btn-primary" @click="$router.push({name:'newProduct'})" v-wave><em class="fa fa-plus-circle"></em></button>
        <button class="btn btn-secondary" @click="getProducts" v-wave><em class="mdi mdi-refresh-circle"></em></button>
      </div>

      <div class="input-group mb-3">
        <input type="text" class="form-control" placeholder="Search products" v-model="searchQuery">
        <button class="btn btn-outline-secondary" type="button" @click="searchProducts">Search</button>
      </div>

      <div class="table-rep-plugin" v-show="!loading">
        <div class="table-responsive b-0" data-pattern="priority-columns">
          <table id="tech-companies-1" class="table  table-striped">
            <thead>
            <tr>
              <th @click="sort('code')" data-priority="1">{{$t('product.code')}}</th>
              <th @click="sort('name')" data-priority="3">{{$t('product.name')}}</th>
              <th @click="sort('active')" data-priority="3">{{$t('product.active')}}</th>
              <th @click="sort('virtual')" data-priority="3">{{$t('product.virtual')}}</th>
              <th @click="sort('category')" data-priority="6">{{$t('product.category')}}</th>
              <th @click="sort('price')" data-priority="6">{{$t('product.price')}}</th>
              <th @click="sort('stock')" data-priority="6">{{$t('product.stock')}}</th>
              <th @click="sort('tax')" data-priority="6">{{$t('product.tax')}}</th>
              <th @click="sort('sales_count')" data-priority="6">{{$t('product.sales_count')}}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="product in sortedProducts" :key="product.id">
              <th @click="$router.push({name:'editProduct',params:{id:product.id}})" class="point"><em class="ion ion-md-arrow-forward text-primary"></em>
                {{ product.code }}
              </th>
              <td>{{ product.name }}</td>
              <td><span> <i v-if="product.active " class="ion-ios-checkmark-circle font-size-18 text-success"></i> <i
                  v-else class="ion-ios-close-circle font-size-18 text-danger"></i></span></td>
              <td><span> <i v-if="product.virtual" class="ion-ios-checkmark-circle font-size-18 text-success"></i> <i
                  v-else class="ion-ios-close-circle font-size-18 text-danger"></i></span></td>
              <td>{{ product.category }}</td>
              <td>{{ product.price }}</td>
              <td>{{ product.stock }}</td>
              <td><span> <i v-if="product.tax" class="ion-ios-checkmark-circle font-size-18 text-success"></i> <i v-else
                                                                                                                  class="ion-ios-close-circle font-size-18 text-danger"></i></span>
              </td>
              <td>{{ product.sales_count }}</td>

            </tr>

            </tbody>
          </table>
        </div>
        <div class="col-md-12 d-flex justify-content-between">
          <button @click="prevPage" class="btn btn-light " :disabled="!(currentPage >1)" v-wave> {{$t('app.previous')}}</button>
          <button @click="nextPage" class="btn btn-light " v-wave
                  :disabled="!((currentPage * pageSize) < products.length)"> {{ $t('app.next')}}
          </button>
        </div>
      </div>
      <div class="d-flex justify-content-center" v-if="loading">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: "ProductsComponent",
  data() {
    return {
      products: [],
      currentPage: 1,
      currentSort: 'name',
      currentSortDir: 'asc',
      pageSize: 12,
      loading: false,
      searchQuery: '',
    }
  },
  methods: {
    searchProducts() {
      // Filter products based on search query
      this.sortedProducts = this.products.filter(product =>
          product.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    getProducts() {
      this.loading = true
      this.axios.get('/products/getData')
          .then(response => {
            this.products = response.data
          })
          .catch(error => {
            console.log(error)
          }).finally(() => {
        this.loading = false
      })
    },
    sort: function (s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort = s;
    },
    nextPage: function () {
      if ((this.currentPage * this.pageSize) < this.products.length) this.currentPage++;
    },
    prevPage: function () {
      if (this.currentPage > 1) this.currentPage--;
    }
  },
  mounted() {
    this.getProducts()

  },
  computed: {
    sortedProducts() {
      // Modify to use filtered products if search query is present
      let productsToSort = this.searchQuery ? this.products.filter(product =>
          product.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      ) : this.products;

      let sortedProducts = [...productsToSort];
      sortedProducts.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === 'desc') modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
      let start = (this.currentPage - 1) * this.pageSize;
      let end = this.currentPage * this.pageSize;
      return sortedProducts.slice(start, end);
    },
    rows() {
      return this.users.length
    }
  }
}
</script>

<style scoped>
@import url('../../assets/libs/admin-resources/rwd-table/rwd-table.min.css');

.point {
  cursor: pointer;
}
</style>