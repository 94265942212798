<template>
  <div class="row col-md-12">
    <button class="btn btn-outline-info text-uppercase" @click.prevent="openSelector">
      cadastrar endereço
    </button>
    <ModalComponent ref="modal">
      <div class="row">
        <div class="card col-md-12 bg-soft-light rounded-2 mt-3 ">
          <div class="row">
            <PerfectScrollbar class="col-md-12" style="max-height: 600px;">
              <div class="content">
                <AddressForm @updatedAddress="executeAddressUpdate"/>
              </div>
            </PerfectScrollbar>
          </div>
        </div>
      </div>
    </ModalComponent>
    <PerfectScrollbar class="col-md-12 mt-2" style="max-height: 400px;" v-if="addresses.length > 0">
      <label class="radio-tile-group col-md-12 p-1 m-0" v-for="(address,index) in addresses" :key="index">
        <div class="input-container">
          <input
              :id="address.id"
              class="radio-button"
              :value="address.id"
              v-model="selectedAddress"
              :checked="addressInCart === address.id"
              type="radio"
              name="radio"
              @change="selectAddress(address.id)"
          />
          <div class="radio-tile">
            <div class="icon walk-icon text-dark text-center">
              <span>{{ address.lines }}, {{ address.number }}</span>
              <small class="text-muted d-block font-size-11">{{ address.district }}, {{ address.city }} - {{
                  address.zip
                }}</small>
            </div>
          </div>
        </div>
      </label>
    </PerfectScrollbar>
  </div>
</template>
<script>
import AddressForm from "@/components/pdv/details/delivery/AddressForm.vue";
import {usePosStore} from "@/store/PosStore";
import ModalComponent from "@/components/helper/modal/ModalComponent.vue";
// import {BModal} from "bootstrap-vue";

export default {
  name: 'AddressSelector',
  components: {ModalComponent, AddressForm},
  setup: () => ({posStore: usePosStore()}),
  props: {
    noAddress: {},
  },
  data() {
    return {
      selectedAddress: null,
      addresses: [],
      zip: '',
      showAddressForm: false,
      cartIdCopy: null,
      addressLoaded: false,
      modalShow: false,
    }
  },
  methods: {
    openSelector: function () {
      this.$refs.modal.openModal();
    },
    selectAddress: function (addressId) {
      this.axios.put(`pos/address/${this.posStore.getCart.id}`, {addressId: addressId}).then((response) => {
        this.$refs.modal.closeModal();
        this.$emit('address-selected', response.data)
        this.posStore.updateCart()
      }).catch((error) => {
        console.log(error)
      })
    },
    executeAddressUpdate() {
      this.getAddressForCart().finally(() => {
        this.$refs.modal.closeModal()
      })
    },
    getAddressForCart: async function () {
      this.addressLoaded = false
      return this.axios.get(`pos/address/${this.posStore.getCart.id}`).then((response) => {
        this.addressLoaded = true
        this.addresses = response.data
      }).catch((error) => {
        this.addressLoaded = true
        this.$notify('Erro ao carregar endereços')
        console.log(error)
        this.addresses = []
      })
    },
  },
  beforeMount() {
    this.getAddressForCart()
  },
  computed: {
    addressInCart() {
      return this.posStore.getCart.shipping_address_id
    }
  },
  created() {
    this.emitter.on('paymentEnded', () => {
      //kill component
      this.cartIdCopy = null
    })

  }
}
</script>
<style scoped lang="scss">
::selection {
  background: #d5bbf7;
}

.card input[type="radio"] {
  display: none;
}

$primary-color: #079ad9;


.radio-tile-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;


  .input-container {
    position: relative;
    height: 4rem;
    width: 100%;
    margin: 0.5rem;


    .radio-button {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      margin: 0;
      cursor: pointer;
    }

    //default tile styles
    .radio-tile {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      border: 2px solid $primary-color;
      border-radius: 5px;
      padding: 1rem;
      transition: transform 300ms ease;
    }

    .icon svg {
      fill: $primary-color;
      width: 3rem;
      height: 3rem;
    }

    .radio-tile-label {
      text-align: center;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: $primary-color;
    }

    //active tile styles
    .radio-button:checked + .radio-tile {
      background-color: white;
      border: 2px solid $primary-color;
      color: white;
      transform: scale(1.1, 1.1);

      .icon svg {
        fill: white;
        background-color: $primary-color;
      }

      .radio-tile-label {
        color: white;
        background-color: $primary-color;
      }
    }
  }
}
</style>