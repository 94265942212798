<template>
  <div class="page-content">
    <div class="container-fluid">
      <div class="row d-flex justify-content-center">
        <div id="preloader" v-if="this.isLoading">
          <div id="status">
            <div class="spinner"></div>
          </div>
        </div>
        <div class="col-md-6 text-center" style="margin-top: 25%">
          <Form @submit="onSubmit" v-slot="{ invalid }">
            <Field
                name="document"
                rules="required|min:11|max:18"
                v-slot="{ errors, field }" v-model.trim="document"
            >
              <input
                  type="tel"
                  class="form-control p-3"
                  placeholder="Digite o CPF/CNPJ Do Cliente"
                  v-bind="field"
                  :class="{ 'is-invalid': errors[0] }"
              />
              <span class="invalid-feedback">{{ errors[0] }}</span>
            </Field>
            <Field
                v-if="showRemainForm"
                name="fullname"
                rules="required|min:2|max:100|min-word:2"
                v-slot="{ errors, field }" v-model.trim="form.fullname"
            >
              <!-- Nome -->
              <input
                  type="text"
                  name="name"
                  class="form-control mt-2"
                  v-bind="field"
                  placeholder="Antonio Martins dos Santos"

                  :class="{ 'is-invalid': errors[0] }"
              />
              <span class="invalid-feedback">{{ errors[0] }}</span>
            </Field>
            <Field
                v-if="showRemainForm"
                name="phone"
                rules="required|numeric|min:10|max:11"
                v-model.trim="form.phone"
                v-slot="{ errors,field }"
            >
              <!-- Telefone -->
              <input
                  type="tel"
                  name="phone"
                  class="form-control"
                  placeholder="Telefone"
                  v-bind="field"
                  :class="{ 'is-invalid': errors[0] }"
              />
              <span class="invalid-feedback">{{ errors[0] }}</span>
            </Field>
            <Field
                v-if="showRemainForm"
                name="email"
                rules="email"
                v-slot="{ errors, field }"
                v-model.trim="form.email"
            >
              <!-- Email -->
              <input
                  type="email"
                  name="email"
                  class="form-control"
                  placeholder="Email(opcional)"
                  v-bind="field"
                  :class="{ 'is-invalid': errors[0] }"
              />
              <span class="invalid-feedback">{{ errors[0] }}</span>
            </Field>
            <button
                class="btn btn-success w-100 mt-4"
                v-if="!showRemainForm"
                :disabled="disableSaleButton || invalid"
            >
              Iniciar Venda
            </button>
            <button
                class="btn btn-success w-100 mt-4"
                v-if="showRemainForm"
                :disabled="disableSaleButton || invalid"
            >
              Registrar e iniciar Venda
            </button>
          </Form>
        </div>
        <OpenedCarts/>
      </div>
    </div>
  </div>
</template>
<script>
import {defineRule, Field, Form} from "vee-validate";
import {email, max, min, numeric, required} from "@vee-validate/rules";
import {usePosStore} from "@/store/PosStore";
import {useCashierStore} from "@/store/cashierStore";
import OpenedCarts from "@/components/pdv/OpenedCarts.vue";


//create a validation minimum two words
// import { extend } from 'vee-validate';

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);
defineRule('max', max);
defineRule('numeric', numeric);

defineRule('min-word', (value) => {
  if (value.split(' ').length < 2) {
    return 'O campo {_field_} precisa ter no mínimo duas palavras'
  }
  return true;

});

export default {
  name: 'SaleInitialize',
  components: {
    OpenedCarts,
    Field,
    Form
  },
  setup: () => (
      {posStore: usePosStore(), cashierStore: useCashierStore()}
  ),
  data() {
    return {
      isLoading: false,
      document: null,
      form: {
        fullname: null,
        phone: null,
        email: null,
      },
      showRemainForm: false,
      disableSaleButton: true
    }
  },
  methods: {
    onSubmit() {
      if (!this.cashierStore.getCashierIsOpen) {
        this.$notify({
          text: this.$t('cashier.alerts.cashierIsClosed'),
          type: 'error'
        })
        return
      }
      if (this.showRemainForm) {
        this.registerCustomer()
      } else {
        this.startSale()
      }
    },
    searchCustomer() {
      // search customer by document
      this.axios.post('/exist', {document: this.document}).then(response => {
        let exist = response.data.exists;
        if (!exist) {
          this.showRemainForm = true;
          this.disableSaleButton = true;
        } else {
          this.disableSaleButton = false;
          this.showRemainForm = false;
        }
      }).catch(error => {
        console.log(error);
        if (error.response.status === 422) {
          this.$notify({
            text: 'CPF Inválido',
            type: 'error'
          })
        } else {
          this.$notify({
            text: 'Erro ao buscar cliente',
            type: 'error'
          })
        }

      })
    },
    async startSale()   {
      this.isLoading = true;
      await this.posStore.setCartNowHash(this.document)
      this.axios.post('pos/start', {document: this.document}).then(response => {
        this.isLoading = false;
        this.posStore.selectCart(response.data)
      }).catch(() => {
        this.isLoading = false;
        this.$notify({text: 'Erro ao iniciar venda', type: 'error'})
      })
    },
    registerCustomer() {
      //add document to form
      this.form.document = this.document;
      this.axios.post('/customers', this.form).then(() => {
        this.$notify('Cliente registrado com sucesso');
        this.showRemainForm = false;
      }).catch(error => {
        console.log(error);
        if (error.response.status === 422) {
          this.$notify('Algum campo está inválido')
        } else {
          this.$notify('Erro ao cadastrar cliente')
        }

      }).finally(() => {
        // wait 1 second to start sale
        setTimeout(() => {
          this.startSale();
        }, 1000);
      })

    },
  },
  watch: {
    document() {
      if (this.document.length > 10) {
        this.searchCustomer();
      }
    },
    form: {
      handler() {
        this.disableSaleButton = !(this.form.fullname && this.form.phone);
      },
      deep: true
    }
  },
}
</script>
<style scoped>
::selection {
  background: #d5bbf7;
}

.card input[type="radio"] {
  display: none;
}
</style>