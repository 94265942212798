<template>
  <div class="page-content">
    <div class="container-fluid">
      <vue-tabs :options="{ useUrlFragment: false }">
        <v-tab :name="paymentMethod.name" v-for="paymentMethod in PaymentMethods" :key="paymentMethod.id">
          <DeliveryPaymentMethod :data="paymentMethod" :component-name="paymentMethod.payment_provider"/>
        </v-tab>
      </vue-tabs>
    </div>
  </div>
</template>

<script>
import {Tabs, Tab} from 'vue3-tabs-component';
import DeliveryPaymentMethod from "@/components/deliveryCredit/DeliveryPaymentMethod.vue";

export default {
  name: "deliveryCredit",
  components: {DeliveryPaymentMethod, VueTabs: Tabs, VTab: Tab},
  data() {
    return {
      PaymentMethods: []
    }
  },
  methods: {
    getPaymentMethods: function () {
      this.axios.get('deliveryCredit/paymentMethods')
          .then(response => {
            this.PaymentMethods = response.data
          })
    },
  },
  beforeMount() {
    this.getPaymentMethods()
  }
}
</script>

<style scoped>

</style>