<template>
  <div class="page-content">
    <div class="container-fluid">
      <div class="card">
        <div class="card-header">
          informações de aplicativos
        </div>
        <div class="card-body">
          <ul class="list-unstyled">
            <li>
              <strong>Informações de Versão: {{version}}</strong>
            </li>
            <li>
              <strong>Nome do aplicativo: {{applicationName}}</strong>
            </li>
            <li>
              <strong>Nome do pacote: {{packageName}}</strong>
            </li>
            <li>
              <strong>Build: {{build}}</strong>
            </li>
          </ul>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          chave de fornecedor
        </div>
        <div class="cart-body container">
          <div class="mb-3 position-relative">
            <label class="form-label" for="validationCustom01">Sua Chave</label>
            <input type="password" class="form-control" id="validationCustom01" v-model="settingsStore.vendorCode" placeholder="VendorKey">
            <div class="valid-tooltip">
              Looks good!
            </div>

            <div class="mb-0 mt-2 text-center">
              <div>
                <button type="submit" @click="settingsStore.saveVendorCode" v-wave class="btn btn-pink  waves-light ">
                  Salvar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          Loja Configurada
        </div>
        <div class="cart-body container">
          <div class="mb-3 position-relative">
            <label class="form-label" for="validationCustom01">Lojas</label>
            <select class="form-select" aria-label="Default select example" v-model="settingsStore.store">
              <option selected value="" disabled>Selecione uma loja</option>
              <option v-for="warehouse in settingsStore.getWarehouses"  :key="warehouse.id" :value="warehouse.id" :selected="warehouse.id === settingsStore.getStore">{{warehouse.name}}</option>
            </select>

            <div class="mb-0 mt-2 text-center">
              <div>
                <button type="submit" @click="settingsStore.saveStore" v-wave class="btn btn-pink  waves-light ">
                  Salvar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useSettingsStore} from "@/store/settingsStore";

export default {
  name: "SettingsComponent",
  setup(){
    const settingsStore = useSettingsStore();
    return {
      settingsStore,
    }
  },
  data() {
    return {
      version: this.buildInfo.version,
      applicationName:this.buildInfo.applicationName,
      packageName: this.buildInfo.packageName,
      build: this.buildInfo.build,
    }
  },
  beforeMount() {

    this.settingsStore.fetchVendorCode()
    this.settingsStore.fetchWarehouses();
    this.settingsStore.fetchStore();
  }
}
</script>

<style scoped>

</style>
