<template>
  <div>
    <Component :is="componentName" :data="data"/>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import pagarme_payment_link from "@/components/deliveryCredit/paymentMethods/pagarme_payment_link.vue";

export default defineComponent({
  name: "DeliveryPaymentMethod",
  props:{
    componentName: {
      type: String,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  components: {
    pagarme_payment_link
  },
});
</script>
