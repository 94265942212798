<template>
  <h4 class="card-title">{{$t('product.cart.stockManagement')}}</h4>
  <p class="card-title-desc">{{$t('product.cart.stockManagementDescription')}}</p>
  <div>
    <!--    <input type="number" class="form-control" id="defaultQuantity" v-model="productCopy.stock"/>-->
    <EasyDataTable
        :headers="headers"
        :items="items"

    >
      <template #item-treat_negative_as_zero="{treat_negative_as_zero}">
        <span v-if="treat_negative_as_zero">{{$t('app.yes')}}</span>
        <span v-else>{{$t('app.no')}}</span>
      </template>
      <template #item-operation="item">
        <div class="operation-wrapper">
          <button
              class="btn btn-primary"
              @click="editItem(item)"
          > {{$t('app.edit')}}
          </button>
        </div>
      </template>
    </EasyDataTable>
  </div>

  <Form @submit="save" v-if="editing" class="row p-2">
    <div class="form-group col-md-2">
      <Field name="stock" v-model="form.values.stock" v-slot="{field}">
        <label>{{$t('product.stock')}}</label>
        <Input v-bind="field" type="number" class="form-control"/>
      </Field>
    </div>
    <div class="form-group col-md-2">
      <Field name="reserved_stock" v-model="form.values.reserved_stock" v-slot="{field}">
        <label>{{$t('product.reservedStock')}}</label>
        <Input v-bind="field" type="number" class="form-control"/>
      </Field>
    </div>
    <div class="form-group col-md-2">
      <Field name="oversold_max" v-model="form.values.oversold_max" v-slot="{field}">
        <label>{{$t('product.oversoldMax')}}</label>
        <Input v-bind="field" type="number" class="form-control"/>
      </Field>
    </div>
    <div class="form-group col-md-2">
      <Field name="pre_sale" v-model="form.values.pre_sale" v-slot="{field}">
        <label>{{$t('product.preSale')}}</label>
        <Input v-bind="field" type="number" class="form-control"/>
      </Field>
    </div>
    <div class="form-group col-md-12 mt-2">
      <button type="submit" class="btn btn-primary">{{$t('app.save')}}</button>
    </div>
  </Form>

</template>
<script>
import EasyDataTable from 'vue3-easy-data-table'
import 'vue3-easy-data-table/dist/style.css';
import {ref} from "vue";
import {collect} from "collect.js";
import {useForm} from "vee-validate";
import {Field, Form} from "vee-validate";
import axios from "@/boostrap/apiConfig"
import {useNotification} from "@kyvg/vue3-notification";

export default {
  name: 'StockLevels',
  props: {
    productCopy: {}
  },
  components: {
    EasyDataTable,
    Field,
    Form

  },
  setup(props, { emit }) {
    let editing = ref(false)
    let itemEditing = ref({})
    const { notify } = useNotification()
    const stockLevels = ref(props.productCopy.stock_levels)
    const form = useForm({
      initialValues: itemEditing.value,
    })

    const save = (values) => {
      axios.put(`/product/${props.productCopy.id}/stock_levels/${itemEditing.value.id}`, values)
          .then(() => {
            editing.value = false
            notify({
              title: 'Sucesso',
              text: 'Salvo com sucesso',
              type: 'success'
            });
            emit('update')
          }).catch((error) => {
        console.log(error)
        notify({
          title: 'Erro',
          text: 'Erro ao salvar',
          type: 'error'
        });
      })
    }

    const headers = [
      {text: "Deposito", value: "warehouse_id"},
      {text: "Estoque", value: "stock"},
      {text: "Estoque Reservado", value: "reserved_stock", sortable: true},
      {text: "estoque sobrevendido", value: "oversold_max", sortable: true},
      {text: "Estoque de Pré Venda", value: "pre_sale", sortable: true},
      {text: "tratar Negativo como 0", value: "treat_negative_as_zero"},
      {text: "Operações", value: "operation"},
    ];
    console.log(stockLevels.value)

    const items = collect(stockLevels.value).toArray()
    const editItem = (item) => {
      editing.value = true
      itemEditing.value = item
      form.setValues({...item})
    }

    return {
      headers,
      items,
      save,
      editItem,
      editing,
      form
    };
  },
  methods: {},
  computed: {}
}
</script>