<template>
  <PerfectScrollbar :options="{
    suppressScrollX: true,
  }" :class="'p-3'">
  <FormGenerator :form-schema="formSchema"
                 v-if="!loading" @onSubmit="onSubmit"/>

  <div v-else class="d-flex justify-content-center align-items-center" style="height: 100vh">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  </PerfectScrollbar>
</template>

<script>
import {usePlannerStore} from "@/store/plannerStore";
import {defineRule} from "vee-validate";
import FormGenerator from "@/components/helper/formGenerator/FormGenerator.vue";
// import { required, max, min, numeric } from "@vee-validate/rules";

defineRule("date", (value) => {
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  return regex.test(value) || "Date must be in format YYYY-MM-DD";
});

export default {
  name: "SpecialPeriods",
  components: {FormGenerator},
  data() {
    return {

      formSchema: [
        {
          "name": "name",
          "label": "Nome",
          "span": "full",
          "type": "text",
          "rules": "required|max:255",
        },
        {
          "name": "max_delivery",
          "label": "Maximo de Entrega",
          "span": "left",
          "type": "number",
          "comment": "Número máximo de entrega para este período",
          "rules": "required",
        },
        {
          "name": "start_at",
          "label": "Hora de inicio do Periodo",
          "mode": "time",
          "span": "left",
          "type": "time",
          "rules": "required",
        },
        {
          "name": "end_at",
          "label": "Hora de Fim do Periodo",
          "mode": "time",
          "span": "left",
          "type": "time",
          "rules": "required",
        },
        {
          "name": "receive_until",
          "label": "Recebe até",
          "mode": "time",
          "span": "left",
          "type": "time",
          "comment": "Horario limite para o cliente receber o pedido",
          "rules": "required",
        },
        {
          "name": "is_especial_period",
          "label": "Periodo Especial",
          "span": "left",
          "type": "checkbox",
          "checked": true,
          "disabled": true,
          "rules": "",
          "tab": "Periodo Especial"
        },
        {
          "name": "starts_date",
          "label": "Data Inicial de periodo",
          "mode": "date",
          "span": "left",
          "hidden": 0,
          "type": "date",
          "tab": "Periodo Especial",
          "rules": "required",
        },
        {
          "name": "ends_date",
          "label": "Data Final de Periodo",
          "mode": "date",
          "span": "left",
          "hidden": 0,
          "type": "date",
          "tab": "Periodo Especial",
          "rules": "required",
        },
        {
          "name": "schedule",
          "rules": "required",
          "label": "agenda",
          "span": "full",
          "type": "select",
          "multi": false,
          "options": this.schedules(),

        }
      ],
      loading: false,
    };
  },
  methods: {
    schedules() {
      return this.plannerStore.getSchedules.map((item) => {
        return {
          id: item.id,
          name: item.name,
        };
      })
    },

    onSubmit(values) {
      let id = this.$route.params.id;
      this.plannerStore.createSpecialPeriod(id,values).then(() => {
        this.$emit("closeModal")
      }).catch((error) => {
        console.log(error)
      })
    },
  },
  setup() {
    const plannerStore = usePlannerStore();
    return {
      plannerStore,
    };
  },
  beforeMount() {
    this.loading = true;
    this.plannerStore.retrieveSSchedule().finally(() => {
      this.loading = false;
    })
  },
};
</script>


