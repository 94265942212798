<template>
  <div class="container-fluid mt-3">
    <save-update-action-bar @save="save" @update="update"></save-update-action-bar>
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body gap-3">
            <div class="col-sm-12">
              <label class="mb-1">{{$t('product.descriptions.name')}}</label>
              <input type="text" v-model="productCopy.name" class="form-control" placeholder="Nome do Produto"/>
            </div>
            <div class="col-sm-12">
              <label class="mb-1">{{$t('product.descriptions.description_short')}}</label>
              <textarea id="textarea" class="form-control" v-model="productCopy.description_short" maxlength="225" rows="3"
                        placeholder="Descrição curta do Produto"></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-body gap-3">
            <div class="col-sm-12">
              <label class="mb-1">{{$t('product.descriptions.sku')}}</label>
              <input type="text" v-model="productCopy.user_defined_id" class="form-control" placeholder="Codigo do produto(SKU)"/>
            </div>
            <div class="col-sm-12">
              <label class="mb-1">{{$t('product.descriptions.gtin')}}</label>
              <input type="text" v-model="productCopy.gtin" class="form-control" placeholder="GTIN"/>
            </div>
            <div class="col-sm-12">
              <label class="mb-1">{{$t('product.descriptions.mpn')}}</label>
              <input type="text"  v-model="productCopy.mpn" class="form-control" placeholder="MPN"/>
            </div>
            <div class="col-sm-12">
              <label class="mb-1">{{$t('product.descriptions.ncm')}}</label>
              <input type="text" v-model="productCopy.ncm" class="form-control" placeholder="NCM"/>
            </div>
            <div class="row">
            <div class="col-md-6">
              <label class="mb-1">{{$t('product.descriptions.icmsTributeSituation')}}</label>
              <select class="form-select" v-model="productCopy.icms_tribute_situations">
                <option v-for="option in icmsTributeSituations" :value="option.value" :key="option.value">{{option.name}}</option>
              </select>
            </div>
            <div class="col-md-6">
              <label class="mb-1">{{$t('product.descriptions.icmsOrigem')}}</label>
              <select class="form-select" v-model="productCopy.icms_origem">
                <option v-for="option in icmsOrigemOptions" :value="option.value" :key="option.value">{{option.description}}</option>
              </select>
            </div>
            <div class="col-md-6">
              <label class="mb-1">{{$t('product.descriptions.pisTribute')}}</label>
              <select class="form-select" v-model="productCopy.pis_tribute_situations">
                <option v-for="option in pisTributeOptions" :value="option.value" :key="option.value">{{option.description}}</option>
              </select>
            </div>
            <div class="col-md-6">
              <label class="mb-1">{{$t('product.descriptions.cofinsTribute')}}</label>
              <select class="form-select" v-model="productCopy.cofins_tribute_situations">
                <option v-for="option in cofinsTributeOptions" :value="option.value" :key="option.value">{{option.name}}</option>
              </select>
            </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="card bg-white">
          <div class="card-body">
            <div class="col-md-12">
              <div class="col-sm-12">
                <label class="mb-1">{{$t('product.descriptions.description')}}</label>
                <vue-editor v-model="productCopy.description"></vue-editor>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import {VueEditor} from "vue3-editor";
import SaveUpdateActionBar from "@/components/generic/SaveUpdateActionBar";

export default {
  name: "DescriptionsComponent",
  props: {
    product: {}
  },
  data() {
    return {
      productCopy: {},
      icmsTributeSituations: [
        {"name": "Tributada integralmente", "value": "00"},
        {"name": "Tributada com cobrança do ICMS por substituição tributária", "value": "10"},
        {"name": "Com redução de base de cálculo", "value": "20"},
        {"name": "Isenta ou não tributada e com cobrança do ICMS por substituição tributária", "value": "30"},
        {"name": "Isenta", "value": "40"},
        {"name": "Não tributada", "value": "41"},
        {"name": "Outras", "value": "49"},
        {"name": "Suspensão", "value": "50"},
        {"name": "Diferimento", "value": "51"},
        {"name": "ICMS cobrado anteriormente por substituição tributária", "value": "60"},
        {"name": "Com redução de base de cálculo e cobrança do ICMS por substituição tributária", "value": "70"},
        {"name": "Outras", "value": "90"},
        {"name": "Tributada pelo Simples Nacional com permissão de crédito", "value": "101"},
        {"name": "Tributada pelo Simples Nacional sem permissão de crédito", "value": "102"},
        {"name": "Isenção do ICMS no Simples Nacional para faixa de receita bruta", "value": "103"},
        {
          "name": "Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por substituição tributária",
          "value": "201"
        },
        {
          "name": "Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por substituição tributária",
          "value": "202"
        },
        {
          "name": "Isenção do ICMS no Simples Nacional para faixa de receita bruta e com cobrança do ICMS por substituição tributária",
          "value": "203"
        },
        {"name": "Imune", "value": "300"},
        {"name": "Não tributada pelo Simples Nacional", "value": "400"},
        {
          "name": "ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação",
          "value": "500"
        },
        {"name": "Outros", "value": "900"}
      ],
      icmsOrigemOptions: [
        {"value": "0", "description": "Nacional"},
        {"value": "1", "description": "Estrangeira - Importação direta"},
        {"value": "2", "description": "Estrangeira - Adquirida no mercado interno"},
        {"value": "3", "description": "Nacional com mais de 40% de conteúdo estrangeiro"},
        {"value": "4", "description": "Nacional produzida através de processos produtivos básicos"},
        {"value": "5", "description": "Nacional com menos de 40% de conteúdo estrangeiro"},
        {
          "value": "6",
          "description": "Estrangeira - Importação direta, sem similar nacional, constante em lista da CAMEX"
        },
        {
          "value": "7",
          "description": "Estrangeira - Adquirida no mercado interno, sem similar nacional, constante em lista da CAMEX"
        },
        {"value": "8", "description": "Nacional, mercadoria ou bem com Conteúdo de Importação superior a 70%"},
        {
          "value": "9",
          "description": "Estrangeira - Importação direta, sem similar nacional, constante em lista da CAMEX"
        }
      ],
      pisTributeOptions: [
        {"value": "01", "description": "Operação Tributável com Alíquota Básica"},
        {"value": "02", "description": "Operação Tributável com Alíquota Diferenciada"},
        {"value": "03", "description": "Operação Tributável com Alíquota por Unidade de Medida de Produto"},
        {"value": "04", "description": "Operação Tributável Monofásica - Revenda a Alíquota Zero"},
        {"value": "05", "description": "Operação Tributável por Substituição Tributária"},
        {"value": "06", "description": "Operação Tributável a Alíquota Zero"},
        {"value": "07", "description": "Operação Isenta da Contribuição"},
        {"value": "08", "description": "Operação sem Incidência da Contribuição"},
        {"value": "09", "description": "Operação com Suspensão da Contribuição"},
        {"value": "49", "description": "Outras Operações de Saída"},
        {
          "value": "50",
          "description": "Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Tributada no Mercado Interno"
        },
        {
          "value": "51",
          "description": "Operação com Direito a Crédito – Vinculada Exclusivamente a Receita Não Tributada no Mercado Interno"
        },
        {
          "value": "52",
          "description": "Operação com Direito a Crédito - Vinculada Exclusivamente a Receita de Exportação"
        },
        {
          "value": "53",
          "description": "Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno"
        },
        {
          "value": "54",
          "description": "Operação com Direito a Crédito - Vinculada a Receitas Tributadas no Mercado Interno e de Exportação"
        },
        {
          "value": "55",
          "description": "Operação com Direito a Crédito - Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação"
        },
        {
          "value": "56",
          "description": "Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno e de Exportação"
        },
        {
          "value": "60",
          "description": "Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Tributada no Mercado Interno"
        },
        {
          "value": "61",
          "description": "Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno"
        },
        {
          "value": "62",
          "description": "Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita de Exportação"
        },
        {
          "value": "63",
          "description": "Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno"
        },
        {
          "value": "64",
          "description": "Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas no Mercado Interno e de Exportação"
        },
        {
          "value": "65",
          "description": "Crédito Presumido - Operação de Aquisição Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação"
        },
        {
          "value": "66",
          "description": "Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno e de Exportação"
        },
        {"value": "67", "description": "Crédito Presumido - Outras Operações"},
        {"value": "70", "description": "Operação de Aquisição sem Direito a Crédito"},
        {"value": "71", "description": "Operação de Aquisição com Isenção"},
        {"value": "72", "description": "Operação de Aquisição com Suspensão"},
        {"value": "73", "description": "Operação de Aquisição a Alíquota Zero"},
        {"value": "74", "description": "Operação de Aquisição sem Incidência da Contribuição"},
        {"value": "75", "description": "Operação de Aquisição por Substituição Tributária"},
        {"value": "98", "description": "Outras Operações de Entrada"},
        {"value": "99", "description": "Outras Operações"}
      ],
      cofinsTributeOptions:[
        {"name": "Operação tributável: base de cálculo = valor da operação (alíquota normal – cumulativo/não cumulativo)", "value": "01"},
        {"name": "Operação tributável: base de cálculo = valor da operação (alíquota diferenciada)", "value": "02"},
        {"name": "Operação tributável: base de cálculo = quantidade vendida × alíquota por unidade de produto", "value": "03"},
        {"name": "Operação tributável: tributação monofásica (alíquota zero)", "value": "04"},
        {"name": "Operação tributável: substituição tributária", "value": "05"},
        {"name": "Operação tributável: alíquota zero", "value": "06"},
        {"name": "Operação isenta da contribuição", "value": "07"},
        {"name": "Operação sem incidência da contribuição", "value": "08"},
        {"name": "Operação com suspensão da contribuição", "value": "09"},
        {"name": "Outras operações de saída", "value": "49"},
        {"name": "Operação com direito a crédito: vinculada exclusivamente a receita tributada no mercado interno", "value": "50"},
        {"name": "Operação com direito a crédito: vinculada exclusivamente a receita não tributada no mercado interno", "value": "51"},
        {"name": "Operação com direito a crédito: vinculada exclusivamente a receita de exportação", "value": "52"},
        {"name": "Operação com direito a crédito: vinculada a receitas tributadas e não-tributadas no mercado interno", "value": "53"},
        {"name": "Operação com direito a crédito: vinculada a receitas tributadas no mercado interno e de exportação", "value": "54"},
        {"name": "Operação com direito a crédito: vinculada a receitas não-tributadas no mercado interno e de exportação", "value": "55"},
        {"name": "Operação com direito a crédito: vinculada a receitas tributadas e não-tributadas no mercado interno e de exportação", "value": "56"},
        {"name": "Crédito presumido: operação de aquisição vinculada exclusivamente a receita tributada no mercado interno", "value": "60"},
        {"name": "Crédito presumido: operação de aquisição vinculada exclusivamente a receita não-tributada no mercado interno", "value": "61"},
        {"name": "Crédito presumido: operação de aquisição vinculada exclusivamente a receita de exportação", "value": "62"},
        {"name": "Crédito presumido: operação de aquisição vinculada a receitas tributadas e não-tributadas no mercado interno", "value": "63"},
        {"name": "Crédito presumido: operação de aquisição vinculada a receitas tributadas no mercado interno e de exportação", "value": "64"},
        {"name": "Crédito presumido: operação de aquisição vinculada a receitas não-tributadas no mercado interno e de exportação", "value": "65"},
        {"name": "Crédito presumido: operação de aquisição vinculada a receitas tributadas e não-tributadas no mercado interno e de exportação", "value": "66"},
        {"name": "Crédito presumido: outras operações", "value": "67"},
        {"name": "Operação de aquisição sem direito a crédito", "value": "70"},
        {"name": "Operação de aquisição com isenção", "value": "71"},
        {"name": "Operação de aquisição com suspensão", "value": "72"},
        {"name": "Operação de aquisição a alíquota zero", "value": "73"},
        {"name": "Operação de aquisição sem incidência da contribuição", "value": "74"},
        {"name": "Operação de aquisição por substituição tributária", "value": "75"},
        {"name": "Outras operações de entrada", "value": "98"},
        {"name": "Outras operações", "value": "99"}
      ],
    }
  },
  created() {
    this.productCopy = Object.assign({}, this.product)
  },
  methods: {
    update: function () {
      this.$emit('update')
    },
    save() {
      this.$emit('save', this.productCopy)
    }

  },
  components: {
    VueEditor,
    SaveUpdateActionBar
  }
}
</script>

<style scoped>

</style>