import jsonwebtoken from 'jsonwebtoken';
import {createRouter, createWebHistory, createWebHashHistory} from 'vue-router';
// doesn't have default export, breaking change in 2.0
// Import components
import NotFound from './components/generic/NotFound.vue'
import Dashboard from "./components/dashboard/Dashboard.vue";

import Orders from "./components/orders/Orders.vue";


import Settings from './components/settings/Settings.vue'
import Printers from "./components/settings/Printers.vue";


import Login from './components/auth/Login.vue'
import Verify from './components/auth/Verify.vue'
import ForgotPassword from './components/auth/ForgotPassword.vue'
import ResetPassword from './components/auth/ResetPassword.vue'
import Products from "@/components/products/Products.vue";
import NewProduct from "@/components/products/NewProduct.vue";
import EditProduct from "@/components/products/EditProduct";
import Delivery from "@/components/delivery/Delivery.vue";
import NewDriver from "@/components/delivery/NewDriver.vue";
import DriverStats from "@/components/delivery/drivers/DriverStats.vue";
import PointOfSales from "@/components/pdv/PointOfSales.vue";
import DeliveryCredit from "@/components/deliveryCredit/deliveryCredit.vue";
import Pendencies from "@/components/pendencies/Pendencies.vue";
import PaymentComplete from "@/components/pdv/PaymentComplete.vue";
import SaleInitialize from "@/components/pdv/SaleInitialize.vue";
import {useAuthStore} from "./store/authStore";
import OrderHistoryWrapper from "@/components/orderHistory/OrderHistoryWrapper.vue";
import PlannerWrapper from "@/components/planner/PlannerWrapper.vue";
import EventWrapper from "@/components/planner/EventWrapper.vue";
import CashierWrapper from "@/components/cashier/CashierWrapper.vue";
import PrintWrapper from "@/components/printer/PrintWrapper.vue";
import UsersWrapper from "@/components/users/UsersWrapper.vue";
import NewUser from "@/components/users/NewUser.vue";
import {ref} from "vue";


// other imports remain the same

// define routes array
const routes = [
    {name: "Não Encontrado", path: '/notFound', component: NotFound},
    {name: "dashboard", path: '/', component: Dashboard, meta: {requiresSystemPoweruser: true}},
    //orders
    {name: "orders", path: '/orders', component: Orders, meta: {requiresEmployee: true}},
    {
        name: "ordersHistory",
        path: '/orders/history',
        component: OrderHistoryWrapper,
        meta: {requiresSystemPoweruser: true}
    },
    {name: "configuration", path: '/settings', component: Settings, meta: {requiresSystemPoweruser: true}},
    {name: "printers", path: '/settings/printers', component: Printers, meta: {requiresSystemPoweruser: true}},
    {name: "print", path: '/print', component: PrintWrapper, meta: {canvas: true}},

    //products
    {name: "products", path: '/products', component: Products, meta: {requiresSystemPoweruser: true}},
    {name: "newProduct", path: '/products/new', component: NewProduct, meta: {requiresSystemPoweruser: true}},
    {
        name: "editProduct",
        path: '/products/edit/:id',
        component: EditProduct,
        meta: {requiresSystemPoweruser: true}
    },

    //delivery
    {name: "delivery", path: '/delivery', component: Delivery, meta: {requiresSystemPoweruser: true}},
    {
        name: "newDriver",
        path: '/delivery/driver/new',
        component: NewDriver,
        meta: {requiresSystemPoweruser: true}
    },
    {
        name: "driverStats",
        path: '/delivery/driver/:id/stats',
        component: DriverStats,
        meta: {requiresSystemPoweruser: true}
    },


    // login
    {name: 'login', path: '/login', component: Login, props: true, meta: {noAuth: true}},
    {path: '/forgotPassword', component: ForgotPassword},
    {path: '/reset/:token', component: ResetPassword},
    {path: '/verify/:id/:token', component: Verify},

    {name: "addCredits", path: '/addCredits', component: DeliveryCredit},
    //pos
    {
        name: "cashier",
        path: '/cashier',
        component: CashierWrapper,
        meta: {requiresEmployee: true, requireTerms: true}

    },
    {
        name: "saleStarter",
        path: '/pos/start',
        component: SaleInitialize,
        meta: {requiresEmployee: true, requireTerms: true}
    },
    {
        name: "pos",
        path: '/pos',
        component: PointOfSales,
        meta: {requiresEmployee: true, requireTerms: true},
        props: true
    },
    {
        name: "paymentCompleted",
        path: "/paymentComplete",
        component: PaymentComplete,
        meta: {requiresEmployee: true, requireTerms: true},
        props: true
    },
    {
        name: "useTerms",
        path: "/terms",
        component: require('./components/terms/Terms.vue').default,
        meta: {requiresEmployee: true},
        props: true
    },
    {
        name: "planner",
        path: "/planner",
        component: PlannerWrapper,
        meta: {requiresSystemPoweruser: true},
    },
    {
        name: "event",
        path: "/event/:id",
        component: EventWrapper,
        meta: {requiresSystemPoweruser: true},
    },
    {
        name: "changePassword",
        path: "/changePassword",
        component: require('./components/auth/ChangePassword.vue').default,
        meta: {canvas: true,requiresEmployee: true},
    },
    {
        name: "onboarding", path: '/onboarding', component: require('./components/onboarding/OnBoardingWrapper.vue').default,
    },

    //pendencies
    {name: "pendency", path: '/pendencies', component: Pendencies, meta: {requiresSystemPoweruser: true}},

    //users
    {name: "users", path: '/users', component: UsersWrapper, meta: {requiresSystemPoweruser: true}},
    {name: "createUser", path: '/users/new', component: NewUser, meta: {requiresSystemPoweruser: true}},
    {name: "editUser", path: '/users/edit/:id', component: NewUser, meta: {requiresSystemPoweruser: true}},

    //my company
    {name: "myCompany", path: '/myCompany', component: require('./components/myCompany/MyCompany.vue').default, meta: {requiresSystemPoweruser: true}},
    {name: "myWarehouses", path: '/myCompany/deposits', component: require('./components/warehouses/MyWarehouses.vue').default, meta: {requiresSystemPoweruser: true}},
    {name: "warehouse", path: '/myCompany/deposit/:id', component: require('./components/warehouses/Warehouse.vue').default, meta: {requiresSystemPoweruser: true}},

    {name: "myFiles", path: '/myFiles/:folder?', component: require('./components/fileManager/FileManager.vue').default, meta: {requiresSystemPoweruser: true}},
    {name: "chainDelivery", path: '/chainDelivery', component: require('./components/chainDelivery/ChainDelivery.vue').default, meta: {requiresSystemPoweruser: true}},

    {name: "reports", path: '/reports', component: require('./components/reports/ReportsWrapper.vue').default, meta: {requiresSystemPoweruser: true}},

    {path: '/:pathMatch(.*)*', redirect: '/notFound'}

];

const routerHistory = process.env.IS_ELECTRON ? createWebHashHistory() : createWebHistory();

const router = createRouter({
    history: routerHistory,
    routes,
});

export const navigationHistory = ref([]);
router.beforeEach(async (to) => {


    addNavigationEntry(to);


    const auth = useAuthStore()
    let firstRoute = {path: '/login', query: {redirect: to.fullPath}}
    if (auth.user.authenticated) {
        const accessTier = auth.actualAccessRole()
        // first route with meta key with this access tier requiresSystemAdmin || requiresSystemPoweruser || requiresEmployee
        console.log(accessTier)
        let filteredRoutes = routes.filter((route) => {
            return route.meta && route.meta[accessTier]
        })
        console.log(filteredRoutes)
        console.log(filteredRoutes.length)


        firstRoute = filteredRoutes[0]
    }

    if (!to.matched.length) {
        return '/notFound';
    }

    if (to.matched.some(record => record.meta.requireTerms)) {
        if (!localStorage.getItem('terms')) {
            return {
                path: '/terms',
                query: {redirect: to.fullPath}
            }
        }
    }

    if (auth?.user?.JWToken) {
        let jwtDecoded = jsonwebtoken.decode(auth.user.JWToken);
        let jwtExpiration = jwtDecoded.exp;
        let currentTime = Date.now() / 1000;
        if (jwtExpiration < currentTime) {
            auth.clearAuthInfo();
            return {
                path: '/login',
                query: {redirect: to.fullPath}
            }
        }
    }

    if (to.matched.some(record => record.meta.auth)) {
        if (!auth.user.authenticated) {
            if (to.path !== '/login') {
                return {
                    path: '/login',
                    query: {redirect: to.fullPath}
                }
            }
        }
    }

    console.log(firstRoute)

    if (to.matched.some(record => record.meta.requiresSystemAdmin)) {
        console.log('requiresSystemAdminaaa')
        if (!auth.checkRole('systemRole', 'developer')) {
            return {
                path: firstRoute.path,
            }
        }
    }

    if (to.matched.some(record => record.meta.requiresSystemPoweruser)) {
        console.log('requiresSystemPoweruseraaa')
        if (!auth.checkRole('systemRole', 'COM')) {
            return {
                path: firstRoute.path,
            }
        }
    }
    if (to.matched.some(record => record.meta.requiresEmployee)) {
        console.log('requiresEmployeeaaa')
        if (!auth.checkRole('Employee', 'Employee')) {
            //find First Router with meta requiresEmployee
            return {
                path: firstRoute.path,
            }
        }
    }
    if (auth?.user?.JWToken) {
        let jwtDecoded = jsonwebtoken.decode(auth.user.JWToken);
        console.log(jwtDecoded)
        if (jwtDecoded.user.changePassword) {
            if (to.path !== '/changePassword') {
                return {
                    path: '/changePassword',
                    query: {redirect: to.fullPath}
                }
            }
        }
    }

    if (to.matched.some(record => record.meta.noAuth)) {
        if (auth.user.authenticated) {
            return {
                path: '/',
            }
        }
    }
});


function addNavigationEntry(to) {
    // Find if the entry already exists in the navigation history
    const existingIndex = navigationHistory.value.findIndex(entry => entry.path === to.path);

    // If the entry exists, remove it
    if (existingIndex !== -1) {
        navigationHistory.value.splice(existingIndex, 1);
    }

    // Push the new entry to the navigation history
    navigationHistory.value.push({ name: to.name, path: to.path });

    // Check if the navigation history exceeds the limit of 3
    if (navigationHistory.value.length > 3) {
        // Remove the oldest entry
        navigationHistory.value.shift();
    }
}


export default router;