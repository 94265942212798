<template>
  <div class="page-content">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button class="nav-link active" id="note-tab" data-bs-toggle="tab" data-bs-target="#note" type="button"
                role="tab" aria-controls="note" aria-selected="true">Impressora de bilhete
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="pos-tab" data-bs-toggle="tab" data-bs-target="#pos" type="button" role="tab"
                aria-controls="pos" aria-selected="false">Impressora Térmica
        </button>
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <normal-printer :available-printers="availablePrinters"/>
      <pos-printer :available-printers="availablePrinters"/>
    </div>
  </div>
</template>

<script>
import PosPrinter from "@/components/settings/PosPrinter";
import NormalPrinter from "@/components/settings/NormalPrinter";

export default {
  name: "PrintersComponent",
  components: { NormalPrinter, PosPrinter },
  data() {
    return {
      availablePrinters: [],
    }
  },
  methods: {
    printers: function() {
      this.axios.get('/settings/printers').then(response => {
        this.availablePrinters = response.data
      })
    }
  },
  mounted() {
    this.printers()
  }
}
</script>

