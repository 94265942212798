<template>
  <div  class=" col-md-4 bg-soft-light rounded-2 mt-3 " v-auto-animate>
    <div class="card">
      <div class="mb-2">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-10">
                <span class="text-uppercase bold fw-bold">{{$t('pos.channelSelector')}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="showPayment" v-auto-animate>
        <Form @submit="applyChannel" v-auto-animate>
          <div class="row">
            <div class="col-md-12" v-auto-animate>
              <Field v-model="channel" name="cupon" v-slot="{field}">
                <div class="form-group">
                  <label for="exampleDataList" class="form-label">{{ $t('pos.channels') }}</label>
                  <select class="form-control" v-bind="field"  @change="applyChannel">
                    <option value="">{{ $t('app.select') }}</option>
                    <option v-for="channel in channels" :value="channel.id" :key="channel.id">{{channel.channel}}</option>
                  </select>
                </div>
              </Field>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>

</template>
<script>
import {Form, Field} from 'vee-validate';
import {usePosStore} from "@/store/PosStore";
import {NotifyHandler} from "@/utils/NotifyHandler";
import {ErrorHandler} from "@/utils/ErrorHandler";

export default {
  name: 'ChanelSelector',
  components: {Form, Field},
  setup: () => ({posStore: usePosStore()}),
  props: {
    showProducts: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      showPayment: true,
      channels: [],
      channel: null,
    }
  },
  methods: {
    applyChannel() {
      this.posStore.setChannel(this.channel).then(() => {
        NotifyHandler.handle('Canal de venda aplicado com sucesso');
      }).catch((error) => {
        ErrorHandler.handle(error);
      });
    },
    fetchChannels() {
      this.axios.get('/pos/channels').then((response) => {
        this.channels = response.data;
        this.channel = this.channels.find((channel) => channel.channel === this.activeChannel)?.id
      }).catch((error) => {
        console.log(error);
      });
    }
  },
  computed: {
    activeChannel() {
      return this.posStore.getCart.channel;
    }
  },
  beforeMount() {
    this.fetchChannels();
  }
}
</script>
<style scoped>

.card {
  width: 100%;
  border-radius: 5px;
  padding: 20px;
}

.card .title {
  font-size: 22px;
  font-weight: 500;
}

.card .content {
  margin-top: 20px;
}

.card label.box {
  margin-top: 12px;
  padding: 10px 12px;
  display: flex;
  border-radius: 5px;
  border: 2px solid transparent;
  cursor: pointer;
  transition: all 0.25s ease;
}

</style>