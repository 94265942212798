<template>
  <div class="page-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 ">
          <!--         create filds for : name(string)-->
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">{{ $t('product.newProduct') }}</h4>
              <Form class="form-horizontal" @submit="saveNewProduct">
                <div class="col-md-12 d-flex justify-content-end">
                  <button class="btn btn-dark" type="submit">{{ $t('app.save') }}</button>
                </div>
                <div class="row ">
                  <div class="col-md-6">
                    <div class="form-group position-relative">
                      <Field name="name" v-model="name" rules="required" v-slot="{ errors, valid,field }">
                        <label for="name">{{ $t('product.name') }}</label>
                        <input type="text" class="form-control" id="name" v-bind="field"
                               placeholder="Nome do Produto" :class="{'is-invalid': errors[0],'is-valid':valid }">
                        <span v-if="errors" class="invalid-tooltip">{{ errors[0] }}</span>
                      </Field>
                    </div>
                    <div class="form-group position-relative">
                      <Field name="price" v-model="price" rules="required" v-slot="{ errors, valid,field }">
                        <label for="price">{{ $t('product.price') }}</label>
                        <input type="number" class="form-control" id="price" v-bind="field"
                               placeholder="Preço do Produto" :class="{'is-invalid': errors[0],'is-valid':valid }">
                        <span v-if="errors" class="invalid-tooltip">{{ errors[0] }}</span>
                      </Field>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="categories">{{ $t('product.categories') }}</label>
                      <VueMultiselect v-model="selectedCategories" track-by="name" :options="categories"
                                      :multiple="true"
                                      :placeholder="$t('app.pick')" label="name" :selectLabel="$t('app.clickToSelect')"
                                      :deselectLabel="$t('app.clickToDeselect')">
                      </VueMultiselect>

                    </div>
                    <!--                  catalog(select)-->
                    <div class="form-group">
                      <label for="catalog">{{ $t('product.catalog') }}</label>
                      <VueMultiselect v-model="selectedCatalogs" track-by="name" :options="catalogs" :multiple="false"
                                      :placeholder="$t('app.pick')" label="name" :selectLabel="$t('app.clickToSelect')"
                                      :deselectLabel="$t('app.clickToDeselect')">
                      </VueMultiselect>

                    </div>
                  </div>
                  <!--                    isVirtual(boolean)-->

                  <div class="form-group col-3 d-flex gap-2 my-4">
                    <label for="isVirtual">{{ $t('product.isVirtual') }}</label>
                    <input type="checkbox" id="isVirtual" v-model="is_virtual" switch="none"/>
                    <label for="isVirtual" data-on-label="Sim" data-off-label="Não"></label>
                  </div>
                  <!--                  StockManagement(select)-->
                  <div class="form-group">
                    <label for="stockManagement">{{ $t('product.stockManagement') }}</label>
                    <VueMultiselect v-model="stock_management" track-by="name" :options="stockManagement"
                                    :multiple="false" label="name"
                                    :placeholder="$t('app.pick')" :selectLabel="$t('app.clickToSelect')"
                                    :deselectLabel="$t('app.clickToDeselect')">
                    </VueMultiselect>
                  </div>
                  <!--                    stock(number)-->
                  <div class="form-group position-relative">
                    <Field name="stock" rules="required" v-slot="{ errors, valid, field }" v-model="stock">
                      <label for="stock">{{ $t('product.stock') }}</label>
                      <input type="number" class="form-control" id="stock" v-bind="field"
                             placeholder="Estoque do Produto" :class="{'is-invalid': errors[0],'is-valid':valid }">
                      <span v-if="errors" class="invalid-tooltip">{{ errors[0] }}</span>
                    </Field>
                  </div>
                  <!--                  NCM(String)-->
                  <div class="form-group">
                    <Field name="Ncm" rules="required|min:8|max:8" v-slot="{ errors, valid, field }" v-model="ncm">
                      <label for="ncm">{{ $t('product.ncm') }}</label>
                      <input type="text" class="form-control" id="ncm" v-bind="field"
                             placeholder="NCM do Produto" @focusout="searchNcm"
                             :class="{'is-invalid': errors[0],'is-valid':valid }">
                      <span v-if="errors" class="invalid-tooltip">{{ errors[0] }}</span>
                      <div class="valid-tooltip" v-if="ncmCodes.length > 0">
                        <span v-html="ncm.nomeLongoNcm +' '" v-for="(ncm,index) in ncmCodes" :key="'ncm'+index"></span>
                      </div>
                      <div class="valid-tooltip" v-else>
                        <span>Nenhum NCM encontrado</span>
                      </div>
                    </Field>
                  </div>
                  <div class="form-group col-6">
                    <label for="icmsTributeSituations">{{ $t('product.icmsTributeSituations') }}</label>
                    <VueMultiselect v-model="icms_tribute_situations" track-by="name" :options="icmsTributeSituations"
                                    :multiple="false" label="name"
                                    :placeholder="$t('app.pick')" :selectLabel="$t('app.clickToSelect')"
                                    :deselectLabel="$t('app.clickToDeselect')">
                    </VueMultiselect>
                  </div>
                  <div class="form-group col-6">
                    <label for="icmsTributeSituations">{{ $t('product.icmsOrigem') }}</label>
                    <VueMultiselect v-model="icms_origem" track-by="description" :options="icmsOrigemOptions"
                                    :multiple="false" label="description"
                                    :placeholder="$t('app.pick')" :selectLabel="$t('app.clickToSelect')"
                                    :deselectLabel="$t('app.clickToDeselect')">
                    </VueMultiselect>
                  </div>
                  <div class="form-group col-6">
                    <label for="icmsTributeSituations">{{ $t('product.pisTributeSituations') }}</label>
                    <VueMultiselect v-model="pis_tribute_situations" track-by="description" :options="pisTributeOptions"
                                    :multiple="false" label="description"
                                    :placeholder="$t('app.pick')" :selectLabel="$t('app.clickToSelect')"
                                    :deselectLabel="$t('app.clickToDeselect')">
                    </VueMultiselect>
                  </div>
                  <div class="form-group col-6">
                    <label for="icmsTributeSituations">{{ $t('product.cofinsTributeSituations') }}</label>
                    <VueMultiselect v-model="cofins_tribute_situations" track-by="name" :options="cofinsTributeOptions"
                                    :multiple="false" label="name"
                                    :placeholder="$t('app.pick')" :selectLabel="$t('app.clickToSelect')"
                                    :deselectLabel="$t('app.clickToDeselect')">
                    </VueMultiselect>
                  </div>
                </div>

              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import VueMultiselect from "vue-multiselect";
import {Form, Field} from 'vee-validate';


export default {
  name: "NewProduct",
  data() {
    return {
      ncmCodes: [],
      name: "",
      price: "",
      selectedCatalogs: [],
      selectedCategories: [],
      icms_tribute_situations: "",
      icms_origem: "",
      pis_tribute_situations: "",
      cofins_tribute_situations: "",
      categories: [],
      catalogs: [],
      is_virtual: false,
      stock_management: "",
      stock: "",
      ncm: "",
      icmsTributeSituations: [
        {"name": "Tributada integralmente", "value": "00"},
        {"name": "Tributada com cobrança do ICMS por substituição tributária", "value": "10"},
        {"name": "Com redução de base de cálculo", "value": "20"},
        {"name": "Isenta ou não tributada e com cobrança do ICMS por substituição tributária", "value": "30"},
        {"name": "Isenta", "value": "40"},
        {"name": "Não tributada", "value": "41"},
        {"name": "Outras", "value": "49"},
        {"name": "Suspensão", "value": "50"},
        {"name": "Diferimento", "value": "51"},
        {"name": "ICMS cobrado anteriormente por substituição tributária", "value": "60"},
        {"name": "Com redução de base de cálculo e cobrança do ICMS por substituição tributária", "value": "70"},
        {"name": "Outras", "value": "90"},
        {"name": "Tributada pelo Simples Nacional com permissão de crédito", "value": "101"},
        {"name": "Tributada pelo Simples Nacional sem permissão de crédito", "value": "102"},
        {"name": "Isenção do ICMS no Simples Nacional para faixa de receita bruta", "value": "103"},
        {
          "name": "Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por substituição tributária",
          "value": "201"
        },
        {
          "name": "Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por substituição tributária",
          "value": "202"
        },
        {
          "name": "Isenção do ICMS no Simples Nacional para faixa de receita bruta e com cobrança do ICMS por substituição tributária",
          "value": "203"
        },
        {"name": "Imune", "value": "300"},
        {"name": "Não tributada pelo Simples Nacional", "value": "400"},
        {
          "name": "ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação",
          "value": "500"
        },
        {"name": "Outros", "value": "900"}
      ],
      icmsOrigemOptions: [
        {"value": "0", "description": "Nacional"},
        {"value": "1", "description": "Estrangeira - Importação direta"},
        {"value": "2", "description": "Estrangeira - Adquirida no mercado interno"},
        {"value": "3", "description": "Nacional com mais de 40% de conteúdo estrangeiro"},
        {"value": "4", "description": "Nacional produzida através de processos produtivos básicos"},
        {"value": "5", "description": "Nacional com menos de 40% de conteúdo estrangeiro"},
        {
          "value": "6",
          "description": "Estrangeira - Importação direta, sem similar nacional, constante em lista da CAMEX"
        },
        {
          "value": "7",
          "description": "Estrangeira - Adquirida no mercado interno, sem similar nacional, constante em lista da CAMEX"
        },
        {"value": "8", "description": "Nacional, mercadoria ou bem com Conteúdo de Importação superior a 70%"},
        {
          "value": "9",
          "description": "Estrangeira - Importação direta, sem similar nacional, constante em lista da CAMEX"
        }
      ],
      pisTributeOptions: [
        {"value": "01", "description": "Operação Tributável com Alíquota Básica"},
        {"value": "02", "description": "Operação Tributável com Alíquota Diferenciada"},
        {"value": "03", "description": "Operação Tributável com Alíquota por Unidade de Medida de Produto"},
        {"value": "04", "description": "Operação Tributável Monofásica - Revenda a Alíquota Zero"},
        {"value": "05", "description": "Operação Tributável por Substituição Tributária"},
        {"value": "06", "description": "Operação Tributável a Alíquota Zero"},
        {"value": "07", "description": "Operação Isenta da Contribuição"},
        {"value": "08", "description": "Operação sem Incidência da Contribuição"},
        {"value": "09", "description": "Operação com Suspensão da Contribuição"},
        {"value": "49", "description": "Outras Operações de Saída"},
        {
          "value": "50",
          "description": "Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Tributada no Mercado Interno"
        },
        {
          "value": "51",
          "description": "Operação com Direito a Crédito – Vinculada Exclusivamente a Receita Não Tributada no Mercado Interno"
        },
        {
          "value": "52",
          "description": "Operação com Direito a Crédito - Vinculada Exclusivamente a Receita de Exportação"
        },
        {
          "value": "53",
          "description": "Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno"
        },
        {
          "value": "54",
          "description": "Operação com Direito a Crédito - Vinculada a Receitas Tributadas no Mercado Interno e de Exportação"
        },
        {
          "value": "55",
          "description": "Operação com Direito a Crédito - Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação"
        },
        {
          "value": "56",
          "description": "Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno e de Exportação"
        },
        {
          "value": "60",
          "description": "Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Tributada no Mercado Interno"
        },
        {
          "value": "61",
          "description": "Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno"
        },
        {
          "value": "62",
          "description": "Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita de Exportação"
        },
        {
          "value": "63",
          "description": "Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno"
        },
        {
          "value": "64",
          "description": "Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas no Mercado Interno e de Exportação"
        },
        {
          "value": "65",
          "description": "Crédito Presumido - Operação de Aquisição Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação"
        },
        {
          "value": "66",
          "description": "Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno e de Exportação"
        },
        {"value": "67", "description": "Crédito Presumido - Outras Operações"},
        {"value": "70", "description": "Operação de Aquisição sem Direito a Crédito"},
        {"value": "71", "description": "Operação de Aquisição com Isenção"},
        {"value": "72", "description": "Operação de Aquisição com Suspensão"},
        {"value": "73", "description": "Operação de Aquisição a Alíquota Zero"},
        {"value": "74", "description": "Operação de Aquisição sem Incidência da Contribuição"},
        {"value": "75", "description": "Operação de Aquisição por Substituição Tributária"},
        {"value": "98", "description": "Outras Operações de Entrada"},
        {"value": "99", "description": "Outras Operações"}
      ],
      cofinsTributeOptions: [
        {
          "name": "Operação tributável: base de cálculo = valor da operação (alíquota normal – cumulativo/não cumulativo)",
          "value": "01"
        },
        {"name": "Operação tributável: base de cálculo = valor da operação (alíquota diferenciada)", "value": "02"},
        {
          "name": "Operação tributável: base de cálculo = quantidade vendida × alíquota por unidade de produto",
          "value": "03"
        },
        {"name": "Operação tributável: tributação monofásica (alíquota zero)", "value": "04"},
        {"name": "Operação tributável: substituição tributária", "value": "05"},
        {"name": "Operação tributável: alíquota zero", "value": "06"},
        {"name": "Operação isenta da contribuição", "value": "07"},
        {"name": "Operação sem incidência da contribuição", "value": "08"},
        {"name": "Operação com suspensão da contribuição", "value": "09"},
        {"name": "Outras operações de saída", "value": "49"},
        {
          "name": "Operação com direito a crédito: vinculada exclusivamente a receita tributada no mercado interno",
          "value": "50"
        },
        {
          "name": "Operação com direito a crédito: vinculada exclusivamente a receita não tributada no mercado interno",
          "value": "51"
        },
        {"name": "Operação com direito a crédito: vinculada exclusivamente a receita de exportação", "value": "52"},
        {
          "name": "Operação com direito a crédito: vinculada a receitas tributadas e não-tributadas no mercado interno",
          "value": "53"
        },
        {
          "name": "Operação com direito a crédito: vinculada a receitas tributadas no mercado interno e de exportação",
          "value": "54"
        },
        {
          "name": "Operação com direito a crédito: vinculada a receitas não-tributadas no mercado interno e de exportação",
          "value": "55"
        },
        {
          "name": "Operação com direito a crédito: vinculada a receitas tributadas e não-tributadas no mercado interno e de exportação",
          "value": "56"
        },
        {
          "name": "Crédito presumido: operação de aquisição vinculada exclusivamente a receita tributada no mercado interno",
          "value": "60"
        },
        {
          "name": "Crédito presumido: operação de aquisição vinculada exclusivamente a receita não-tributada no mercado interno",
          "value": "61"
        },
        {
          "name": "Crédito presumido: operação de aquisição vinculada exclusivamente a receita de exportação",
          "value": "62"
        },
        {
          "name": "Crédito presumido: operação de aquisição vinculada a receitas tributadas e não-tributadas no mercado interno",
          "value": "63"
        },
        {
          "name": "Crédito presumido: operação de aquisição vinculada a receitas tributadas no mercado interno e de exportação",
          "value": "64"
        },
        {
          "name": "Crédito presumido: operação de aquisição vinculada a receitas não-tributadas no mercado interno e de exportação",
          "value": "65"
        },
        {
          "name": "Crédito presumido: operação de aquisição vinculada a receitas tributadas e não-tributadas no mercado interno e de exportação",
          "value": "66"
        },
        {"name": "Crédito presumido: outras operações", "value": "67"},
        {"name": "Operação de aquisição sem direito a crédito", "value": "70"},
        {"name": "Operação de aquisição com isenção", "value": "71"},
        {"name": "Operação de aquisição com suspensão", "value": "72"},
        {"name": "Operação de aquisição a alíquota zero", "value": "73"},
        {"name": "Operação de aquisição sem incidência da contribuição", "value": "74"},
        {"name": "Operação de aquisição por substituição tributária", "value": "75"},
        {"name": "Outras operações de entrada", "value": "98"},
        {"name": "Outras operações", "value": "99"}
      ],
      stockManagement: [
        {name: "Simples", value: "single"}
      ]
    };
  },
  methods: {
    saveNewProduct() {
      this.axios.post('product/create', {
        name: this.name,
        price: this.price,
        categories: this.selectedCategories,
        catalogs: this.selectedCatalogs,
        is_virtual: this.is_virtual,
        icms_tribute_situations: this.icms_tribute_situations.value,
        icms_origem: this.icms_origem.value,
        pis_tribute_situations: this.pis_tribute_situations.value,
        cofins_tribute_situations: this.cofins_tribute_situations.value,
        stock_management: this.stock_management.value,
        stock: this.stock,
        ncm: this.ncm
      }).then(response => {
        this.$notify('Produto criado com sucesso');
        console.log(response);
        this.$router.push({name: 'editProduct', params: {id: response.data.product.id}});
      })
    },
    getCategories() {
      this.axios.get('/categories').then(response => {
        console.log(response);
        //map id as key
        this.categories = response.data;

        if (this.product && this.product.categories.length > 0) {
          //get only name and id from categories
          this.selectedCategories = this.product.categories.map((category) => {
            return {id: category.id, name: category.name}
          })
        }
      })
    },
    getCatalogs() {
      this.axios.get('/catalogs').then(response => {
        console.log(response);
        //map id as key
        this.catalogs = response.data;

        if (this.product && this.product.catalogs.length > 0) {
          //get only name and id from categories
          this.selectedCatalog = this.product.catalogs.map((catalog) => {
            return {id: catalog.id, name: catalog.name}
          })
        }
      })
    },
    searchNcm() {
      this.axios.get('/product/ncm/' + this.ncm).then(response => {
        console.log(response.data)
        // get first 3 results
        this.ncmCodes = response.data.slice(0, 3)
      }).catch(() => {
        this.$notify('erro ao buscar ncm')
      })
    }
  },
  mounted() {
    this.getCategories();
    this.getCatalogs();

  },
  components: {
    VueMultiselect,
    Form,
    Field
  },

}
</script>

<style scoped>
input {
  padding: 10px !important;
}
</style>