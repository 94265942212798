import { defineStore } from 'pinia';

export const useFormGeneratorStore = defineStore('formGenerator', {
    state: () => ({
        forms: []
    }),
    actions: {
        registerForm(formGeneratorInstance) {
            this.forms.push(formGeneratorInstance);
        },
        unregisterForm(formGeneratorInstance) {
            const index = this.forms.indexOf(formGeneratorInstance);
            if (index > -1) {
                this.forms.splice(index, 1);
            }
        }
    }
});