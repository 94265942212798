<template lang="html">
  <div class="account-bg">
    <Transition>
      <div id="preloader" v-if="this.isLoading">
        <div id="status">
          <div class="spinner"></div>
        </div>
      </div>
    </Transition>
    <notifications position="bottom right"/>
    <error-box :error.sync="error"/>
    <div class="account-pages my-5 pt-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card">
              <div class="card-body">
                <div class="text-center mt-4">
                  <div class="mb-3">
                    <router-link :to="{name:'dashboard'}" class="auth-logo">
                      <img src="@/assets/images/logo-sl.png" height="100" class="logo-dark mx-auto" alt="">
                      <img src="@/assets/images/logo-sl.png" height="100" class="logo-light mx-auto" alt="">
                    </router-link>
                  </div>
                </div>
                <div class="p-3">
                  <h4 class="font-size-18 text-muted mt-2 text-center">Alterar Senha</h4>
                  <p class="text-muted text-center mb-4">Insira as informações abaixo para alterar sua senha.</p>
                  <Form @submit="changeUserPassword" class="form-horizontal">
                    <div class="mb-3">
                      <Field name="currentPassword" v-model="currentPassword" rules="required"
                             v-slot="{ errors, field }">
                        <label class="form-label" for="currentPassword">Senha Atual</label>
                        <input v-bind="field" class="form-control" type="password" placeholder="Senha Atual"
                               :class="{'is-danger': errors[0] }"/>
                        <span v-if="errors" class="help is-danger">{{ errors[0] }}</span>
                      </Field>
                    </div>

                    <div class="mb-3">
                      <Field name="newPassword" v-model="newPassword" rules="required" v-slot="{ errors, field }">
                        <label class="form-label" for="newPassword">Nova Senha</label>
                        <input v-bind="field" class="form-control" type="password" placeholder="Nova Senha"
                               :class="{'is-danger': errors[0] }"/>
                        <span v-if="errors" class="help is-danger">{{ errors[0] }}</span>
                      </Field>
                    </div>

                    <div class="mb-3">
                      <Field name="confirmPassword" v-model="confirmPassword" rules="required"
                             v-slot="{ errors, field }">
                        <label class="form-label" for="confirmPassword">Confirme a Nova Senha</label>
                        <input v-bind="field" class="form-control" type="password" placeholder="Confirme a Nova Senha"
                               :class="{'is-danger': errors[0] }"/>
                        <span v-if="errors" class="help is-danger">{{ errors[0] }}</span>
                      </Field>
                    </div>

                    <div class="mb-3 row mt-4">
                      <div class="col-sm-6 text-start">
                        <router-link :to="{name:'dashboard'}" class="btn btn-danger w-md " v-wave
                                :class="{ 'is-loading': isLoading }">Cancelar
                        </router-link>
                      </div>
                      <div class="col-sm-6 text-end">
                        <button type="submit" class="btn btn-primary w-md " v-wave
                                :class="{ 'is-loading': isLoading }">Alterar Senha
                        </button>
                      </div>
                    </div>
                  </form>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import ErrorBox from '../generic/ErrorBox.vue';
import {Form, Field, defineRule} from 'vee-validate';
import {required} from '@vee-validate/rules';
import {useAuthStore} from "@/store/authStore";
import {NotifyHandler} from "@/utils/NotifyHandler";

defineRule('required', required);

export default {
  name: 'ChangePasswordComponent',
  setup() {
    const authStore = useAuthStore();
    return {authStore}
  },

  data() {
    return {
      error: null,
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      isLoading: false
    };
  },

  methods: {
    async changeUserPassword() {
      try {
        this.isLoading = true;
        await this.authStore.changePassword({
          password: this.currentPassword,
          newPassword: this.newPassword,
          confirmPassword: this.confirmPassword
        });
        NotifyHandler.handle('success', this.$t('app.auth.passwordChanged'));
        await this.authStore.logout();
      } catch (e) {
        this.error = e;
        this.isLoading = false;
      }
    },
  },

  components: {
    'error-box': ErrorBox,
    Form,
    Field
  },

};
</script>
