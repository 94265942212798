<template>
    <input type="text" class="form-control bg-white p-2"
           :placeholder="`Pesquisar ${header}...`" v-model="searchString">

</template>
<script>
export default {
  name: 'posHeader',
  props: {
    fixed: {
      type: Boolean,
      default: true,
      required: true
    },
    header: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      searchString: ''
    }
  },
  methods: {
    toggleFixed() {
      this.$emit('toggle-fixed')
    }
  },
  watch: {
    searchString() {
      this.$emit('searchHandler', this.searchString)
    }
  }
}
</script>
