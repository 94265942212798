<template>
  <div class="page-content">
    <div class="container-fluid">
      <div class="card">
        <div class="card-body" v-if="!loading && !finished">
          <form-wizard color="#2f8ee0" @on-complete="submitForm">
            <tab-content :title="$t('app.selectReport')" :before-change="() => this.selectReport()">
              <form-generator :form-schema="reportFields" :no-button="true" ref="reportSelector"/>
            </tab-content>
            <tab-content :title="$t('app.fillRequiredFields')" :lazy="true">
              <FormGenerator :form-schema="getReportFields()" :no-button="true" ref="reportParams"/>
            </tab-content>
          </form-wizard>
        </div>
        <div class="card-body" v-if="loading">
          <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
          <div v-if="!loading && finished">
            <div class="vh-100 d-flex justify-content-center align-items-center">
              <div class="card col-md-12 bg-white shadow-md p-5">
                <div class="mb-4 text-center">
                  <svg xmlns="http://www.w3.org/2000/svg" class="text-success bi bi-check-circle" width="75" height="75"
                       fill="currentColor"  viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path
                        d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                  </svg>
                </div>
                <div class="text-center col-12">
                  <h1>{{$t('app.reportGenerated')}}</h1>
                   <p>{{$t('app.reportGeneratedMessage')}}</p>
                  <button class="btn btn-success" @click="finished = false">{{$t('app.generateAnotherReport')}}</button>
                </div>
              </div>
          </div>

      </div>

    </div>
  </div>
  </div>
</template>

<script>
import {FormWizard, TabContent} from 'vue3-form-wizard'
import 'vue3-form-wizard/dist/style.css'
import {useReportStore} from "@/store/reportStore";
import FormGenerator from "@/components/helper/formGenerator/FormGenerator.vue";

export default {
  name: "ReportsWrapper",
  components: {FormGenerator, FormWizard, TabContent},
  setup() {
    const reportStore = useReportStore()
    return {
      reportStore
    }
  },
  data() {
    return {
      loading: true,
      finished: false,
      reportFields: [
        {
          name: "selectReportField",
          label: this.$t('app.selectReport'),
          type: "select",
          options: this.getOptions
        }
      ]
    }
  },
  methods: {
    async submitForm() {
      let form = this.$refs.reportParams
      let result = await form.validate()
      if (result.valid === false) {
        return;
      }
      let report = this.reportStore.getSelectedReport
      this.reportStore.generateReport(report.id, form.form).then(() => {
        this.$notify('relatorio enviado para fila de processamento')
        this.finished = true
      }).catch(() => {
        this.$notify('Erro ao gerar relatório')
      })
    },
    getReportFields() {
      let report = this.reportStore.getSelectedReport
      console.log(report)
      if (report) {
        return report.fields
      }
      return []
    },
    selectReport() {
      let form = this.$refs.reportSelector.form
      if (form.selectReportField == null) {
        this.$notify('Selecione um relatório')
        return false
      }
      this.reportStore.setSelectedReport(form.selectReportField.id)
      return true
    },
    getOptions() {
      let reports = Object.values(this.reportStore.getReportsAvailable) || []
      return reports.map(report => {
        return {
          id: report.id,
          name: this.$t(report.id)
        }
      })
    }
  },
  mounted() {
    this.loading = true
    this.reportStore.fetchReports().finally(() => {
      this.loading = false
    })
  }

}
</script>

<style scoped lang="scss">


</style>