<template>
  <div v-auto-animate>

    <div class="card">
      <div class="card-header bg-white">
        <div class="row">
          <div class="col-md-6">
            <div class="title text-center">
              <h3>{{ delivery.order.order_number }}</h3>
              <h5>{{ delivery.code }}</h5>
            </div>
            <div class="row gap-2 justify-content-center p-1 mt-3 rounded-4">
              <div class="col-auto badge bg-primary p-2"
                   :style="'background-color:' +this.delivery.order_state.color+'!important'">
                {{ this.delivery.order_state.name }}
              </div>
              <div class="col-auto badge  p-2"
                   :class="{'bg-success':checkPunctuality() ==='Adiantado','bg-danger': checkPunctuality() === 'Atrasado','bg-primary':checkPunctuality() === 'No Prazo' } ">
                {{ checkPunctuality() }}
              </div>
              <div class=" col-auto badge bg-secondary  p-2">{{ this.delivery.period.name }}</div>
              <div class=" col-auto badge bg-info  p-2">{{ this.delivery.provider }}</div>
              <div class=" col-auto badge bg-info  p-2" v-if="this.delivery.buyed_shipping">Ticket Disponivel</div>
              <div class=" col-auto badge bg-primary  p-2">{{ this.delivery?.warehouse?.name }}</div>
              <div class="col-auto" v-if="this.delivery.national">
                <NationalSvg/>
              </div>
            </div>
          </div>
          <div class=" col-md-6 mt-3">
            <div class="d-flex gap-3 justify-content-center">
              <button class="btn btn-outline-primary btn-lg" v-wave @click.prevent="showInfo = !showInfo">
                <i class="fa fa-arrow-down " v-if="!showInfo"></i>
                <i class="fa fa-arrow-up " v-if="showInfo"></i>
              </button>
              <button class="btn btn-outline-primary btn-lg" @click="changeDateDelivery" v-wave>
                <i class="fa fa-calendar "></i>
              </button>
              <button class="btn btn-outline-primary btn-lg" @click="changeAddress" v-wave>
                <i class="fa fa-truck"></i>
              </button>
              <button class="btn btn-outline-primary btn-lg" @click="changePeriodDelivery" v-wave>
                <i class="fa fa-clock"></i>
              </button>
              <button class="btn btn-outline-primary btn-lg" v-if="driverInfo" @click="seeDriverInfo" v-wave>
                <i class="fa-solid fa-id-card"></i>
              </button>
            </div>
            <div class="col-md-12 mt-3" v-if="this.buttonDefinitions != null">
              <div class="row g-2">
                <div class=" col-md-6 " aria-label="Vertical button group">
                  <button type="button" @click="printInformation"
                          class=" w-100 btn btn-primary" v-wave>
                    Imprimir
                  </button>
                </div>
                <div class="col-md-6" v-if="this.checkPrepare === true" role="group"
                     aria-label="Vertical button group">
                  <button type="button" @click="putInPrepare"
                          class=" w-100 btn btn-success " v-wave>
                    Preparar
                  </button>
                </div>
                <div class="col-md-6" v-if="this.checkTransport === true" role="group"
                     aria-label="Vertical button group">
                  <button type="button" @click="putInReady"
                          class="btn btn-secondary  w-100 " v-wave>Pronto
                  </button>
                </div>
                <div class="col-md-6" v-if="this.checkWaitPost === true" role="group"
                     aria-label="Vertical button group">
                  <button type="button" @click="putInPost"
                          class="btn btn-info  w-100" v-wave>Postado
                  </button>
                </div>
                <div class="col-md-6" v-if="this.checkCompleted === true" role="group"
                     aria-label="Vertical button group">
                  <button type="button" @click="putInCompleted"
                          class="btn btn-success  w-100" v-wave>Completar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showInfo" v-auto-animate>
      <div class="row g-2">
        <div class="col-md-4 flex gap-2">
          <div class="card m-auto rounded-5">
            <CountComponent :date-init="dateInit"
                            :date-end="dateEnd" class="m-auto p-2"></CountComponent>
          </div>
        </div>
        <div class="col-md-8">
          <div class="card rounded-5">
            <Products @error="errorFunction" :data="this.delivery.delivery_items"/>
          </div>
        </div>
        <div class="col-md-12 mt-3">

          <div class="card rounded-5">
            <CustomerInfo @error="errorFunction" :data="this.delivery"/>
          </div>
        </div>

        <div class="col-md-12 mt-3">
          <NfeImportForm @error="errorFunction" @refresh="refresh" @close="nfeImportForm = false"
                         :delivery_id="this.delivery.id"
                         :nfe-import-form="nfeImportForm"/>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import Products from "./Products.vue";
import NfeImportForm from "./NfeImportForm.vue";
import NationalSvg from "./NationalSvg.vue";
import CustomerInfo from "./CustomerInfo.vue";
import CountComponent from "./CountComponent.vue";
import {usePrintPageDetailsStore} from "@/store/printPageDetailsStore";

export default {
  components: {CountComponent, NationalSvg, NfeImportForm, Products, CustomerInfo},
  props: ["data", "buttonDefinitions", "exhibition"],
  name: 'orderComponent',
  setup() {
    const printPageDetailStore = usePrintPageDetailsStore()
    return {printPageDetailStore}
  },
  data() {
    return {
      showInfo: false,
      error: null,
      delivery: this.data,
      nfeImportForm: false,
      printOptions: []
    }
  },
  computed: {
    badges: function () {
      // first badge checkPunctuality()
      let badges = []
      badges.push({
        name: this.checkPunctuality(),
        color: this.checkPunctuality() === 'Adiantado' ? 'success' : this.checkPunctuality() === 'Atrasado' ? 'danger' : 'primary'
      })

      // this.delivery.period.name

      badges.push({
        name: this.delivery.period.name,
        color: 'secondary'
      })

      // this.delivery.provider

      badges.push({
        name: this.delivery.provider,
        color: 'info'
      })

      // this.delivery.buyed_shipping

      if (this.delivery.buyed_shipping) {
        badges.push({
          name: 'Ticket Disponivel',
          color: 'info'
        })
      }

      // this.delivery.warehouse.name

      badges.push({
        name: this.delivery.warehouse.name,
        color: 'primary'
      })


      // this.delivery.national

      if (this.delivery.national) {
        badges.push({
          name: 'Nacional',
          color: 'primary'
        })
      }

      return badges
    },
    dateInit: function () {
      if (this.delivery.preparation_start_time === null) {
        return null
      }
      return (new Date(this.delivery.preparation_start_time)).getTime() / 1000
    },
    dateEnd: function () {
      if (this.delivery.preparation_end_time === null) {
        //return now dat
        if (this.exhibition) {
          return null
        } else {
          return null
        }
      }
      return (new Date(this.delivery.preparation_end_time)).getTime() / 1000
    },
    checkPrepare: function () {
      if (this.buttonDefinitions != null) {
        // get all ids states in buttondefinitions.allowed_to_prepare
        let allowed_to_prepare = this.buttonDefinitions.allowed_to_prepare.map(function (item) {
          return item.id
        })
        // check if current state is in allowed_to_prepare

        return allowed_to_prepare.includes(this.delivery.order_state_id)
      } else {
        return false
      }
    },
    checkTransport: function () {
      if (this.buttonDefinitions != null) {
        // get all ids states in buttondefinitions.allowed_to_prepare
        let allowed_to_transport = this.buttonDefinitions.allowed_to_call_transport.map(function (item) {
          return item.id
        })
        // check if current state is in allowed_to_prepare
        return allowed_to_transport.includes(this.delivery.order_state_id)
      } else {
        return false
      }
    },
    checkWaitPost: function () {
      if (this.buttonDefinitions != null) {
        // get all ids states in buttondefinitions.allowed_to_prepare
        let allowed_to_wait_post = this.buttonDefinitions.waiting_for_post.map(function (item) {
          return item
        })
        let provider_allowed_to_wait_post = this.buttonDefinitions.providers_needs_action_post_transport.map(function (item) {
          return item.code
        })
        // check if current state is in allowed_to_prepare
        return allowed_to_wait_post.includes(this.delivery.order_state_id) && provider_allowed_to_wait_post.includes(this.delivery.provider)
      } else {
        return false
      }

    },
    checkCompleted: function () {
      if (this.buttonDefinitions != null) {
        // get all ids states in buttondefinitions.allowed_to_prepare
        let allowed_to_completed = this.buttonDefinitions.allowed_to_complete.map(function (item) {
          return item.id
        })
        // check if current state is in allowed_to_prepare
        return allowed_to_completed.includes(this.delivery.order_state_id)
      } else {
        return false
      }
    },
    driverInfo: function () {
      return this.delivery?.driver_info?.driver
    }
  },
  methods: {
    seeDriverInfo() {
      let driver = this.driverInfo
      this.$swal({
        title: driver.name,
        html: `
          <div style="text-align: left;">
            <p><strong>Driver ID:</strong> ${driver.driver_id}</p>
            <p><strong>Phone:</strong> ${driver.phone}</p>
            <p><strong>Plate Number:</strong> ${driver.plate_number}</p>
            <p><strong>Deliveries:</strong> ${driver.deliveries}</p>
          </div>
        `,
        imageUrl: driver.photo,
        imageWidth: 100,
        imageHeight: 100,
        imageAlt: 'Driver Photo',
        confirmButtonText: 'Close'
      });
    },
    changePeriod(value) {
      this.axios.patch('/pos/set-delivery-period', {
        delivery_period_id: value,
        delivery_id: this.delivery.id
      }).finally(() => {
        this.refresh()
      })
    },
    async fetchAvailablePeriods() {
      return this.axios.get(`/pos/available-periods-to-delivery/${this.delivery.id}`);
    },
    async changePeriodDelivery() {
      let self = this;
      let periods = await this.fetchAvailablePeriods();

      let array = []
      Object.values(periods.data.periods).forEach(function(item) {
        let key = item.id;
        array[key] = item.name;
      });
      this.$swal({
        'title': "Alterar periodos",
        input: 'select',
        inputOptions: array,
        inputPlaceholder: 'required',
        showCancelButton: true,
        inputValidator: function (value) {
          return new Promise(function (resolve) {
            if (value !== '') {
              resolve();
            } else {
              resolve('You need to select a Tier');
            }
          });
        }
      }).then(function (result) {
        if (result.isConfirmed) {
          self.changePeriod(result.value)
        }
      })
    },
    changeAddress: function () {
      let self = this
      let initialAddress = {
        name: this.delivery.address.name,
        lines: this.delivery.address.lines,
        zip: this.delivery.address.zip,
        city: this.delivery.address.city,
        details: this.delivery.address.details,
        district: this.delivery.address.district,
        number: this.delivery.address.number
      }
      this.$swal({
        title: 'Alterar endereço de entrega',
        html: '<input id="swal-input1" class="swal2-input" placeholder="Nome" type="text" value="' + initialAddress.name + '">' +
            '<input id="swal-input2" class="swal2-input" placeholder="Endereço" type="text" value="' + initialAddress.lines + '">' +
            '<input id="swal-input3" class="swal2-input" placeholder="CEP"  type="text" value="' + initialAddress.zip + '">' +
            '<input id="swal-input4" class="swal2-input" placeholder="Cidade" type="text" value="' + initialAddress.city + '">' +
            '<input id="swal-input5" class="swal2-input" placeholder="Detalhes" type="text" value="' + initialAddress.details + '">' +
            '<input id="swal-input6" class="swal2-input" placeholder="Bairro" type="text" value="' + initialAddress.district + '">' +
            '<input id="swal-input7" class="swal2-input" placeholder="Número" type="text" value="' + initialAddress.number + '">',
        focusConfirm: false,
        preConfirm: () => {
          return [
            document.getElementById('swal-input1').value,
            document.getElementById('swal-input2').value,
            document.getElementById('swal-input3').value,
            document.getElementById('swal-input4').value,
            document.getElementById('swal-input5').value,
            document.getElementById('swal-input6').value,
            document.getElementById('swal-input7').value
          ]
        }
      }).then((result) => {
        if (result.value) {
          self.axios.put(`orders/change/${self.delivery.id}/changeAddress`, {
            name: result.value[0],
            lines: result.value[1],
            zip: result.value[2],
            city: result.value[3],
            details: result.value[4],
            district: result.value[5],
            number: result.value[6]
          }).then((response) => {
            let newAddress = response.data.address
            self.delivery.address = newAddress
            this.$swal({
              title: 'Sucesso',
              text: 'Endereço de entrega alterado com sucesso',
              icon: 'success',
              timer: 2000
            })
          }).catch(() => {
            this.$swal({
              title: 'Erro',
              text: 'Erro ao alterar endereço de entrega',
              icon: 'error',
              timer: 2000
            })
          })
        }

      })
    },
    changeDateDelivery: function () {
      let self = this
      this.$swal({
        title: 'Alterar data de entrega',
        html: '<input id="swal-input1" class="swal2-input" placeholder="Data de entrega" type="date">',
        focusConfirm: false,
        preConfirm: () => {
          return [
            document.getElementById('swal-input1').value
          ]
        }
      }).then((result) => {
        if (result.value) {
          self.axios.patch(`orders/change/${this.delivery.id}/changeDeliveryDate`, {
            date: result.value[0]
          }).then(() => {
            this.$swal({
              title: 'Sucesso',
              text: 'Data de entrega alterada com sucesso',
              icon: 'success',
              timer: 2000
            })
            this.refresh()
          }).catch(() => {
            this.$swal({
              title: 'Erro',
              text: 'Erro ao alterar data de entrega',
              icon: 'error',
              timer: 2000
            })
          })
        }
      })
    },
    checkPunctuality: function () {
      if (this.delivery.delivery_in > this.moment().format('YYYY-MM-DD')) {
        return 'Adiantado'
      } else if (this.delivery.delivery_in === this.moment().format('YYYY-MM-DD')) {
        return 'No Prazo'
      } else {
        return 'Atrasado'
      }
    },
    errorFunction: function (err) {
      this.$emit('error', err)
    },
    printInformation: function () {
      var self = this;
      this.axios.get(`/settings/printOptions/${this.delivery.id}`).then(response => {
        this.printOptions = response.data
        this.$swal.fire({
          title: 'Imprimir Informações',
          input: 'select',
          inputOptions: this.printOptions,
          inputPlaceholder: 'required',
          showCancelButton: true,
          inputValidator: function (value) {
            return new Promise(function (resolve) {
              if (value !== '') {
                resolve();
              } else {
                resolve('You need to select a Tier');
              }
            });
          }
        }).then(function (result) {

          if (result.isConfirmed) {
            self.axios.post(`/settings/printOptions/${self.delivery.id}`, {
                  option: result.value
                },
                {
                  responseType: result.value === 'notes' ? 'blob' : 'json'
                }
            ).then(response => {
              if (response.data.url) {
                self.$swal.fire({
                  title: 'Imprimir Informações',
                  html: `<iframe src="${response.data.url.url || response.data.url}" style="width: 100%; height: 500px"></iframe>`,
                  showCancelButton: true,
                  showConfirmButton: false,
                  cancelButtonText: 'Fechar',
                  cancelButtonColor: '#d33',
                  width: '100%',
                  height: '100%',
                  padding: '0',
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  allowEnterKey: false,
                  stopKeydownPropagation: false,
                  onOpen: () => {
                    document.querySelector('.swal2-container').style.overflow = 'hidden';
                  }
                })
              } else {
                if (response.data.print) {
                  //if statuscode is 201 then send request to print module
                  if (response.status === 201) {
                    console.log(response.data.print)
                    //make module to request
                    return;
                  }
                  self.printPageDetailStore.setPrintPageDetails(response.data.print)
                  const routeData = self.$router.resolve({name: 'print'});
                  window.open(routeData.href, '_blank');
                } else if (result.value === 'notes') {
                  // Create a new Blob object using the response data
                  const blob = new Blob([response.data]);

                  // Create a URL for the blob object
                  const url = window.URL.createObjectURL(blob);

                  // Create a temporary link element
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', 'print.pdf'); // Set the file name for download

                  // Append the link to the document and trigger the download
                  document.body.appendChild(link);
                  link.click();

                  // Clean up by removing the link and revoking the URL
                  document.body.removeChild(link);
                  window.URL.revokeObjectURL(url);
                }
                // check if a download file

                self.$swal.fire({
                  title: 'Imprimir Informações',
                  text: 'Informações enviadas para impressão',
                  icon: 'success',
                  confirmButtonText: 'Ok'
                })
              }
            }).catch((err) => {
              console.log(err)
              self.$swal.fire({
                title: 'Imprimir Informações',
                text: 'Erro ao enviar informações para impressão',
                icon: 'error',
                confirmButtonText: 'Ok'
              })
            })
          }
        });
      }).catch(error => {
        this.errorFunction(error)
      })

    },
    refresh: function () {
      this.$emit('refresh')
    },
    putInPrepare: function () {
      let button = event.target
      button.disabled = true
      this.axios.put(`/orders/${this.delivery.id}/prepare`).then(response => {
        this.delivery = response.data.delivery;
        button.disabled = false
      }).catch(error => {
        button.disabled = false
        //check if error have reponse.data.showImportForm
        if (error.response.data.showImportForm) {
          // show nfe import form
          this.nfeImportForm = true
        } else {
          this.errorFunction(error)
        }
        this.errorFunction(error.response.data.error)
      })
    },
    putInReady: function () {
      let button = event.target
      button.disabled = true
      this.axios.put(`/orders/${this.delivery.id}/ready`).then(response => {
        this.delivery = response.data.delivery;
        button.disabled = false
      }).catch(error => {
        button.disabled = false
        this.errorFunction(error.response.data.error)
      })
    },
    putInPost: function () {
      let button = event.target
      button.disabled = true
      this.axios.put(`/orders/${this.delivery.id}/post`).then(response => {
        this.delivery = response.data.delivery;
        button.disabled = false
      }).catch(error => {
        button.disabled = false
        this.errorFunction(error.response.data.error)
      })
    },
    putInCompleted: function () {
      let button = event.target
      button.disabled = true
      this.axios.put(`/orders/${this.delivery.id}/completed`).then(response => {
        this.delivery = response.data.delivery;
        button.disabled = false
      }).catch(error => {
        button.disabled = false
        this.errorFunction(error.response.data.error)
      })
    },
  },
}
</script>

//style scss
