<template>
  <div class="col-xl-4 col-sm-6" >
    <div class="card shadow-none border">
      <div class="card-body p-3">
        <div class="position-relative">
          <div class="dropdown">
            <a class="font-size-16 text-muted" role="button" data-bs-toggle="dropdown"
               aria-haspopup="true">
              <i class="mdi mdi-dots-horizontal"></i>
            </a>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" href="#" @click="folderDelete(folder)">{{$t('app.delete')}}</a></li>
            </ul>
          </div>
        </div>
        <div class="d-flex mt-3">
          <div class="overflow-hidden me-auto">
            <h5 class="font-size-15 text-truncate mb-1">
              <contenteditable tag="span" class="text-body" :contenteditable="editable" v-model="message" :no-nl="true" :no-html="true" @returned="enterPressed" />
            </h5>
            <p class="text-muted text-truncate mb-0">
              {{ $tc('app.filesInsideCount', [folder.filesInsideCount]) }}</p>
          </div>
          <div class="align-self-end ms-2">
            <p class="text-muted mb-0 font-size-13"><i class="mdi mdi-clock"></i>
              {{
              moment(folder.lastModified).diff(moment(), 'days') > -1 ? moment(folder.lastModified).fromNow() :
              moment(folder.lastModified).format('DD/MM/YYYY')
              }}
            </p>
          </div>
        </div>
        <div class="card-footer bg-transparent">
          <div class="d-block">
            <button class="btn btn-secondary w-100" @click="fetchFiles(folder.folderLocation)">{{$t('app.open')}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import contenteditable from 'vue-contenteditable';
import {useFileManagerStore} from "@/store/fileManangerStore";
export default {
  name: 'FolderComponent',
  data() {
    return {
      message: '',
      editable: true
    }
  },
  setup() {
    const fileMangerStore = useFileManagerStore()
    return {
      fileMangerStore
    }
  },
  components : {
    contenteditable
  },
  props: {
    folder: {}
  },
  beforeMount() {
    this.message = this.folder.name;
  },
  methods: {
    folderDelete(folder) {
      this.fileMangerStore.deleteFileOrFolder(folder).finally(() => {
        this.fetchFiles();
      })
    },
    enterPressed(event) {
      this.editable = false
      this.fileMangerStore.rename(this.folder, event).finally(() => {
        this.editable = true
        this.fetchFiles();
      })
    },
    fetchFiles(folder = null) {
      this.loading = true
      this.fileMangerStore.getFilesFetch(folder).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
<style scoped>

.card {
  margin-bottom: 24px;
  -webkit-box-shadow: 0 2px 3px #e4e8f0;
  box-shadow: 0 2px 3px #e4e8f0;
}

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #eff0f2;
  border-radius: 1rem;
}


a {
  text-decoration: none !important;
}
</style>