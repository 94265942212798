<template>
  <div class="card">
    <div class="card-body">
      <div class="mb-4 d-flex align-items-center">
        <img :src="'https://ui-avatars.com/api/?name='+driver.user.name+'+'+driver.user.surname" alt=""
             class="avatar-lg rounded-circle img-thumbnail">
        <div class="ms-3">
          <h5 class="font-size-16 mb-1"><a href="#"
                                           class="text-dark">{{ driver.user.name + ' ' + driver.user.surname }}</a>
          </h5>
          <p class="text-muted mb-0">{{ driver.user.email }}</p>
        </div>
      </div>
      <ul class="list-unstyled mb-0">
        <li><i class="ion ion-md-information-circle text-success me-2"></i>Livre</li>
      </ul>
    </div>

    <div class="btn-group" role="group">
      <button type="button" @click="showRepass= !showRepass" class="btn btn-outline-light text-dark"><i
          class="ion ion-ios-cog me-1"></i>
      </button>
      <router-link :to="{name:'driverStats', params:{id:driver.id}}" type="button" class="btn btn-outline-light text-dark"><i
          class="ion ion-ios-pie me-1"></i>
      </router-link>
    </div>
    <Form  @submit="save">

        <Field name="repasse" rules="required|min:0|max:100" v-slot="{ errors, valid,field }" v-model="repass" v-if="showRepass">
          <div class="input-group" >
            <input type="number" min="0" max="100" placeholder="repasse" v-bind="field" class="form-control"
                   :class="{'is-invalid': errors[0],'is-valid':valid }" >
            <button type="submit" class="btn btn-success">Salvar</button>

          </div>
          <span v-if="errors" class="invalid-tooltip">{{ errors[0] }}</span>
        </Field>
        <small class="p-2" v-if="showRepass"><i class="fa fa-info"></i>{{$t("drivers.transferPercentage")}}</small>
    </Form>
  </div>

</template>
<script>
import {Form, Field} from 'vee-validate'
export default {
  name: 'DriverCard',
  components: {
    Form,
    Field
  },
  props: {
    driver: {}
  },
  data() {
    return {
      showRepass: false,
      repass: this.driver.rate_to_pay
    }
  },
  methods: {
    save() {
      this.$emit('save', {id: this.driver.id, rate_to_pay: this.repass})
    }
  },

}
</script>
