<template>
  <div class="page-content">
    <div class="container-fluid">
      <Tabs v-if="product" :options="{ useUrlFragment: false }">
        <Tab :name="$t('product.tab.general')">
          <GeneralConfiguration v-if="product" :product="product" @save="save" @update="update"/>
        </Tab>
        <Tab :name="$t('product.tab.price')">
          <price-lines v-if="product" :product="product"/>
        </Tab>
        <Tab :name="$t('product.tab.description')">
          <descriptions v-if="product" :product="product" @save="save" @update="update"/>
        </Tab>
        <Tab :name="$t('product.tab.details')">
          <product-details v-if="product" :product="product" @save="save" @update="update"/>
        </Tab>
        <Tab :name="$t('product.tab.attachment')">
          <attachament v-if="product" :product="product" @save="save" @update="update"/>
        </Tab>
        <Tab :name="$t('product.tab.delivery')">
          <delivery v-if="product" :product="product" @save="save" @update="update"/>
        </Tab>
        <Tab :name="$t('product.tab.cart')">
          <cart v-if="product" :product="product" @save="save" @update="update"/>
        </Tab>
        <Tab :name="$t('product.tab.customFields')">
          <custom-fields v-if="product" :product="product" @save="save" @update="update"/>
        </Tab>
        <Tab :name="$t('product.tab.seo')">
          <seo v-if="product" :product="product" @save="save" @update="update"/>
        </Tab>
        <Tab :name="$t('product.tab.classification')" v-if="product && product.classification.length > 0">
          <classification v-if="product" :product="product" @save="save" @update="update"/>
        </Tab>
      </Tabs>
    </div>
  </div>
</template>

<script>
//you can also import this in your style tag
import {Tabs, Tab} from 'vue3-tabs-component';
// import 'vue-naTabs/themes/vue-tabs.css'

import GeneralConfiguration from "@/components/products/tabs/General.vue";
import PriceLines from "@/components/products/tabs/PriceLines.vue";
import Descriptions from "@/components/products/tabs/Descriptions.vue";
import productDetails from "@/components/products/tabs/Details.vue";
import Attachament from "@/components/products/tabs/Attachament";
import Delivery from "@/components/products/tabs/Delivery";
import Cart from "@/components/products/tabs/Cart";
import CustomFields from "@/components/products/tabs/CustomFields";
import Seo from "@/components/products/tabs/Seo";
import Classification from "@/components/products/tabs/Classification.vue";


export default {
  name: 'EditProduct',
  data() {
    return {
      productId: this.$route.params.id,
      product: null
    }
  },
  methods: {
    getProduct() {
      this.$swal({
        name: 'Carregando Produto',
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading()
        }
      })
      this.axios.get(`/products/getData/${this.productId}`).then(response => {
        this.product = response.data;
        this.$swal.close()
      })
    },
    update() {
      this.product = null
      this.getProduct()
    },
    save(product) {
      this.$swal({
        name: 'Salvando o Produto',
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading()
        }
      })
      this.axios.post(`/product/${this.productId}/update`, product).then(() => {
        this.product = null
        this.getProduct()
        this.$notify('Produto atualizado com sucesso')
        this.$swal.close()
      }).catch(() => {
        this.$swal({
          name: 'Erro ao atualizar produto',
          icon: 'error'
        })
        this.$notify('Erro ao atualizar produto')
      })
    }
  },
  mounted() {
    this.getProduct();
  },
  components: {
    Classification,
    PriceLines,
    GeneralConfiguration,
    Descriptions,
    productDetails,
    Attachament,
    Delivery,
    Cart,
    CustomFields,
    Seo,
    Tabs,
    Tab,
  }
}
</script>

