<template>
  <div class="container">
  <div class="card p-3 m-3 d-flex justify-content-center text-center" v-if="loaded === false">
    <div class="spinner m-0" style="position:initial"></div>
    <div class="text-success mt-3"><h5>{{ loadMessage }}</h5></div>

  </div>
  <div class="card p-3 m-3" v-else>
    <Form @submit="onSubmit" class="d-flex justify-content-center gap-3">
      <div class="form-group mb-3">
        <label for="creditsInput">{{ $t('deliveryCredit.credits') }}</label>
        <Field v-model.number="credits" name="creditsInput" rules="required|currency" v-slot="{ errors,field }">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">R$</span>
            </div>
            <input type="text" class="form-control" id="creditsInput" v-bind="field"/>
          </div>
          <span class="text-danger">{{ errors[0] }}</span>
        </Field>
      </div>
      <button type="submit" class="btn btn-primary">{{ $t('deliveryCredit.addCredits') }}</button>
    </Form>

    <CopylinkPayment v-if="link" :link="link"></CopylinkPayment>

    <div v-if="data.instructions != ''">
      <div class="d-flex justify-content-center">
        <button class="btn btn-link" @click="show = !show">{{ $t('deliveryCredit.paymentInstructions') }}</button>
      </div>
      <transition name="fade">
        <div class="card mb-3" v-if="show">
          <div class="card-header">{{$t('deliveryCredit.paymentInstructions')}}</div>
          <div class="card-body">
            <p>{{$t('deliveryCredit.paymentInstructionsDescription')}}</p>
            <p>{{ data.instructions }}</p>
          </div>
        </div>
      </transition>
    </div>
  </div>
  </div>
</template>

<script>
import {Form, Field, defineRule} from 'vee-validate'
import CopylinkPayment from "@/components/pdv/CopylinkPayment.vue";

defineRule('currency', function (value) {

  if (value >= 10) {
    return true
  }

  return 'O valor deve ser maior que 10'
})


export default {
  name: 'CreditForm',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  components: {
    CopylinkPayment,
    Form,
    Field
  },
  data() {
    return {
      show: false,
      credits: null,
      loaded: false,
      link: null,
      loadMessage: 'Carregando metodo de pagamento...'
    }
  },
  methods: {
    onSubmit() {
      this.loaded = false
      this.loadMessage = 'Processando pagamento...'
      this.axios.post('/deliveryCredit/pay', {
        'amount': this.credits,
        'payment_method': this.data.id
      }).then((response) => {
        this.loaded = true
        this.credits = null
        this.link = response.data.redirectUrl
        window.open(response.data.redirectUrl, '_blank');
      }).catch((error) => {
        this.loaded = true
        console.log(error)
      })
    }
  },
  mounted() {
    this.loaded = true
  }
}
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>