<script>
import {useMyWarehousesStore} from "@/store/myWarehousesStore";

export default {
  name: "MyWarehouses",
  setup() {
    const myWarehousesStore = useMyWarehousesStore()
    return {
      myWarehousesStore
    }
  },
  data() {
    return {
      warehouses: []
    }
  },
  beforeMount() {
    this.myWarehousesStore.fetchWarehouses()
  }
}
</script>

<template>
  <div class="page-content">
    <div class="container-fluid">
      <div class="row">
        <RouterLink :to="{name:'warehouse',params:{id:warehouse.id}}" class="col-md-4"
                    v-for="warehouse in myWarehousesStore.getWarehouses" :key="warehouse.id">
          <div class=" card border">
            <div class="card-body">
              <div class="row">
                <div class="col-12 d-inline-block">
                  <strong class="d-block">{{ warehouse.name }}</strong>
                  <strong class="d-block text-black font-size-22">
                    {{ $t('app.products') }}
                  </strong>
                  <small class="text-muted">{{ $t('app.productsCheck') }}</small>

                  <div class="d-flex gap-2">
                    <div class="d-flex gap-1 text-success align-items-center">
                      <em class="fa fa-check-circle"></em>
                      <span>{{ warehouse.productsCheck.ok }}</span>
                    </div>

                    <div class="d-flex gap-1 text-warning align-items-center">
                      <em class="fa fa-exclamation-circle"></em>
                      <span>{{ warehouse.productsCheck.warning }}</span>
                    </div>

                    <div class="d-flex gap-1 text-danger align-items-center">
                      <em class="fa fa-exclamation-triangle"></em>
                      <span>{{ warehouse.productsCheck.danger }}</span>
                    </div>
                  </div>

                  <strong class="d-block text-black font-size-16 mt-2" v-if="warehouse.hasDistributionCenter">
                    <span class="d-block">{{ $t('app.hasDistribution') }}</span>
                    <small class="text-muted">{{warehouse.distributionCenter.name}}</small>
                  </strong>

                  <strong class="d-block text-black font-size-16 mt-2">
                    <span class="d-block">{{ $t('app.address') }}</span>
                    <small class="text-muted">{{warehouse.address}}</small>
                  </strong>


                </div>
              </div>

            </div>
          </div>
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>