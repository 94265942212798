<template>
  <div>
    <div v-if="!hide" class="progress-bar-container">
      <div :style="{ width: progressBarWidth + '%' }" class="progress-bar"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  data() {
    return {
      progress: 0,
      intervalId: null,
      hide: true
    };
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    progressBarWidth() {
      return this.progress;
    }
  },
  watch: {
    loading(newVal) {
      if (newVal) {
        this.startProgress();
      } else {
        this.resetProgress();
      }
    },

  },
  methods: {
    startProgress() {
      this.hide = false;
      this.intervalId = setInterval(() => {
        if (this.progress >= 100) {
          clearInterval(this.intervalId);
          return;
        }
        this.progress += 1;
      }, 1000); // Adjust this value for the speed of progress
    },
    resetProgress() {
      this.progress = 100;


      clearInterval(this.intervalId);


      setTimeout(() => {
        this.progress = 0;
        this.hide = true;
      }, 500);

    }
  }
};
</script>

<style scoped>
.progress-bar-container {
  width: 100%;
  height: 4px;
  background-color: lightgray;
}

.progress-bar {
  height: 100%;
  background-color: blue;
  transition: width 0.5s ease-in-out;
}
</style>