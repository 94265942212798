<template>
  <div class="file-upload">
    <input type="file"  @change="uploadFile" />
    <i class="fa fa-arrow-up"></i>
  </div>
</template>
<script>
import {useFileManagerStore} from "@/store/fileManangerStore";

export default {
  name: 'UploadButton',
  setup() {
    const fileMangerStore = useFileManagerStore()
    return {
      fileMangerStore
    }
  },
  methods: {
    uploadFile(event) {
      this.fileMangerStore.uploadFile(event.target.files[0]).then(() => {
        this.fetchFiles()
      }).catch((error)=>{
        console.log(error)
      }).finally(() => {
        event.target.value = null
        this.fileMangerStore.fetchSpaceAvailable()
      })
    },
    fetchFiles(folder = null) {
      this.loading = true
      this.fileMangerStore.getFilesFetch(folder).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
<style scoped lang="scss">
.file-upload{
  cursor: pointer;
  height:50px;
  width:50px;
  border-radius: 25px;
  position:relative;

  display:flex;
  justify-content:center;
  align-items: center;

  border:4px solid #FFFFFF;
  overflow:hidden;
  background-image: linear-gradient(to bottom, #2590EB 50%, #FFFFFF 50%);
  background-size: 100% 200%;
  transition: all 1s;
  color: #FFFFFF;
  font-size:16px;

  input[type='file']{
    height:50px;
    width:50px;
    position:absolute;
    top:0;
    left:0;
    opacity:0;
    cursor:pointer;

  }

  &:hover{

    background-position: 0 -100%;

    color:#2590EB;

  }


}
</style>