<template>

  <div  class="col-md-4  bg-soft-light rounded-2 mt-3 ">
    <div class="card">
      <div class="mb-2">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-10">
                <span class="text-uppercase bold fw-bold">Método de pagamento</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="showPayment">
        <div class="col-md-12" v-if="posStore.getCartTotals">
          <div class="row d-flex justify-content-between" v-for="(detail,index) in posStore.getCartTotals" :key="index">
            <div class="col-6">{{ trans(index) }}</div>
            <div class="price col-6 text-end" v-if="index === 'totalDiscounts'"
                 v-currency="collect(detail).sum('savings') / 100">{{ detail / 100 }}
            </div>
            <div class="price col-6 text-end" v-else v-currency="detail / 100">{{ detail / 100 }}</div>
          </div>
        </div>
        <div class="col-md-12" v-else>
          <div class="spinner" style="position: initial"></div>
        </div>
        <ListOfPaymentMethods :paymentMethods="paymentMethods" v-if="loaded"/>
        <div class="col-md-12" v-else>
          <div class="spinner" style="position: initial"></div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked v-model="issue_invoice">
            <label class="form-check-label" for="flexSwitchCheckChecked">Emitir Nota Fiscal</label>
          </div>
        </div>
        <div class="col-md-12">
          <button class="btn btn-primary w-100" @click="checkout" :disabled="!canPay">Finalizar</button>
        </div>
      </div>
    </div>
  </div>

  <ModalComponent ref="cashPayment">
    <CashPayment :value-to-pay="posStore.getCartTotals.totalPostTaxes /100" @pay="closeOrder"/>
  </ModalComponent>

</template>
<script>
import ListOfPaymentMethods from "@/components/pdv/details/paymentMethod/ListOfPaymentmethods.vue";
import {usePosStore} from "@/store/PosStore";
import {collect} from "collect.js";
import ModalComponent from "@/components/helper/modal/ModalComponent.vue";
import CashPayment from "@/components/pdv/details/paymentMethod/CashPayment.vue";
import {useCashierStore} from "@/store/cashierStore";


export default {
  name: 'PaymentMethods',
  props: {
    showProducts: {
      type: Boolean,
      default: true
    },
  },
  emits:['checkout'],
  setup: () => ({posStore: usePosStore(), cashierStore: useCashierStore()}),
  data() {
    return {
      showPayment: true,
      selectedPaymentMethod: null,
      paymentMethods: [],
      loaded: false,
      cartTotals: null,
      issue_invoice: true,
      paymentMethodIsCash: false
    }
  },
  methods: {
    collect,
    checkout() {
      if (this.paymentMethodIsCash) {
        this.$refs.cashPayment.openModal()
        return
      }else{
        let sale = {
          type: 'sale',
          amount: this.posStore.getCartTotals.totalPostTaxes /100,
          needsConversion: true
        }
        this.cashierStore.createPendingTransactions([sale])
      }
      this.closeOrder()
    },
    closeOrder() {
      this.$swal({
        title: 'finalizando pedido',
        text: 'Aguarde enquanto finalizamos seu pedido',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        didOpen: () => {
          this.$swal.showLoading()
        }
      })
      this.axios.post(`pos/checkout/${this.posStore.getCart.id}`, {
        issue_invoice: this.issue_invoice
      }).then(async (response) => {
        this.$swal.close()
        this.$notify('Pedido finalizado com sucesso')
        this.$emit('checkout')
        await this.cashierStore.releasePendingTransactions()
        this.emitter.emit('paymentEnded', response.data)
      }).catch((error) => {
        console.log(error)
        this.$swal({
          title: 'Ocorreu um erro ao finalizar o pedido',
          text: 'Tente novamente',
          icon: 'error'
        })
        this.$notify('Ocorreu um erro ao finalizar o pedido')
      })
    },
    getPaymentMethods: function () {
      this.loaded = false
      this.axios.get(`pos/payment-methods/${this.posStore.getCart.id}`).then((response) => {
        this.paymentMethods = response.data
        this.loaded = true
        let payment_method  = this.paymentMethods.find((method) => parseInt(method.id) === parseInt(this.posStore.getCart.payment_method_id));
        if (payment_method) {
          this.paymentMethodIsCash = payment_method.payment_provider === 'cash'
        }
      })
    },

    trans(val) {
      switch (val) {
        case 'totalPostTaxes':
          return 'Total'
        case 'totalPreTaxes':
          return 'Subtotal'
        case 'totalTaxes':
          return 'Impostos'
        case 'totalDiscounts':
          return 'Descontos'
        case 'totalShipping':
          return 'Frete'
        case 'totalShippingPreTaxes':
          return 'Frete sem impostos'
      }
      return val
    }
  },
  beforeMount() {
    this.getPaymentMethods()
    this.posStore.calculateTotals()
  },
  computed:{
    canPay(){
      return this.posStore.getCartTotals.totalPostTaxes > 0
    }
  },
  created() {
    this.emitter.on('paymentMethodSelected', (data) => {
      this.paymentMethodIsCash = this.paymentMethods.find((method) => parseInt(method.id) === parseInt(data)).payment_provider === 'cash'
    })

    this.emitter.on('paymentEnded', () => {
      //kill component
      this.cartCopy = null

    })
  },
  components: {
    CashPayment,
    ModalComponent,
    ListOfPaymentMethods
  }
}
</script>
<style scoped>
.cartProductsWrapper {
  max-height: 250px;
  padding: 10px;

  overflow-y: hidden;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.card {
  width: 100%;
  border-radius: 5px;
  padding: 20px;
}

.card .title {
  font-size: 22px;
  font-weight: 500;
}

.card .content {
  margin-top: 20px;
}

.card label.box {
  margin-top: 12px;
  padding: 10px 12px;
  display: flex;
  border-radius: 5px;
  border: 2px solid transparent;
  cursor: pointer;
  transition: all 0.25s ease;
}

</style>