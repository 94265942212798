import axios from './apiConfig';
import auth from '../auth';
import moment from 'moment';
import 'moment/locale/pt-br'
import Pusher from 'pusher-js';
import {info} from '@/build';
import mitt from 'mitt';
const emitter = mitt();


export const globalProperties = {
    axios,
    auth,
    moment,
    Pusher,
    baseURL: axios.defaults.baseURL,
    buildInfo: info,
    emitter
}
