<template>
  <div class="container-fluid mt-3">
    <save-update-action-bar @save="save" @update="update"></save-update-action-bar>
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">

            <h4 class="card-title">{{$t('product.cart.stackable')}}</h4>
            <p class="card-title-desc">{{$t('product.cart.stackableDescription')}}</p>
            <div>
              <input type="checkbox" id="stackable" v-model="productCopy.stackable" switch="none" />
              <label for="stackable" data-on-label="Sim" data-off-label="Não"></label>
            </div>


            <h4 class="card-title">{{$t('product.cart.allowOutOfStockPurchases')}}</h4>
            <p class="card-title-desc">{{$t('product.cart.allowOutOfStockPurchasesDescription')}}</p>
            <div>
              <input type="checkbox" id="allowStock" v-model="productCopy.allow_out_of_stock_purchases" switch="none"/>
              <label for="allowStock" data-on-label="Sim" data-off-label="Não"></label>
            </div>
            <h4 class="card-title">{{$t('product.cart.defaultQuantity')}}</h4>
            <p class="card-title-desc">{{$t('product.cart.defaultQuantityDescription')}}</p>
            <div>
              <input type="number" class="form-control"  id="defaultQuantity" v-model="productCopy.quantity_default" />
            </div>
            <h4 class="card-title">{{$t('product.cart.minimumQuantity')}}</h4>
            <p class="card-title-desc">{{$t('product.cart.minimumQuantityDescription')}}</p>
            <div>
              <input type="number" class="form-control"  id="minimumQuantity" v-model="productCopy.quantity_min" />
            </div>
            <h4 class="card-title">{{$t('product.cart.maximumQuantity')}}</h4>
            <p class="card-title-desc">{{$t('product.cart.maximumQuantityDescription')}}</p>
            <div>
              <input type="number" class="form-control" id="maximumQuantity" v-model="productCopy.quantity_max" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <StockLevels :product-copy="productCopy" @update="update"/>
      </div>
    </div>
  </div>
</template>

<script>
import SaveUpdateActionBar from "@/components/generic/SaveUpdateActionBar";
import StockLevels from "@/components/products/tabs/StockLevels.vue";

export default {
  name: "CartComponent",
  props: {
    product: {}
  },
  data() {
    return {
      productCopy: {}
    }
  },
  created() {
    this.productCopy = Object.assign({}, this.product)
  },
  components: {
    StockLevels,
    SaveUpdateActionBar
  },
  methods:{
    save(){
      this.$emit('save', this.productCopy)
    },
    update(){
      this.$emit('update')
    }
  }
}
</script>