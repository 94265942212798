import {defineStore} from "pinia";
import {useStorage} from "@vueuse/core";
import axios from "@/boostrap/apiConfig";
import {useAuthStore} from "@/store/authStore";
import {collect} from "collect.js";
import {useNotification} from "@kyvg/vue3-notification";


export const useCashierStore = defineStore({
    id: 'cashierStore',
    state: () => ({
        transactions: useStorage('transactions', []),
        cashierIsOpen: useStorage('cashierIsOpen', false),
        cashierIsLocked: useStorage('cashierIsLocked', false),
        transactionsWeek: useStorage('transactionsWeek', []),
        authStore: useAuthStore(),
        perspective: useAuthStore().user.username,
        perspectives: [],
        reportData: null,
        changingPerspective: false,
        pendingTransactions: useStorage('pendingTransactions', [])

    }),
    getters: {
        getReportData() {
            return this.reportData;
        },
        getTransactions() {
            //sort by created_at
            return this.transactions.sort((a, b) => {
                return new Date(b.created_at) - new Date(a.created_at);
            });
        },
        getCashierIsOpen() {
            return this.cashierIsOpen;
        },
        getCashierIsLocked() {
            return this.cashierIsLocked;
        },
        getBalance() {
            let allTransactionsExceptClosedCashier = this.getTransactions.filter(transaction => transaction.type !== 'close-cashier');

            let allClosedCashiers = this.getTransactions.filter(transaction => transaction.type === 'close-cashier');

            let totalTransactionExceptClosedCashier = collect(allTransactionsExceptClosedCashier).sum('amount');
            console.log(totalTransactionExceptClosedCashier)
            let totalClosedCashiers = collect(allClosedCashiers).sum('amount');
            console.log(totalClosedCashiers)
            return totalTransactionExceptClosedCashier - totalClosedCashiers;
        },
        getTotals() {
            const transactions = this.getTransactions;
            // Filter out transactions where 'closed' is false
            const openTransactions = transactions.filter(transaction => transaction.closed);

            // Calculate the sum of 'amount' property for open transactions
            const sum = openTransactions.reduce((total, transaction) => total + transaction.amount, 0);

            return sum;
        },
        getPerspective() {
            return this.perspective;
        },
        getPerspectives() {
            return this.perspectives;
        },
        getDataForChart() {
            //first group by type
            let groupedTransactions = this.groupTransactionsByType(this.transactionsWeek);

            return groupedTransactions
        },
        getChangingPerspective() {
            return this.changingPerspective
        }

    },
    actions: {
        async fetchCashierIsLocked() {
            axios.get('/transactions/cashier-lock').then((response) => {
                console.log('islock:',response.data.isLock)
                this.cashierIsLocked = response.data.isLock
            })
        },
        createPendingTransactions(transactions) {
            this.pendingTransactions = transactions
        },
        async releasePendingTransactions() {
            this.pendingTransactions.forEach((transaction) => {
                this.createTransaction(transaction.type, transaction.amount, transaction.needsConversion).then(() => {
                    let {notify} = useNotification()
                    notify({
                        title: "Transação Pendente",
                        text: `Transação de ${transaction.amount} do tipo ${transaction.type} foi liberada`,
                    })
                })
            })
            this.pendingTransactions = []
        },
        clearAllData() {
            this.transactions = [];
            this.transactionsWeek = [];
            this.perspective = this.authStore.user.username;
            this.perspectives = [];
            this.reportData = null;
            this.changingPerspective = false
        },

        async generateReport(type) {
            let url = '/transactions/' + this.perspective + '/' + type;
            let result = await axios.get(url);
            this.reportData = result.data.transactions
        },
        groupTransactionsByType(data) {
            console.log(data)
            const types = [...new Set(data.map((transaction) => transaction.type))];
            const result = types.map((type) => ({
                label: this.translateType(type),
                backgroundColor: this.stringToColor(type),
                data: Array(7).fill(0), // Initialize the data array with zeroes for each day of the week
            }));

            data.forEach((transaction) => {
                const transactionWeekDay = new Date(transaction.created_at).getDay();
                const typeIndex = types.indexOf(transaction.type);
                if (typeIndex !== -1) {
                    result[typeIndex].data[transactionWeekDay]++;
                }
            });

            return result;
        },
        translateType(type) {
            switch (type) {
                case 'open-cashier':
                    return 'Abertura de Caixa'
                case 'sale':
                    return 'Venda'
                case 'addition':
                    return 'Adição'
                case 'subtraction':
                    return 'Subtração'
                case 'close-cashier':
                    return 'Fechamento de Caixa'
                case 'change':
                    return 'Troco'
                case 'refund':
                    return 'Devolução'
                case 'payments-to-suppliers':
                    return 'Pagamentos a Fornecedores'
            }

        },
        stringToColor(string) {
            let hash = 0;
            string.split('').forEach(char => {
                hash = char.charCodeAt(0) + ((hash << 5) - hash)
            })
            let colour = '#'
            for (let i = 0; i < 3; i++) {
                const value = (hash >> (i * 8)) & 0xff
                colour += value.toString(16).padStart(2, '0')
            }
            return colour
        },
        setIsLocked(isLocked) {
            this.cashierIsLocked = isLocked;
        },
        async fetchTransactionsWeekly() {
            return axios.get('transactions/' + this.perspective + '/week').then((response) => {
                this.transactionsWeek = response.data.transactions;
            })
        },
        changePerspective(perspective) {
            this.changingPerspective = true
            this.perspective = perspective;
            this.fetchAllData().then(() => {
                let {notify} = useNotification()
                notify({
                    title: "Perspectiva Alterada",
                    text: `Perspectiva alterada para ${perspective}`,
                })
                this.changingPerspective = false
            })

        },
        async fetchAllData() {
            await this.fetchCashierIsLocked()
            await this.fetchPerspectives();
            await this.fetchTransactions();
            await this.fetchCashierIsOpen();
            await this.fetchTransactionsWeekly();
        },
        async fetchPerspectives() {
            return axios.get('transactions/perspectives').then((response) => {
                this.perspectives = Object.values(response.data.perspectives);
            })
        },
        async fetchTransactions() {
            return axios.get('transactions/' + this.perspective).then((response) => {
                this.transactions = response.data.transactions;
            })
        },
        async fetchCashierIsOpen() {
            return axios.get('transactions/' + this.perspective + '/open-cashier').then((response) => {
                this.cashierIsOpen = response.data.transaction != null
            })
        },
        async createTransaction(type, amount, needsConversion = false) {
            return axios.post(`transactions/create/${type}`, {
                amount: amount,
                username: this.authStore.user.username,
                needsConversion: needsConversion
            }).then((response) => {
                this.transactions.push(response.data.transaction);
            })
        }
    }
})