<template>
  <div class="container">
    <div class="row">
      <PaymentMethod @selectedPaymentMethodHandler="selectedPaymentMethodHandler" :cart-payment-method="posStore.getCart.payment_method_id" :payment-method="method"
                     v-for="(method, index) in paymentMethods" :key="index"/>
    </div>

  </div>
</template>
<script>
import PaymentMethod from "@/components/pdv/details/paymentMethod/PaymentMethod.vue";
import {usePosStore} from "@/store/PosStore";


export default {
  name: 'ListOfPaymentmethods',
  components: {PaymentMethod},
  setup: () => ({posStore: usePosStore()}),
  props: {
    paymentMethods: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      selectedPaymentMethod: null
    }
  },
  methods: {
    selectedPaymentMethodHandler: function (method) {
      this.axios.put(`pos/payment-methods/${this.posStore.getCart.id}`, {id: method}).then(() => {
        this.selectedPaymentMethod = method
        this.emitter.emit('paymentMethodSelected', method)
        this.$notify('Método de pagamento selecionado com sucesso')
      })
    }
  }
}
</script>
<style scoped>
::selection {
  background: #d5bbf7;
}

.card input[type="radio"] {
  display: none;
}
</style>