<template>
    <div class="row">
    <div class="col-6" >
      <h3 v-if="title">{{title}}</h3>
    </div>
    <div class="col-6 gap-3 d-flex justify-content-end ">
      <button v-if="!noUpdate" @click="update" type="button" class="btn btn-light" v-wave>{{$t('app.update')}}</button>
      <button @click="save" type="button" class="btn btn-primary waves-light" v-wave>{{$t('app.save')}}</button>
    </div>
    </div>

</template>

<script>
export default {
  name: "SaveUpdateActionBar",
  props: {
    noUpdate: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    }
  },
  methods: {
    save() {
      this.$emit('save');
    },
    update() {
      this.$emit('update');
    }
  }
}
</script>

<style scoped>

</style>