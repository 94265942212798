<template>
  <div class="row">
    <div class="col-xl-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">{{ $t('dashboard.deliveryMap') }}</h4>
          <GoogleMap api-key="AIzaSyDYr2ToaFPuUjMAxeM0rrAQZ5L2OwG7AVg"
                     :center="center"
                     :zoom="zoom"
                     style="width: 100%; height: 500px;"
                     @click="handleClick"
          >
            <Circle v-for="(location, index) in locations" :key="index+'m'"
                    :options="{
                      center:location.center,
        radius: location.range,
        strokeColor: '#FF0000',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#FF0000',
            fillOpacity: 0.35,
            }"
            >
            </Circle>

            <Marker v-for="(delivery, index) in deliveries" :key="index+'d'"
                    :options="{
                        position: {
                          lat:parseFloat(delivery.lat),
                          lng:parseFloat(delivery.lng)
                        },
                           label: 'D'

                      }"/>

          </GoogleMap>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {GoogleMap, Circle, Marker} from "vue3-google-map";

export default {
  name: 'DeliveryMap',
  components: {GoogleMap, Circle, Marker},
  data() {
    return {
      zoom: 8,
      deliveries: [],
      center: {lat: 0, lng: 0},
      icon: {
        url: 'https://i.ibb.co/Q93c5FJ/store-2017.png',
        scaledSize: {width: 32, height: 32}
      },
      locations: [
        {
          center: {lat: 0, lng: 0},
          range: 5000, // 5 km
          circleOptions: {
            fillColor: '#008000',
            fillOpacity: 0.35,
            strokeWeight: 1,
            clickable: false
          }
        }
      ],

    }
  },
  methods: {
    handleClick(event) {
      this.center = event.latLng.toJSON()
    },
    getLocations() {
      this.axios.get('dashboard/getLocations')
          .then(response => {
            this.locations = response.data
            this.locations.forEach(location => {
              this.deliveries.push(...location.deliveries)
            })
            this.getCenterFromLocations()
          })
    },
    getCenterFromLocations() {
      let lat = 0
      let lng = 0
      this.locations.forEach(location => {
        lat += location.center.lat
        lng += location.center.lng
      })
      //mediate zoom and center
      this.center = {lat: lat / this.locations.length, lng: lng / this.locations.length}
    }
  },
  beforeMount() {
    this.getLocations()

  }
}
</script>