let env = process.env.NODE_ENV || 'development';

if (env === 'development' && process.env.VUE_APP_MODE === 'test') {
    env = 'test';
}
console.log(process.env)

console.log(process.env.VUE_APP_MODE);
console.log('RUNNING ON ENVIRONMENT', env);


const commonConfig = {
    env: env,
    imageSaver: {
        path: 'https://via.placeholder.com/150/FFF/000000?text=Imagem+Indispon%C3%ADvel',
        title: ':fO2H,of?^j[yDf6ozofjZayfkj[j[j[f6ay?^ay%gj[V@kCj[ayofj[j[ayaeayj[j[%gj[RPayRjayRPfkjZaej[kCofj[ayayozayj[j[RPayaefQozj[fQayaeayf6fk',
    },
};

const config = {
    development: {
        ...commonConfig,
        appDebug: true,
        serviceUrl: 'http://localhost:3000',
    },
    test: {
        ...commonConfig,
        appDebug: true,
        serviceUrl: 'http://localhost:65534',
    },
    production: {
        ...commonConfig,
        appDebug: false,
        serviceUrl: 'https://com.mirelladoces.com.br',
    },
};

module.exports = config[env];