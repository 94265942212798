import {createApp} from 'vue';
// import VueAlertify from 'vue-alertify';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueEcho from '@/plugins/echo/echo';
import VueNumber from 'vue-number-animation';
import {globalProperties} from '@/boostrap/globalProperties';
import {directives} from '@/boostrap/directives';
import App from '@/App.vue';
import pinia from './boostrap/pinia';
import router from './router';
import Notifications from '@kyvg/vue3-notification'
import Vue3ConfirmDialog from 'vue3-confirm-dialog';
import 'vue3-confirm-dialog/style';
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
import {createVfm} from 'vue-final-modal'
import {autoAnimatePlugin} from '@formkit/auto-animate/vue'
import VueExcelEditor from 'vue3-excel-editor'
import vueClickOutsideElement from 'vue-click-outside-element'
import { VueMasonryPlugin } from "vue-masonry";


const vfm = createVfm()
import 'vue-final-modal/style.css'
import VWave from "v-wave";
import {useLocalizationStore} from "@/store/localizationStore";
import {ErrorHandler} from "@/utils/ErrorHandler";

const localizationStore = useLocalizationStore(pinia);

const app = createApp(App);

ErrorHandler.init()

// app.use(VueAlertify);
app.use(VueSweetalert2)
app.use(VueEcho)
app.use(pinia)
app.use(vueClickOutsideElement)
app.use(router)
app.use(Notifications)
app.use(Vue3ConfirmDialog)
app.use(vfm)
app.use(VueExcelEditor)
app.use(localizationStore.i18n)
app.use(VueMasonryPlugin)
app.use(autoAnimatePlugin)
app.use(PerfectScrollbar).use(VWave, {
    color: 'white',
    initialOpacity: 0.5,
    easing: 'ease-in',
})
app.component('vue3-confirm-dialog', Vue3ConfirmDialog.default)
app.use(VueNumber);

// app.use(directives.currencyFilter, {
//     symbol: 'R$',
//     thousandsSeparator: '.',
//     fractionCount: 2,
//     fractionSeparator: ',',
//     symbolPosition: 'front',
//     symbolSpacing: true
// });

for (const property in globalProperties) {
    app.config.globalProperties[property] = globalProperties[property];
}

for (const directiveName in directives) {
    app.directive(directiveName, directives[directiveName]);
}

app.mount('#app');
