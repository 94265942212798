<template>
  <div class="container">
    <FormGenerator :form-schema="addressForm" @onSubmit="submitForm" :initial-values="address"/>
  </div>
</template>

<script>
import {usePosStore} from "@/store/PosStore";
import FormGenerator from "@/components/helper/formGenerator/FormGenerator.vue";


export default {
  setup: () => ({posStore: usePosStore()}),
  components: {
    FormGenerator,
  },
  data() {
    return {
      address: {
        lines: '',
        name: '',
        zip: '',
        city: '',
        country: 35,
        state: 84,
        details: '',
        number: '',
        district: '',
      },
      addressForm: [
        {
          "name": "zip",
          "label": "cep",
          "span": "left",
          "type": "text",
          "blur": this.buscarEndereco,
          "rules": "required",
          "mask": "#####-###"
        },
        {
          "name": "lines",
          "label": "Endereço",
          "span": "left",
          "type": "textarea",
          "rules": "required"
        },
        {
          "name": "name",
          "label": "name",
          "span": "right",
          "type": "text",
          "rules": "required"
        },
        {
          "name": "city",
          "label": "cidade",
          "span": "right",
          "type": "text",
          "rules": "required"
        },
        {
          "name": "country",
          "label": "País",
          "span": "left",
          "selectValue": 35,
          "async": {
            "url": "auth/location/country",
          },
          "type": "dropdown",
          "rules": "required"
        },
        {
          "name": "state",
          "label": "Estado",
          "span": "right",
          "selectValue": 84,
          "async": {
            "url": "auth/location/state",
          },
          "type": "dropdown",
          "dependsOn": "country",
          "rules": "required"
        },
        {
          "name": "details",
          "label": "detalhes",
          "span": "left",
          "type": "textarea",
        },
        {
          "name": "number",
          "label": "Numero",
          "span": "right",
          "type": "text",
          "rules": "required"
        },
        {
          "name": "district",
          "label": "Bairro",
          "span": "left",
          "type": "text",
          "rules": "required"
        }
      ],
      filteredStates: [],
    };
  },
  methods: {
    async submitForm(form) {
      try {
        this.axios.post(`/pos/address/${this.posStore.getCart.id}`, form).then(() => {
          this.$notify('Endereço cadastrado com sucesso!');
          this.posStore.updateCart()
          this.$emit('updatedAddress',true)
        })
      } catch (error) {
        console.error(error);
      }
    },
    async buscarEndereco() {
      try {
        if (this.address.zip.length === 0) {
          return;
        }
        this.axios.get(`/cep/${this.address.zip}`).then((response) => {
          console.log(response)
          this.address.lines = response.data.logradouro;
          this.address.district = response.data.bairro;
          this.address.city = response.data.localidade;
          this.address.details = response.data.complemento;
        })
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>