<template>
  <Form @submit="importNfe" v-if="nfeImportForm" class="border border-light p-5">

    <p class="h4 mb-4 text-center">Importar nota fiscal</p>
    <div class="mb-3 row">
      <div class="col-md-6">
        <Field name="Serie" v-model="serie" rules="required" v-slot="{ errors,field }">
          <input type="text" class="form-control" :class="{'is-invalid':errors.length >0 }" placeholder="Serie*"
                 required v-bind="field">
          <div v-if="errors" class="invalid-tooltip">
            {{ errors[0] }}
          </div>
        </Field>
      </div>
      <div class="col-md-6">
        <Field name="Numero" v-model="number" rules="required" v-slot="{ errors,field }">
          <input type="text" class="form-control" :class="{'is-invalid':errors.length >0 }" placeholder="Number*"
                 required v-bind="field">
          <div v-if="errors" class="invalid-tooltip">
            {{ errors[0] }}
          </div>
        </Field>
      </div>
    </div>
    <div class="col-md-12 mt-5">
      <Field name="Chave" v-model="key" rules="required" v-slot="{ errors, field }">
        <input type="text" class="form-control mb-5 " :class="{'is-invalid':errors.length >0 }" placeholder="Chave*"
               required v-bind="field">

      </Field>
    </div>
    <div class="mb-3">
      <label class="form-label">DANFE</label>
      <input type="file" class="filestyle" name="danfe" @change="onUploadDanfe" data-buttonname="btn-secondary"
             id="filestyle-0" tabindex="-1"
             style="position: absolute; clip: rect(0px, 0px, 0px, 0px);">
      <div class="bootstrap-filestyle input-group">
        <div name="filedrag" style="position: absolute; width: 100%; height: 35px; z-index: -1;"></div>
        <input type="text" class="form-control " v-model="danfeName" placeholder="" disabled=""
               style="border-top-left-radius: 0.25rem; border-bottom-left-radius: 0.25rem;"> <span
          class="group-span-filestyle input-group-btn" tabindex="0">
        <label for="filestyle-0" style="margin-bottom: 0;"
               class="btn btn-secondary "><span
            class="buttonText">Choose file</span></label></span></div>
    </div>
    <button type="submit" class="btn btn-primary" v-wave> Enviar</button>
  </Form>
</template>
<script>
import {Form, Field} from 'vee-validate'
export default {
  name: 'NfeImportForm',
  components: {
    Form,
    Field
  },
  props: {
    nfeImportForm: {},
    delivery_id: {}
  },
  data() {
    return {
      xml: null,
      key: null,
      serie: null,
      number: null,
      danfe: null,
      danfeName: null
    }
  },
  methods: {
    importNfe: function () {
      // create formdata to send everithing via axios
      let formData = new FormData();
      formData.append('key', this.key);
      formData.append('serie', this.serie);
      formData.append('number', this.number);
      formData.append('danfe', this.danfe);
      formData.append('delivery_id', this.delivery_id)
      this.axios.post('/orders/nfe/import', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(() => {
        this.$notify('Nota fiscal importada com sucesso');
        this.$emit('close')
        this.$emit('refresh')
      }).catch(error => {
        this.$emit('error', error.response.data);
      });
    },
    onUploadXml: function () {
      // this.xml = this.$refs.file.files[0];
    },
    onUploadDanfe: function () {
      let element = event.target;
      this.danfe = element.files[0]
      this.danfeName = this.danfe.name
    }
  }
}
</script>
