import {defineStore} from "pinia";
import {useStorage} from '@vueuse/core'
import axios from "@/boostrap/apiConfig"
import router from '@/router'
import SHA1 from 'crypto-js/sha1';

export const usePosStore = defineStore('pos', {
    state: () => ({
        cart: useStorage('cart', {}),
        loaded: false,
        carts: useStorage('carts', []),
        cartTotals: useStorage('cartTotals', {}),
        deliveryMethods: useStorage('deliveryMethods', {}),
        deliveryLoading: false,
        cartUserNow: useStorage('cartUserNow', null),
        paymentData: null,
        products: [],
    }),
    getters: {
        getCart() {
            return this.cart
        },
        getPaymentData() {
            return this.paymentData
        },
        getCartTotals() {
            return this.cartTotals
        },
        getDeliveryMethods() {
            return this.deliveryMethods
        },
        getCarts() {
            return this.carts
        },
        getProducts() {
            return this.products
        },
        getCartUserNow() {
            return this.cartUserNow
        }
    },
    actions: {
        deleteCart(cart) {
            axios.delete('pos/cart/' + cart.id).then(() => {
                this.retrieveCarts()
            }).catch(error => {
                console.log(error);
            })
        },
        clearAll() {
            this.cart = {}
            this.cartTotals = {}
            this.deliveryMethods = {}
            this.loaded = false
            this.deliveryLoading = false
            this.paymentData = null
        },
        getCatalogs() {
            axios.get('/poscatalogs')
                .then(response => {
                    this.products = response.data.products
                })
                .catch(error => {
                    console.log(error)
                })
        },
        setPaymentData(paymentData) {
            this.paymentData = paymentData
        },
        setCart(cart) {
            this.cart = cart
        },
        setOrderStore(val, store) {
            axios.put('/pos/storeOrder/' + this.cart.id + '/' + val, {
                warehouse_id: store
            }).then(() => {
                this.updateCart()
            }).catch(error => {
                console.log(error);
            })
        },
        async setChannel(channel) {
           return  axios.put('/pos/channel/' + this.cart.id, {
                channel_id: channel
            }).then(() => {
                this.updateCart()
            }).catch(error => {
                console.log(error);
                throw error
            })
        },
        calculateTotals: function () {
            this.cartTotals = {}
            axios.get(`pos/cart-totals/${this.cart.id}`).then((response) => {
                const reversedKeys = Object.keys(response.data).reverse();
                const reversed = {};
                reversedKeys.forEach(function (key) {
                    reversed[key] = response.data[key];
                });
                this.cartTotals = reversed
            })
        },
        async setCartNowHash(document) {
            this.cartUserNow = SHA1(document).toString()
        },
        async selectCart(cart) {
            this.cart = cart
            console.log("userDocument",cart.customer.document)
            await this.setCartNowHash(cart.customer.document)
            await router.push({name: 'pos'})
        },
        retrieveDeliveryMethods() {
            this.deliveryLoading = true
            axios.get(`pos/delivery-methods/${this.cart.id}`).then((response) => {
                this.deliveryMethods = response.data
                this.deliveryLoading = false
            }).catch((error) => {
                console.log(error)
                this.deliveryLoading = false
                this.deliveryMethods = []
            }).finally(() => {
                this.calculateTotals()
            })
        },
        updateCart() {
            if (this.cart == null) return
            this.loaded = false
            axios.get('pos/cart/' + this.cart.id).then((response) => {
                this.cart = null
                this.cart = response.data
                this.loaded = true
            }).finally(() => {
                this.calculateTotals()
                this.retrieveDeliveryMethods()
            })
        },
        async retrieveCarts() {
            return axios.get('/pos/carts').then(response => {
                this.carts = response.data;
            }).catch(error => {
                console.log(error);
            })
        },
        applyCupon(cupon) {
            axios.post(`/pos/cupons/apply/${this.getCart.id}`, {cupon: cupon}).then(() => {
                this.updateCart()
            }).catch((error) => {
                console.log(error);
            });
        },
        removeDiscount(cupon) {
            axios.delete(`/pos/cupons/remove/${this.getCart.id}/${cupon}`).then(() => {
                this.updateCart()
            }).catch((error) => {
                console.log(error);
            });
        }
    }
})