<template lang="html">
  <!--  <nav class="navbar">-->

  <!--    <div class="navbar-menu" :class="{ 'is-active': menuClosed }" @click="menuClosed = false">-->
  <!--      <div class="navbar-start">-->

  <!--        <router-link to="/users" class="navbar-item" v-if="user.authenticated && auth.checkRole('systemRole', 'admin')">Users</router-link>-->

  <!--      </div>-->

  <!--      <div class="navbar-end">-->
  <!--        <span class="navbar-item" v-if="user.authenticated" >{{user.name}}({{user.systemRole}})</span>-->
  <!--        <router-link to="/login" class="navbar-item" v-if="!user.authenticated">Login</router-link>-->
  <!--        <router-link to="/register" class="navbar-item" v-if="!user.authenticated">Register</router-link>-->
  <!--        <a class="navbar-item" v-if="user.authenticated" @click="logout()">Logout</a>-->
  <!--      </div>-->

  <!--    </div>-->

  <!--  </nav>-->
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <a href="/" class="logo logo-light">
                        <span class="logo-sm">
                            <img src="/assets/images/logo-sm.png" alt="" height="22">
                        </span>
            <span class="logo-lg">
                            <img src="/assets/images/logo-light.png" alt="" height="20">
                        </span>
          </a>
        </div>

        <button type="button" class="btn btn-sm px-3 font-size-24 header-item" v-wave="{color: '#0a1832'}"
                @click="closeMenu">
          <i class="mdi mdi-menu"></i>
        </button>

        <div class="d-none d-sm-block ms-2">
          <h4 class="page-title font-size-18">
            {{ $t('menu.' + getCurrentRouterName) }}
          </h4>
          <span class="d-block m-0 position-absolute top-70">
            <BreadCrumb/>
          </span>
        </div>


      </div>

      <div class="d-flex">

       <LanguageSelector/>

        <DeliveryCreditBalance ref="balance"/>
        <notifications/>

        <div class="dropdown d-inline-block ms-2">
          <button type="button" class="btn header-item " v-wave="{color: '#0a1832'}" id="page-header-user-dropdown"
                  data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{ user.name }}
          </button>
          <div class="dropdown-menu dropdown-menu-end">
            <!-- item-->
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" v-if="user.authenticated" @click="logout()"><i
                class="dripicons-exit font-size-16 align-middle me-2"></i>
              Logout</a>
            <router-link class="dropdown-item" v-if="user.authenticated" :to="{name:'changePassword'}"><i
                class="dripicons-lock font-size-16 align-middle me-2"></i>
              {{ $t('app.auth.changePassword') }}
            </router-link>
          </div>
        </div>


      </div>
    </div>
  </header>

</template>

<script>

import Notifications from "./Notifications.vue";
import DeliveryCreditBalance from "@/components/generic/DeliveryCreditBalance.vue";
import {useAuthStore} from "../../store/authStore";
import BreadCrumb from "@/components/generic/BreadCrumb.vue";
import LanguageSelector from "@/components/generic/LanguageSelector.vue";

export default {
  components: {LanguageSelector, BreadCrumb, DeliveryCreditBalance, Notifications},
  name: "NavbarComponent",
  setup() {
    const authStore = useAuthStore();
    return {
      authStore
    }
  },
  data() {
    return {
      user: this.authStore.user,
      menuClosed: null,
    }
  },

  methods: {
    logout() {
      this.authStore.logout();
    },
    closeMenu() {
      // add this classes to body sidebar-enable vertical-collpsed
      document.body.classList.toggle("sidebar-enable");
      document.body.classList.toggle("vertical-collpsed");
    }
  },

  mounted: function () {
    const links = document.querySelectorAll(".navbar-item");
    links.forEach(link => {
      link.addEventListener("click", function () {
        link.classList.remove("is-active");
      });
    });
  },
  computed: {
    getCurrentRouterName() {
      return this.$route.name;
    }
  }
};
</script>

<style lang="scss" scoped>
/* https://github.com/jgthms/bulma/issues/2514 */
/* Issue with menus not closing because Bulma does come with any javascript. The @media screen and mounted hook is the fix for that */
@media screen and (min-width: 1025px) {
  .navbar-item.is-hoverable:hover .navbar-dropdown {
    display: block !important;
  }
  .navbar-item.is-hoverable:focus-within .navbar-dropdown {
    display: none;
  }
}
.top-70{
  top: 70%;
}
</style>
