

<template>
  <Tabs :options="{ useUrlFragment: false }">
    <Tab :name="$t(tab.tab)" v-for="tab in tabs" :key="tab.tab">
       <FormGenerator :form-schema="tab.fields" :initial-values="initialValues" @onSubmit="onSubmit"/>
    </Tab>
  </Tabs>
</template>

<script>
import {Tabs, Tab} from 'vue3-tabs-component';
import FormGenerator from "@/components/helper/formGenerator/FormGenerator.vue";

export default {
  name: "TabsFormGenerator",
  components: {FormGenerator, Tabs, Tab},
  props: {
    tabs: {
      type: Array,
      default: () => []
    },
    initialValues: {
      type: Object,
      default: () => {
      }
    },

  },
  methods: {
    onSubmit(form) {
      this.$emit('onSubmit',form)
    }
  }

}
</script>
<style scoped>

</style>