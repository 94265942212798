import {defineStore} from "pinia";
import router from "@/router";
import axios from '../boostrap/apiConfig';
import {ErrorHandler} from "@/utils/ErrorHandler";
import {useStorage} from "@vueuse/core";


export const useFileManagerStore = defineStore('fileManager', {
    state: () => ({
        files: [],
        folders: [],
        currentPath: '',
        currentFolder: '',
        spaceAvailable: {
            spaceAvailable: 0,
            maxSpaceAvailable: 0
        },
        folderHistory: useStorage('folderHistory', [])
    }),
    getters: {
        getFiles() {
            return this.files;
        },
        getFolders() {
            return this.folders;
        },
        getCurrentPath() {
            return this.currentPath;
        },
        getCurrentFolder() {
            return this.currentFolder;
        },
        getSpaceAvailable() {
            return this.spaceAvailable;
        },
        getFolderHistory() {
            return this.folderHistory;
        }
    },
    actions: {
        async getFilesFetch(folder = null) {
            const routerFolder = router.currentRoute.value.params.folder || null;
            // Update the current path and navigate if necessary
            if ((folder || folder === "base") && this.currentPath !== folder) {
                console.log("Entered with new folder:", folder);
                this.currentPath = folder;
                await router.replace({ name: 'myFiles', params: { folder: folder } });
                // Update folder history for back navigation
                this.folderHistory.push(folder);
            } else if (routerFolder && this.currentPath !== routerFolder) {
                console.log("Entered with router folder:", routerFolder);
                this.currentPath = routerFolder;
                await router.replace({ name: 'myFiles', params: { folder: routerFolder } });
                // Update folder history for back navigation
                if (routerFolder) {
                    this.folderHistory.push(routerFolder);
                }
            }

            // Construct the URL for fetching files
            let url = '/files';

            if (this.currentPath) {
                console.log("Fetching files for folder:", this.currentPath);
                url += '?folderPath=' + this.currentPath;
            }

            // Fetch files and handle response
            try {
                const response = await axios.get(url);
                const filesData = response.data.files;

                this.currentFolder = response.data.path
                this.folders = filesData.filter(file => file.type === 'folder');
                this.files = filesData.filter(file => file.type === 'file');
            } catch (error) {
                console.error("Error fetching files:", error);
                ErrorHandler.handle(error, "error fetching files");
                throw error;
            } finally {
                await this.fetchSpaceAvailable();
            }
        },
        async goBack() {
            if (this.folderHistory.length > 1) {  // Ensure there's a previous folder
                this.folderHistory.pop();  // Remove the current folder
                const previousFolder = this.folderHistory.pop();  // Get the previous folder

                // Navigate to the previous folder
                await this.getFilesFetch(previousFolder);
            }
        },
        async fetchSpaceAvailable() {
            return axios.get('files/spaceAvailable').then((response) => {
                this.spaceAvailable = response.data;
            }).catch((error) => {
                ErrorHandler.handle(error, "error fetching files");
                throw error;
            })
        },
        async rename(folderOrFolder, newName) {
            return axios.post('/files/rename', {
                fileOrFolder: folderOrFolder,
                newName: newName,
                currentPath: this.currentPath
            }).finally(() => {
                this.fetchSpaceAvailable();
            })

        },
        async createFolder() {
            return axios.post('/files/createFolder', {
                currentPath: this.currentPath
            }).catch((error) => {
                ErrorHandler.handle(error, "error creating folder");
                throw error;
            }).finally(() => {
                this.fetchSpaceAvailable();
            })
        },
        async deleteFileOrFolder(fileOrFolder) {
            return axios.delete('/files/delete', {
                data: {
                    fileOrFolder: fileOrFolder,
                    currentPath: this.currentPath
                }
            }).catch((error) => {
                ErrorHandler.handle(error, "error deleting file or folder");
                throw error;
            }).finally(() => {
                this.fetchSpaceAvailable();
            })

        },
        async downloadFile(file) {
            try {
                const response = await axios.get('/files/download', {
                    params: {
                        file: file.id,
                        currentPath: this.currentPath
                    },
                    responseType: 'blob'
                });

                // Create a new Blob object using the response data
                const blob = new Blob([response.data]);

                // Create a URL for the blob object
                const url = window.URL.createObjectURL(blob);

                // Create a temporary link element
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', file.name); // Set the file name for download

                // Append the link to the document and trigger the download
                document.body.appendChild(link);
                link.click();

                // Clean up by removing the link and revoking the URL
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);

            } catch (error) {
                console.error('Error downloading the file:', error);
                ErrorHandler.handle(error, "error downloading file");
            } finally {
                await this.fetchSpaceAvailable();
            }
        },
        async uploadFile(file) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('currentPath', this.currentPath);

            return axios.post('/files/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'

                }
            }).catch((error) => {
                ErrorHandler.handle(error, error.response.data.error);
                throw error;
            }).finally(() => {
                this.fetchSpaceAvailable();
            })
        }


    }

});