import {defineStore} from "pinia";
import axios from "@/boostrap/apiConfig";
import {useNotification} from "@kyvg/vue3-notification";

export const usePlannerStore = defineStore('planner', {
    state: () => ({
        planners: [],
        planner: null,
        products: [],
        nonAddedProducts: [],
        schedules: [],
        periods: []

    }),
    getters: {
        getPlanners() {
            return this.planners;
        },
        getPlanner() {
            return this.planner;
        },
        getProducts() {
            return this.products;
        },
        getNonAddedProducts() {
            return this.nonAddedProducts;
        },
        getSchedules() {
            return this.schedules;
        },
        getPeriods() {
            return this.periods;
        }

    },
    actions: {
        async fetchPlanners() {
            try {
                const response = await axios.get('planner');
                this.planners = response.data.planners
            } catch (error) {
                this.handleError(error, 'fetchPlanners');
            }
        },
        async fetchPlanner(id) {
            try {
                const response = await axios.get('planner/' + id);
                this.planner = response.data.planner
            } catch (error) {
                this.handleError(error, 'fetchPlanner');
            }
        },
        async fetchProducts(id) {
            try {
                this.products = [];
                const response = await axios.get('planner/' + id + '/products');
                this.products = response.data.products
            } catch (error) {
                this.handleError(error, 'fetchProducts');
            }
        },
        async fetchNotAddedProducts(id) {
            try {
                this.nonAddedProducts = [];
                const response = await axios.get('planner/' + id + '/products?notAdded=true');
                this.nonAddedProducts = response.data.products
            } catch (error) {
                this.handleError(error, 'fetchNotAddedProducts');
            }
        },
        async addProductsToPlanner(plannerId, productIds) {
            try {
                await axios.post('planner/' + plannerId + '/products', {productIds: productIds});
                await this.fetchNotAddedProducts(plannerId);
                await this.fetchProducts(plannerId);
            } catch (error) {
                this.handleError(error, 'addProductToPlanner');
                throw error;
            }
        },
        async removeProductsFromPlanner(plannerId, productIds) {
            try {
                await axios.delete('planner/' + plannerId + '/products', {data: {productIds: productIds}});
                await this.fetchNotAddedProducts(plannerId);
                await this.fetchProducts(plannerId);
            } catch (error) {
                this.handleError(error, 'removeProductsFromPlanner');
                throw error;
            }
        },
        async retrieveSSchedule() {
            try {
                const response = await axios.get('planner/schedules');
                this.schedules = response.data.schedules
            } catch (error) {
                this.handleError(error, 'retrieveSSchedule');
            }
        },
        async fetchPeriods(id) {
            try {
                const response = await axios.get('planner/' + id + '/periods');
                this.periods = response.data.periods
            } catch (error) {
                this.handleError(error, 'fetchPeriods');
            }
        },
        async createSpecialPeriod(id, period) {
            try {
                await axios.post('planner/' + id + '/periods', period);
                await this.fetchPeriods(id);
            } catch (error) {
                this.handleError(error, 'createSpecialPeriod');
                throw error;
            }
        },
        async deleteSpecialPeriod(id, period) {
            try {
                await axios.delete('planner/' + id + '/periods/' + period);
                await this.fetchPeriods(id);
            } catch (error) {
                this.handleError(error, 'deleteSpecialPeriod');
                throw error;
            }
        },
        handleError(error, method) {
            let {notify} = useNotification();
            let message = error.message;
            console.log(error);
            if (error.response) {
                message = error.response.data.message;
            }
            notify({
                title: 'Error in ' + method + '()',
                text: message,
                type: 'error'
            })

        }
    }
})