<template lang="html">
  <div class="account-bg">
    <Transition>
      <div id="preloader" v-if="this.isLoading">
        <div id="status">
          <div class="spinner"></div>
        </div>
      </div>
    </Transition>
    <notifications position="bottom right"/>
    <error-box :error.sync="error"/>
    <div class="account-pages my-5 pt-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card">
              <div class="card-body">
                <div class="text-center mt-4">
                  <div class="mb-3">
                    <router-link :to="{name:'dashboard'}" class="auth-logo">
                      <img src="@/assets/images/logo-sl.png" height="100" class="logo-dark mx-auto"
                           alt="">
                      <img src="@/assets/images/logo-sl.png" height="100" class="logo-light mx-auto"
                           alt="">
                    </router-link>
                  </div>
                </div>
                <div class="p-3">
                  <h4 class="font-size-18 text-muted mt-2 text-center">Bem Vindo de Volta!</h4>
                  <p class="text-muted text-center mb-4">Faça login para continuar no COM.</p>
                  <Form @submit="login" class="form-horizontal">
                    <div class="mb-3">
                      <Field v-model="username" name="username" rules="required" v-slot="{ errors,field }">
                        <label class="form-label" for="username">Username</label>
                        <input v-bind="field" class="form-control" type="text" placeholder="username"
                               :class="{'is-danger': errors[0] }"/>
                        <span v-if="errors" class="help is-danger">{{ errors[0] }}</span>
                      </Field>
                    </div>

                    <div class="mb-3">
                      <Field name="Password" v-model="password" rules="required" v-slot="{ errors,field }">
                        <label class="form-label" for="userpassword">Password</label>
                        <input v-bind="field" class="form-control" type="password" placeholder="Password"
                               :class="{'is-danger': errors[0] }"/>
                        <span v-if="errors" class="help is-danger">{{ errors[0] }}</span>
                      </Field>
                    </div>

                    <div class="mb-3" v-if="needsVendorToken">
                      <Field v-model="vendorToken" name="vendorToken" rules="alpha_num" v-slot="{ errors,field }">
                        <label class="form-label" for="vendorToken">Token de Fornecedor</label>
                        <input v-bind="field" class="form-control" type="password" placeholder="Token de Fornecedor"
                               :class="{'is-danger': errors[0] }"/>
                        <span v-if="errors" class="help is-danger">{{ errors[0] }}</span>
                      </Field>
                    </div>

                    <div class="mb-3 row mt-4">
                      <div class="col-sm-12 text-end">
                        <button type="submit" class="btn btn-primary w-md " v-wave
                                :class="{ 'is-loading': isLoading }">Login
                        </button>
                      </div>
                    </div>
                  </form>
                </div>

              </div>
            </div>
            <div class="mt-5 text-center">
              <RouterLink :to="{name:'onboarding'}" class="text-muted"><i
                  class="mdi mdi-account-circle me-1"></i> Não tem uma conta? Crie uma agora mesmo!</RouterLink>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ErrorBox from '../generic/ErrorBox.vue';
import {Form, Field, defineRule} from 'vee-validate';
import {required, alpha_num} from '@vee-validate/rules';
import {useAuthStore} from "../../store/authStore";

defineRule('required', required);

defineRule('alpha_num', alpha_num);


export default {
  name: 'LoginComponent',
  setup() {
    const authStore = useAuthStore();
    return {authStore}
  },
  props: {
    err: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      error: null,
      username: '',
      password: '',
      vendorToken: '',
      needsVendorToken: false,
      isLoading: true // auth.login is resetting is-loading when an error occurs through context
    };
  },
  methods: {
    async login() { // with automatic handleSubmit validation: can't pass parameters but less code to write.
      this.isLoading = true;
      try {
        let credentials = {
          username: this.username,
          password: this.password,
          vendorToken: this.vendorToken
        };
       await  this.authStore.checkForVendorToken(credentials, this.$route.query.redirect)
        // this.auth.login(this, credentials, this.$route.query.redirect); // submit the login
      } catch (e) {
        if (e.response.status === 403) {
          console.log('needs vendor token')
          this.needsVendorToken = true;
        }
        this.isLoading = false;
      } finally {
        this.isLoading = false;
      }
    },
  },
  computed: {
    loginError() {
      return this.authStore.getError
    }
  },
  mounted() {
    if (this.err !== null) {
      console.log('Relog error: ' + this.err);
      this.error = this.err;
    }
    this.isLoading = false;
  },
  watch: {
    loginError(val) {
      this.error = val;
    }
  },

  components: {
    'error-box': ErrorBox,
    Form,
    Field
  },

};
</script>

<style lang="css">
#loginForm {
  width: 33%;
}
</style>
