<template>
    <div>

            <label :for="field.hashId" class="mall-label mb-1">{{ field.name }}</label>
            <textarea rows="6" class="form-control" :name="'fields['+field.hashId+']'"
                      :id="'field-'+ field.hashId "
                      data-mall-custom-field></textarea>


    </div>
</template>

<script>
    export default {
      name: 'textareaComponent',
        props: {
            field: {
                type: Object,
                required: true,
            },
            item: {
                type: Object,
                required: true,
            },
        },

    }
</script>

<style lang="scss" scoped>

</style>
