<template>
  <div class="page-content">
    <div class="container-fluid">
      <div class="py-2 d-flex justify-content-end gap-2">

        <router-link class="btn btn-dark" :to="{name:'delivery'}"><em class="fa fa-arrow-left"></em></router-link>
        <!--      <button class="btn btn-dark" @click="getProducts"><em class="mdi mdi-refresh-circle"></em></button>-->
      </div>
      <div class="container-fluid mt-3" v-if="stats">
        <h4 class="card-title mb-4">Maixcon oliveira</h4>
          <div class="row">
            <div class="col-md-6 col-xl-3">
              <div class="card text-center">
                <div class="mb-2 card-body text-muted">
                  <h3 class="text-info mt-2">{{stats.total_pay_to_driver}}</h3> {{ $t('drivers.totalPayToDriver')}}
                </div>
              </div>
            </div>
            <div class="col-md-6 col-xl-3">
              <div class="card text-center">
                <div class="mb-2 card-body text-muted">
                  <h3 class="text-purple mt-2">{{stats.total_delivery_montly}}</h3> {{ $t('drivers.totalDeliveryMontly')}}
                </div>
              </div>
            </div>
            <div class="col-md-6 col-xl-3">
              <div class="card text-center">
                <div class="mb-2 card-body text-muted">
                  <h3 class="text-danger mt-2">{{stats.total_deliveries}}</h3> {{ $t('drivers.totalDeliveries')}}
                </div>
              </div>
            </div>
            <div class="col-md-6 col-xl-3">
              <div class="card text-center">
                <div class="mb-2 card-body text-muted">
                  <h3 class="text-primary mt-2">{{ stats.total_pay_to_driver_not_paid }}</h3> {{ $t('drivers.totalPayToDriverNotPaid')}}
                </div>
              </div>
            </div>
          </div>
  
        <div class="row">
          <div class="col-xl-12">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title mb-4">{{ $t('drivers.deliveries')}}</h4>

                <div class="table-responsive text-center border-0 ">
                  <h2 v-if="deliveries.length === 0">{{ $t('drivers.noDeliveries') }}</h2>
                  <table v-else class="table mt-4 mb-0 table-centered table-nowrap">

                    <tbody>

                    <tr v-for="delivery in deliveries " :key="delivery.id">
                      <td>
                        {{ delivery.code }}
                      </td>
                      <td><i class="mdi mdi-checkbox-blank-circle"></i>
                        {{ delivery.delivery_status.at(-1).status.split('\\').at(-1) }}
                      </td>
                      <td>
                        {{ (delivery.pay_to_driver.value /100).toFixed(2) }} / {{ (delivery.selected_shipping.price /100).toFixed(2) }}
                        <p class="m-0 text-muted">Amount</p>
                      </td>
                      <td>
                        {{ delivery.created_at  }}
                        <p class="m-0 text-muted">Date</p>
                      </td>
                      <td>
                        <button class="btn btn-success btn-rounded" @click="paid(delivery.pay_to_driver.id)" v-if="!delivery.pay_to_driver.paid"> {{$t('drivers.pay')}}</button>
                        <button class="btn btn-success btn-rounded" disabled v-else > <i class="fa fa-check-circle"></i></button>
                      </td>
                    </tr>

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DriverStats",
  data() {
    return {
      stats: null,
      deliveries: []
    };
  },
  methods:{
    paid(id){
      this.axios.put(`drivers/${id}/paid`).then(() => {
        this.$notify('Pagamento realizado com sucesso')
        this.getStats()

      })
    },
    getStats(){
      this.axios.get(`drivers/${this.$route.params.id}/stats`).then(response => {
        this.stats = response.data
        this.deliveries = response.data.deliveries
      })
    }
  },
  beforeMount() {
    this.getStats()
  }
}
</script>

<style scoped>

</style>