<template>
  <div class="row">
    <common :field.sync="field" :fieldType="fieldType"></common>
    <MutilValueNewField :type="fieldType" :field="field"></MutilValueNewField>
    <div class="col-md-12">
      <button @click="save" class="btn btn-primary"> salvar</button>
    </div>
  </div>
</template>

<script>
import Common from "@/components/products/customFields/newField/Common";
import MutilValueNewField from "@/components/products/customFields/MutilValueNewField";

export default {
  name: "dropdownComponent",
  props: {
    fieldType: {},
    usedField: {}
  },
  watch: {
    usedField: function (newVal) {
      this.field = newVal.field;
    }
  },
  methods: {
    save() {

      this.$emit('save', this.field);

    }
  },
  data() {
    return {
      field: {
        name: '',
        price: 0,
        mandatory: false,
        values: []
      }
    }
  },
  components: {
    Common,
    MutilValueNewField
  }
}
</script>

<style scoped>

</style>