<template>
  <div class="container-fluid mt-3">
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">{{ $t('product.generalConfiguration.active') }}</h4>
            <p class="card-title-desc">{{ $t('product.generalConfiguration.activeDescription') }}</p>
            <div>
              <input type="checkbox" @change="publishProduct" id="published" v-model="productCopy.published"
                     switch="none"/>
              <label for="published" data-on-label="Sim" data-off-label="Não"></label>
            </div>


          </div>
        </div>

      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">{{ $t('product.generalConfiguration.categories') }}</h4>
            <div class="row">
              <div class="col-md-10">
                <VueMultiselect v-model="productCopy.categories" track-by="name" :options="categories" :multiple="true"
                                label="name" :placeholder="$t('app.pick')" :selectLabel="$t('app.clickToSelect')"
                                :deselectLabel="$t('app.clickToDeselect')">
                </VueMultiselect>
              </div>
              <div class="col-md-2">
                <button class="btn btn-primary" @click="updateCategories"><em class="fa fa-save"></em></button>
              </div>
            </div>
            <h4 class="card-title mt-2">{{ $t('product.generalConfiguration.catalogs') }}</h4>
            <div class="row">
              <div class="col-md-10">
                <VueMultiselect v-model="selectedCatalog" track-by="name" :options="catalogs" :multiple="false"
                                label="name" :placeholder="$t('app.pick')" :selectLabel="$t('app.clickToSelect')"
                                :deselectLabel="$t('app.clickToDeselect')">
                </VueMultiselect>
              </div>
              <div class="col-md-2">
                <button class="btn btn-primary" @click="updateCatalogs"><em class="fa fa-save"></em></button>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">{{ $t('product.generalConfiguration.attributes') }}</h4>
            <p class="card-title">{{ $t('product.generalConfiguration.attributesDescription') }}</p>
            <table class="table table-bordered">
              <thead>
              <tr>
                <th scope="col">{{ $t('product.generalConfiguration.name') }}</th>
                <th scope="col">{{ $t('product.generalConfiguration.description') }}</th>
                <th scope="col">{{ $t('product.generalConfiguration.valid') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(attributes,index) in attributesToCheck" :key="'attributes'+index">
                <td>{{ attributes.name }}</td>
                <td>{{ attributes.description }}</td>
                <td>
                  <em v-if="attributes.condition" class="fa fa-check-circle text-success"></em>
                  <em v-else class="fa fa-times-circle text-danger"></em>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card text-white mb-3 fadeAnimation"
             :class="{'bg-warning': product.approval === 'pending', 'bg-success': product.approval === 'approved', 'bg-danger': product.approval === 'rejected'}">
          <div class="card-header fadeAnimation"
               :class="{'bg-warning': product.approval === 'pending', 'bg-success': product.approval === 'approved', 'bg-danger': product.approval === 'rejected'}">
            <h3>{{ $t('product.generalConfiguration.status') }}</h3>
            <h4>{{ $t('product.generalConfiguration.' + product.approval) }}</h4>
          </div>
        </div>
        <div class="card">
          <EasyDataTable
              :headers="alterationHeader"
              :items="product.approvals">

              <template #item-status="{status}">
                <span v-if="status === 'pending'" class="text-warning">{{ $t('product.generalConfiguration.statusAlteration.pending') }}</span>
                <span v-if="status === 'approved'" class="text-success">{{ $t('product.generalConfiguration.statusAlteration.approved') }}</span>
                <span v-if="status === 'rejected'" class="text-danger">{{ $t('product.generalConfiguration.statusAlteration.rejected') }}</span>
              </template>

          </EasyDataTable>
        </div>
      </div>

    </div>

  </div>
</template>
<script>


import VueMultiselect from 'vue-multiselect'
import EasyDataTable from "vue3-easy-data-table";

export default {
  name: 'GeneralConfiguration',
  props: {
    product: {}
  },
  data() {
    return {
      alterationHeader: [
        {
          text: this.$t('product.generalConfiguration.alterationHeader.field'),
          value: 'property'
        },
        {
          text: this.$t('product.generalConfiguration.alterationHeader.oldValue'),
          value: 'old_value'
        },
        {
          text: this.$t('product.generalConfiguration.alterationHeader.newValue'),
          value: 'new_value'
        },
        {
          text: this.$t('product.generalConfiguration.alterationHeader.date'),
          value: 'created_at'
        },
        {
          text: this.$t('product.generalConfiguration.alterationHeader.status'),
          value: 'status'
        },
        {
          text: this.$t('product.generalConfiguration.alterationHeader.comments'),
          value: 'comments'
        }
      ],
      productCopy: {},
      categories: [],
      selectedCategories: null,
      catalogs: [],
      selectedCatalog: this.product.catalogs,
      attributesToCheck: [
        {
          name: this.$t('product.generalConfiguration.sku'),
          description: this.$t('product.generalConfiguration.skuDescription'),
          condition: this.product.user_defined_id?.trim().match("^(?=.*[A-Z])(?=.*[0-9])[A-Z0-9]+$") && this.product.user_defined_id.length >= 3
        },
        {
          name: this.$t('product.generalConfiguration.shortDescription'),
          description: this.$t('product.generalConfiguration.shortDescriptionDescription'),
          condition: this.product.description_short?.trim().length > 0 && this.product.description_short.length < 500
        },
        {
          name: this.$t('product.generalConfiguration.description'),
          description: this.$t('product.generalConfiguration.descriptionDescription'),
          condition: this.product.description?.trim().length > 0 && this.product.description.length < 2500
        },
        {
          name: this.$t('product.generalConfiguration.stock'),
          description: this.$t('product.generalConfiguration.stockDescription'),
          condition: this.product.stock > 0
        },
        {
          name: this.$t('product.generalConfiguration.categories'),
          description: this.$t('product.generalConfiguration.categoryDescription'),
          condition: this.product.categories?.length > 0
        },
        {
          name: this.$t('product.generalConfiguration.price'),
          description: 'O produto deve possuir um preço definido.',
          condition: this.product.prices?.length > 0
        },
        {
          name: this.$t('product.generalConfiguration.ncm'),
          description: this.$t('product.generalConfiguration.ncmDescription'),
          condition: this.product.ncm?.trim().length > 0
        },
        {
          name: this.$t('product.generalConfiguration.images'),
          description: this.$t('product.generalConfiguration.imagesDescription'),
          condition: this.product.image_sets?.length > 0
        },
        {
          name: this.$t('product.generalConfiguration.dimensions'),
          description: this.$t('product.generalConfiguration.dimensionsDescription'),
          condition: this.product.width != null && this.product.height != null && this.product.weight != null && this.product.length != null
        },
        {
          name: this.$t('product.generalConfiguration.classification'),
          description: this.$t('product.generalConfiguration.classificationDescription'),
          condition: this.product.classification?.find((classification) => {
            return classification.value == null || classification.value === ''
          }) == null
        }
      ]
    }
  },
  created() {
    this.productCopy = Object.assign({}, this.product)
  },
  methods: {
    publishProduct() {
      if (this.productCopy.published === false) {
        this.unpublishProduct()
        return;
      }
      this.axios.post(`/product/${this.productCopy.id}/publish`, {}).then(() => {
        this.$emit('update')
        this.$notify('Produto publicado com sucesso')
      }).catch(() => {
        this.$notify('Erro ao publicar o produto')
        this.productCopy.published = false
      })
    },
    unpublishProduct() {
      this.axios.post(`/product/${this.productCopy.id}/unpublish`, {}).then(() => {
        this.$emit('update')
        this.$notify('Produto despublicado com sucesso')
      }).catch(() => {
        this.$notify('Erro ao despublicar o produto')
        this.productCopy.published = true
      })
    },
    sendForApproval() {
      //check if all the attributes are valid
      let valid = true;
      this.attributesToCheck.forEach((attribute) => {
        if (!attribute.condition) {
          valid = false;
        }
      });
      if (!valid) {
          this.$notify('Envio cancelado');
      } else {
        this.sendApproval()
      }
    },
    sendApproval() {
      this.axios.put(`/product/${this.productCopy.id}/improve`).then(() => {
        this.$notify('Produto enviado para aprovação');
        this.$emit('update')
      }).catch(() => {
        this.$notify('Erro ao enviar produto para aprovação');
      });
    },
    updateCatalogs() {
      this.axios.post(`/product/${this.productCopy.id}/catalogs`, {
        catalogs: this.selectedCatalog
      }).then(() => {
        this.$notify('Catalogos atualizados com sucesso')
      }).catch(() => {
        this.$notify('Erro ao atualizar catalogos')
      })
    },

    updateCategories() {
      this.axios.post(`/product/${this.productCopy.id}/categories`, {
        categories: this.productCopy.categories
      }).then(() => {
        this.$notify('Categorias atualizadas com sucesso')
      }).catch(() => {
        this.$notify('Erro ao atualizar categorias')
      })
    },
    getCategories() {
      this.axios.get('/categories').then(response => {
        //map id as key
        this.categories = response.data;

        if (this.productCopy.categories.length > 0) {
          //get only name and id from categories
          this.selectedCategories = this.productCopy.categories.map((category) => {
            return {id: category.id, name: category.name}
          })
        }
      })
    },
    getCatalogs() {
      this.axios.get('/catalogs').then(response => {

        //map id as key
        this.catalogs = response.data;

        if (this.productCopy.catalogs.length > 0) {
          //get only name and id from categories
          this.selectedCatalog = this.productCopy.catalogs.map((catalog) => {
            return {id: catalog.id, name: catalog.name}
          })
        }
      })
    }
  },
  mounted() {
    this.getCategories()
    this.getCatalogs()
  },
  components: {
    EasyDataTable,
    VueMultiselect,
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>