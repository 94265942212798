<template>
  <div class="mall-form-checkbox">
    <label :for="'field-'+field.hashId" class="mall-label">
      <input type="checkbox" class="mall-input-checkbox" :id="'field-'+ field.hashId "
             :name="'fields['+field.hashId+']'"
             data-mall-custom-field
             :data-id="field.hashId">
      {{ field.name }}
    </label>

  </div>
</template>

<script>
export default {
  name: 'checkboxComponent',
  props: {
    field: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },

}
</script>

<style lang="scss" scoped>

</style>