<script>
export default {
  name: "DropdownSelect",
  data() {
    return {
      validOptions: [],
      selectedValue: this.value,
    }
  },
  methods: {
    updateValue() {
      this.$emit('input', this.selectedValue); // Emit the change
    },
    setOrFetch() {
      if (this.asyncUrl) {
        let url = this.asyncUrl
        if (this.dependsOnValue) {
          url = `${this.asyncUrl}/${this.dependsOnValue}`
        }
        this.axios.get(url).then((response) => {
          this.validOptions = response.data
          if (this.selectValue) {
            this.selectedValue = this.selectValue
            this.updateValue()
          }
        })
      } else {
        this.validOptions = this.options
      }
    }
  },
  watch: {
    value(newVal) {
      this.selectedValue = newVal; // Update local data when prop changes
    },
    dependsOnValue() {
      this.setOrFetch()
    }
  },
  props: {
    value: {
      type: String,
      default: null
    },
    selectValue: {},
    options: {
      type: Array,
      default: () => {
        return []
      }
    },
    asyncUrl: {
      type: String,
      default: null
    },
    dependsOnValue: {
      default: null
    }
  },
  beforeMount() {
    this.setOrFetch()
  }
}
</script>

<template>
  <select class="form-control" v-model="selectedValue" @change="updateValue">
    <option value="" disabled selected>Não Selecionado</option>
    <option :value="option.id" v-for="(option, index) in validOptions" :key="'opt'+index">
      {{ option.name }}
    </option>
  </select>
</template>

<style scoped>

</style>