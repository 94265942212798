<template>
  <div class="col-md-6">
    <div class="card info-box">
      <div class="card-header d-flex justify-content-between">
        <strong>Vendas</strong>
      </div>
      <bar-chart :chartData="chartData" :height="280"></bar-chart>
    </div>
  </div>
</template>
<script>
import {BarChart} from 'vue-chart-3';
import {Chart, registerables} from "chart.js";
import {useCashierStore} from "@/store/cashierStore";

Chart.register(...registerables);
export default {
  name: 'ChartTransactions',
  setup() {
    const cashierStore = useCashierStore()
    return {cashierStore}
  },
  data() {
    return {
      chartData: {
        labels: [
          this.$t("app.daysOfWeek.sunday"),
          this.$t("app.daysOfWeek.monday"),
          this.$t("app.daysOfWeek.tuesday"),
          this.$t("app.daysOfWeek.wednesday"),
          this.$t("app.daysOfWeek.thursday"),
          this.$t("app.daysOfWeek.friday"),
          this.$t("app.daysOfWeek.saturday"),
        ],
        datasets: this.cashierStore.getDataForChart
      },
    }
  },
  components: {
    BarChart
  }
}
</script>
<style scoped>

.info-box {
  max-height: 350px;
  overflow-y: auto;
}

</style>