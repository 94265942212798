<template>
  <div class="p-4">
  <table class="table table-shopping">
    <thead>
    <tr>
      <th>Produto</th>
      <th class="th-description">Qtd</th>
      <th>Completar</th>
    </tr>
    </thead>
    <tbody>
    <Product @error="errorFunction" :data="product" v-for="product in data" :key="product.id"/>
    </tbody>
  </table>
  </div>
</template>
<script>
import Product from "./Product.vue";

export default {
  name: 'ProductsComponent',
  components: { Product },
  props: ['data'],
  data() {
    return { duration: 1600 }
  },
  methods: {
    errorFunction: function(error) {
      this.$emit('error', error);
    }
  }
}
</script>
