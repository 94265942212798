<template>
  <div class="card p-4">
    <div class="row">
      <div class="col-md-6">
        <Form @submit="calculate">
          <div class="form-group">
            <label for="cost">{{ $t('product.prices.markup.cost') }}</label>
            <Field name="cost" :label="$t('product.prices.markup.cost')" class="form-control" v-model="cost"
                   :placeholder="$t('product.prices.markup.costPlaceholder')"
                   rules="required"/>
            <ErrorMessage name="cost" class="text-danger"/>
          </div>
          <div class="form-group">
            <label for="packaging">{{ $t('product.prices.markup.packaging') }}</label>
            <Field name="packaging" :label="$t('product.prices.markup.packaging')" class="form-control"
                   v-model="packaging"
                   :placeholder="$t('product.prices.markup.packagingPlaceholder')"
                   rules="required"/>
            <ErrorMessage name="packaging" class="text-danger"/>
          </div>
          <div class="form-group">
            <label for="shipping">{{ $t('product.prices.markup.shipping') }}</label>
            <Field name="shipping" :label="$t('product.prices.markup.shipping')" class="form-control" v-model="shipping"
                   :placeholder="$t('product.prices.markup.shippingPlaceholder')"
                   rules="required"/>
            <ErrorMessage name="shipping" class="text-danger"/>
          </div>
          <div class="form-group">
            <label for="profit">{{ $t('product.prices.markup.profit') }}</label>
            <Field name="profit" :label="$t('product.prices.markup.profit')" class="form-control" v-model="profit"
                   :placeholder="$t('product.prices.markup.profitPlaceholder')"
                   rules="required"/>
            <ErrorMessage name="profit" class="text-danger"/>
          </div>
          <div class="form-group">
            <label for="commissions">{{ $t('product.prices.markup.commissions') }}</label>
            <Field name="commissions" disabled :label="$t('product.prices.markup.commissions')" class="form-control"
                   v-bind="commissions" :value="commissions"
                   :placeholder="$t('product.prices.markup.commissionsPlaceholder')"/>
            <ErrorMessage name="commissions" class="text-danger"/>
          </div>
          <div class="form-group">
            <label for="simpleTax">{{ $t('product.prices.markup.simpleTax') }}</label>
            <Field name="simpleTax" :label="$t('product.prices.markup.simpleTax')" class="form-control"
                   v-model="simpleTax" :placeholder="$t('product.prices.markup.simpleTaxPlaceholder')"/>
            <ErrorMessage name="simpleTax" class="text-danger"/>
          </div>
          <div class="form-group">
            <label for="icmsTax">{{ $t('product.prices.markup.icmsTax') }}</label>
            <Field name="icmsTax" :label="$t('product.prices.markup.icmsTax')" class="form-control" v-model="icmsTax"
                   :placeholder="$t('product.prices.markup.icmsTaxPlaceholder')"
            />
            <ErrorMessage name="icmsTax" class="text-danger"/>
          </div>
          <div class="form-group">
            <label for="pisTax">{{ $t('product.prices.markup.pisTax') }}</label>
            <Field name="pisTax" :label="$t('product.prices.markup.pisTax')" class="form-control" v-model="pisTax"
                   :placeholder="$t('product.prices.markup.pisTaxPlaceholder')"
            />
            <ErrorMessage name="pisTax" class="text-danger"/>
          </div>
          <div class="form-group">
            <label for="cofinsTax">{{ $t('product.prices.markup.cofinsTax') }}</label>
            <Field name="cofinsTax" :label="$t('product.prices.markup.cofinsTax')" class="form-control"
                   v-model="cofinsTax" :placeholder="$t('product.prices.markup.cofinsTaxPlaceholder')"/>
            <ErrorMessage name="cofinsTax" class="text-danger"/>
          </div>
          <div class="form-group mt-3">
            <button type="submit" class="btn btn-primary">{{$t('product.prices.markup.calculate')}}</button>
          </div>

        </Form>
      </div>
      <div class="col-md-6">
        <div class="card text-white mb-3 fadeAnimation" :class="'bg-'+alertType">
          <div class="card-header fadeAnimation" :class="'bg-'+alertType">
            <h3>{{ $t('product.prices.suggested_price') }}</h3>
            <h4>{{ jsonResult.totalCost }}</h4>
          </div>

          <div class="card-body">
            <div class="row">
            <div class="col-md-6">
              <h5 class="card-title">{{ $t('product.prices.factor') }}</h5>
              <p class="card-text"> {{ jsonResult.factor }}</p>
              <h5 class="card-title">{{ $t('product.prices.markup.profit') }}</h5>
              <h3 class="card-text">{{ jsonResult.totalProfit }}</h3>
              <h5 class="card-title">{{ $t('product.prices.markup.commissions') }}</h5>
              <h3 class="card-text">{{ jsonResult.commissionAmount }}</h3>
              <h5 class="card-title">{{ $t('product.prices.markup.simpleTax') }}</h5>
              <h3 class="card-text">{{ jsonResult.simpleTaxAmount }}</h3>
              <h5 class="card-title">{{ $t('product.prices.markup.icmsTax') }}</h5>
              <h3 class="card-text">{{ jsonResult.icmsTaxAmount }}</h3>
              <h5 class="card-title">{{ $t('product.prices.markup.pisTax') }}</h5>
              <h3 class="card-text">{{ jsonResult.pisTaxAmount }}</h3>
              <h5 class="card-title">{{ $t('product.prices.markup.cofinsTax') }}</h5>
              <h3 class="card-text">{{ jsonResult.cofinsTaxAmount }}</h3>
            </div>
            </div>
          </div>
          <div class="card-footer fadeAnimation" v-if="alertMsg" :class="'bg-'+alertType">
            <p>{{ alertMsg }}</p>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {vMaska} from "maska";
import {Form, Field, ErrorMessage} from "vee-validate"

export default {
  name: "MarkupCalculate",
  props: {
    commissions: {
      type: Number,
      required: true
    },
  },
  directives: {maska: vMaska},
  components: {
    Form,
    Field,
    ErrorMessage
  },
  data() {
    return {
      alertType: "success",
      alertMsg: "",
      cost: '',
      packaging: '',
      shipping: '',
      profit: '',
      simpleTax: '',
      icmsTax: '',
      pisTax: '',
      cofinsTax: '',
      jsonResult: {}
    };
  },
  methods: {
    formatInput(value) {
      return isNaN(value) ? 0 : Number(value);
    },

    formatToCurrency(value) {
      return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
    },
    formatToPercentage(value) {
      return value.toLocaleString('pt-BR', {style: 'percent', minimumFractionDigits: 2});
    },
    formatToNumber(value) {
      return value.toLocaleString('pt-BR', {minimumFractionDigits: 2});
    },
    calculate() {
      const formattedCost = this.formatInput(this.cost);

      const formattedPackaging = this.formatInput(this.packaging);

      const formattedShipping = this.formatInput(this.shipping);

      const formattedProfit = this.formatInput(this.profit);

      const formattedCommissions = this.commissions

      const formattedSimpleTax = this.formatInput(this.simpleTax);

      const formattedIcmsTax = this.formatInput(this.icmsTax);

      const formattedPisTax = this.formatInput(this.pisTax);

      const formattedCofinsTax = this.formatInput(this.cofinsTax);

      const totalPercentage = formattedProfit + formattedCommissions + formattedSimpleTax + formattedIcmsTax + formattedPisTax + formattedCofinsTax;

      if (formattedCost === "") {
        this.alertMsg = "Please, enter a cost!";
        this.alertType = "danger";
        return;
      }

      if (formattedProfit === "") {
        this.alertMsg = "Please, enter a profit!";
        this.alertType = "danger";
        return;
      }
      if (totalPercentage > 100) {
        this.alertMsg = "The sum of all percentages cannot be greater than 100!";
        this.alertType = "warning";
        return;
      }
      const factor = 100 / (100 - totalPercentage);

      if (factor > 6 && factor < 8) {
        this.alertMsg = this.$t('product.prices.markupWarning');
        this.alertType = "warning"
      } else if (factor > 8) {
        this.alertMsg = this.$t('product.prices.markupHighWarning');
        this.alertType = "danger"
      } else {
        this.alertMsg = ""
        this.alertType = "success"
      }

      const totalAmount = (formattedCost + formattedPackaging + formattedShipping) / (1 - (formattedProfit + formattedCommissions + formattedSimpleTax + formattedIcmsTax + formattedPisTax + formattedCofinsTax) / 100);

      this.jsonResult = {
        totalCost: this.formatToCurrency(totalAmount),
        factor: this.formatToNumber(factor),
        totalProfit: this.formatToCurrency(totalAmount * (formattedProfit / 100)),
        commissionAmount: this.formatToCurrency(totalAmount * (formattedCommissions / 100)),
        simpleTaxAmount: this.formatToCurrency(totalAmount * (formattedSimpleTax / 100)),
        icmsTaxAmount: this.formatToCurrency(totalAmount * (formattedIcmsTax / 100)),
        pisTaxAmount: this.formatToCurrency(totalAmount * (formattedPisTax / 100)),
        cofinsTaxAmount: this.formatToCurrency(totalAmount * (formattedCofinsTax / 100)),
        // Additional
        rawCost: this.formatToCurrency(formattedCost),
        rawPackaging: this.formatToCurrency(formattedPackaging),
        rawShipping: this.formatToCurrency(formattedShipping),
        profitPercentage: this.formatToPercentage((totalAmount * (formattedProfit / 100) / totalAmount) * 100),
        commissionPercentage: this.formatToPercentage((totalAmount * (formattedCommissions / 100) / totalAmount) * 100),
        simpleTaxPercentage: this.formatToPercentage((totalAmount * (formattedSimpleTax / 100) / totalAmount) * 100),
        icmsTaxPercentage: this.formatToPercentage((totalAmount * (formattedIcmsTax / 100) / totalAmount) * 100),
        pisTaxPercentage: this.formatToPercentage((totalAmount * (formattedPisTax / 100) / totalAmount) * 100),
        cofinsTaxPercentage: this.formatToPercentage((totalAmount * (formattedCofinsTax / 100) / totalAmount) * 100),
      };
    }
  }
};
</script>
