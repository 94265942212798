<template>
  <div class="container bootstrap snippets bootdey border-0 p-3" v-auto-animate>
    <div class="panel-body inf-content">
      <div class="row">
        <div class="col-md-12" v-auto-animate>
          <div class="row">
            <div class="col-md-12 d-flex justify-content-between">
              <div class="col-md-6">
                <strong>Informações do cliente</strong>
              </div>
              <div class="col-md-6 d-flex justify-content-end">
                <button @click.prevent="show =  !show" class="bg-transparent border-0 text-warning"><i class="fa fa-2x"
                                                                                                       :class="{ 'fa-eye':!show,'fa-eye-slash':show }"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="row g-2" v-if="show">
            <div class="col-md-3 card">
              <div class="card-header">
                <h3 class="card-title">Nome</h3>
              </div>
              <div class="card-body">
                <p class="card-text bold">{{ data.customer.firstname + ' ' + data.customer.lastname }}</p>
              </div>
            </div>
            <div class="col-md-3 card">
              <div class="card-header">
                <h3 class="card-title">Documento</h3>
              </div>
              <div class="card-body">
                <p class="card-text bold">{{ data.customer.document }}</p>
              </div>
            </div>
            <div class="col-md-3 card">
              <div class="card-header">
                <h3 class="card-title">Forma de Pagamento</h3>
              </div>
              <div class="card-body">
                <p class="card-text bold">{{ data.order.payment.method.name + ' - ' + data.order.parcels + 'x' }}</p>
              </div>
            </div>
            <div class="col-md-3 card">
              <div class="card-header">
                <h3 class="card-title">Telefone</h3>
              </div>
              <div class="card-body">
                <p class="card-text bold">( {{ data.customer.areacode }} ) {{ data.customer.phone }}</p>
              </div>
            </div>
            <div class="col-me-12 card" v-if="documentIsCnpj && documentData !== null">
              <div class="card-header">
                <h3 class="card-title">Empresa</h3>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6">
                    <p class="card-text"><strong>{{ $t('cnpjRaiz') }}:</strong> {{ documentData.cnpj_raiz }}</p>
                    <p class="card-text"><strong>{{ $t('razaoSocial') }}:</strong> {{ documentData.razao_social }}</p>
                    <p class="card-text"><strong>{{ $t('capitalSocial') }}:</strong> {{ documentData.capital_social }}</p>
                    <p class="card-text"><strong>{{ $t('atualizadoEm') }}:</strong> {{ formatDate(documentData.atualizado_em) }}</p>
                    <p class="card-text"><strong>{{ $t('porte') }}:</strong> {{ documentData.porte?.descricao }}</p>
                    <p class="card-text"><strong>{{ $t('naturezaJuridica') }}:</strong> {{ documentData?.natureza_juridica?.descricao }}</p>
                    <p class="card-text"><strong>{{ $t('qualificacaoDoResponsavel') }}:</strong> {{ documentData?.qualificacao_do_responsavel?.descricao }}</p>
                  </div>
                  <div class="col-md-6">
                    <p class="card-text"><strong>{{ $t('atividadePrincipal') }}:</strong> {{ documentData.estabelecimento?.atividade_principal?.descricao }}</p>
                    <p class="card-text"><strong>{{ $t('situacaoCadastral') }}:</strong> {{ documentData.estabelecimento.situacao_cadastral }}</p>
                    <p class="card-text"><strong>{{ $t('emailField') }}:</strong> {{ documentData.estabelecimento.email }}</p>
                  </div>
                </div>

                <!-- Estabelecimento -->
                <div class="row mt-3">
                  <div class="col-12">
                    <h6>{{ $t('estabelecimento') }}</h6>
                    <p class="card-text"><strong>{{ $t('cnpj') }}:</strong> {{ documentData.estabelecimento.cnpj }}</p>
                    <p class="card-text"><strong>{{ $t('tipo') }}:</strong> {{ documentData.estabelecimento.tipo }}</p>
                    <p class="card-text"><strong>{{ $t('nomeFantasia') }}:</strong> {{ documentData.estabelecimento.nome_fantasia }}</p>
                    <p class="card-text"><strong>{{ $t('situacaoCadastral') }}:</strong> {{ documentData.estabelecimento.situacao_cadastral }}</p>
                    <p class="card-text"><strong>{{ $t('dataSituacaoCadastral') }}:</strong> {{ formatDate(documentData.estabelecimento.data_situacao_cadastral) }}</p>
                    <p class="card-text"><strong>{{ $t('dataInicioAtividade') }}:</strong> {{ formatDate(documentData.estabelecimento.data_inicio_atividade) }}</p>
                  </div>
                </div>

                <!-- Atividades Secundárias -->
                <div class="row mt-3">
                  <div class="col-12">
                    <h6>{{ $t('atividadesSecundarias') }}</h6>
                    <ul class="list-group">
                      <li class="list-group-item" v-for="atividade in documentData.estabelecimento.atividades_secundarias" :key="atividade.id">
                        {{ atividade.descricao }}
                      </li>
                    </ul>
                  </div>
                </div>

                <!-- Simples Nacional -->
                <div class="row mt-3" v-if="documentData.simples">
                  <div class="col-12">
                    <h6>{{ $t('simplesNacional') }}</h6>
                    <p class="card-text"><strong>{{ $t('simples') }}:</strong> {{ documentData?.simples?.simples }}</p>
                    <p class="card-text"><strong>{{ $t('dataOpcaoSimples') }}:</strong> {{ formatDate(documentData.simples.data_opcao_simples) }}</p>
                    <p class="card-text"><strong>{{ $t('dataExclusaoSimples') }}:</strong> {{ formatDate(documentData.simples.data_exclusao_simples) }}</p>
                    <p class="card-text"><strong>{{ $t('mei') }}:</strong> {{ documentData.simples.mei }}</p>
                    <p class="card-text"><strong>{{ $t('dataOpcaoMei') }}:</strong> {{ formatDate(documentData.simples.data_opcao_mei) }}</p>
                    <p class="card-text"><strong>{{ $t('dataExclusaoMei') }}:</strong> {{ formatDate(documentData.simples.data_exclusao_mei) }}</p>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-12">
                    <h6>{{ $t('inscricoesEstaduais') }}</h6>
                    <ul class="list-group">
                      <li class="list-group-item" v-for="inscricao in documentData.inscricoes_estaduais" :key="inscricao.inscricao_estadual">
                        {{ inscricao.inscricao_estadual }} ({{ inscricao.estado.sigla }})
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 card">
              <div class="card-header">
                <h3 class="card-title">Endereço</h3>
              </div>
              <div class="card-body">
                <p class="card-text bold">
                  {{
                    data.address.lines + ', ' + data.address.number + ' - ' + data.address.district + ' - ' + data.address.city + ' - ' + data.address.state.name + ' - ' + data.address.country.name
                  }}</p>
                <GoogleMap id="world-map-markers" :center="center" v-if="center" :zoom="15"
                           api-key="AIzaSyDYr2ToaFPuUjMAxeM0rrAQZ5L2OwG7AVg">
                  <Marker :options="{
                    position: center,
                    label: 'L'
                  }"/>
                </GoogleMap>

              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {GoogleMap, Marker} from "vue3-google-map";
import axios from "axios";

export default {
  name: "CustomerInfo",
  components: {
    GoogleMap,
    Marker
  },
  props: {
    data: {}
  },
  data() {
    return {
      show: false,
      documentData: null
    }
  },
  methods: {
    formatDate(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(date).toLocaleDateString(undefined, options);
    },
    async searchCnpj() {
        try {
          console.log('entered')
          let response = await axios.get(`https://publica.cnpj.ws/cnpj/${this.data.customer.document}`)
          this.documentData = response.data
        } catch (e) {
          console.log(e)
          this.documentData = null
        }

    }
  },
  computed: {
    documentIsCnpj() {
      return this.data.customer.document.length === 14
    },
    center() {
      if (!this.data.address.ltd || !this.data.address.lng) {
        return null
      }
      return {
        lat: parseFloat(this.data.address.ltd),
        lng: parseFloat(this.data.address.lng)
      }
    }
  },
  mounted() {
    console.log('getted2')
    if (this.documentIsCnpj) {
      console.log('getted')
      this.searchCnpj()
    }
  }
}
</script>

<style scoped>
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter, .slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

#world-map-markers {
  height: 400px;
}
</style>