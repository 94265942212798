<template>
  <div class="container-fluid mt-3">
    <div class="row">
      <div class="col-md-12 h-50">
        <div class="card">
          <div class="card-header d-flex justify-content-between">
            <h5>Pacote de imagens</h5>
            <button class="btn btn-primary float-right" @click="createNewImageBox">Adicionar novo pacote</button>
          </div>
          <div class="card-body row">
            <transition-group class="row" name="bounce" tag="p">
              <div class="col-lg-4" :key="imageBox.id" v-for="imageBox in product.image_sets">

                <div class="card">
                  <ImageComponent
                      :width="260"
                      :height="273"
                      hash="LdHfL}oJR$WBKnfi%3ofT0kCM{ay"
                      :class="'card-img img-fluid'"
                      v-if="imageBox.images[0]"
                      :src="imageBox.images[0].path"
                      :alt="imageBox.images[0].file_name"
                  />
                  <ImageComponent
                      :class="'card-img img-fluid'"
                      :width="260"
                      :height="273"
                      hash="LdHfL}oJR$WBKnfi%3ofT0kCM{ay"
                      v-else
                      src="https://dummyimage.com/260x273/000/fff.png&text=Nenhuma+Imagem+neste+pacote"
                      alt="Nenhuma Imagem"
                  />
                  <div class="card-img-overlay bg-opacity-50 bg-black  ">
                    <div class="col-md-12">
                      <h4 class="card-title text-white mt-0">{{ imageBox.name }}</h4>
                    </div>
                    <div class="col-md-12 d-flex justify-content-between">
                      <button type="button" v-if="product.image_sets.length > 1 && !imageBox.is_main_set"
                              class="btn btn-danger btn-sm" @click="deleteImageSet(imageBox.id)">
                        <i class="fas fa-trash"></i>
                      </button>
                      <button type="button" class="btn btn-primary btn-sm"
                              v-if="activeImageSet == null|| activeImageSet.id != imageBox.id"
                              @click="setActive(imageBox)">
                        <i class="fas fa-folder-open"></i>
                      </button>
                    </div>

                  </div>
                </div>
              </div>
            </transition-group>
          </div>
        </div>
      </div>
      <transition name="fade">
        <div class="col-md-12" v-if="activeImageSet">
          <div class="card">
            <div class="card-header  d-flex justify-content-between">
              <h5>{{ activeImageSet.name }}</h5>
              <div class="gap-2 d-flex">
                <button type="button" class="btn btn-danger btn-sm" @click="closeImageSet">
                  <i class="fas fa-times"></i>
                </button>
                <button type="button" v-if="activeImageSet.is_main_set == 0 && activeImageSet.images.length != 0"
                        class="btn btn-primary btn-sm"
                        @click="setMainImageSet">
                  <i class="fas fa-star"></i>
                </button>
              </div>
            </div>
            <div class="card-body">
              <transition-group class="row" name="bounce" tag="p">
                <div class="col-md-2 p-1" :key="image.id" v-for="image in activeImageSet.images">
                  <ImageComponent
                      :class="'card-img img-fluid'"
                      width="70"
                      height="70"
                      :hash="image.title != null ? image.title :'LdHfL}oJR$WBKnfi%3ofT0kCM{ay'"
                      :src="image.description != 'deleting' ? image.path : 'https://dummyimage.com/260x273/000/fff.png&text=Apagando'"
                      :alt="image.name"
                  />
                  <div class="card-img-overlay bg-opacity-50 bg-black  ">
                    <div class="col-md-12 d-flex justify-content-between">
                      <button v-if="image.description != 'deleting'" type="button" class="btn btn-danger btn-sm"
                              @click="deleteImageFromSet(image.id)">
                        <i class="fas fa-trash"></i>
                        <!--                        SORT UP AND DOWN BUTTON-->
                      </button>
                    </div>
                  </div>

                </div>

              </transition-group>
              <div class="col-md-12">

                <div class="alert alert-danger mt-3 position-relative" role="alert"
                     v-for="(alert,index) in alertMessages" :key="'alert'+index">
                  {{ alert.image[0] }}
                </div>
                <transition name="fade">
                  <UploadImages v-show="uploading == false" ref="image" v-model="imagesToUpload" class="mt-3"
                                @changed="handleImage" :max="10"/>
                </transition>
                <transition name="fade">
                  <div class="d-flex justify-content-center mt-3" v-if="uploading">
                    <div class="spinner-border text-primary" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </transition>

              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import UploadImages from "vue-upload-drop-images"
import ImageComponent from "../../helper/lazyload/ImageComponent.vue";


export default {
  name: "AttachamentComponent",
  props: {
    product: {}
  },
  data() {
    return {
      activeImageSet: null,
      imagesToUpload: [],
      alertMessages: [],
      uploading: false
    }
  },
  methods: {
    setMainImageSet() {
      if (this.activeImageSet) {
        this.$confirm(
            {
              message: 'Tem certeza que deseja definir este pacote como principal?',
              button: {
                no: 'Não',
                yes: 'Sim'
              },
              callback: confirm => {
                if (confirm) {
                  this.axios.post(`/product/${this.product.id}/image-set/${this.activeImageSet.id}/set-main`)
                      .then(() => {
                        this.$notify('Pacote definido como principal com sucesso!')
                        this.activeImageSet.is_main_set = 1
                        this.product.image_sets.forEach(imageSet => {
                          if (imageSet.id === this.activeImageSet.id) {
                            imageSet.is_main_set = 1
                          } else {
                            imageSet.is_main_set = 0
                          }
                        })
                      })
                      .catch(() => {
                        this.$notify('Ocorreu um erro ao definir o pacote como principal')
                      })
                }
              }
            }
        )
      }
    },
    deleteImageFromSet(imageId) {
      this.$confirm(
          {
            message: 'Tem certeza que deseja apagar esta imagem?',
            button: {
              no: 'Não',
              yes: 'Sim'
            },
            /**
             * Callback Function
             * @param {Boolean} confirm
             */
            callback: confirm => {
              if (confirm) {
                this.activeImageSet.images.find(image => image.id === imageId).description = 'deleting'
                this.axios.delete(`/product/${this.product.id}/image-set/${this.activeImageSet.id}/deleteImage/${imageId}`)
                    .then(() => {
                      this.activeImageSet.images = this.activeImageSet.images.filter(image => image.id !== imageId)
                      this.update()
                      this.$notify('Imagens apagada com sucesso')
                    }).catch(() => {
                  this.$notify('Erro ao apagar imagem')
                  this.activeImageSet.images.find(image => image.id === imageId).description = ''
                })
              }
            }
          }
      )

    },
    handleImage: function (files) {
      if (files.length > 0) {
        this.uploading = true
        this.imagesToUpload = files
        this.alertMessages = []
        let formData = new FormData();
        for (let i = 0; i < files.length; i++) {
          formData.append('images[' + i + ']', files[i]);
        }
        this.axios.post(`/product/${this.product.id}/image-set/${this.activeImageSet.id}/addImage`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
        ).then((response) => {
          // clear files to upload
          console.log(response.data)
          this.imagesToUpload = []

          this.$refs.image.reset()
          // this.update()
          this.activeImageSet = response.data.imageSet
          if (response.data.alerts != null) {
            this.$notify({
              title: 'Atenção',
              text: 'Imagens enviadas porem Algumas falharam, Verifique a mensagem de resposta e tente novamente',
              type: 'warn'
            })
            this.alertMessages = response.data.alerts
          } else {
            this.$notify('Imagens enviadas com sucesso')
          }
        }).catch((error) => {
          console.log(error)
          this.$notify('Erro ao enviar imagens')
        }).finally(() => {
          this.uploading = false
        })
      }
    },
    closeImageSet() {
      this.activeImageSet = null
    },
    setActive(imageSet) {
      this.$confirm(
          {
            message: 'Deseja abrir o pacote de imagens ' + imageSet.name + '?',
            button: {
              no: 'Não',
              yes: 'Sim'
            },
            /**
             * Callback Function
             * @param {Boolean} confirm
             */
            callback: confirm => {
              if (confirm) {
                this.activeImageSet = imageSet;
                this.$emit('setActive', imageSet);
              }
            }
          }
      )
    },
    generateRandomHash() {
      // generate rash lenght 24
      let hash = '';
      for (let i = 0; i < 22; i++) {
        hash += Math.floor(Math.random() * 16).toString(16);
      }
      return hash;

    },
    createNewImageBox() {
      this.$confirm({
        message: 'Tem certeza que deseja criar um novo pacote de imagens?', // Mensagem de confirmação
        button: {
          no: 'Não',  // Botão de negação
          yes: 'Sim', // Botão de afirmação
        },
        callback: confirm => {
          if (confirm) {
            // Lógica para criação do pacote de imagens
            this.axios.post(`/product/${this.product.id}/image-set`).then(() => {
              this.$notify('Pacote de imagens criado com sucesso');
              this.update();
            })
          } else {
            // Lógica para cancelamento da ação
            this.$notify('Cancelado');
          }
        }
      });

    },
    deleteImageSet(id) {
      this.$confirm({
        message: 'Tem certeza que deseja excluir este pacote de imagens?', // Mensagem de confirmação
        button: {
          no: 'Não',  // Botão de negação
          yes: 'Sim', // Botão de afirmação
        },
        callback: confirm => {
          if (confirm) {
            // Lógica para exclusão do pacote de imagens
            this.axios.delete(`/product/${this.product.id}/image-set/${id}`).then(() => {
              this.$notify('Pacote de imagens excluído com sucesso');
              this.update();
            })
          } else {
            // Lógica para cancelamento da ação
            this.$notify('Solicitação cancelada');
          }
        }
      });
    },
    update() {
      this.$emit('update')
      //upate active image set
    },

  },
  components: {
    ImageComponent,
    UploadImages
  }
}
</script>

<style scoped>
.bounce-enter-active {
  animation: bounce-in .7s;
}

.bounce-leave-active {
  animation: bounce-in .7s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.20);
  }
  100% {
    transform: scale(1);
  }

}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0
}
</style>