import {useStorage} from "@vueuse/core";
import {defineStore} from "pinia";


export const usePrintPageDetailsStore = defineStore('printPageDetails', {
    state: () => ({
        data: useStorage('printPageDetails', {}),
    }),
    getters: {
        getPrintPageDetails() {
            return this.data;
        }
    },
    actions: {
        setPrintPageDetails(data) {
            this.data = data;
        }
    }

});