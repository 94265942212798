<template>
  <svg width="20px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
       xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<circle style="fill:#00C85F;" cx="256" cy="256" r="256"/>
    <path style="fill:#FFF046;" d="M246.207,103.892L28.673,248.915c-5.241,3.495-5.241,11.196,0,14.69l217.534,145.022
	c5.93,3.954,13.656,3.954,19.586,0l217.534-145.022c5.241-3.495,5.241-11.196,0-14.69L265.793,103.892
	C259.863,99.938,252.137,99.938,246.207,103.892z"/>
    <circle style="fill:#4B82E1;" cx="256" cy="256" r="105.931"/>
    <path style="fill:#FFFFFF;" d="M165.515,201.127c-3.47,5.709-6.403,11.761-8.758,18.111c49.205-3.345,128.166,5.419,197.503,76.211
	c2.472-6.151,4.404-12.57,5.692-19.229C292.095,211.143,216.738,199.457,165.515,201.127z"/>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g></svg>
</template>
<script>
export default {
  name: 'NationalSvg'
}
</script>