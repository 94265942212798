import {defineStore} from "pinia";

import axios from "@/boostrap/apiConfig";
import {ErrorHandler} from "@/utils/ErrorHandler";
export const useReportStore = defineStore({
    id: 'reportStore',
    state: () => ({
        reportsAvailable: [],
        selectedReport: null
    }),
    getters: {
        getReportsAvailable() {
            return this.reportsAvailable;
        },
        getSelectedReport() {
            return this.selectedReport;
        }
    },
    actions: {
        setSelectedReport(report) {
            if (report) {
                this.getReportsAvailable.forEach((item) => {
                    if (item.name === report) {
                        report = item
                    }
                })
                this.selectedReport = report
            }
        },
        fetchReports() {
            return axios.get('reportAvailable').then((response) => {
                this.reportsAvailable = response.data.reports
            }).catch((error) => {
                ErrorHandler.handle(error)
            })
        },
       async  generateReport(type, params){
            return axios.post('report/'+type, {params}).then((response) => {
                return response.data
            }).catch((error) => {
                ErrorHandler.handle(error)
            })

        }
    }
});