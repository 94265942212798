<script>
export default {
  name: "FilterBySearch",
  props: {
    data: {
      required: true
    }
  },
  data() {
    return {
      searchText: ''
    }
  },
  computed: {
    isActive() {
      return this.searchText.length > 0;
    }
  },
  methods: {
    filterData() {
      const asArray = Object.entries(this.data);
      let filtered = []
      // filter orders by delivery or order code
      for (let i = 0; i < asArray.length; i++) {
        for (let j = 0; j < asArray[i][1].length; j++) {
          if (asArray[i][1][j].order.order_number.toString().includes(this.searchText) || asArray[i][1][j].code.toString().includes(this.searchText)) {
            filtered.push(asArray[i][1][j])
          }
        }
      }
      // transform to object again
      let obj = {}
      for (let i = 0; i < filtered.length; i++) {
        if (!Object.prototype.hasOwnProperty.call(obj, filtered[i].delivery_in)) {
          obj[filtered[i].delivery_in] = [];
        }
        obj[filtered[i].delivery_in].push(filtered[i])
      }
      return obj
    }
  },
}
</script>

<template>
  <div class="col-md-4">
    <input type="text" id="search" class="form-control p-2" v-model="searchText"
           placeholder="Procurar">
  </div>

</template>

<style scoped>
#search{
  padding: 10px!important;
}
</style>