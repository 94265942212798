<template>
  <div class="">
    <div class=" card bg-soft-light">
      <div class="card-body">
        <div class="row gap-2">
          <div class=" col-sm-12 col-md-3 text-center ">
            <ImageComponent :src="product.image.path" :alt="product.name" :width="100" :height="100"
                            class="img-fluid rounded-start"/>
          </div>
          <div class=" col-sm-12 col-md-3 text-center text-md-start">
            <h5>{{ product.name }}</h5>

            <span class="font-size-22 text-success text-center">{{
                product.prices.find((price) => {
                  return price.code = 'BRL'
                }).price_formatted
              }}</span>
            <small class="muted d-block">{{product.sku}}</small>
          </div>
          <div class=" col-sm-12 col-md-3">
            <div class="col-md-12 col-xxl-12">
              <QuantityButton @quantity="productQuantityHandler"/>
            </div>
          </div>
          <div class=" col-sm-12 col-md-2">
            <div class="d-flex justify-content-center">
              <button class="add-to-cart w-100 btn btn-primary p-2" style="padding: .655rem!important;" @click="handleAddToCart"><i
                  class="fas fa-shopping-cart"></i></button>
            </div>
          </div>
        </div>
      </div>
      <ModalComponent :ref="'m'+product.id">
        <perfect-scrollbar style="overflow-x:hidden">
          <CustomFieldsPos :quantity="productQuantity" :fields="product.custom_fields" :product="product"
                           @closeModal="closeModal"/>
        </perfect-scrollbar>
      </ModalComponent>
    </div>
  </div>

</template>

<script>
import QuantityButton from "@/components/pdv/products/QuantityButton.vue";

import {usePosStore} from "@/store/PosStore";
import ModalComponent from "@/components/helper/modal/ModalComponent.vue";
import CustomFieldsPos from "@/components/pdv/products/CustomFields.vue";
import ImageComponent from "@/components/helper/lazyload/ImageComponent.vue";


export default {
  name: "PosProduct",
  components: {ImageComponent, CustomFieldsPos, ModalComponent, QuantityButton},
  props: {
    product: {
      type: Object,
      required: true
    },
  },
  setup: () => ({posStore: usePosStore()}),
  data() {
    return {
      showModal: false,
      productQuantity: 1
    }
  },
  methods: {
    closeModal: function () {
      let ref = 'm' + this.product.id;
      this.$refs[ref].closeModal();
    },
    updateCart: function () {
      this.posStore.updateCart()
      let ref = 'm' + this.product.id;
      this.$refs[ref].closeModal();
    },
    productQuantityHandler(quantity) {
      this.productQuantity = quantity
    },
    handleAddToCart: function () {
      let ref = 'm' + this.product.id;
      // if products have additional show modal
      if (this.product.custom_fields.length > 0) {

        this.$refs[ref].openModal();
      } else {
        this.$swal({
          title: 'Aguarde...',
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading()
          }
        })
        this.axios.post('pos/addToCart', {
          product: this.product.hashId,
          cart: this.posStore.getCart.id,
          quantity: this.productQuantity
        }).then(() => {
          this.$notify('Produto adicionado com sucesso')
          this.$refs[ref].closeModal();
          this.posStore.updateCart()
          this.$swal.close()
        }).catch(() => {
          this.$swal.close()
          this.$notify('Erro ao adicionar produto')
        })
      }
    }
  },

}
</script>

<style scoped>
.w-10 {
  width: 5px;
}


.h-90 {
  height: 95%;
}

.controlled-card {
  min-height: 250px;
}


</style>
<style scoped>
:deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

:deep .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}

.modal__title {
  margin: 0 2rem 0 0;
  font-size: 1.5rem;
  font-weight: 700;
}

.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
</style>

<style scoped>
.dark-mode div:deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>