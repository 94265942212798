<template>
  <div class="page-content position-relative  " v-if="!loading">
    <div class="cashier-locked d-flex justify-content-center" v-if="cashierStore.getCashierIsLocked">
      <div class="card p-5 text-center m-auto card-cashier-lock">
        <h1 class="d-block">{{ $t('cashier.alerts.cashierIsLocked') }}</h1>
        <span class="d-block">{{ $t('cashier.alerts.cashierIsLockedDescription') }}</span>
      </div>
    </div>
    <div class="cashier-locked d-flex justify-content-center" v-if="cashierStore.getChangingPerspective">
      <div class="card p-5 text-center m-auto card-cashier-lock">
        <h1 class="d-block">{{ $t('cashier.alerts.changingPerspective') }}</h1>
        <div class="col-12">
          <div class="text-center">
            <div class="spinner-border text-primary m-2" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="alert alert-danger text-center" v-if="!cashierStore.getCashierIsOpen">{{ $t('cashier.alerts.cashierIsClosed') }}</div>
    <div class="row">
      <div class="col-md-3" v-if="authStore.checkRole('systemRole', 'COM')">
        <div class="card cashier-box position-relative">
          <div class="p-2">
            <span class="fa fa-dollar-sign icon-circle"></span>
          </div>
          <div class="mt-auto">
            <div class="p-2">
              <small>{{ $t('cashier.balance.title')}}</small>
              <div class="d-flex justify-content-between">
                <h3 v-currency="totals /100" v-if="showCashier"></h3>
                <h3 v-else>*****</h3>
                <PasswordProtectedButton @onClick="toggleCashier" class="btn btn-transparent btn-sm btn-circle btn-sm">
                  <span class="fa fa-eye " v-if="!showCashier"></span>
                  <span class="fa fa-eye-slash " v-else></span>
                </PasswordProtectedButton>
              </div>
              <small>{{ $t('cashier.balance.missing') }}</small>
              <div class="d-flex justify-content-between">
                <h3 v-currency="(cashierStore.getBalance - totals) /100" v-if="showCashier"></h3>
                <h3 v-else>*****</h3>
                <PasswordProtectedButton @onClick="toggleCashier" class="btn btn-transparent btn-sm btn-circle btn-sm">
                  <span class="fa fa-eye " v-if="!showCashier"></span>
                  <span class="fa fa-eye-slash " v-else></span>
                </PasswordProtectedButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card cashier-box position-relative">
          <div class="p-2">
            <span class="fa fa-chart-line icon-circle"></span>
          </div>
          <div class="mt-auto">
            <div class="p-2">
              <small>{{ $t('cashier.transactions.title')}}</small>
              <h2>{{ cashierStore.getTransactions.length }}</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card cashier-box position-relative">
          <div class="p-2">
            <span class="fa fa-user icon-circle"></span>
          </div>
          <div class="mt-auto">
            <div class="p-2">
              <small>{{ $t('cashier.user') }}</small>
              <CashierPerspective/>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card cashier-box position-relative">
          <div class="p-2">
            <span class="fa fa-running icon-circle"></span>
          </div>
          <div class="mt-auto">
            <div class="p-2 d-flex gap-3">
              <AdditionTransaction/>
              <SubtractTransaction/>
              <PasswordProtectedButton class="btn btn-circle btn-secondary" v-wave v-if="cashierStore.getCashierIsOpen"
                                       @onClick="closeCashier"><span
                  class="fa fa-power-off"></span></PasswordProtectedButton>
              <PasswordProtectedButton class="btn btn-circle btn-primary" @onClick="openCashier" v-wave
                                       v-if="!cashierStore.getCashierIsOpen"><span class="fa fa-power-off"></span>
              </PasswordProtectedButton>
              <PasswordProtectedButton @onClick="toggleCashier" class="btn btn-transparent btn-sm btn-circle btn-sm">
                <span class="fa fa-eye fa" v-if="!showCashier"></span>
                <span class="fa fa-eye-slash fa" v-else></span>
              </PasswordProtectedButton>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <CashierTransactions :cashier-store="cashierStore" :show-cashier="showCashier"/>
          <ChartTransactions v-if="authStore.checkRole('systemRole', 'COM') && !cashierStore.getChangingPerspective"/>

        </div>
      </div>
    </div>
  </div>
  <div v-else class="page-content">
    <div class="col-12">
      <div class="text-center">
        <div class="spinner-border text-primary m-2" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {defineComponent} from 'vue'
import PasswordProtectedButton from "@/components/helper/PasswordProtectedButton.vue";
import {useCashierStore} from "@/store/cashierStore";
import {useAuthStore} from "@/store/authStore";
import CashierTransactions from "@/components/cashier/CashierTransactions.vue";
import ChartTransactions from "@/components/cashier/ChartTransactions.vue";
import AdditionTransaction from "@/components/cashier/AdditionTransaction.vue";
import SubtractTransaction from "@/components/cashier/SubtractTransaction.vue";
import CashierPerspective from "@/components/cashier/CashierPerspective.vue";


export default defineComponent({
  name: "CashierWrapper",
  setup() {
    let cashierStore = useCashierStore()
    let authStore = useAuthStore()
    return {cashierStore, authStore}
  },
  methods: {
    toggleCashier() {
      this.showCashier = !this.showCashier
    },
    openCashier() {
      this.$swal({
        title: 'valor inicial do caixa',
        text: 'digite o valor inicial do caixa incluindo moedas e notas',
        html: '<input id="swal-input1" class="swal2-input" placeholder="350" type="number">',
        focusConfirm: false,
        preConfirm: () => {
          return document.getElementById('swal-input1').value

        }
      }).then((result) => {
        this.loading = true
        if (result.value) {
          this.cashierStore.createTransaction('open-cashier', result.value, true).then(async () => {
            this.$swal({
              title: 'caixa aberto',
              text: 'o caixa foi aberto com sucesso',
              icon: 'success',
              timer: 2000,
              timerProgressBar: true,
              showConfirmButton: false,
            })
            await this.cashierStore.fetchAllData()
          }).catch(() => {
            this.$swal({
              title: 'erro ao abrir o caixa',
              text: 'houve um erro ao abrir o caixa',
              icon: 'error',
              timer: 2000,
              timerProgressBar: true,
              showConfirmButton: false,
            })
          }).finally(() => {
            this.loading = false
          })
        }
      })
    },
    closeCashier() {
      this.$swal({
        title: 'valor Final do caixa',
        text: 'Realize o fechamento do caixa, digite o valor final do caixa incluindo moedas e notas',
        html: '<input id="swal-input1" class="swal2-input" placeholder="350" type="number">',
        focusConfirm: false,
        preConfirm: () => {
          return document.getElementById('swal-input1').value

        }
      }).then((result) => {
        this.loading = true
        if (result.value) {
          this.cashierStore.createTransaction('close-cashier', result.value, true).then(async () => {
            this.$swal({
              title: 'caixa fechado',
              text: 'o caixa foi fechado com sucesso',
              icon: 'success',
              timer: 2000,
              timerProgressBar: true,
              showConfirmButton: false,
            })
            await this.cashierStore.fetchAllData()
          }).catch(() => {
            this.$swal({
              title: 'erro ao fechar o caixa',
              text: 'houve um erro ao fechar o caixa',
              icon: 'error',
              timer: 2000,
              timerProgressBar: true,
              showConfirmButton: false,
            })
          }).finally(() => {
            this.loading = false
          })
        }
      })
    },
    startListenCashierEvents() {
      this.$echo.channel('cashier').listen('.LockUnlockCashierEvent', (e) => {
        this.cashierStore.setIsLocked(e.isLock)
      })
    },

  },
  data() {
    return {
      showCashier: false,
      loading: false,
    }
  },
  components: {
    CashierPerspective,
    SubtractTransaction,
    AdditionTransaction,
    ChartTransactions,
    CashierTransactions,
    PasswordProtectedButton,
  },
  computed: {
    totals() {
      const transactions = this.cashierStore.getTransactions;
      // Filter out transactions where 'closed' is false
      const openTransactions = transactions.filter(transaction => !transaction.closed);

      // Calculate the sum of 'amount' property for open transactions
      const sum = openTransactions.reduce((total, transaction) => total + transaction.amount, 0);

      return sum;
    }
  },
  mounted() {
    this.startListenCashierEvents()
  },
  unmounted() {
    this.$echo.leaveChannel('cashier')
    this.cashierStore.clearAllData()
  },
  beforeMount() {
    this.loading = true
    this.cashierStore.fetchAllData().then(() => {
      this.loading = false
    })

  }
})
</script>

<style scoped>
.card-cashier-lock {
  left: 100px;
}

.cashier-locked {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(16, 16, 16, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
}

.cashier-box {
  height: 200px;
}

.icon-circle {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-circle {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>